import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  id: "input-list"
};
const _hoisted_2 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_input, {
    modelValue: _ctx.search,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.search = $event),
    list: "input-list",
    trim: "",
    onChange: $options.changeSearch,
    disabled: $props.disabled
  }, null, 8, ["modelValue", "onChange", "disabled"]), _createElementVNode("datalist", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminWorkers, (worker, index) => {
    return _openBlock(), _createElementBlock("option", {
      key: index,
      value: `${$options.formatName(worker, $props.fmtName)} id:${worker.id}`
    }, [$props.showInfo ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createTextVNode(_toDisplayString(worker.position) + " / " + _toDisplayString(worker.department_name), 1)], 64)) : _createCommentVNode("", true)], 8, _hoisted_2);
  }), 128))])]);
}