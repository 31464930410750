import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { militaryStatusOptions, profileReadonly } from "@/helpers/profile";
import { setValueTimeout } from "@/helpers/timeout";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "theThirteenth",
  data: () => ({
    optionYesNo,
    militaryStatusOptions,
    military_service_state: null,
    ranks: [],
    military_card_number: "",
    military_commissariat_name: "",
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    endpoint: "military-ranks",
    template: {
      rank: null,
      category: null,
      branch: null,
      military_card_number: null,
      military_commissariat_name: null
    },
    states: {
      rank_state: null,
      category_state: null,
      branch_state: null,
      military_card_number_state: null,
      military_commissariat_name_state: null
    }
  }),
  created() {
    this.profileObjSaved.military_service = this.profileObj.military_service;
    this.profileObjSaved.military_ranks = this.profileObj.military_ranks ? this.profileObj.military_ranks.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
    this.ranks = this.profileObj.military_ranks.map(record => ({
      ...record,
      ...this.states
    }));
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData(key) {
      if (this.profileObjSaved[key] === this.profileObj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        this.profileObjSaved[key] = this.profileObj[key];
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    },
    submitDataEndpoint(obj, key) {
      const value = obj[key];
      if (this.profileObjSaved.military_ranks[obj.id][key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.military_ranks[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.military_ranks[response.id] = {
          ...this.template
        };
        obj.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeDataEndpoint(obj, id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved.military_ranks[id];
        obj.splice(index, 1);
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};