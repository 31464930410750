import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
import { workerToStates, stateWorker } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import AdminWorkersChg from "./AdminWorkersChg.vue";
export default {
  name: "AdminWorkers",
  data: () => ({
    showModalDismiss: false,
    currentItem: {},
    sort: null,
    optionsSort: [{
      value: "last_name,first_name,middle_name",
      text: "по фамилии"
    }, {
      value: "department_name",
      text: "по подразделению"
    }],
    stateWorker,
    fmtNameShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "problem",
      label: ""
    }, {
      key: "worker_code",
      label: "Табельный номер"
    }, {
      key: "FIO",
      label: "ФИО"
    }, {
      key: "department_name",
      label: "Подразделение"
    }, {
      key: "position",
      label: "Должность"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap worker-date-list"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => stateWorker[value]
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center p-0"
    }, {
      key: "btnDismiss",
      label: "Уволить",
      class: "text-center p-0"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsChange: {
      title: "Редактировать работника",
      iconType: "pencil-square"
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminWorkersChg
  },
  created() {
    this.updateAdminWorkers({
      page: 1,
      sort: this.sort
    });
    this.updateDepartments();
  },
  methods: {
    ...mapActions("administration", ["updateAdminWorkers", "stateToAdminWorkers", "changeAdminWorkers"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    formatName,
    // обработка изменения сортировки
    async sortChanged({
      sortBy
    }) {
      await this.updateAdminWorkers({
        page: this.currentPage,
        sort: sortBy
      });
    },
    async clickDismiss(item) {
      this.currentItem = item;
      this.showModalDismiss = true;
    },
    async doDismiss() {
      const fio = formatName(this.currentItem, fmtNameShort);
      await this.stateToAdminWorkers({
        id: this.currentItem.id,
        stateTo: workerToStates.toResigned,
        msg: `Работник ${fio} уволен`
      });
      this.currentPage = 1;
      await this.updateAdminWorkers({
        page: 1,
        sort: this.sort
      });
    },
    async clickChange(data) {
      await this.changeAdminWorkers(data);
      await this.updateAdminWorkers({
        page: this.currentPage,
        sort: this.sort
      });
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminWorkersCount / this.perPage, this.currentPage);
      this.updateAdminWorkers({
        page: this.currentPage,
        sort: this.sort
      });
    }

    // rowClass(item, type) {
    //   if (!item || type !== "row") return;
    //   if (!item.is_safe_email) return "table-danger";
    // },
  },

  computed: {
    ...mapGetters("administration", ["adminWorkers", "adminWorkersLoading", "adminWorkersCount"]),
    ...mapGetters("referenceBooks", ["departmentsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminWorkers({
          page: value,
          sort: this.sort
        });
      }
    },
    disableChange() {
      return !checkPermissions(this.$perm.change_wa_worker);
    }
  }
};