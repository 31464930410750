import { fetchPages } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { getWorkplaces } from "@/auth/auth.js";
import { wrapCommitDispatch } from "@/helpers/global";

const {
  ASSIGNEE,
  ASSIGNEE_LOADING,
  WORKPLACES,
  WORKPLACES_LOADING,
  WORKPLACES_COUNT,
} = mutations;

const userStore = {
  namespaced: true,
  state: {
    assignee: [],
    assigneeLoading: false,
    workplaces: [],
    workplacesCount: 0,
    workplacesLoading: false,
  },
  getters: {
    assignee: ({ assignee }) => assignee,
    assigneeLoading: ({ assigneeLoading }) => assigneeLoading,
    workplaces: ({ workplaces }) => workplaces,
    workplacesCount: ({ workplacesCount }) => workplacesCount,
    workplacesLoading: ({ workplacesLoading }) => workplacesLoading,
  },
  mutations: {
    [ASSIGNEE](state, value) {
      state.assignee = value;
    },
    [ASSIGNEE_LOADING](state, value) {
      state.assigneeLoading = value;
    },
    [WORKPLACES](state, value) {
      state.workplaces = value;
    },
    [WORKPLACES_LOADING](state, value) {
      state.workplacesLoading = value;
    },
    [WORKPLACES_COUNT](state, value) {
      state.workplacesCount = value;
    },
  },
  actions: {
    // запросисть ответственных
    async updateAssignee(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchPages("/workers/", {
            worker_rank__code: "junior",
            department__code: "compliance",
          });
          context.commit(ASSIGNEE, response);
        },
        ASSIGNEE_LOADING
      );
    },

    // запросисть рабочие места
    async updateWorkplaces(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const arrayWorkplaces = Object.values(getWorkplaces(false));
          context.commit(WORKPLACES, arrayWorkplaces);
          context.commit(WORKPLACES_COUNT, arrayWorkplaces.length);
        },
        WORKPLACES_LOADING
      );
    },
  },
};

export default userStore;
