import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import PartnersFormAbout from "./PartnersFormAbout.vue";
import PartnersFormInfo from "./PartnersFormInfo.vue";
import PartnersChecking from "./PartnersChecking.vue";
import PartnersResult from "./PartnersResult.vue";
import { mapGetters, mapActions } from "vuex";
import theNotification from "@/components/theNotification";
import { statesPartners } from "@/helpers/states";
import links from "@/router/links";
export default {
  name: "PartnersFormBase",
  props: {
    msg: {
      type: String,
      required: true
    } // сюда получаем id партнера
  },

  components: {
    BreadCrumbToHome,
    PartnersFormAbout,
    PartnersFormInfo,
    theNotification,
    PartnersChecking,
    PartnersResult
  },
  data: () => ({
    statesPartners
  }),
  created() {
    this.updatePartnerById(this.msg);
  },
  methods: {
    ...mapActions("partners", ["updatePartnerById", "changeStatePartner"]),
    async clickBtnProc(state, msg) {
      await this.changeStatePartner({
        state,
        id: this.msg,
        msg
      });
    }
  },
  computed: {
    ...mapGetters("partners", ["partnerForm", "partnerFormLoading"]),
    disabledStart() {
      return !this.partnerForm.initiator_department || !this.partnerForm.assignee;
    },
    isAdminPath() {
      return this.$route.path.includes(links.adminPartnersForm);
    },
    breadcrumbs() {
      return this.isAdminPath ? [{
        text: "Администрирование",
        to: {
          name: "AdminArea"
        }
      }, {
        text: "Справочник деловых партнеров",
        to: {
          name: "AdminPartners"
        }
      }] : [{
        text: "Проверка деловых партнеров",
        to: {
          name: "Partners"
        }
      }];
    }
  }
};