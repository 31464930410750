import PartnersSingleRisk from "./PartnersSingleRisk.vue";
import PartnersTotalRisk from "./PartnersTotalRisk.vue";
import { mapGetters } from "vuex";
import { statesPartners } from "@/helpers/states";
export default {
  name: "PartnersChecking",
  components: {
    PartnersSingleRisk,
    PartnersTotalRisk
  },
  data: () => ({}),
  methods: {},
  computed: {
    ...mapGetters("partners", ["partnerForm"]),
    firstCheck() {
      return statesPartners[this.partnerForm.state] !== statesPartners.draft;
    },
    secondCheck() {
      return ["forward", "pending", "approved", "declined"].includes(this.partnerForm.state);
    }
  }
};