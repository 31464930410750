import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { stateBackup } from "@/helpers/text.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminBackupAdd from "./AdminBackupAdd.vue";
export default {
  name: "AdminBackup",
  data: () => ({
    currentPage: 1,
    perPage: 10,
    tableFields: [{
      key: "number",
      label: "№"
    }, {
      key: "name",
      label: "Копия"
    }, {
      key: "created",
      label: "Создана",
      formatter: value => {
        return formatDate(value, fmtDateMonthTime);
      },
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => {
        return stateBackup[value];
      }
    }, {
      key: "btnUnpack",
      label: "Восстановить",
      class: "p-0 text-center"
    }, {
      key: "btnRemove",
      label: "Удалить",
      class: "p-0"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminBackupAdd
  },
  created() {
    this.updateAdminBackup({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminBackup", "createAdminBackup", "removeAdminBackup", "unpackAdminBackup"]),
    async clickCreateBackup(data) {
      await this.createAdminBackup(data);
      this.currentPage = 1;
      await this.updateAdminBackup({
        page: 1
      });
    },
    async clickRemove(item) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Удалить резервную копию ${item["name"]} ?`, {
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Удалить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      });
      if (isConfirmed) {
        await this.removeAdminBackup(item);
        this.currentPage = 1;
        await this.updateAdminBackup({
          page: 1
        });
      }
    },
    async clickUnpack(item) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Восстановить резервную копию ${item["name"]} ?`, {
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Восстановить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      });
      if (isConfirmed) {
        await this.unpackAdminBackup(item);
      }
    }
  },
  computed: {
    ...mapGetters("administration", ["adminBackup", "adminBackupLoading", "adminBackupCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminBackup({
          page: value
        });
      }
    }
  }
};