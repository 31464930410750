import "core-js/modules/es.array.push.js";
export default {
  name: "AttachmentByOne",
  props: {
    limitFiles: {
      type: Number,
      default: 0
    },
    attachments: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    files: []
  }),
  created() {
    this.files = this.attachments.map(el => ({
      id: el.id,
      name: el.file_name,
      file: el.document
    }));
  },
  methods: {
    handlerAdd(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.files.push(files[0]);
      this.emitUpdateFiles();
      this.$refs.fileInput.value = null;
    },
    handlerDel(index) {
      this.files.splice(index, 1);
      this.emitUpdateFiles();
    },
    emitUpdateFiles() {
      this.$emit("updateFiles", this.files);
    },
    clickAddFile() {
      this.$refs.fileInput.click();
    }
  }
};