import { innerCheckMeasures, NONAME_SHORT, optionYesNo, MEASURES_NO } from "@/helpers/text";
import { formatDate, formatDateObj, fmtDate, fmtDateShort, dateObjInit } from "@/helpers/date";
import Calendar from "@/components/Helpers/Calendar.vue";
import { statesInnerCheck } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InnerCheckViewHeader",
  components: {
    Calendar
  },
  data: () => ({
    modalCompleteShow: false,
    modalReturnShow: false,
    modalPerformShow: false,
    fmtDateShort,
    optionYesNo,
    statesInnerCheck,
    NONAME_SHORT,
    modalCompleteDate: {
      ...dateObjInit
    },
    modalTakenMeasures: null,
    modalPerformComment: "",
    modalPerformDefection: false,
    modalPerformFile: null
  }),
  methods: {
    ...mapActions("innerCheck", ["changeStateInnerCheck", "changeInnerCheck", "createInnerCheckComments"]),
    formatDate,
    // нажали кнопку "Утвердить проверку"
    clickBtnComplete() {
      this.modalCompleteDate = {
        ...dateObjInit
      };
      this.modalTakenMeasures = null;
      this.modalCompleteShow = true;
    },
    // нажали кнопку "Вернуть на доработку"
    clickBtnReturn() {
      this.modalPerformComment = "";
      this.modalReturnShow = true;
    },
    // нажали кнопку "ок" модального окна утверждения проверки
    async handlerComplete() {
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        noLoading: true,
        params: {
          date_confirmed: formatDateObj(this.modalCompleteDate, fmtDate, null),
          measures: [this.modalTakenMeasures]
        }
      });
      await this.changeStateInnerCheck({
        id: this.innerCheckForm.id,
        state: "completed",
        msg: "Внутренняя проверка утверждена"
      });
    },
    // нажали кнопку "Провести проверку"
    clickBtnPerform() {
      this.modalPerformComment = "";
      this.modalPerformDefection = false;
      this.modalPerformFile = null;
      this.modalPerformShow = true;
    },
    // нажали кнопку "ок" модального окна проведения проверки
    async handlerPerform() {
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        noLoading: true,
        params: {
          violation_detected: this.modalPerformDefection
        }
      });
      if (this.modalPerformFile) {
        const formData = new FormData();
        formData.append("report", this.modalPerformFile);
        await this.changeInnerCheck({
          id: this.innerCheckForm.id,
          noLoading: true,
          params: formData,
          fileUpload: true
        });
      }
      await this.createInnerCheckComments({
        id: this.innerCheckForm.id,
        body: {
          body: this.modalPerformComment
        }
      });
      await this.changeStateInnerCheck({
        id: this.innerCheckForm.id,
        state: "checked",
        msg: "Внутренняя проверка проведена"
      });
    },
    // нажали кнопку "ок" модального окна возврата на доработку
    async handlerReturn() {
      await this.createInnerCheckComments({
        id: this.innerCheckForm.id,
        body: {
          body: this.modalPerformComment
        }
      });
      await this.changeStateInnerCheck({
        id: this.innerCheckForm.id,
        state: "assigned",
        msg: "Внутренняя проверка возвращена на доработку"
      });
    },
    // обработка файла
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    // добавить файл
    handlerAdd(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.modalPerformFile = files[0];
      this.$refs.fileInput.value = null;
    },
    // нажали кнопку "Назначить проверку"
    clickBtnAssign() {
      this.changeStateInnerCheck({
        id: this.innerCheckForm.id,
        state: "assigned",
        msg: "Внутренняя проверка назначена"
      });
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckForm"]),
    // показать кнопку "Назначить проверку"
    // условие - статус черновик и есть переход to_state_assigned и указана плановая дата и выбран исполнитель
    viewBtnAssign() {
      return statesInnerCheck[this.innerCheckForm.state] === statesInnerCheck.draft && this.innerCheckForm.transitions.includes("to_state_assigned") && this.innerCheckForm.date_planned && this.innerCheckForm.assignee;
    },
    // показать кнопку "Провести проверку"
    // условие - статус assigned и есть переход to_state_checked,
    viewBtnPerform() {
      return statesInnerCheck[this.innerCheckForm.state] === statesInnerCheck.assigned && this.innerCheckForm.transitions.includes("to_state_checked");
    },
    // показать кнопку "Утвердить проверку"
    // условие - статус checked и есть переход to_state_completed,
    viewBtnComplete() {
      return statesInnerCheck[this.innerCheckForm.state] === statesInnerCheck.checked && this.innerCheckForm.transitions.includes("to_state_completed");
    },
    // показать кнопку "Вернуть на доработку"
    // условие - статус checked и есть переход to_state_assigned,
    viewBtnReturn() {
      return statesInnerCheck[this.innerCheckForm.state] === statesInnerCheck.checked && this.innerCheckForm.transitions.includes("to_state_assigned");
    },
    // текст выявленного нарушения
    violationDetected() {
      return this.innerCheckForm.violation_detected ? "Выявлено" : this.innerCheckForm.violation_detected === null ? "..." : "Не выявлено";
    },
    // текст принятых мер
    measures() {
      return Array.isArray(this.innerCheckForm.measures) && this.innerCheckForm.measures.length > 0 ? this.innerCheckForm.measures.map(key => innerCheckMeasures[key]).join(", ") : MEASURES_NO;
    },
    // доступность кнопки "ок" модального окна утверждения проверки
    okModalCompleteDisabled() {
      return !formatDateObj(this.modalCompleteDate, fmtDate, null) || !this.modalTakenMeasures;
    },
    // доступность кнопки "ок" модального окна проведения проверки и возврата на доработку
    okModalPerformDisabled() {
      return !this.modalPerformComment.trim().length;
    },
    // массив для выбора принятых мер
    optionsMeasures() {
      return Object.keys(innerCheckMeasures).map(key => ({
        value: key,
        text: innerCheckMeasures[key]
      }));
    }
  }
};