import { formatName, fmtNameShort } from "@/helpers/text.js";
export default {
  name: "thePersona",
  props: {
    firstname: {
      type: String,
      default: null
    },
    lastname: {
      type: String,
      default: null
    },
    middlename: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: "target"
    }
  },
  data: () => ({
    fmtNameShort
  }),
  methods: {
    formatName
  }
};