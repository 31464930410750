import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HotlineFormLog",
  data: () => ({
    fmtNameShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "event",
      label: "Событие"
    }, {
      key: "timestamp",
      label: "Дата/время",
      formatter: value => formatDate(value, fmtDateMonthTime)
    }, {
      key: "worker",
      label: "Работник",
      class: "text-nowrap"
    }]
  }),
  methods: {
    ...mapActions("hotline", ["updateHlineEvents"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.hlineEventsCount / this.perPage, this.currentPage);
      this.updateHlineEvents({
        hotmsg_id: this.idMessage,
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg", "hlineEvents", "hlineEventsCount", "hlineEventsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateHlineEvents({
          hotmsg_id: this.idMessage,
          page: value
        });
      }
    }
  }
};