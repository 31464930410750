import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "19. Являетесь ли Вы индивидуальным предпринимателем или самозанятым?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.ip_selfemployed,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.ip_selfemployed = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('ip_selfemployed')),
          state: _ctx.ip_selfemployed_state,
          disabled: $options.readonly,
          options: _ctx.optionsIpSelfemployed
        }, null, 8, ["modelValue", "state", "disabled", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_ctx.profileObj.ip_selfemployed ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "ОГРНИП"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          id: "ogrnip",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.ogrnip = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.submitData('ogrnip')),
          class: _normalizeClass(["form-control", (_ctx.ogrnip_state === false ? 'is-invalid' : '', _ctx.ogrnip_state === true ? 'is-valid' : '')]),
          disabled: $options.readonly,
          maxlength: "15"
        }, null, 42, _hoisted_1), [[_vModelText, _ctx.ogrnip]])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  });
}