import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "DeclarationFormComments",
  data: () => ({
    fields: [{
      key: "created_at",
      label: "",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap text-muted"
    }, {
      key: "user_detail",
      label: "",
      formatter: value => formatName(value, fmtNameShort),
      class: "text-nowrap"
    }, {
      key: "body",
      label: ""
    }],
    perPage: 10,
    gotoPage: 1,
    currentPage: 1
  }),
  methods: {
    ...mapActions("declarationForm", ["updateDeclFormComments"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.declFormCommentsCount / this.perPage, this.currentPage);
    }
  },
  computed: {
    ...mapGetters("declarationForm", ["declarationFormObj", "declFormComments", "declFormCommentsCount", "declFormCommentsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateDeclFormComments({
          id: this.declarationFormObj.id,
          page: value
        });
      }
    }
  }
};