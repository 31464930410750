import { riskLevelPartners, fmtNameFull } from "@/helpers/text";
import { formatDateObj, fmtDate } from "@/helpers/date";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { mapGetters, mapActions } from "vuex";
import { statesPartners } from "@/helpers/states";
export default {
  name: "PartnersTotalRisk",
  components: {
    Calendar,
    UserSelector
  },
  data: () => ({
    fmtNameFull
  }),
  methods: {
    ...mapActions("referenceBooks", ["updateDepartments"]),
    ...mapActions("partners", ["changePartner"]),
    async updateCheckRisk(key, data) {
      let value = this.partnerForm[key];
      if (key === "assignee") {
        value = data.id;
        this.partnerForm.assignee = data.id;
      } else if (key === "contract_date") {
        value = formatDateObj(data, fmtDate, null);
        if (!value) return;
      }
      await this.changePartner({
        id: this.partnerForm.id,
        params: {
          [key]: value
        }
      });
    }
  },
  computed: {
    ...mapGetters("partners", ["partnerForm"]),
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    optionsRisk() {
      return Object.keys(riskLevelPartners).map(key => ({
        value: key,
        text: riskLevelPartners[key]
      }));
    },
    departmentOptions() {
      return Object.keys(this.departments).map(key => ({
        value: this.departments[key],
        text: key
      }));
    },
    readOnlyContract() {
      return statesPartners[this.partnerForm.state] !== statesPartners.approved;
    },
    readOnlyTotalRisk() {
      return statesPartners[this.partnerForm.state] !== statesPartners.pending;
    },
    readOnlyAssign() {
      return statesPartners[this.partnerForm.state] !== statesPartners.draft;
    }
  },
  created() {
    this.updateDepartments();
  }
};