import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createBlock(_component_b_card, {
    "img-src": $props.item.image_preview,
    "img-alt": "image preview",
    "img-bottom": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.description || _ctx.noDescription), 1)]),
      _: 1
    })]),
    _: 1
  }, 8, ["img-src"]);
}