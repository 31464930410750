import mutations from "@/store/mutations";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { fetchAPI, getUrl } from "@/helpers/fetch";
import { innerCheckReason } from "@/helpers/text";
import { formatObjDate } from "@/helpers/date";

function additionInnerCheck(obj) {
  obj.datePlanned = formatObjDate(obj.date_planned);
  return obj;
}

const {
  INNER_CHECKS,
  INNER_CHECKS_COUNT,
  INNER_CHECKS_LOADING,
  INNER_CHECKS_CHANGES,
  INNER_CHECKS_CHANGES_COUNT,
  INNER_CHECKS_CHANGES_LOADING,
  INNER_CHECKS_COMMENT,
  INNER_CHECKS_COMMENT_COUNT,
  INNER_CHECKS_COMMENT_LOADING,
  INNER_CHECK_FORM,
  INNER_CHECK_FORM_LOADING,
  INNER_CHECK_REP_LOADING,
  INNER_CHECK_REP_COUNT,
  INNER_CHECK_REP_DATA,
  INNER_CHECK_REP_FILE,
  // вложения
  IC_ATTACH_LIST,
  IC_ATTACH_LIST_COUNT,
  IC_ATTACH_LIST_LOADING,
  IC_ATTACH_ITEM,
  IC_ATTACH_ITEM_LOADING,
} = mutations;

const formInit = {
  foundations_coi: [],
  foundations_message: [],
  foundations_cp: [],
};

const innerCheck = {
  namespaced: true,

  state: {
    innerCheck: [],
    innerCheckCount: 0,
    innerCheckLoading: false,
    innerCheckChanges: [],
    innerCheckChangesCount: 0,
    innerCheckChangesLoading: false,
    innerCheckComment: [],
    innerCheckCommentCount: 0,
    innerCheckCommentLoading: false,
    innerCheckForm: JSON.parse(JSON.stringify(formInit)),
    innerCheckFormLoading: false,
    // report
    reportLoading: false,
    reportCount: 0,
    reportData: [],
    reportFile: "",
    // вложения
    icAttachList: [],
    icAttachListCount: 0,
    icAttachListLoading: false,
    icAttachItem: {},
    icAttachItemLoading: false,
  },

  getters: {
    innerCheck: ({ innerCheck }) => innerCheck,
    innerCheckCount: ({ innerCheckCount }) => innerCheckCount,
    innerCheckLoading: ({ innerCheckLoading }) => innerCheckLoading,
    innerCheckChanges: ({ innerCheckChanges }) => innerCheckChanges,
    innerCheckChangesCount: ({ innerCheckChangesCount }) =>
      innerCheckChangesCount,
    innerCheckChangesLoading: ({ innerCheckChangesLoading }) =>
      innerCheckChangesLoading,
    innerCheckComment: ({ innerCheckComment }) => innerCheckComment,
    innerCheckCommentCount: ({ innerCheckCommentCount }) =>
      innerCheckCommentCount,
    innerCheckCommentLoading: ({ innerCheckCommentLoading }) =>
      innerCheckCommentLoading,
    innerCheckForm: ({ innerCheckForm }) => innerCheckForm,
    innerCheckFormLoading: ({ innerCheckFormLoading }) => innerCheckFormLoading,
    // report
    reportLoading: ({ reportLoading }) => reportLoading,
    reportCount: ({ reportCount }) => reportCount,
    reportData: ({ reportData }) => reportData,
    reportFile: ({ reportFile }) => reportFile,
    // вложения
    icAttachList: ({ icAttachList }) => icAttachList,
    icAttachListCount: ({ icAttachListCount }) => icAttachListCount,
    icAttachListLoading: ({ icAttachListLoading }) => icAttachListLoading,
    icAttachItem: ({ icAttachItem }) => icAttachItem,
    icAttachItemLoading: ({ icAttachItemLoading }) => icAttachItemLoading,
  },

  mutations: {
    [INNER_CHECKS](state, value) {
      state.innerCheck = value;
    },

    [INNER_CHECKS_COUNT](state, value) {
      state.innerCheckCount = value;
    },

    [INNER_CHECKS_LOADING](state, value) {
      state.innerCheckLoading = value;
    },

    [INNER_CHECKS_CHANGES](state, value) {
      state.innerCheckChanges = value;
    },

    [INNER_CHECKS_CHANGES_COUNT](state, value) {
      state.innerCheckChangesCount = value;
    },

    [INNER_CHECKS_CHANGES_LOADING](state, value) {
      state.innerCheckChangesLoading = value;
    },

    [INNER_CHECKS_COMMENT](state, value) {
      state.innerCheckComment = value;
    },

    [INNER_CHECKS_COMMENT_COUNT](state, value) {
      state.innerCheckCommentCount = value;
    },

    [INNER_CHECKS_COMMENT_LOADING](state, value) {
      state.innerCheckCommentLoading = value;
    },

    [INNER_CHECK_FORM](state, value) {
      state.innerCheckForm = value;
    },

    [INNER_CHECK_FORM_LOADING](state, value) {
      state.innerCheckFormLoading = value;
    },

    // report
    [INNER_CHECK_REP_LOADING](state, value) {
      state.reportLoading = value;
    },
    [INNER_CHECK_REP_COUNT](state, value) {
      state.reportCount = value;
    },
    [INNER_CHECK_REP_DATA](state, value) {
      state.reportData = value;
    },
    [INNER_CHECK_REP_FILE](state, value) {
      state.reportFile = value;
    },
    // вложения
    [IC_ATTACH_LIST](state, value) {
      state.icAttachList = value;
    },
    [IC_ATTACH_LIST_COUNT](state, value) {
      state.icAttachListCount = value;
    },
    [IC_ATTACH_LIST_LOADING](state, value) {
      state.icAttachListLoading = value;
    },
    [IC_ATTACH_ITEM](state, value) {
      state.icAttachItem = value;
    },
    [IC_ATTACH_ITEM_LOADING](state, value) {
      state.icAttachItemLoading = value;
    },
  },

  actions: {
    // запрос с сервера списка внутренних проверок
    async updateInnerCheck(context, data = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = {};
          if (data.page) requestParams.page = data.page;
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI("/innerchecks/", requestParams);
          context.commit(INNER_CHECKS, response.results);
          context.commit(INNER_CHECKS_COUNT, response.count);
        },
        INNER_CHECKS_LOADING
      );
    },

    // запросить форму ВП
    async updateInnerCheckForm(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/innerchecks/${id}/`);
          context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
        },
        INNER_CHECK_FORM_LOADING
      );
    },

    // изменить ВП
    async changeInnerCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/innerchecks/${data.id}/`,
            null,
            "PATCH",
            data.params,
            data.fileUpload
          );
          context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
          return "Изменения для проверки внесены";
        },
        !data.noLoading ? INNER_CHECK_FORM_LOADING : undefined
      );
    },

    // удалить ВП
    async removeInnerCheck(context, item) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/innerchecks/${item.id}/`, null, "DELETE");
          return `Внутренняя проверка ${item.id} удалена`;
        },
        INNER_CHECKS_LOADING
      );
    },

    // Добавлен эндпойнт /conflicts/{id}/innercheck/. Принимает пустой POST-запрос, создает внутреннюю проверку,
    // к которой уже привязаны автор, организация и данный КИ. Возвращает эту новую проверку (в том числе ее id).
    async createInnerCheck(context, data = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          let response = {};
          context.commit(INNER_CHECK_FORM, additionInnerCheck(response)); // обнулим
          switch (data.type) {
            case innerCheckReason.foundations_coi:
              response = await fetchAPI(
                `/conflicts/${data.id}/innercheck/`,
                null,
                "POST"
              );
              context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
              return `Внутренняя проверка для КИ ${data.id} создана`;
            case innerCheckReason.foundations_message:
              response = await fetchAPI(
                `/hotline-messages/${data.id}/innercheck/`,
                null,
                "POST"
              );
              context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
              return `Внутренняя проверка для сообщения горячей линии ${data.id} создана`;
            default: {
              response = await fetchAPI("/innerchecks/", null, "POST");
              // создать пустую ВП
              context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
              return "Внутренняя проверка создана";
            }
          }
        },
        INNER_CHECKS_LOADING
      );
    },

    // сменить статус ВП
    async changeStateInnerCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/innerchecks/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          context.commit(INNER_CHECK_FORM, additionInnerCheck(response));
          return data.msg;
        },
        INNER_CHECK_FORM_LOADING
      );
    },

    // Эндпойнт /innercheck-events/ выдает историю ВП (фильтрация по id ВП - innercheck_id)
    async updateInnerCheckChanges(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = { innercheck_id: data.id };
          if (data.page) requestParams.page = data.page;
          const response = await fetchAPI("/innercheck-events/", requestParams);
          context.commit(INNER_CHECKS_CHANGES, response.results);
          context.commit(INNER_CHECKS_CHANGES_COUNT, response.count);
        },
        INNER_CHECKS_CHANGES_LOADING
      );
    },

    // inner check comments get
    async updateInnerCheckComments(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = {};
          if (data.page) requestParams.page = data.page;
          const response = await fetchAPI(
            `/innerchecks/${data.id}/comments/`,
            requestParams
          );
          context.commit(INNER_CHECKS_COMMENT, response.results);
          context.commit(INNER_CHECKS_COMMENT_COUNT, response.count);
        },
        INNER_CHECKS_COMMENT_LOADING
      );
    },

    // отчет по ВП
    async updateReport(context, data = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = {};
          if (data.page) requestParams.page = data.page;
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI("/innerchecks/", requestParams);
          context.commit(INNER_CHECK_REP_DATA, response.results);
          context.commit(INNER_CHECK_REP_COUNT, response.count);
        },
        INNER_CHECK_REP_LOADING
      );
    },

    // создать комментарий по ВП
    async createInnerCheckComments(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/innerchecks/${data.id}/comments/`,
          null,
          "POST",
          data.body
        );
        return "Комментарий по внутренней проверке создан";
      });
    },

    // запрос файла для отчета
    async downloadReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = {};
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          context.commit(
            INNER_CHECK_REP_FILE,
            getUrl("/innerchecks/download/", requestParams)
          );
        },
        INNER_CHECK_REP_LOADING
      );
    },

    // сброс формы ВП
    async dropICForm(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(
          INNER_CHECK_FORM,
          additionInnerCheck(JSON.parse(JSON.stringify(formInit)))
        );
      });
    },

    // вложения
    // запрос списка
    async updateICAttachList(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/innerchecks/${data.ICId}/attachments/`,
            data.params
          );
          context.commit(IC_ATTACH_LIST, response.results);
          context.commit(IC_ATTACH_LIST_COUNT, response.count);
        },
        IC_ATTACH_LIST_LOADING
      );
    },
    // создать вложение
    async createICAttach(context, data) {
      context.commit(IC_ATTACH_ITEM, {}); // обнуление данных
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/innerchecks/${data.ICId}/attachments/`,
            null,
            "POST",
            data.formData,
            true
          );
          context.commit(IC_ATTACH_ITEM, response);
          return "Основание добавлено";
        },
        IC_ATTACH_ITEM_LOADING
      );
    },
    // удалить вложени
    async deleteICAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/innerchecks/${data.ICId}/attachments/${data.attachId}/`,
            null,
            "DELETE"
          );
          return "Основание удалено";
        },
        IC_ATTACH_LIST_LOADING
      );
    },
    // изменить вложение
    async changeICAttach(context, data) {
      context.commit(IC_ATTACH_ITEM, {}); // обнуление данных
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/innerchecks/${data.ICId}/attachments/${data.attachId}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(IC_ATTACH_ITEM, response);
          return "Основание изменено";
        },
        IC_ATTACH_ITEM_LOADING
      );
    },
  },
};

export default innerCheck;
