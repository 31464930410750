import mutations from "@/store/mutations";
import { fetchAPI } from "@/helpers/fetch";
import { wrapCommitDispatch } from "@/helpers/global";
import { formatObjDate } from "@/helpers/date";

function handlerNotificationList(arr) {
  return arr.map((el) => ({ ...el, deadlineObj: formatObjDate(el.deadline) }));
}

const {
  // adminNotification
  ADMIN_NOTIFICATION_LIST,
  ADMIN_NOTIFICATION_COUNT,
  ADMIN_NOTIFICATION_LOADING,
  ADMIN_NOTIFICATION_ITEM,
  ADMIN_NOTIFICATION_ITEM_LOADING,
  // dataAdress
  DATA_ADDRESS,
  DATA_ADDRESS_LOADING,
} = mutations;

const administration2 = {
  namespaced: true,
  state: {
    // adminNotification
    adminNotificationList: [],
    adminNotificationCount: 0,
    adminNotificationLoading: false,
    adminNotificationItem: {},
    adminNotificationItemLoading: false,
    // dataAdress
    dataAddress: {},
    dataAddressLoading: false,
  },
  getters: {
    // adminNotification
    adminNotificationList: ({ adminNotificationList }) => adminNotificationList,
    adminNotificationCount: ({ adminNotificationCount }) =>
      adminNotificationCount,
    adminNotificationLoading: ({ adminNotificationLoading }) =>
      adminNotificationLoading,
    adminNotificationItem: ({ adminNotificationItem }) => adminNotificationItem,
    adminNotificationItemLoading: ({ adminNotificationItemLoading }) =>
      adminNotificationItemLoading,
    // dataAdress
    dataAddress: ({ dataAddress }) => {
      if (
        typeof dataAddress === "object" &&
        Array.isArray(dataAddress.suggestions)
      )
        return dataAddress.suggestions.map((s) => s.value);
      return [];
    },
    dataAddressLoading: ({ dataAddressLoading }) => dataAddressLoading,
  },
  mutations: {
    // adminNotification
    [ADMIN_NOTIFICATION_LIST](state, value) {
      state.adminNotificationList = value;
    },
    [ADMIN_NOTIFICATION_COUNT](state, value) {
      state.adminNotificationCount = value;
    },
    [ADMIN_NOTIFICATION_LOADING](state, value) {
      state.adminNotificationLoading = value;
    },
    [ADMIN_NOTIFICATION_ITEM](state, value) {
      state.adminNotificationItem = value;
    },
    [ADMIN_NOTIFICATION_ITEM_LOADING](state, value) {
      state.adminNotificationItemLoading = value;
    },
    // dataAdress
    [DATA_ADDRESS](state, value) {
      state.dataAddress = value;
    },
    [DATA_ADDRESS_LOADING](state, value) {
      state.dataAddressLoading = value;
    },
  },
  actions: {
    // adminNotification List
    async updateAdminNotification(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/notif-settings/", data);
          context.commit(
            ADMIN_NOTIFICATION_LIST,
            handlerNotificationList(response.results)
          );
          context.commit(ADMIN_NOTIFICATION_COUNT, response.count);
        },
        ADMIN_NOTIFICATION_LOADING
      );
    },
    // adminNotification Item
    async changeAdminNotificationItem(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/notif-settings/${data.id}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(ADMIN_NOTIFICATION_ITEM, response);
          return "Изменения сохранены";
        },
        ADMIN_NOTIFICATION_ITEM_LOADING
      );
    },
    // dataAdress
    async updateDataAdress(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const url =
            "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
          const params = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Token " + process.env.VUE_APP_DADATA,
            },
            body: JSON.stringify({ query: data }),
          };
          const response = await fetch(url, params);
          const text = await response.text();
          if (!response.ok) console.log(`ошибка ${response.status} ${text}`);
          else context.commit(DATA_ADDRESS, text ? JSON.parse(text) : {});
        },
        DATA_ADDRESS_LOADING
      );
    },
  },
};

export default administration2;
