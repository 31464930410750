import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import { loginBegin, textLink4Prod } from "@/auth/auth";
import { agreements } from "@/helpers/auth";
export default {
  name: "theLogin",
  data: () => ({
    textLink4Prod,
    email: "",
    password: "",
    emailPlaceholder: "Заполните поле по шаблону example@example.com",
    magicLink: null,
    agreement: false,
    agreements,
    docKey: "PD",
    showModal: false
  }),
  async mounted() {
    if (this.messageSingle) {
      await this.showNotify(this.messageSingle);
      await this.clearNotifySingle();
    }
  },
  methods: {
    ...mapActions(["showNotify", "clearNotifySingle"]),
    // обработка подтверждения формы
    async handlerConfirm(bvFormEvt) {
      // Prevent from closing
      bvFormEvt.preventDefault();
      try {
        this.magicLink = await loginBegin(this.email, this.password);
      } catch (error) {
        this.showNotify({
          msg: error.message,
          title: "Ошибка входа",
          variant: "danger"
        });
      } finally {
        this.clearForm();
      }
    },
    // очистка формы
    clearForm() {
      this.email = "";
      this.password = "";
    },
    // показываем текст соглашения
    showText(key) {
      this.docKey = key;
      this.showModal = true;
    }
  },
  components: {
    theNotification
  },
  computed: {
    ...mapGetters(["messagePool", "messageSingle"])
  }
};