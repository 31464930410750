import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "mb-0 text-muted"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createBlock(_component_b_row, {
    class: "align-items-center"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "7",
      lg: "9",
      xl: "10"
    }, {
      default: _withCtx(() => [_createElementVNode("p", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.arrayForTable, item => {
        return _openBlock(), _createElementBlock("span", {
          key: item.name,
          class: "me-2"
        }, _toDisplayString(item.data), 1);
      }), 128))])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "5",
      lg: "3",
      xl: "2",
      class: "text-md-right"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        disabled: $props.disabledDeclActions,
        variant: "link",
        onClick: $options.emitChangeEvent,
        class: "border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "edit",
          "fixed-width": ""
        })]),
        _: 1
      }, 8, ["disabled", "onClick"]), _createVNode(_component_b_button, {
        disabled: $props.disabledDeclActions,
        variant: "link",
        onClick: $options.emitRemoveEvent,
        class: "border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: ['far', 'trash-alt'],
          "fixed-width": ""
        })]),
        _: 1
      }, 8, ["disabled", "onClick"])]),
      _: 1
    })]),
    _: 1
  });
}