import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PersonalAreaCard",
  data: () => ({}),
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions("homeStats", ["setOnlyNew"]),
    clickToNew(event) {
      event.preventDefault();
      this.setOnlyNew(true);
      this.$router.push(this.item.link);
    }
  },
  computed: {
    ...mapGetters("homeStats", ["stats"]),
    subtitle() {
      return this.stats[this.item.name] ? `${this.item.subtitle} ${this.stats[this.item.name].total}` : "";
    }
  }
};