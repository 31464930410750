import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "TwentySixth",
  data: () => ({
    sites: [],
    hasWebsiteState: null,
    optionYesNo
  }),
  created() {
    this.sites = this.profileObj.websites !== null ? this.profileObj.websites.map(site => ({
      name: site,
      nameState: null
    })) : [];
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData() {
      this.hasWebsiteState = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_website_exposure: this.profileObj.has_website_exposure
      }).then(() => {
        setValueTimeout(this, "hasWebsiteState");
      });
    },
    submitSitesUpdate(index) {
      if (index < this.sites.length) {
        this.sites[index].nameState = false;
      }
      this.updateProfile({
        id: this.profileObj.id,
        websites: this.sites.filter(site => site.name && site.name.length > 0).map(el => el.name)
      }).then(() => {
        if (index < this.sites.length) {
          setValueTimeout(this.sites[index], "nameState");
        }
      });
    },
    addSite() {
      this.sites.push({
        name: "",
        nameState: null
      });
    },
    removeRow(index) {
      this.sites.splice(index, 1);
      this.submitSitesUpdate(index);
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};