import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, limitBound, NONAME_DOTS, fmtNameShort } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import InnerCheckListDetails from "./InnerCheckListDetails.vue";
import links from "@/router/links";
import { statesInnerCheck } from "@/helpers/states";
export default {
  name: "InnerCheckList",
  components: {
    TrafficLight,
    InnerCheckListDetails
  },
  data: () => ({
    fmtNameShort,
    fmtDateShort,
    NONAME_DOTS,
    links,
    statesInnerCheck,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "id",
      label: "№"
    }, {
      key: "assignee_detail",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "date_planned",
      label: "Провести до",
      class: "text-nowrap"
    }, {
      key: "date_actual",
      label: "Проведено",
      class: "text-nowrap"
    }, {
      key: "date_confirmed",
      label: "Утверждено",
      class: "text-nowrap"
    }, {
      key: "violation_detected",
      label: "Нарушение",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "",
      class: "p-0"
    }],
    showModalRemove: false,
    currentItem: {}
  }),
  methods: {
    ...mapActions("innerCheck", ["updateInnerCheck", "removeInnerCheck"]),
    formatName,
    formatDate,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.innerCheckCount / this.perPage, this.currentPage);
      this.updateInnerCheck({
        page: this.currentPage
      });
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeInnerCheck(this.currentItem);
      this.currentPage = 1;
      await this.updateInnerCheck({
        page: 1
      });
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheck", "innerCheckCount", "innerCheckLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateInnerCheck({
          page: value
        });
      }
    }
  }
};