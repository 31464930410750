import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import theNotification from "@/components/theNotification";
import SignPEP from "@/components/Helpers/SignPEP.vue";
import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, signTypes } from "@/helpers/text";
import { statesKnowledgeUser, statesKnowledge } from "@/helpers/states";
import links from "@/router/links";
import { needSignPEP } from "@/helpers/knowledge";
export default {
  name: "ProfileLNAFormView",
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  // id назначения, но не материала
  components: {
    BreadCrumbToHome,
    SignPEP,
    theNotification
  },
  created() {
    const params = {
      id: this.msg
    };
    if (this.isAltUI) params.alt = true;
    this.updateLnaUserForm(params);
  },
  data: () => ({
    statesKnowledge,
    statesKnowledgeUser,
    signTypes,
    fmtDateShort,
    fmtNameShort
  }),
  computed: {
    ...mapGetters("knowledge", ["lnaUserForm", "lnaUserFormLoading"]),
    isAltUI() {
      return this.$route.path.includes(links.profileAcquaintanceAltView);
    },
    breadcrumbs() {
      return this.isAltUI ? [{
        text: "Библиотека знаний",
        to: {
          name: "KnowledgeAlt"
        }
      }, {
        text: this.lnaUserForm.section_name,
        to: links.knowledgeListAlt + this.lnaUserForm.section
      }] : [{
        text: "Личный кабинет",
        to: {
          name: "ProfileAcquaintance"
        }
      }];
    },
    showBtnSign() {
      return !this.isAltUI && statesKnowledgeUser[this.lnaUserForm?.assignment.state] !== statesKnowledgeUser.finished;
    }
  },
  methods: {
    ...mapActions("knowledge", ["updateLnaUserForm", "changeStateLna"]),
    formatDate,
    formatName,
    signPEP() {
      if (this.lnaUserForm.confirmation_ways.includes(needSignPEP)) this.$refs.btnPEP.handlerClick();else this.changeStateLna({
        id: this.msg,
        state: "finished"
      });
    }
  }
};