import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import links from "@/router/links";
import { limitBound } from "@/helpers/text";
import { statesKnowledgeUser } from "@/helpers/states";
export default {
  name: "ProfileLNA",
  data: () => ({
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fields: [{
      key: "libmat_id",
      label: "#"
    }, {
      key: "title",
      label: "Наименование ЛНА"
    }, {
      key: "document_date",
      label: "Дата ЛНА",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "document_number",
      label: "Номер ЛНА"
    }, {
      key: "deadline",
      label: "Срок",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "state",
      label: "Статус",
      formatter: value => statesKnowledgeUser[value]
    }]
  }),
  methods: {
    ...mapActions("knowledge", ["updateLnaUserList"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.lnaUserListCount / this.perPage, this.currentPage);
      this.updateLnaUserList({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("knowledge", ["lnaUserList", "lnaUserListCount", "lnaUserListLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateLnaUserList({
          page: value
        });
      }
    }
  }
};