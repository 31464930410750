import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "bread-crumb-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_breadcrumb = _resolveComponent("b-breadcrumb");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_breadcrumb, {
    items: $options.breadcrumbs,
    class: "p-0 mb-0 small bg-transparent"
  }, null, 8, ["items"])]);
}