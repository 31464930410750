import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { checkPermissions } from "@/auth/auth";
export default {
  name: "ProfileArea",
  components: {
    BreadCrumbToHome,
    theNotification
  },
  data: () => ({
    links
  }),
  created() {
    if (this.$ff.DECLARATION) {
      this.setDeclarationsPage(1);
      this.fetchDeclarations4User({
        sortBy: "state_modified_at",
        sortDesc: true,
        page: 1,
        filters: {}
      });
    }
    if (this.$ff.KNOWLEDGE && checkPermissions(this.$perm.view_my_libmaterial)) {
      this.updateLnaUserList();
    }
    if (this.$ff.EDUCATION && checkPermissions(this.$perm.start_my_course)) this.updateUserCourse({
      page: 1
    });
    if (this.$ff.PROFILE) this.fetchProfiles();
    if (this.$ff.NOTIFICATION) this.updateNotifyLk();
  },
  computed: {
    ...mapGetters("education", ["coursUserCount"]),
    ...mapGetters("profiles", ["profilesCount"]),
    ...mapGetters("declarations", ["declarationsCount"]),
    ...mapGetters("declarationForm", ["pathProps"]),
    ...mapGetters("knowledge", ["lnaUserListCount"]),
    ...mapGetters("homeStats", ["notifyLkCount"])
  },
  methods: {
    ...mapActions("profiles", ["fetchProfiles"]),
    ...mapActions("education", ["updateUserCourse"]),
    ...mapActions("declarations", ["fetchDeclarations4User", "setDeclarationsPage"]),
    ...mapActions("declarationForm", ["newDeclaration"]),
    ...mapActions("knowledge", ["updateLnaUserList"]),
    ...mapActions("homeStats", ["updateNotifyLk"]),
    async clickNewDecl() {
      await this.newDeclaration();
      if (this.pathProps) this.$router.push(links.profileDeclarationForm + this.pathProps);
    }
  }
};