import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import SecurityEventsSidebar from "./SecurityEventsSidebar.vue";
import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateLong, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort, switchText, fmtNameFull } from "@/helpers/text";
import { checkPermissions } from "@/auth/auth.js";
export default {
  name: "SecurityEvents",
  data: () => ({
    showModal: false,
    showSidebar: false,
    fmtNameFull,
    fmtDateLong,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    results: ["Успешно", "Неуспешно"],
    tableFields: [{
      key: "id",
      label: "id"
    }, {
      key: "user_detailT",
      label: "Инициатор"
    }, {
      key: "user_detail.login",
      label: "Инициатор (login)"
    }, {
      key: "target_user_detailT",
      label: "Объект"
    }, {
      key: "target_user_detail.login",
      label: "Объект (login)"
    }, {
      key: "supposed_emailT",
      label: "Предпол. логин"
    }, {
      key: "timestampT",
      label: "Дата и время"
    }, {
      key: "event_verbose",
      label: "Событие"
    }, {
      key: "extra_info",
      label: "Примечание"
    }, {
      key: "ip_address",
      label: "ip"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    filters: {}
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    SecurityEventsSidebar
  },
  created() {
    this.updateSecurityEvents({
      page: 1
    });
    this.updateOrganizations();
    this.updateSecEvents();
    if (checkPermissions(this.$perm.list_orgs)) this.tableFields.push({
      key: "organization_name",
      label: "Организация"
    });
  },
  methods: {
    ...mapActions("administration", ["updateSecurityEvents", "updateSecurityEventsItem", "updateSecurityGuide"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateSecEvents"]),
    formatDate,
    formatName,
    switchText,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.securityEventsCount / this.perPage, this.currentPage);
      this.updateSecurityEvents({
        page: this.currentPage,
        ...this.filters
      });
    },
    clickItem(id) {
      this.updateSecurityEventsItem(id);
      this.showModal = true;
    },
    async applyFilters(data) {
      this.filters = {};
      Object.keys(data).forEach(key => {
        const value = ["timestamp_gte", "timestamp_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") this.filters[key] = value;
      });
      if (this.currentPage === 1) await this.updateSecurityEvents({
        page: 1,
        ...this.filters
      });else this.currentPage = 1;
    }

    // clickShowSettings() {
    //   this.updateSecurityGuide();
    //   this.$bvModal.show("securitySettingsModal");
    // },
  },

  computed: {
    ...mapGetters("administration", ["securityEvents", "securityEventsCount", "securityEventsLoading", "securityEventsItem", "securityEventsItemLoading", "securityGuide", "securityGuideLoading"]),
    items4table() {
      return Array.isArray(this.securityEvents) ? this.securityEvents.map(el => ({
        ...el,
        supposed_emailT: el.supposed_email === null ? "" : `"${el.supposed_email}"`,
        timestampT: formatDate(el.timestamp, fmtDateLong),
        user_detailT: formatName(el.user_detail, fmtNameShort),
        target_user_detailT: formatName(el.target_user_detail, fmtNameShort)
      })) : [];
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateSecurityEvents({
          page: value,
          ...this.filters
        });
      }
    }
  }
};