import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mb-0"
};
const _hoisted_2 = {
  class: "text-muted mb-0"
};
const _hoisted_3 = {
  class: "text-muted mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("small", null, [_createElementVNode("p", _hoisted_1, [_createElementVNode("strong", null, _toDisplayString(_ctx.workplaces[$props.workplaceIndex].login), 1)]), _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.workplaces[$props.workplaceIndex].position), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.workplaces[$props.workplaceIndex].organization_name), 1)]);
}