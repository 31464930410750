import { mapActions, mapGetters } from "vuex";
import { formatDateObj, fmtDate, dateObjInit } from "@/helpers/date";
import StatsKnowledge from "./StatsKnowledge.vue";
import Calendar from "@/components/Helpers/Calendar.vue";
import { option4select } from "@/helpers/global";
import { selectAll } from "@/helpers/text";
export default {
  name: "KnowledgeReport",
  components: {
    StatsKnowledge,
    Calendar
  },
  props: {
    section: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showReport: false,
    typeSelected: "statsKnow",
    departmentSelected: null,
    typeOptions: [{
      value: "statsKnow",
      text: "Статистика ознакомления"
    }],
    selected: {},
    // параметры формирования отчета
    startDate: {
      ...dateObjInit
    },
    endDate: {
      ...dateObjInit
    }
  }),
  methods: {
    ...mapActions("knowledge", ["updateReport", "downloadReport"]),
    setSelected() {
      //this.selected.report_type = this.typeSelected;
      this.selected.department_id = this.departmentSelected;
      this.selected.section_id = this.section;
      this.selected.date_end_real_gte = formatDateObj(this.startDate, fmtDate, null);
      this.selected.date_end_real_lte = formatDateObj(this.endDate, fmtDate, null);
    },
    async clickReport() {
      this.setSelected();
      await this.updateReport(this.selected);
      this.showReport = true;
    },
    async clickDownload() {
      this.showReport = false;
      this.setSelected();
      await this.downloadReport(this.selected);
      this.$refs.fileDownload.click();
    }
  },
  computed: {
    ...mapGetters("knowledge", ["lnaReportLoading", "lnaReportFile"]),
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    departmentOptions() {
      return selectAll.concat(option4select(this.departments, false));
    }
  }
};