import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import InnerCheckViewChanges from "./InnerCheckViewChanges.vue";
import InnerCheckFormComments from "./InnerCheckFormComments.vue";
import InnerCheckViewHeader from "./InnerCheckViewHeader.vue";
import theNotification from "@/components/theNotification";
import InnerCheckFormInfo from "./InnerCheckFormInfo.vue";
import InnerCheckFormSource from "./InnerCheckFormSource.vue";
import InnerCheckFormAttachments from "./InnerCheckFormAttachments.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InnerCheckFormBase",
  components: {
    InnerCheckFormSource,
    InnerCheckFormInfo,
    BreadCrumbToHome,
    theNotification,
    InnerCheckViewChanges,
    InnerCheckViewHeader,
    InnerCheckFormComments,
    InnerCheckFormAttachments
  },
  data: () => ({
    breadcrumbs: [{
      text: "Внутренние проверки",
      to: {
        name: "InnerCheck"
      }
    }]
  }),
  props: {
    msg: {
      type: String,
      required: true
    } // сюда получаем id
  },

  async created() {
    await this.updateInnerCheckForm(this.msg);
    await this.updateInnerCheckDirectory();
  },
  methods: {
    ...mapActions("administration", ["updateInnerCheckDirectory"]),
    ...mapActions("innerCheck", ["updateInnerCheckForm"])
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckFormLoading"])
  }
};