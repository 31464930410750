import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateTime } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
// import {
//   states,
//   statesCp,
//   statesTasks,
//   statesDeclarations,
// } from "@/helpers/states";

export default {
  name: "EventsList",
  props: {
    declId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [
    // {
    //   key: "entity",
    //   label: "Объект",
    // },
    {
      key: "event",
      label: "Событие"
    }, {
      key: "timestamp",
      label: "Дата/время",
      formatter: value => {
        return formatDate(value, fmtDateTime);
      }
    }, {
      key: "worker",
      label: "Работник",
      class: "text-nowrap"
    }]
  }),
  methods: {
    ...mapActions("declarations", ["updateEvents"]),
    formatName,
    // stateTitle(entity, state) {
    //   switch (entity) {
    //     case "task":
    //       return `Переведена в статус "${statesTasks[state] || state}"`;
    //     case "coi":
    //       return `Переведен в статус "${states[state] || state}"`;
    //     case "cp":
    //       return `Переведена в статус "${statesCp[state] || state}"`;
    //     case "coid":
    //       return `Переведена в статус "${statesDeclarations[state] || state}"`;
    //     default:
    //       return `Переведена в статус "${state}"`;
    //   }
    // },

    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.declarationEventsCount / this.perPage, this.currentPage);
    }
  },
  computed: {
    ...mapGetters("declarations", ["declarationEvents", "declarationEventsCount", "declarationEventsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateEvents({
          decl_id: this.declId,
          page: value
        });
      }
    }
  }
};