import perm from "@/auth/permissions.js";

// Периодичность контроля КП
export const periods4Cp = {
  daily: "ежедневно",
  weekly: "еженедельно",
  monthly: "ежемесячно",
  quarterly: "ежеквартально",
  halfyearly: "каждые полгода",
  yearly: "ежегодно",
  once: "единоразово",
};

export const btn4transitCp = [
  {
    stateFrom: "draft",
    stateTo: "submitted",
    perm: perm.add_wa_cp,
    type: "simple",
    text: "Завершить редактирование",
    variant: "primary",
    msg: "Редактирование КП завершено",
    validate: {
      cp_type: "Тип контрольной процедуры",
      period: "Периодичность контроля",
      task: "Задание",
    },
  },
  {
    stateFrom: "submitted",
    modal: "cpModalSubmitted2draft",
    stateTo: "draft",
    perm: perm.assign_wa_cp,
    type: "taskBtn",
    msg: "КП возвращена на доработку",
    settings: {
      buttonTitle: "Вернуть на доработку",
      modalTitle: "Вернуть КП на доработку",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "submitted",
    modal: "cpkModalSubmitted2assigned",
    stateTo: "assigned",
    perm: perm.assign_wa_cp,
    type: "taskBtn",
    msg: "Ответственный по КП назначен",
    settings: {
      buttonTitle: "Назначить ответственного",
      modalTitle: "Назначить ответственного для КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
      addAssignee: true,
      addDeadline: true,
    },
  },
  {
    stateFrom: "assigned",
    modal: "cpModalAssigned2checked",
    stateTo: "checked",
    perm: perm.check_wa_cp,
    type: "taskBtn",
    msg: "КП проведена",
    settings: {
      buttonTitle: "Провести КП",
      modalTitle: "Провести КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "checked",
    modal: "cpModalСhecked2completed",
    stateTo: "completed",
    perm: perm.complete_wa_cp,
    type: "taskBtn",
    msg: "Рассмотрение КП завершено",
    settings: {
      buttonTitle: "Завершить КП",
      modalTitle: "Завершить КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "checked",
    modal: "cpModalСhecked2prolonged",
    stateTo: "prolonged",
    perm: perm.prolong_wa_cp,
    type: "taskBtn",
    msg: "КП продлена",
    settings: {
      buttonTitle: "Продлить КП",
      modalTitle: "Продлить КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "prolonged",
    modal: "cpModalProlonged2prochecked",
    stateTo: "prochecked",
    perm: perm.check_wa_cp,
    type: "taskBtn",
    msg: "КП проведена",
    settings: {
      buttonTitle: "Провести КП",
      modalTitle: "Провести КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "prochecked",
    modal: "cpModalProchecked2completed",
    stateTo: "completed",
    perm: perm.complete_wa_cp,
    type: "taskBtn",
    msg: "Рассмотрение КП завершено",
    settings: {
      buttonTitle: "Завершить КП",
      modalTitle: "Завершить КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "prochecked",
    modal: "cpModalProchecked2prolonged",
    stateTo: "prolonged",
    perm: perm.prolong_wa_cp,
    type: "taskBtn",
    msg: "КП продлена",
    settings: {
      buttonTitle: "Продлить КП",
      modalTitle: "Продлить КП",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
];
