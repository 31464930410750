import { FIO } from "@/helpers/text";
import { formatDate, fmtDateShort } from "@/helpers/date";
//import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportCpInfo",
  //______________________
  data: () => ({
    //----------------
    timingControl: [{
      author: "Петров Петр Петрович"
    }, {
      author: "Иванов Иван Иванович"
    }],
    timingControlCount: 11,
    timingControlLoading: false,
    //----------------
    currentPage: 1,
    perPage: 10,
    filters: {},
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "author",
      label: "ФИО работника",
      class: "text-nowrap",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "position",
      label: "Должность работника"
    }, {
      key: "created_at",
      label: "Дата обнаружения КИ",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "2",
      label: "Тип КИ"
    }, {
      key: "comment",
      label: "Комментарий работника"
    }]
  }),
  //______________________
  computed: {
    //...mapGetters("", [""]),

    isEmpty() {
      return false; //!this.timingControlCount;
    },

    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        // this.updateTimingControl({
        //   page: value,
        //   filters: this.filters,
        // });
      }
    }
  },

  //______________________
  methods: {
    //...mapActions("", [""]),
  }
};