import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "theFifteenth",
  data: () => ({
    criminal_record_state: null,
    has_criminal_record_state: null,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    optionYesNo
  }),
  created() {
    this.profileObjSaved.administrative_record = this.profileObj.administrative_record;
    this.profileObjSaved.criminal_record = this.profileObj.criminal_record;
    this.profileObjSaved.has_criminal_record = this.profileObj.has_criminal_record;
    this.profileObjSaved.has_administrative_record = this.profileObj.has_administrative_record;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData(key) {
      if (this.profileObjSaved[key] === this.profileObj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        this.profileObjSaved[key] = this.profileObj[key];
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};