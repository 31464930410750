import { option4select } from "@/helpers/global";
import { sourceHotline, fmtNameFull, selectAll } from "@/helpers/text";
import { statesHotline } from "@/helpers/states";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { dateObjInit } from "@/helpers/date";
export default {
  name: "HotLineListSidebar",
  components: {
    Calendar,
    UserSelector
  },
  data: () => ({
    fmtNameFull,
    curator: null,
    assignee: null,
    showItem: true,
    filters: {
      source: null,
      state: null,
      message_number: null,
      applicant_search: null,
      response_number: null,
      submitted_at_gte: {
        ...dateObjInit
      },
      submitted_at_lte: {
        ...dateObjInit
      },
      state_modified_gte: {
        ...dateObjInit
      },
      state_modified_lte: {
        ...dateObjInit
      },
      response_date_gte: {
        ...dateObjInit
      },
      response_date_lte: {
        ...dateObjInit
      },
      deadline_gte: {
        ...dateObjInit
      },
      deadline_lte: {
        ...dateObjInit
      },
      curator_id: null,
      assignee_id: null
    }
  }),
  methods: {
    clearFilters() {
      this.showItem = false;
      this.curator = null;
      this.assignee = null;
      Object.keys(this.filters).forEach(key => {
        if (["submitted_at_gte", "submitted_at_lte", "state_modified_gte", "state_modified_lte", "response_date_gte", "response_date_lte", "deadline_gte", "deadline_lte"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    },
    setFilter(key, data) {
      this.filters[key] = data.id;
    }
  },
  computed: {
    sourceOption() {
      return selectAll.concat(sourceHotline);
    },
    sourceState() {
      return selectAll.concat(option4select(statesHotline));
    }
  }
};