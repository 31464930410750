import { mapGetters, mapActions } from "vuex";
import { statesRiskMatrix } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth";
export default {
  name: "MatrixRiskListDetails",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    propItem: {}
  }),
  created() {
    this.propItem = {
      ...this.item
    };
  },
  methods: {
    ...mapActions("risk", ["changeMatrixFormRisks"]),
    async changeData(key) {
      await this.changeMatrixFormRisks({
        matrixId: this.matrixForm.id,
        riskId: this.propItem.id,
        params: {
          [key]: this.propItem[key]
        }
      });
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixForm"]),
    readOnly() {
      return statesRiskMatrix[this.matrixForm.state] !== statesRiskMatrix.draft || !checkPermissions(this.$perm.change_wa_matrix);
    }
  }
};