import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import Calendar from "@/components/Helpers/Calendar.vue";
import { statesHotline } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
import { formatDateObj, fmtDate } from "@/helpers/date";
import { innerCheckReason } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "HotlineFormResult",
  components: {
    Calendar
  },
  data: () => ({
    file: null
  }),
  methods: {
    ...mapActions("hotline", ["changeHlineMsg"]),
    ...mapActions("innerCheck", ["createInnerCheck"]),
    async changeData(key, data) {
      let value = this.hlineMsg[key];
      if (["response_date"].includes(key)) {
        value = formatDateObj(data, fmtDate, null);
        if (!value) return;
      }
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [key]: value
        }
      });
    },
    async handlerDelFile(key) {
      this.hlineMsg[key] = null;
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [key]: null
        }
      });
    },
    clickAddFile(key) {
      this.file = key;
      this.$refs.fileInput.click();
    },
    async handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.hlineMsg[this.file] = files[0].name;
      const formData = new FormData();
      formData.append(this.file, files[0]);
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: formData,
        fileUpload: true
      });
      this.$refs.fileInput.value = null;
    },
    // создать ВП
    async clickAddInnerCheck() {
      await this.createInnerCheck({
        id: this.hlineMsg.id,
        type: innerCheckReason.foundations_message
      });
      if (this.innerCheckForm.id) this.$router.push(links.innerCheckForm + this.innerCheckForm.id);
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckLoading"]),
    showBtn() {
      return statesHotline[this.hlineMsg.state] === statesHotline.completed;
    },
    readOnly() {
      return !["assigned", "prolonged"].includes(this.hlineMsg.state) || !checkPermissions(this.$perm.change_wa_hotmsg);
    }
  }
};