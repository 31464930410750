import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_table = _resolveComponent("b-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("h5", _hoisted_1, _toDisplayString($options.title), 1), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    responsive: "",
    "show-empty": "",
    items: _ctx.partnerReportData,
    fields: _ctx.tableFields,
    stacked: "md",
    small: "",
    "empty-text": "Данных нет"
  }, null, 8, ["items", "fields"])]);
}