import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { periodOptions } from "@/helpers/date";
export default {
  name: "AdminKnowledge",
  data: () => ({
    form: {
      days: 10,
      period: "month",
      organisation: "all",
      department: "all"
    },
    periodOptions,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    await this.updateKnowledge();
    await this.updateOrganizations();
    await this.updateDepartments();
    this.form.days = this.adminKnowledge.days;
    this.form.period = this.adminKnowledge.period;
    this.form.organisation = this.adminKnowledge.organisation;
    this.form.department = this.adminKnowledge.department;
  },
  methods: {
    ...mapActions("administration", ["updateKnowledge", "changeKnowledge"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateDepartments"]),
    async clickSave() {
      this.changeKnowledge(this.form);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminKnowledge", "adminKnowledgeLoading"]),
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    },
    orgOptions() {
      return Object.keys(this.organizations).reduce((acc, curr) => {
        acc.push({
          value: this.organizations[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    }
  }
};