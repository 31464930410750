import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
export default {
  name: "SecuritySettings",
  data: () => ({
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    this.updateSecurityGuide();
  },
  methods: {
    ...mapActions("administration", ["updateSecurityGuide"])
  },
  computed: {
    ...mapGetters("administration", ["securityGuide", "securityGuideLoading"])
  }
};