import { validate } from "@/helpers/validate";
export default {
  name: "AdminOrgAdd",
  props: {
    settings: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => ({
        fullname: "",
        inn: "",
        shortname: ""
      })
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    name: "",
    inn: "",
    shortname: "",
    nameState: null,
    innState: null,
    organizationForm: ""
  }),
  methods: {
    changeOrg() {
      this.organizationForm = this.item.organization_form;
      this.name = this.item.fullname;
      this.inn = this.item.inn;
      this.shortname = this.item.shortname;
      this.showModal = true;
    },
    handlerOk(bvModalEvt) {
      if (!this.validateName() || !this.validateINN()) {
        bvModalEvt.preventDefault();
        return;
      }
      this.$emit("updateOrg", {
        params: {
          fullname: this.name !== this.item.fullname ? this.name : null,
          shortname: this.shortname !== this.item.shortname ? this.shortname : null,
          inn: this.inn !== this.item.inn ? this.inn : null,
          organization_form: this.organizationForm !== this.item.organization_form ? this.organizationForm : null
        },
        item: this.item
      });
      this.showModal = false;
    },
    validateName() {
      const validName = this.name.trim() !== "";
      this.nameState = !validName ? validName : null;
      return validName;
    },
    validateINN() {
      const validINN = validate({
        data: this.inn,
        dataRequired: "inn"
      });
      this.innState = !validINN ? validINN : null;
      return validINN;
    }
  }
};