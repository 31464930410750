import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "MatrixChangeList",
  data: () => ({
    fmtNameShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "timestamp",
      label: "",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-muted text-nowrap"
    }, {
      key: "event",
      label: ""
    }, {
      key: "FIO",
      label: ""
    }]
  }),
  methods: {
    ...mapActions("risk", ["updateMatrixFormChange"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.matrixFormChangeCount / this.perPage, this.currentPage);
      this.updateMatrixFormChange({
        id: this.matrixForm.id,
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixFormChange", "matrixFormChangeLoading", "matrixFormChangeCount", "matrixForm"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateMatrixFormChange({
          id: this.matrixForm.id,
          page: value
        });
      }
    }
  }
};