import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_DeclarationsList = _resolveComponent("DeclarationsList");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _createVNode(_component_b_nav, {
    pills: "",
    class: "mb-4 mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_nav_item, {
      "link-classes": "h3 border-0 me-4",
      active: _ctx.showCOI,
      onClick: _cache[0] || (_cache[0] = $event => _ctx.showCOI = true)
    }, {
      default: _withCtx(() => [_createTextVNode("Декларации конфликтов интересов")]),
      _: 1
    }, 8, ["active"]), _createVNode(_component_b_nav_item, {
      "link-classes": "h3 border-0",
      active: !_ctx.showCOI,
      onClick: _cache[1] || (_cache[1] = $event => _ctx.showCOI = false)
    }, {
      default: _withCtx(() => [_createTextVNode("Декларации подарков")]),
      _: 1
    }, 8, ["active"])]),
    _: 1
  }), _withDirectives(_createVNode(_component_DeclarationsList, {
    "declarations-obj": _ctx.declarationsObj
  }, null, 8, ["declarations-obj"]), [[_vShow, _ctx.showCOI]]), _withDirectives(_createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm text-center p-5"
  }, null, 512), [[_vShow, !_ctx.showCOI]])]);
}