import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { formatDateObj, fmtDate, formatObjDate } from "@/helpers/date";
import Calendar from "@/components/Helpers/Calendar.vue";
import { passportIssuerCodeMask } from "@/helpers/masks";
export default {
  name: "ThirtyFirst",
  components: {
    Calendar
  },
  data: () => ({
    passportIssuerCodeMask,
    pass_series_state: null,
    pass_num_state: null,
    pass_issuer_state: null,
    pass_issue_date: null,
    pass_issuer_code: null,
    pass_issue_date_state: null,
    pass_issuer_code_state: null,
    has_foreign_pass_state: null,
    profileObjSaved: {}
  }),
  created() {
    this.pass_issue_date = formatObjDate(this.profileObj.pass_issue_date);
    this.pass_issuer_code = passportIssuerCodeMask(this.profileObj.pass_issuer_code);

    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    this.profileObjSaved.pass_series = this.profileObj.pass_series;
    this.profileObjSaved.pass_num = this.profileObj.pass_num;
    this.profileObjSaved.pass_issuer = this.profileObj.pass_issuer;
    this.profileObjSaved.pass_issue_date = this.profileObj.pass_issue_date;
    this.profileObjSaved.pass_issuer_code = this.profileObj.pass_issuer_code;
    this.profileObjSaved.foreign_pass_series = this.profileObj.foreign_pass_series;
    this.profileObjSaved.foreign_pass_num = this.profileObj.foreign_pass_num;
    this.profileObjSaved.foreign_pass_issuer = this.profileObj.foreign_pass_issuer;
    this.profileObjSaved.has_foreign_pass = this.profileObj.has_foreign_pass;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    ...mapActions(["showNotify"]),
    submitData(key, date) {
      let value = ["pass_issue_date"].includes(key) ? formatDateObj(date, fmtDate, null) : this.profileObj[key];
      if (key === "pass_issuer_code") {
        value = this[key].replace(/[-\s]/g, "");
      }
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  },
  watch: {
    pass_issuer_code(newVal) {
      this.pass_issuer_code = passportIssuerCodeMask(newVal);
    }
  }
};