export function setValueTimeout(
  obj,
  key,
  value = true,
  timeout = 1500,
  valueTimeout = null
) {
  if (typeof obj !== "object" || !obj) {
    return;
  }
  obj[key] = value;
  setTimeout(() => {
    obj[key] = valueTimeout;
  }, timeout);
}
