import { innerCheckReason } from "@/helpers/text";
import { statesPartners } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
import { option4select } from "@/helpers/global";
//import links from "@/router/links";

export default {
  name: "PartnersResult",
  data: () => ({
    showModal: false,
    typeSource: null,
    idSource: "",
    tableFields: [{
      key: "index",
      label: "№"
    }, {
      key: "title",
      label: "Повод"
    }, {
      key: "remove",
      label: "Удалить",
      class: "text-center"
    }]
  }),
  methods: {
    ...mapActions("partners", ["changePartner"]),
    addSource() {
      this.typeSource = null;
      this.idSource = "";
      this.showModal = true;
    },
    async handlerAdd() {
      // const key =
      //   innerCheckReason[this.typeSource] === innerCheckReason.coi
      //     ? "foundations_coi"
      //     : "foundations_message";
      // await this.changeInnerCheck({
      //   id: this.innerCheckForm.id,
      //   params: { [key]: this.innerCheckForm[key].concat(this.idSource) },
      // });
    },
    async removeSource(item) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Удалить повод ${item.title}?`, {
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Удалить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      });
      if (!isConfirmed) return;
      // this.innerCheckForm[item.key].splice(item.index, 1);
      // await this.changeInnerCheck({
      //   id: this.innerCheckForm.id,
      //   params: { [item.key]: this.innerCheckForm[item.key] },
      // });
    }
  },

  computed: {
    ...mapGetters("partners", ["partnerForm"]),
    okModalAddDisabled() {
      return !this.typeSource || !this.idSource.trim().length;
    },
    optionsSource() {
      return option4select(innerCheckReason);
    },
    readOnly() {
      return statesPartners[this.partnerForm.state] === statesPartners.draft;
    },
    source() {
      return [];
      // return [].concat(
      //   this.innerCheckForm.foundations_coi.map((coi, index) => ({
      //     key: "foundations_coi",
      //     index,
      //     link: this.innerCheckForm.foundations_coi_coid[coi]
      //       ? links.declarationForm +
      //         this.innerCheckForm.foundations_coi_coid[coi]
      //       : "",
      //     title: innerCheckReason.coi + " #" + coi,
      //   })),
      //   this.innerCheckForm.foundations_message.map((msg, index) => ({
      //     key: "foundations_message",
      //     index,
      //     link: links.hotlineForm + msg,
      //     title: innerCheckReason.msg + " #" + msg,
      //   }))
      // );
    }
  }
};