import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound } from "@/helpers/text.js";
export default {
  name: "AdminCountries",
  data: () => ({
    currentItem: {},
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [
    // { key: "id", label: "#" },
    {
      key: "title",
      label: "Название"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsChange: {
      title: "Редактировать роль",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        title: {
          required: true,
          label: "Название"
        }
      }
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminElementAdd
  },
  created() {
    this.updateAdminRoles({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminRoles", "changeAdminRoles"]),
    // пагинация
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminRolesCount / this.perPage, this.currentPage);
      this.updateAdminRoles({
        page: this.currentPage
      });
    },
    // изменить роль
    async clickChange(data) {
      await this.changeAdminRoles(data);
      await this.updateAdminRoles({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminRoles", "adminRolesLoading", "adminRolesCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminRoles({
          page: value
        });
      }
    }
  }
};