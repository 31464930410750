import { NONAME_SHORT } from "@/helpers/text";
export default {
  name: "ActivityListDetailsAdd",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    NONAME_SHORT,
    file: null,
    comment: ""
  }),
  methods: {
    addDetails() {
      this.file = null;
      this.comment = "";
      this.$bvModal.show(this.item.idModal);
    },
    handlerOk() {
      this.$emit("addDetails", {
        addTo: this.item.addTo,
        file: this.file,
        comment: this.comment
      });
    },
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    handlerAdd(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      this.$refs.fileInput.value = null;
    }
  },
  computed: {
    okModalDisabled() {
      return !this.comment.trim().length;
    }
  }
};