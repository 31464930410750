// шаблон для работы с декларацией тексты мэппинг полей и т.п.
const declarationSectionText = {
  1: {
    text: "1. Выполняете ли Вы работу по совместительству или осуществляете иную оплачиваемую деятельность на основании гражданско-правового договора?",
    //switchSection: false,
    switchSectionId: "1",
    switchShow: true,
    bottomSection: [
      "Наименование юридического лица и/или индивидуального предпринимателя, в котором выполняется работа по совместительству, осуществляется деятельность на основании гражданско-правового договора",
      "Организационно-правовая форма",
      "ИНН юридического лица и/ или индивидуального предпринимателя",
      "Занимаемая должность",
      "Основания осуществления деятельности на основании гражданско-правового договора",
      "Дата начала работы",
      "id",
    ],
    switchSectionMaping: "has_part_time_job",
    bottomSectionMaping: {
      keyDB: "part_time_jobs",
      multy: true,
      multyDB: {
        1: {
          keyDB: "entity_name",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "organization_form",
          indexBS: 1,
          multy: false,
          keyInObject: false,
        },
        3: {
          keyDB: "entity_inn",
          indexBS: 2,
          multy: false,
          keyInObject: false,
        },
        4: {
          keyDB: "position",
          indexBS: 3,
          multy: false,
          keyInObject: false,
        },
        5: {
          keyDB: "foundations",
          indexBS: 4,
          multy: false,
          keyInObject: false,
        },
        6: {
          keyDB: "service_start",
          indexBS: 5,
          multy: false,
          keyInObject: false,
        },
        7: {
          keyDB: "id",
          indexBS: 6,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionInfo: [], // пока это не используе, задумывалось для сокращенного вывода информации по секции декларации
    bottomSectionData: {}, // здесь будут данные из API
    // шаблон для отрисовки запроса/ввода информации по секции
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_name",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "organization_form",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_inn",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "number",
        dataRequired: "inn",
        dataInvalidMessage: "ИНН должен быть 10 или 12 цифр",
        gridLg: "6",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "position",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "foundations",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      5: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "service_start",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "date",
        gridLg: "4",
        // dataRequired: "date",
        // dataInvalidMessage: "Дата должна быть выбрана",
      },
      6: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 6,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  2: {
    text: "2. Являетесь ли Вы индивидуальным предпринимателем/ самозанятым гражданином?",
    //switchSection: false,
    switchSectionId: "2",
    switchShow: true,
    bottomSection: ["ИНН", "ОГРНИП", "id"],
    switchSectionMaping: "is_self_employed",
    bottomSectionMaping: {
      keyDB: "ind_enterprise",
      multy: true,
      multyDB: {
        1: {
          keyDB: "ip_inn",
          indexBS: 0,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
        2: {
          keyDB: "ogrnip",
          indexBS: 1,
          multy: false,
          keyInObject: false,
        },
        3: {
          keyDB: "id",
          indexBS: 2,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionInfo: [],
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "ip_inn",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "id",
        // dataRequired: "inn",
        // dataInvalidMessage: "ИНН должен быть 10 или 12 цифр",
        gridLg: "0",
        hidden: true,
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "ogrnip",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "number",
        dataRequired: "ogrnip",
        dataInvalidMessage: "ОГРНИП должен быть 15 цифр",
        gridLg: "4",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  3: {
    text: "3. Принимаете ли Вы участие в деятельности юридических лиц, в том числе зарегистрированных за пределами Российской Федерации? (прямое, косвенное, бенефициарное)",
    //switchSection: false,
    switchSectionId: "3",
    switchShow: true,
    bottomSection: [
      "Имеете ли Вы в силу своего участия в деятельности юридических лиц, возможность оказывать влияние на решения, принимаемые такими лицами? (управление компанией, заключение сделок и т.п.)",
      "Наименование юридического лица",
      "Организационно-правовая форма",
      "ИНН юридического лица",
      "id",
    ],
    switchSectionMaping: "participates_in_legal_entities",
    bottomSectionMaping: {
      keyDB: "influenced_entities",
      multy: true,
      multyDB: {
        //1: { keyDB: "id", indexBS: 0, multy: false, keyInObject: false },
        1: {
          keyDB: "has_influence",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "entity_name",
          indexBS: 1,
          multy: false,
          keyInObject: false,
        },
        3: {
          keyDB: "organization_form",
          indexBS: 2,
          multy: false,
          keyInObject: false,
        },
        4: {
          keyDB: "entity_inn",
          indexBS: 3,
          multy: false,
          keyInObject: false,
        },
        5: {
          keyDB: "id",
          indexBS: 4,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
      onlyDB: {},
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "has_influence",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "checkbox",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_name",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "organization_form",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_inn",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "number",
        dataRequired: "inn",
        dataInvalidMessage: "ИНН должен быть 10 или 12 цифр",
        gridLg: "4",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  4: {
    text: "4. Владеете ли Вы информацией об участии Ваших близких родственников (свойственников)**  в деятельности юридических лиц?",
    //switchSection: false,
    switchSectionId: "4",
    switchShow: true,
    bottomSection: [
      "Имеют ли Ваши близкие родственники в силу своего участия в деятельности юридических лиц возможность оказывать влияние на решения, принимаемые такими лицами? (управление компанией, заключение сделок и т.п.)",
      "Степень родства (свойства)",
      "Фамилия",
      "Имя",
      "Отчество",
      "Наименование юридического лица",
      "Организационно-правовая форма",
      "ИНН юридического лица",
      "id",
    ],
    switchSectionMaping: "relatives_participate_in_entities",
    bottomSectionMaping: {
      keyDB: "relatives_entities",
      multy: true,
      multyDB: {
        1: {
          keyDB: "has_influence",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },

        2: {
          keyDB: "relation_degree",
          indexBS: 1,
          multy: false,
          keyInObject: false,
        },
        3: {
          keyDB: "last_name",
          indexBS: 2,
          multy: false,
          keyInObject: false,
        },
        4: {
          keyDB: "first_name",
          indexBS: 3,
          multy: false,
          keyInObject: false,
        },
        5: {
          keyDB: "middle_name",
          indexBS: 4,
          multy: false,
          keyInObject: false,
        },
        6: {
          keyDB: "entity_name",
          indexBS: 5,
          multy: false,
          keyInObject: false,
        },
        7: {
          keyDB: "organization_form",
          indexBS: 6,
          multy: false,
          keyInObject: false,
        },
        8: {
          keyDB: "entity_inn",
          indexBS: 7,
          multy: false,
          keyInObject: false,
        },
        9: {
          keyDB: "id",
          indexBS: 8,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "has_influence",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "checkbox",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "relation_degree",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "last_name",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "first_name",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "middle_name",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      5: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_name",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      6: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "organization_form",
        indexName: 6,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      7: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_inn",
        indexName: 7,
        keyInObject: false,
        keyIn: false,
        type: "number",
        dataRequired: "inn",
        dataInvalidMessage: "ИНН должен быть 10 или 12 цифр",
        gridLg: "4",
      },
      8: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 8,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  5: {
    text: `5. Владеете ли Вы информацией о том, что Ваши близкие родственники (свойственники)** являются работниками настоящей организации?`,
    //switchSection: false,
    switchSectionId: "5",
    switchShow: true,
    bottomSection: [
      "Степень родства (свойства)",
      "Фамилия",
      "Имя",
      "Отчество",
      "Занимаемая должность",
      "id",
    ],
    switchSectionMaping: "has_relatives_in_mdto",
    bottomSectionMaping: {
      keyDB: "relatives_in_mdto",
      multy: true,
      multyDB: {
        1: {
          keyDB: "relation_degree",
          indexBS: 0,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        2: {
          keyDB: "last_name",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        3: {
          keyDB: "first_name",
          indexBS: 2,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        4: {
          keyDB: "middle_name",
          indexBS: 3,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        5: {
          keyDB: "position",
          indexBS: 4,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
        6: {
          keyDB: "id",
          indexBS: 5,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },

    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "relation_degree",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "last_name",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "first_name",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "middle_name",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "position",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "id",
        hidden: true,
      },
      5: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  6: {
    text: "6. Владеете ли Вы информацией о том, что Ваши близкие родственники (свойственники)** являются работниками организаций Транспортного комплекса Москвы?",
    //switchSection: false,
    switchSectionId: "6",
    switchShow: true,
    bottomSection: [
      "Степень родства",
      "Фамилия",
      "Имя",
      "Отчество",
      "Наименование юридического лица",
      "Организационно-правовая форма",
      "Занимаемая должность",
      "id",
    ],
    switchSectionMaping: "has_relatives_in_mostrans",
    bottomSectionMaping: {
      keyDB: "relatives_in_mostrans",
      multy: true,
      multyDB: {
        1: {
          keyDB: "relation_degree",
          indexBS: 0,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        2: {
          keyDB: "last_name",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        3: {
          keyDB: "first_name",
          indexBS: 2,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        4: {
          keyDB: "middle_name",
          indexBS: 3,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        5: {
          keyDB: "entity_name",
          indexBS: 4,
          multy: false,
          keyInObject: false,
        },
        6: {
          keyDB: "organization_form",
          indexBS: 5,
          multy: false,
          keyInObject: false,
        },
        7: {
          keyDB: "position",
          indexBS: 6,
          multy: false,
          keyInObject: false,
        },
        8: {
          keyDB: "id",
          indexBS: 7,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },

    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "relation_degree",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "last_name",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "first_name",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "middle_name",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      4: {
        name: null,
        data: null,
        keyForDB: "entity_name",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      5: {
        name: null,
        data: null,
        keyForDB: "organization_form",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      6: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "position",
        indexName: 6,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      7: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 7,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  7: {
    text: "7. Владеете ли Вы информацией о том, что Ваши близкие родственники (свойственники)** являются деловыми партнерами или сотрудниками делового партнера настоящей организации?",
    //switchSection: false,
    switchSectionId: "7",
    switchShow: true,
    bottomSection: [
      "Степень родства",
      "Фамилия",
      "Имя",
      "Отчество",
      "Наименование юридического лица",
      "Организационно-правовая форма",
      "ИНН юридического лица",
      "Занимаемая должность",
      "id",
    ],
    switchSectionMaping: "has_relatives_mdto_partners",
    bottomSectionMaping: {
      keyDB: "relatives_mdto_partners",
      multy: true,
      multyDB: {
        1: {
          keyDB: "relation_degree",
          indexBS: 0,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        2: {
          keyDB: "last_name",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        3: {
          keyDB: "first_name",
          indexBS: 2,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        4: {
          keyDB: "middle_name",
          indexBS: 3,
          multy: false,
          keyInObject: false,
          keyIn: false,
        },
        5: {
          keyDB: "entity_name",
          indexBS: 4,
          multy: false,
          keyInObject: false,
        },
        6: {
          keyDB: "organization_form",
          indexBS: 5,
          multy: false,
          keyInObject: false,
        },
        7: {
          keyDB: "entity_inn",
          indexBS: 6,
          multy: false,
          keyInObject: false,
        },
        8: {
          keyDB: "position",
          indexBS: 7,
          multy: false,
          keyInObject: false,
        },
        9: {
          keyDB: "id",
          indexBS: 8,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
      onlyDB: {},
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "relation_degree",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "last_name",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "first_name",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "middle_name",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "text",
        gridLg: "4",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_name",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      5: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "organization_form",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      6: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "entity_inn",
        indexName: 6,
        keyInObject: false,
        keyIn: false,
        type: "number",
        dataRequired: "inn",
        dataInvalidMessage: "ИНН должен быть 10 или 12 цифр",
        gridLg: "4",
      },
      7: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "position",
        indexName: 7,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      8: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 8,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  8: {
    text: "8. Являетесь(лись) ли Вы государственным, муниципальным служащим, военнослужащим или иным лицом, для которых в целях противодействия коррупции установлены ограничения, запреты и требования?",
    //switchSection: false,
    switchSectionId: "8",
    switchShow: true,
    bottomSection: [
      "Вид службы/деятельности",
      "Место работы/службы",
      "Должность",
      "Дата начала службы/работы",
      "Дата окончания службы/работы",
      "id",
    ],
    switchSectionMaping: "is_civil_servant",
    bottomSectionMaping: {
      keyDB: "civil_services",
      multy: true,
      multyDB: {
        1: {
          keyDB: "civil_service_kind",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "service_place",
          indexBS: 1,
          multy: false,
          keyInObject: false,
        },
        3: {
          keyDB: "position",
          indexBS: 2,
          multy: false,
          keyInObject: false,
        },
        4: {
          keyDB: "service_start",
          indexBS: 3,
          multy: false,
          keyInObject: false,
        },
        5: {
          keyDB: "service_end",
          indexBS: 4,
          multy: false,
          keyInObject: false,
        },
        6: {
          keyDB: "id",
          indexBS: 5,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "civil_service_kind",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "service_place",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      2: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "position",
        indexName: 2,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      3: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "service_start",
        indexName: 3,
        keyInObject: false,
        keyIn: false,
        type: "date",
        gridLg: "6",
      },
      4: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "service_end",
        indexName: 4,
        keyInObject: false,
        keyIn: false,
        type: "date",
        gridLg: "6",
      },
      5: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 5,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  9: {
    text: "9. Осуществляли ли Вы в отношении организации управленческую деятельность, контрольно-надзорные и организационно-распорядительные функции, рассматривали документы и материалы организации и принимали по ним решения, занимая должность государственного, муниципального служащего или иного лица, для которого в целях противодействия коррупции установлены ограничения, запреты и требования?",
    switchSectionId: "9",
    switchShow: true,
    bottomSection: ["Опишите подробно тип и суть конфликта интересов", "id"],
    switchSectionMaping: "has_administrative_restrictions",
    bottomSectionMaping: {
      keyDB: "administrative_restrictions",
      multy: true,
      multyDB: {
        1: {
          keyDB: "description",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "id",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "description",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  10: {
    text: "10. Имеются ли какие-либо конфликты интересов, не указанные в п.п. 1-9? ",
    //switchSection: false,
    switchSectionId: "10",
    switchShow: true,
    bottomSection: ["Опишите подробно тип и суть конфликта интересов", "id"],
    switchSectionMaping: "has_other_COI",
    bottomSectionMaping: {
      keyDB: "other_conditions",
      multy: true,
      multyDB: {
        1: {
          keyDB: "description",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "id",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "description",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  11: {
    text: "11. Нарушали ли Вы требования внутренних документов, регламентирующих порядок обмена подарками и знаками делового гостеприимства?",
    switchSectionId: "11",
    switchShow: true,
    bottomSection: ["Опишите подробно суть нарушения", "id"],
    switchSectionMaping: "has_violations",
    bottomSectionMaping: {
      keyDB: "violations",
      multy: true,
      multyDB: {
        1: {
          keyDB: "description",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "id",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "description",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  12: {
    text: "12. Известно ли Вам о каких-либо иных обстоятельствах, не указанных выше, которые вызывают или могут вызвать конфликт интересов, или могут создать впечатление у Ваших коллег и руководителей, что Вы принимаете решения под воздействием конфликта интересов?",
    //switchSection: false,
    switchSectionId: "12",
    switchShow: true,
    bottomSection: ["Опишите подробно тип и суть", "id"],
    switchSectionMaping: "knows_about_other_circumstances",
    bottomSectionMaping: {
      keyDB: "other_conflicts",
      multy: true,
      multyDB: {
        1: {
          keyDB: "description",
          indexBS: 0,
          multy: false,
          keyInObject: false,
        },
        2: {
          keyDB: "id",
          indexBS: 1,
          multy: false,
          keyInObject: false,
          hidden: true,
        },
      },
    },
    bottomSectionData: {}, // здесь будут данные из API
    bottomSectionRenderTemplate: {
      0: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "description",
        indexName: 0,
        keyInObject: false,
        keyIn: false,
        type: "text",
      },
      1: {
        name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
        data: null, // пока пусто, потом здесь будет значение
        keyForDB: "id",
        indexName: 1,
        keyInObject: false,
        keyIn: false,
        type: "id", // валиден всегда
        hidden: true,
      },
    },
  },
  // 14: {
  //   text:
  //     "13. Если ответ на вопрос 11, и/или 12 «да»,- изложите в поле 13.1. подробную информацию для всестороннего рассмотрения и оценки обстоятельств.",
  //   switchSection: true,
  //   switchSectionId: "14",
  //   switchShow: false,
  //   bottomSection: ["Опишите подробно тип и суть"],
  //   switchSectionMaping: "has_circumstances_detail",
  //   bottomSectionMaping: {
  //     keyDB: "circumstances_detail",
  //     multy: true,
  //     multyDB: {
  //       1: {
  //         keyDB: "description",
  //         indexBS: 0,
  //         multy: false,
  //         keyInObject: false,
  //       },
  //       2: {
  //         keyDB: "id",
  //         indexBS: 1,
  //         multy: false,
  //         keyInObject: false,
  //         hidden: true,
  //       },
  //     },
  //   },
  //   bottomSectionData: {}, // здесь будут данные из API
  //   bottomSectionRenderTemplate: {
  //     0: {
  //       name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
  //       data: null, // пока пусто, потом здесь будет значение
  //       keyForDB: "description",
  //       indexName: 0,
  //       keyInObject: false,
  //       keyIn: false,
  //       type: "text",
  //     },
  //     1: {
  //       name: null, // пока пусто, потом по индексу indexBS подтянем из bottomSection
  //       data: null, // пока пусто, потом здесь будет значение
  //       keyForDB: "id",
  //       indexName: 1,
  //       keyInObject: false,
  //       keyIn: false,
  //       type: "id", // валиден всегда
  //       hidden: true,
  //     },
  //   },
  // },
};

export default declarationSectionText;
