import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "ThirtyThird",
  data: () => ({
    optionYesNo,
    has_relatives_high_position_state: null,
    profileObjSaved: {}
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.has_relatives_high_position = this.profileObj.has_relatives_high_position;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData(key) {
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        setValueTimeout(this, [key] + "_state");
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"])
  }
};