import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { periodicityOptions } from "@/helpers/date";
export default {
  name: "AdminAdmin",
  data: () => ({
    form: {
      email: "admin@mdto.ru",
      period: "month"
    },
    periodicityOptions,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    await this.updateAdmin();
    this.form.email = this.adminAdmin.email;
    this.form.period = this.adminAdmin.period;
  },
  methods: {
    ...mapActions("administration", ["updateAdmin", "changeAdmin"]),
    async clickSave() {
      this.changeAdmin(this.form);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminAdmin", "adminAdminLoading"])
  }
};