import { mapGetters } from "vuex";
export default {
  name: "theWorkplace",
  props: {
    workplaceIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    ...mapGetters("paUser", ["workplaces"])
  },
  methods: {}
};