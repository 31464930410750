import { monthsOptions, daysOptions, yearsOptions, dateObjInit } from "@/helpers/date";
export default {
  name: "theCalendar",
  props: {
    title: {
      type: String,
      default: "Дата"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    classCustom: {
      type: String,
      default: ""
    },
    onlyYear: {
      type: Boolean,
      default: false
    },
    cols: {
      type: String,
      default: ""
    },
    date: {
      type: Object,
      default: () => ({
        ...dateObjInit
      })
    }
  },
  data: () => ({
    daysOptions,
    monthsOptions,
    yearsOptions,
    propDate: {
      ...dateObjInit
    }
  }),
  created() {
    this.propDate = {
      ...this.date
    };
  },
  methods: {
    changeValue() {
      this.$emit("changeValue", this.propDate);
    }
  },
  watch: {
    date(newVal) {
      this.propDate = {
        ...newVal
      };
    }
  }
};