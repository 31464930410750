import links from "@/router/links";
import { ff } from "@/helpers/global";
import perm from "@/auth/permissions.js";

export const adminCards = [
  {
    enabled: ff.ADMINISTRATION,
    title: "Пользователи",
    link: links.adminUsers,
    hook: "users",
    perm: perm.view_admin_user,
  },
  // {
  //   enabled: ff.SETTINGS,
  //   title: "Резервное копирование",
  //   link: links.adminBackup,
  //   hook: "backup",
  // },
  // {
  //   enabled: ff.SETTINGS,
  //   title: "Журнал событий",
  //   link: links.adminActivity,
  //   hook: "activity",
  // },
  {
    enabled: ff.ADMINISTRATION,
    title: "Организации",
    link: links.adminOrg,
    hook: "organization",
    perm: perm.view_admin_organization,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Подразделения",
    link: links.adminDepartment,
    hook: "department",
    perm: perm.view_admin_department,
  },
  {
    enabled: ff.PROFILE,
    title: "Личные листки кадровика",
    link: links.profilesKadr,
    hook: "profilesKadr",
    perm: perm.view_wa_profile,
  },
  {
    enabled: ff.PROFILE,
    title: "Проверки личных листков",
    link: links.profileChecks,
    hook: "profileChecks",
    perm: perm.view_wa_profilecheck,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Статистика",
    link: links.adminStatistics,
    hook: "statistics",
    perm: perm.access_admin_orgs_stats,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Журнал безопасности",
    link: links.securityEvents,
    hook: "securityEvents",
    perm: perm.view_security_events,
  },
].filter((el) => el.enabled);

export const settingsCards = [
  {
    enabled: ff.SETTINGS,
    title: "Общие",
    link: links.adminCommon,
    perm: perm.set_site_properties,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Настройки безопасности",
    link: links.securitySettings,
    perm: perm.view_security_events,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Настройка личного листка",
    link: links.profileSheetSettings,
    perm: perm.change_profile_required_fields,
  },
  // {
  //   enabled: ff.KNOWLEDGE,
  //   title: "Модуль «Библиотека знаний»",
  //   link: links.adminKnowledge,
  // },
  // {
  //   enabled: ff.EDUCATION,
  //   title: "Модуль «Обучение»",
  //   link: links.adminEducation,
  // },
  // {
  //   enabled: ff.DECLARATION,
  //   title: "Модуль «Декларации»",
  //   link: links.adminDeclaration,
  // },
  // {
  //   enabled: ff.INNERCHECK,
  //   title: "Модуль «Внутренние проверки»",
  //   link: links.adminInnerCheck,
  // },
  {
    enabled: ff.RISK,
    title: "Настройка оценки рисков",
    link: links.adminRisk,
    perm: perm.change_risk_calc_settings,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Настройки уведомлений",
    link: links.adminNotification,
    perm: perm.view_admin_notif_settings,
  },
  // {
  //   enabled: ff.HOTLINE,
  //   title: "Модуль «Горячая линия»",
  //   link: links.adminHotLine,
  // },
  // {
  //   enabled: ff.SETTINGS,
  //   title: "Модуль «Администрирование»",
  //   link: links.adminAdmin,
  // },
].filter((el) => el.enabled);

export const directoryCards = [
  {
    enabled: ff.ADMINISTRATION,
    title: "Справочник «Работники»",
    link: links.adminWorkers,
    perm: perm.view_wa_worker,
  },
  {
    enabled: ff.PARTNERS,
    title: "Справочник «Деловые партнёры»",
    perm: perm.view_admin_bp,
    link: links.adminPartners,
  },
  // {
  //   enabled: ff.SETTINGS,
  //   title: "Справочник «Рабочие и нерабочие дни»",
  //   link: links.adminCalendar,
  // },
  {
    enabled: ff.KNOWLEDGE,
    title: "Справочник «Типы ЛНА»",
    link: links.adminLnaTypes,
    perm: perm.view_admin_lnatype,
  },
  // {
  //   enabled: ff.DECLARATION,
  //   title: "Справочник «Типы конфликтов интересов»",
  //   link: links.adminDirectoryList + "coi",
  // },
  {
    enabled: ff.DECLARATION,
    title: "Справочник «Типы контрольных процедур»",
    link: links.adminCpTypes,
    perm: perm.view_admin_cp_types,
  },
  {
    enabled: ff.HOTLINE,
    // task #185 - справочник "Категории сообщений на горячую линию" на "Типы сообщений на горячую линию"
    title: "Справочник «Типы сообщений на горячую линию»",
    link: links.adminHlCategory,
    perm: perm.view_admin_hotmsgcat,
  },
  {
    enabled: ff.INNERCHECK,
    title: "Справочник «Категории проверки»",
    link: links.adminInnerCheck,
    perm: perm.view_admin_innercheck_categories,
  },
  {
    enabled: ff.PROFILE,
    title: "Справочник «Страны»",
    link: links.adminCountries,
    perm: perm.access_admin,
  },
  {
    enabled: ff.ADMINISTRATION,
    title: "Справочник «Роли пользователей»",
    link: links.adminRoles,
    perm: perm.view_admin_role_names,
  },
].filter((el) => el.enabled);
