import { formatDate, fmtDateShort } from "@/helpers/date";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import links from "@/router/links";
import { statesPartners } from "@/helpers/states";
import { mapGetters, mapActions } from "vuex";
import { limitBound, CONST_NONAME, formatName, fmtNameShort, typeRiskLevel } from "@/helpers/text";
export default {
  name: "PartnersList",
  components: {
    TrafficLight
  },
  data: () => ({
    statesPartners,
    CONST_NONAME,
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    currentItem: {},
    showModalRemove: false
  }),
  computed: {
    ...mapGetters("partners", ["partners", "partnersCount", "partnersLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updatePartners({
          ...this.sortBy,
          page: value
        });
      }
    },
    sortBy() {
      return this.isAdminPath ? {
        sort: "full_name"
      } : {};
    },
    isAdminPath() {
      return this.$route.path === links.adminPartners;
    },
    tableFields() {
      return this.isAdminPath ? [{
        key: "id",
        label: "#"
      }, {
        key: "full_name",
        label: "Наименование"
      }, {
        key: "contract_date",
        label: "Дата договора",
        class: "text-nowrap",
        formatter: value => formatDate(value, fmtDateShort)
      }, {
        key: "contract_num",
        label: "№ договора",
        class: "text-nowrap"
      }, {
        key: "resulting_risk_level",
        label: "Уровень риска",
        class: "text-center"
      }] : [{
        key: "id",
        label: "#"
      }, {
        key: "state",
        label: "Статус",
        class: "text-nowrap",
        formatter: value => statesPartners[value]
      }, {
        key: "user_detail",
        label: "Инициатор",
        formatter: value => {
          return formatName(value, fmtNameShort);
        }
      }, {
        key: "full_name",
        label: "Наименование"
      }, {
        key: "checks",
        label: "Внутренние проверки",
        class: "text-nowrap"
      }, {
        key: "resulting_risk_level",
        label: "Уровень риска",
        class: "text-center"
      }, {
        key: "contract_date",
        label: "Дата договора",
        class: "text-nowrap",
        formatter: value => formatDate(value, fmtDateShort)
      }, {
        key: "contract_num",
        label: "№ договора",
        class: "text-nowrap"
      }, {
        key: "btnRemove",
        label: "",
        class: "p-0"
      }];
    }
  },
  methods: {
    ...mapActions("partners", ["updatePartners", "removePartner"]),
    typeRiskLevel,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.partnersCount / this.perPage, this.currentPage);
      this.updatePartners({
        ...this.sortBy,
        page: this.currentPage
      });
    },
    clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removePartner(this.currentItem);
      this.currentPage = 1;
      await this.updatePartners({
        ...this.sortBy,
        page: 1
      });
    }
  }
};