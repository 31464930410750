import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_form_group = _resolveComponent("b-form-group");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_ctx.coiForm.declaration ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0
    }, {
      default: _withCtx(() => [_createTextVNode(" Декларация о конфликте интересов #" + _toDisplayString(_ctx.coiForm.declaration), 1)]),
      _: 1
    })) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createVNode(_component_b_col, {
      cols: "auto",
      class: "pt-2"
    }, {
      default: _withCtx(() => [_createTextVNode("Внутренняя проверка #")]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "1",
      class: "me-6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.coiForm.innercheck,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.coiForm.innercheck = $event),
        type: "number",
        trim: "",
        onChange: _cache[1] || (_cache[1] = $event => $options.changeData('innercheck')),
        disabled: $options.readOnly
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto",
      class: "ms-6 pt-2"
    }, {
      default: _withCtx(() => [_createTextVNode("Сообщение горячей линии #")]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.coiForm.hotmsg,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.coiForm.hotmsg = $event),
        type: "number",
        trim: "",
        onChange: _cache[3] || (_cache[3] = $event => $options.changeData('hotmsg')),
        disabled: $options.readOnly
      }, null, 8, ["modelValue", "disabled"])]),
      _: 1
    })], 64))]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Основание проверки (текст)"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.coiForm.foundations_additional,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.coiForm.foundations_additional = $event),
      onChange: _cache[5] || (_cache[5] = $event => $options.changeData('foundations_additional')),
      placeholder: "Введите текст",
      rows: "3",
      "max-rows": "3",
      trim: "",
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  })]);
}