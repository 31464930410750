import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "15. Привлекались ли к уголовной ответственности?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_criminal_record,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_criminal_record = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_criminal_record')),
          state: _ctx.has_criminal_record_state,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_ctx.profileObj.has_criminal_record ? (_openBlock(), _createBlock(_component_b_form_input, {
        key: 0,
        modelValue: _ctx.profileObj.criminal_record,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.profileObj.criminal_record = $event),
        onChange: _cache[3] || (_cache[3] = $event => $options.submitData('criminal_record')),
        state: _ctx.criminal_record_state,
        disabled: $options.readonly,
        placeholder: "если да, то когда и за что (укажите статьи)",
        type: "text",
        trim: ""
      }, null, 8, ["modelValue", "state", "disabled"])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  });
}