import { mapActions, mapGetters } from "vuex";
import { formatDateObj, fmtDate, dateObjInit } from "@/helpers/date";
import ReportCount from "./ReportCount.vue";
import ReportRisk from "./ReportRisk.vue";
import Calendar from "@/components/Helpers/Calendar.vue";
export default {
  name: "PartnersReport",
  data: () => ({
    showReport: false,
    typeSelected: "report_num_bp",
    typeOptions: [{
      value: "report_num_bp",
      text: "Количество деловых партнеров"
    }, {
      value: "report_risk_level_by_bp",
      text: "Итоговый уровень риска"
    }],
    selected: {},
    // параметры формирования отчета
    startDate: {
      ...dateObjInit
    },
    endDate: {
      ...dateObjInit
    }
  }),
  components: {
    ReportCount,
    ReportRisk,
    Calendar
  },
  methods: {
    ...mapActions("partners", ["updateReport", "downloadReport"]),
    setSelected() {
      this.selected.report_type = this.typeSelected;
      this.selected.submitted_gte = formatDateObj(this.startDate, fmtDate, null);
      this.selected.submitted_lte = formatDateObj(this.endDate, fmtDate, null);
    },
    async clickReport() {
      this.setSelected();
      await this.updateReport(this.selected);
      this.showReport = true;
    },
    async clickDownload() {
      this.showReport = false;
      this.setSelected();
      await this.downloadReport(this.selected);
      this.$refs.fileDownload.click();
    }
  },
  computed: {
    ...mapGetters("partners", ["partnerReportLoading", "partnerReportFile"])
  }
};