import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { statesEducProg } from "@/helpers/states";
import links from "@/router/links";
import { mapActions, mapGetters } from "vuex";
import { limitBound, optionsDesc, CONST_NONAME, optionsEducFormat } from "@/helpers/text";
import CourseSidebar from "./CourseSidebar.vue";
import thePersona from "@/components/Helpers/thePersona";
export default {
  name: "EducationTestsInner",
  data: () => ({
    currentItem: {},
    CONST_NONAME,
    showModalRemove: false,
    showSidebar: false,
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    sortBy: null,
    sortDesc: true,
    optionsDesc,
    filters: {
      edu_type: "internal",
      course_format: null,
      start_date_gte: null,
      end_date_lte: null,
      owner_department_id: null,
      instructor_search: null
    },
    optionsSort: [{
      value: "owner_department_name",
      text: "по владельцу"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "start_date",
      text: "по дате начала"
    }, {
      value: "end_date",
      text: "по дате окончания"
    }, {
      value: "instructor",
      text: "по тренеру"
    }],
    tableFields: [{
      key: "id",
      label: "№",
      class: "font-mono"
    }, {
      key: "title",
      label: "Программа"
    }, {
      key: "formatT",
      label: "Формат"
    }, {
      key: "owner_department_name",
      label: "Владелец",
      class: "text-nowrap"
    }, {
      key: "instructor",
      label: "Тренер",
      class: "text-nowrap"
    }, {
      key: "start_dateT",
      label: "Начало",
      class: "text-nowrap font-mono"
    }, {
      key: "end_dateT",
      label: "Окончание",
      class: "text-nowrap font-mono"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "pass",
      label: "Прошли",
      class: "text-nowrap"
    }, {
      key: "btnChange",
      label: ""
    }, {
      key: "btnRemove",
      label: ""
    }]
  }),
  components: {
    CourseSidebar,
    thePersona
  },
  computed: {
    ...mapGetters("education", ["cours", "coursLoading", "coursCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateCourses({
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: this.filters,
          page: value
        });
      }
    },
    items4table() {
      return Array.isArray(this.cours) ? this.cours.map(el => ({
        ...el,
        formatT: optionsEducFormat.find(x => x.value === el.format)?.text,
        start_dateT: formatDate(el.start_date, fmtDateShort),
        end_dateT: formatDate(el.end_date, fmtDateShort),
        stateT: statesEducProg[el.state]
      })) : [];
    }
  },
  methods: {
    ...mapActions("education", ["updateCourses", "deleteCourse"]),
    // обработка изменения сортировки
    sortChanged({
      sortBy,
      sortDesc
    }) {
      this.currentPage = 1;
      this.updateCourses({
        sortBy,
        sortDesc: sortDesc ? "-" : "",
        filters: this.filters,
        page: 1
      });
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.coursCount / this.perPage, this.currentPage);
      this.updateCourses({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: this.currentPage
      });
    },
    // применение фильтров по нажатии кнопки "Применить"
    async applyFilters(filters) {
      this.filters.course_format = filters.format;
      this.filters.start_date_gte = formatDateObj(filters.dateFrom, fmtDate, null);
      this.filters.end_date_lte = formatDateObj(filters.dateUntill, fmtDate, null);
      this.filters.owner_department_id = filters.owner;
      this.filters.instructor_search = filters.instructor;
      this.filters.is_new = filters.new;
      if (this.currentPage === 1) await this.updateCourses({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: 1
      });else this.currentPage = 1;
    },
    clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.deleteCourse(this.currentItem);
      this.currentPage = 1;
      await this.updateCourses({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: 1
      });
    }
  }
};