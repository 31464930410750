import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "mb-0"
};
const _hoisted_2 = {
  class: "me-3"
};
const _hoisted_3 = {
  key: 0,
  class: "me-3"
};
const _hoisted_4 = {
  key: 1,
  class: "me-3 text-muted"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createBlock(_component_b_card, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.coiTemplate), (keyname, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "details-wrap",
        key: index
      }, [$props.item[keyname] ? (_openBlock(), _createElementBlock("p", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.coiTemplate[keyname].title), 1), _ctx.coiTemplate[keyname].type === 'data' ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.formatDate($props.item[keyname])), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.item[keyname] === true ? "Да" : $props.item[keyname]), 1))])) : _createCommentVNode("", true)]);
    }), 128)), !$options.disabledAction && !_ctx.innerCheckLoading ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
      key: 0,
      class: "mt-2",
      variant: "outline-primary",
      onClick: $options.clickAddInnerCheck,
      size: "sm"
    }, {
      default: _withCtx(() => [_createTextVNode("Добавить внутреннюю проверку")]),
      _: 1
    }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_innercheck]]) : _createCommentVNode("", true), _ctx.innerCheckLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 1,
      class: "text-primary"
    })) : _createCommentVNode("", true)]),
    _: 1
  });
}