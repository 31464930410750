import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "bootstrap-vue-next";
export default {
  __name: 'theNotification',
  setup(__props) {
    const store = useStore();
    const messagePool = computed(() => store.getters["messagePool"]);
    const {
      show
    } = useToast();
    watch(messagePool, newValue => {
      show(newValue.msg, {
        title: newValue.title,
        variant: newValue.variant,
        pos: "top-right",
        value: 5000,
        interval: 100,
        progressProps: {
          variant: "primary"
        }
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("span");
    };
  }
};