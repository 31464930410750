import "core-js/modules/es.array.push.js";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { states } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort } from "@/helpers/text";
import { btn4transitCoi } from "@/helpers/coi";
import { validate } from "@/helpers/validate";
import declTemplate from "@/components/Declaration/declarationSectionTemplate";
import coiTemplate from "./coiTemplate";
export default {
  name: "COIFormHeader",
  data: () => ({
    states,
    fmtDateShort,
    links,
    fmtNameShort,
    noValidKeys: ["checkbox", "id"],
    showAlert: false,
    alertTitle: ""
  }),
  components: {
    TaskButton
  },
  methods: {
    ...mapActions("coi", ["changeStateCoi", "updateCOIAttach", "updateCOIComments", "createCOIAttach", "createCOIComments", "changeCOIForm", "updateCOIEvents"]),
    formatDate,
    formatName,
    async clickAction(btn, data) {
      this.showAlert = false;
      // сначала проверка на обязательные поля
      const errors = [];
      Object.values(this.coiRenderTemplate).forEach(el => {
        if (!this.noValidKeys.includes(el.type)) {
          const validResult = el.dataRequired ? validate({
            dataRequired: el.dataRequired,
            data: this.coiForm[el.keyForDB]
          }) : Boolean(this.coiForm[el.keyForDB]);
          if (!validResult) errors.push(coiTemplate[el.keyForDB]?.title + (el.dataInvalidMessage ? ` (${el.dataInvalidMessage})` : ""));
        }
      });
      if (!this.coiForm.declaration && !this.coiForm.innercheck && !this.coiForm.hotmsg && String(this.coiForm.foundations_additional).trim() === "") errors.push("Источник");
      if (errors.length) {
        this.alertTitle = errors.join(", ");
        this.showAlert = true;
        return;
      }
      if (btn.type === "taskBtn") {
        // change coi
        if (btn.settings && (btn.settings.addAssignee || btn.settings.addDeadline)) {
          const params = {};
          if (btn.settings.addAssignee) params.assignee = data.worker;
          if (btn.settings.addDeadline) params.deadline = data.deadline;
          await this.changeCOIForm({
            id: this.coiForm.id,
            params
          });
        }
        // add comment
        if (data.comment.trim() !== "") {
          await this.createCOIComments({
            id: this.coiForm.id,
            body: data.comment.trim()
          });
          this.updateCOIComments({
            id: this.coiForm.id,
            page: 1
          });
        }
        // add file
        if (data.files.length > 0) {
          for (const file of data.files) {
            const formData = new FormData();
            formData.append("document", file);
            formData.append("file_name", file.name);
            await this.createCOIAttach({
              id: this.coiForm.id,
              formData
            });
          }
          this.updateCOIAttach({
            id: this.coiForm.id,
            page: 1
          });
        }
      }
      // смена статуса
      await this.changeStateCoi({
        id: this.coiForm.id,
        msg: btn.msg,
        state: btn.stateTo
      });
      this.updateCOIEvents({
        coi_id: this.coiForm.id,
        page: 1
      });
    }
  },
  computed: {
    ...mapGetters("coi", ["coiForm"]),
    stateBtns() {
      return btn4transitCoi.filter(el => el.stateFrom === this.coiForm.state);
    },
    coiRenderTemplate() {
      if (this.coiForm.coi_type_code) {
        const template = Object.values(declTemplate).find(el => el.bottomSectionMaping.keyDB === this.coiForm.coi_type_code);
        return template ? template.bottomSectionRenderTemplate : {};
      } else return {};
    }
  }
};