// import UserSelector from "@/components/Helpers/UserSelector";
import Calendar from "@/components/Helpers/Calendar.vue";
import { selectAll } from "@/helpers/text";
import { dateObjInit } from "@/helpers/date";
export default {
  name: "DeclarationListFullSidebar",
  components: {
    Calendar
  },
  data: () => ({
    showItem: true,
    filters: {
      state: null,
      department: null,
      dateFrom: {
        ...dateObjInit
      },
      dateUntill: {
        ...dateObjInit
      },
      author: null,
      assignee: null,
      number: null
    },
    stateOptions: selectAll.concat([{
      value: "Создана",
      text: "Создана"
    }, {
      value: "На рассмотрении",
      text: "На рассмотрении"
    }, {
      value: "Рассмотрена",
      text: "Рассмотрена"
    }])
  }),
  methods: {
    selectedUser(user) {
      console.log(user);
    },
    clearFilters() {
      this.showItem = false;
      Object.keys(this.filters).forEach(key => {
        if (["dateFrom", "dateUntill"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    }
  }
};