import "core-js/modules/es.array.push.js";
import { SITE_NAME, NONAME_FIRST, NONAME_LAST, NONAME_MIDDLE } from "@/helpers/text.js";
import links from "@/router/links";
import { mapActions, mapGetters } from "vuex";
import { logout, loginBegin, getUserInfo, textLink4Prod, lsUserId } from "@/auth/auth.js";
import theWorkplace from "./PersonalArea/theWorkplace.vue";
import { userInitials } from "@/helpers/text";
import { phoneMask } from "@/helpers/masks";
export default {
  name: "theHeader",
  created() {
    this.updateWorkplaces();
    this.userObj = getUserInfo();
    this.updateCommon();
  },
  computed: {
    ...mapGetters("paUser", ["workplacesLoading", "workplacesCount", "workplaces"]),
    ...mapGetters("homeStats", ["newNotifyLk"]),
    ...mapGetters("administration", ["adminCommon", "adminCommonLoading"])
  },
  components: {
    theWorkplace
  },
  data: () => ({
    phoneMask,
    textLink4Prod,
    userInitials,
    links,
    SITE_NAME,
    NONAME_FIRST,
    NONAME_LAST,
    NONAME_MIDDLE,
    magicLink: null,
    userObj: {},
    modalTitle: "Сменить пользователя",
    modalLoginIndex: null,
    password: "",
    userChangeModal: false,
    showModalExit: false
  }),
  methods: {
    ...mapActions("paUser", ["updateWorkplaces"]),
    ...mapActions(["showNotify"]),
    ...mapActions("administration", ["updateCommon"]),
    // смена рабочего места
    clickWorkplace(index) {
      this.modalLoginIndex = index;
      this.modalTitle = `Сменить пользователя на ${this.workplaces[index].login}?`;
      this.password = "";
      this.userChangeModal = true;
    },
    async handlerLogoutFunc() {
      try {
        //await logout();
        this.magicLink = await loginBegin(this.workplaces[this.modalLoginIndex].login, this.password);
        localStorage.removeItem(lsUserId);
      } catch (error) {
        this.showNotify({
          msg: error.message,
          title: "Ошибка смены пользователя",
          variant: "danger"
        });
      }
    },
    // обработка выхода
    handlerLogout() {
      this.showModalExit = true;
    },
    async doLogout() {
      try {
        await logout();
      } catch (error) {
        this.showNotify({
          msg: error.message,
          title: "Ошибка выхода из системы",
          variant: "danger"
        });
      } finally {
        this.$router.push(links.auth);
      }
    }
  }
};