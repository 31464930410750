import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound } from "@/helpers/text";
export default {
  name: "theHistory",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    fields: {
      type: Array,
      default: () => []
    },
    busy: {
      type: Boolean,
      default: false
    },
    defaultFields: {
      type: Array,
      default: () => [{
        key: "index",
        label: "№"
      }, {
        key: "name",
        label: "Описание"
      }, {
        key: "date",
        label: "Дата",
        formatter: value => formatDate(value, fmtDateShort)
      }]
    }
  },
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    currentPage: 1
  }),
  methods: {
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.count / this.perPage, this.currentPage);
      this.$emit("setPage", this.currentPage);
    }
  },
  computed: {
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.$emit("setPage", value);
      }
    },
    tableFields() {
      return this.defaultFields.concat(this.fields);
    }
  }
};