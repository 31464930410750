import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { checkPermissions } from "@/auth/auth";
import CP from "./CP.vue";
export default {
  name: "CPBase",
  components: {
    theNotification,
    BreadCrumbToHome,
    CP
  },
  data: () => ({}),
  async created() {
    this.setOnlyNew(null); // обнулимся
    if (checkPermissions(this.$perm.view_wa_cp)) {
      this.fetchCp();
      this.updateCpDirectory();
    }
  },
  computed: {
    ...mapGetters("homeStats", ["onlyNew"]),
    ...mapGetters("cp", ["cpCount"])
  },
  methods: {
    ...mapActions("homeStats", ["setOnlyNew"]),
    ...mapActions("cp", ["fetchCp", "updateCpDirectory"]),
    checkPermissions
  }
};