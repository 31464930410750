import { formatLocalDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
export default {
  name: "EducationCourseCertificate",
  props: {
    msg: String,
    //сюда получаем id курса
    testName: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    links
  }),
  methods: {
    ...mapActions("education", ["updateCourse4User"]),
    formatLocalDate
  },
  async created() {
    await this.updateCourse4User(this.msg);
  },
  computed: {
    ...mapGetters("education", ["getCourse", "courseLoading"])
  }
};