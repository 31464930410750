import { optionsEducMaterials } from "@/helpers/text";
export default {
  name: "EducationCourseItem",
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    },
    currentItem: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    optionsEducMaterials
  }),
  methods: {
    onClick(num) {
      this.$emit("changeCurrent", num);
    }
  },
  computed: {}
};