import perm from "@/auth/permissions.js";

export const btn4transitHline = {
  draft: {
    type: "simple",
    text: "Зарегистрировать сообщение",
    variant: "primary",
    state: "submitted",
    perm: perm.add_wa_hotmsg,
    msg: "Сообщение зарегистрировано",
    validate: {
      registration_number: "Номер регистрации",
      arrival_date: "Дата поступления",
      source: "Источник поступления",
      deadline: "Срок исполнения",
      message_type: "Тип сообщения",
      referenced_org: "Организация",
      message_category: "Категория сообщения",
      content: "Суть сообщения",
    },
    validateAuthor: {
      applicant_full_name: "Заявитель (фамилия, имя, отчество)",
      applicant_type: "Тип заявителя",
    },
    author: "is_anonymous",
    validateWorker: {
      applicant_job: "Место работы заявителя",
    },
    worker: "applicant_type",
    workerType: ["Деловой партнер", "Работник"],
  },
  submitted: {
    type: "taskBtn",
    modal: "taskModalSubmitted",
    state: "redirected",
    perm: perm.redirect_wa_hotmsg,
    msg: "Сообщение направлено куратору",
    settings: {
      buttonTitle: "Направить куратору",
      modalTitle: "Назначить куратора",
      addAssignee: true,
      buttonType: "primary",
      body: { curator: "worker" },
    },
  },
  redirected: {
    type: "taskBtn",
    modal: "taskModalRedirected",
    state: "delegated",
    perm: perm.assign_wa_dept_chief,
    msg: "Сообщение направлено руководителю подразделения",
    settings: {
      buttonTitle: "Направить руководителю подразделения",
      modalTitle: "Назначить руководителя подразделения",
      addAssignee: true,
      buttonType: "primary",
      body: { department_chief: "worker" },
    },
  },
  delegated: {
    type: "taskBtn",
    modal: "taskModalDelegated",
    state: "assigned",
    perm: perm.assign_wa_responsible,
    msg: "Сообщение направлено ответственному исполнителю",
    settings: {
      buttonTitle: "Направить исполнителю",
      modalTitle: "Назначить ответственного исполнителя",
      addAssignee: true,
      buttonType: "primary",
      body: { assignee: "worker" },
    },
  },
  assigned: {
    type: "simple",
    text: "Направить руководителю",
    variant: "primary",
    state: "checked",
    msg: "Сообщение направлено руководителю",
    perm: perm.view_wa_hotmsg,
    validate: {
      prolongation_notice: "Уведомление о продлении",
      response_attachment: "Ответ заявителю/Справка о результатах рассмотрения",
    },
  },
  checked: {
    type: "taskBtn",
    modal: "taskModalChecked",
    state: "prolonged",
    perm: perm.prolong_wa_hotmsg,
    msg: "Рассмотрение сообщения продлено",
    validate: {
      prolongation_notice: "Уведомление о продлении",
    },
    settings: {
      buttonTitle: "Продлить рассмотрение",
      modalTitle: "Продлить рассмотрение сообщения",
      addComment: true,
      buttonType: "primary",
      body: { commentCheif: "comment" },
    },
  },
  prolonged: {
    type: "simple",
    text: "Направить руководителю",
    variant: "primary",
    state: "prochecked",
    msg: "Сообщение направлено руководителю",
    perm: perm.view_wa_hotmsg,
    validate: {
      response_attachment: "Ответ заявителю/Справка о результатах рассмотрения",
      response_date: "Дата ответа",
      response_number: "Номер ответа",
    },
  },
  prochecked: {
    type: "taskBtn",
    modal: "taskModalProchecked",
    state: "completed",
    perm: perm.prolong_wa_hotmsg,
    msg: "Рассмотрение сообщения завершено",
    validate: {
      response_attachment: "Ответ заявителю/Справка о результатах рассмотрения",
      response_date: "Дата ответа",
      response_number: "Номер ответа",
    },
    settings: {
      buttonTitle: "Завершить рассмотрение",
      modalTitle: "Завершить рассмотрение сообщения",
      addComment: true,
      buttonType: "primary",
      body: { commentCheif: "comment" },
    },
  },
  completed: {},
};
