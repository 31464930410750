import AttachmentByOne from "@/components/Helpers/AttachmentByOne";
import { fmtDate, formatDateObj, dateObjInit } from "@/helpers/date";
import { formatName, fmtNameFull } from "@/helpers/text";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
export default {
  name: "TaskButton",
  components: {
    AttachmentByOne,
    Calendar,
    UserSelector
  },
  props: {
    settings: {
      type: Object,
      default: () => ({
        buttonTitle: "Задача",
        modalTitle: "Информация о задаче",
        iconType: "edit",
        limitFiles: 0
      })
    },
    idModal: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    attachments: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    comment: "",
    files: [],
    deadline: {
      ...dateObjInit
    },
    fmtNameFull,
    worker: null,
    showModal: false
  }),
  methods: {
    formatName,
    clearForm() {
      this.comment = "";
      this.files = [];
      this.deadline = {
        ...dateObjInit
      };
      this.worker = null;
      if (this.settings.addSelects) this.settings.selects.forEach(el => {
        el.value = null;
      });
    },
    handlerClick() {
      this.clearForm();
      this.showModal = true;
    },
    handlerOk() {
      const selects = this.settings.addSelects ? this.settings.selects.map(el => ({
        key: el.key,
        value: el.value
      })) : [];
      this.$emit("addData", {
        comment: this.comment,
        files: this.files,
        deadline: formatDateObj(this.deadline, fmtDate, null),
        worker: this.settings.addAssignee ? this.worker.id : null,
        selects
      });
      this.clearForm();
    },
    updateFiles(files) {
      this.files = files;
    },
    selectedWorker(worker) {
      this.worker = worker;
    }
  },
  computed: {
    okDisabled() {
      return this.settings.addDeadline && !formatDateObj(this.deadline, fmtDate, null) || this.settings.addAssignee && !this.worker || this.settings.addSelects && this.settings.selects.every(el => el.value === null);
    }
  }
};