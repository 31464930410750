import mutations from "@/store/mutations";
import { fetchAPI, getUrl } from "@/helpers/fetch";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { formatObjDate } from "@/helpers/date";
import { CONST_NONAME, formatName, fmtNameFull } from "@/helpers/text";
import { statesEducProg } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";

function handlerCourse(obj) {
  obj.readonly = statesEducProg[obj.state] !== statesEducProg.draft;
  obj.startDate = formatObjDate(obj.start_date);
  obj.endDate = formatObjDate(obj.end_date);
  obj.grade_passing = obj.grade_passing * 100;
  return obj;
}

const {
  // reports
  EDUC_REP_LOADING,
  EDUC_REP_COUNT,
  EDUC_REP_DATA,
  EDUC_REP_FILE,
  // программы обучения внутренние
  EDUCATION_COURS,
  EDUCATION_COURS_COUNT,
  EDUCATION_COURS_LOADING,
  // программы обучения внешние
  EDUCATION_COURS_OUT,
  EDUCATION_COURS_OUT_COUNT,
  EDUCATION_COURS_OUT_LOADING,
  // программы обучения пользователя
  EDUCATION_USER_COURS,
  EDUCATION_USER_COURS_COUNT,
  EDUCATION_USER_COURS_LOADING,
  // конкретный курс
  EDUCATION_COURSE,
  EDUCATION_COURSE_LOADING,
  COURSE_LOADING,
  EDUCATION_MATERIAL,
  COURSE_EVENTS,
  COURSE_EVENTS_COUNT,
  COURSE_EVENTS_LOADING,
  // реестр студентов обучения
  STUDENTS_LIST,
  STUDENTS_LIST_COUNT,
  STUDENTS_LIST_LOADING,
  // реестр студентов к назначению
  STUDENTS_TO_ASS,
  STUDENTS_TO_ASS_COUNT,
  STUDENTS_TO_ASS_LOADING,
  // вопросы к тестированию при создании программы обучения
  EDUCATION_QUEST,
  EDUCATION_QUEST_COUNT,
  EDUCATION_QUEST_LOADING,
  // ответы к вопросам тестирования при создании программы обучения
  EDUCATION_ANSWER,
  EDUCATION_ANSWER_COUNT,
  EDUCATION_ANSWER_LOADING,
  // назначение тестирования в личном кабинете
  EDUCATION_TEST,
  EDUCATION_TEST_LOADING,
  // вопрос с вариантами ответа в назначении теста в личном кабинете
  EDUCATION_TEST_QUEST,
  EDUCATION_TEST_QUEST_LOADING,
  // список тестов для проверки
  TESTS_CHECK,
  TESTS_CHECK_COUNT,
  TESTS_CHECK_LOADING,
  // список ответов теста для проверки
  ANSWERS_CHECK,
  ANSWERS_CHECK_COUNT,
  ANSWERS_CHECK_LOADING,
  // данные о назначения прохождения курса в рабочей области
  EDUCATION_TEST_WP,
  EDUCATION_TEST_WP_LOADING,
} = mutations;

const courseStore = {
  namespaced: true,

  state: {
    // программы обучения внутренние
    cours: [],
    coursLoading: false,
    coursCount: 0,
    // программы обучения внешние
    coursOut: [],
    coursOutLoading: false,
    coursOutCount: 0,
    // программы обучения пользователя
    coursUser: [],
    coursUserLoading: false,
    coursUserCount: 0,
    // конкретный курс
    course: { assignment: { test_detail: [] }, materials: [] },
    educationCourseLoading: false,
    courseLoading: false,
    material: {},
    courseEvents: [],
    courseEventsCount: 0,
    courseEventsLoading: false,
    // reports
    reportLoading: false,
    reportCount: 0,
    reportData: [],
    reportFile: "",
    // реестр студентов обучения
    studentsList: [],
    studentsListCount: 0,
    studentsListLoading: false,
    // реестр студентов к назначению
    studentsToAss: [],
    studentsToAssCount: 0,
    studentsToAssLoading: false,
    // вопросы к тестированию при создании программы обучения
    educationQuest: [],
    educationQuestCount: 0,
    educationQuestLoading: false,
    // ответы к вопросам тестирования при создании программы обучения
    educationAnswer: [],
    educationAnswerCount: 0,
    educationAnswerLoading: false,
    // назначение тестирования в личном кабинете
    educationTest: { response_ids: [] },
    educationTestLoading: false,
    // вопрос с вариантами ответа в назначении теста в личном кабинете
    educationTestQuest: { answer_options: [], answer_ids: [] },
    educationTestQuestLoading: false,
    // список тестов для проверки
    testsCheck: [],
    testsCheckCount: 0,
    testsCheckLoading: false,
    // список ответов теста для проверки
    answersCheck: [],
    answersCheckCount: 0,
    answersCheckLoading: false,
    // данные о назначения прохождения курса в рабочей области
    educationTestWp: {},
    educationTestWpLoading: false,
  },

  getters: {
    // конкретный курс
    getCourse: ({ course }) => course,
    material: ({ material }) => material,
    courseLoading: ({ courseLoading }) => courseLoading,
    educationCourseLoading: ({ educationCourseLoading }) =>
      educationCourseLoading,
    courseEvents: ({ courseEvents }) => courseEvents,
    courseEventsCount: ({ courseEventsCount }) => courseEventsCount,
    courseEventsLoading: ({ courseEventsLoading }) => courseEventsLoading,
    // reports
    reportLoading: ({ reportLoading }) => reportLoading,
    reportCount: ({ reportCount }) => reportCount,
    reportData: ({ reportData }) => reportData,
    reportFile: ({ reportFile }) => reportFile,
    // программы обучения внутренние
    cours: ({ cours }) => cours,
    coursLoading: ({ coursLoading }) => coursLoading,
    coursCount: ({ coursCount }) => coursCount,
    // программы обучения внешние
    coursOut: ({ coursOut }) => coursOut,
    coursOutLoading: ({ coursOutLoading }) => coursOutLoading,
    coursOutCount: ({ coursOutCount }) => coursOutCount,
    // программы обучения пользователя
    coursUser: ({ coursUser }) => coursUser,
    coursUserLoading: ({ coursUserLoading }) => coursUserLoading,
    coursUserCount: ({ coursUserCount }) => coursUserCount,
    // реестр студентов обучения
    studentsList: ({ studentsList }) => studentsList,
    studentsListCount: ({ studentsListCount }) => studentsListCount,
    studentsListLoading: ({ studentsListLoading }) => studentsListLoading,
    // реестр студентов к назначению
    studentsToAss: ({ studentsToAss }) => studentsToAss,
    studentsToAssCount: ({ studentsToAssCount }) => studentsToAssCount,
    studentsToAssLoading: ({ studentsToAssLoading }) => studentsToAssLoading,
    // вопросы к тестированию при создании программы обучения
    educationQuest: ({ educationQuest }) => educationQuest,
    educationQuestCount: ({ educationQuestCount }) => educationQuestCount,
    educationQuestLoading: ({ educationQuestLoading }) => educationQuestLoading,
    // ответы к вопросам тестирования при создании программы обучения
    educationAnswer: ({ educationAnswer }) => educationAnswer,
    educationAnswerCount: ({ educationAnswerCount }) => educationAnswerCount,
    educationAnswerLoading: ({ educationAnswerLoading }) =>
      educationAnswerLoading,
    // назначение тестирования в личном кабинете
    educationTest: ({ educationTest }) => educationTest,
    educationTestLoading: ({ educationTestLoading }) => educationTestLoading,
    // вопрос с вариантами ответа в назначении теста в личном кабинете
    educationTestQuest: ({ educationTestQuest }) => educationTestQuest,
    educationTestQuestLoading: ({ educationTestQuestLoading }) =>
      educationTestQuestLoading,
    // список тестов для проверки
    testsCheck: ({ testsCheck }) => testsCheck,
    testsCheckCount: ({ testsCheckCount }) => testsCheckCount,
    testsCheckLoading: ({ testsCheckLoading }) => testsCheckLoading,
    // список ответов теста для проверки
    answersCheck: ({ answersCheck }) => answersCheck,
    answersCheckCount: ({ answersCheckCount }) => answersCheckCount,
    answersCheckLoading: ({ answersCheckLoading }) => answersCheckLoading,
    // данные о назначения прохождения курса в рабочей области
    educationTestWp: ({ educationTestWp }) => educationTestWp,
    educationTestWpLoading: ({ educationTestWpLoading }) =>
      educationTestWpLoading,
  },

  mutations: {
    // конкретный курс
    [EDUCATION_COURSE](state, value) {
      state.course = value;
    },
    [EDUCATION_MATERIAL](state, value) {
      state.material = value;
    },
    [COURSE_LOADING](state, value) {
      state.courseLoading = value;
    },
    [EDUCATION_COURSE_LOADING](state, value) {
      state.educationCourseLoading = value;
    },
    [COURSE_EVENTS](state, value) {
      state.courseEvents = value;
    },
    [COURSE_EVENTS_COUNT](state, value) {
      state.courseEventsCount = value;
    },
    [COURSE_EVENTS_LOADING](state, value) {
      state.courseEventsLoading = value;
    },
    // reports
    [EDUC_REP_LOADING](state, value) {
      state.reportLoading = value;
    },
    [EDUC_REP_COUNT](state, value) {
      state.reportCount = value;
    },
    [EDUC_REP_DATA](state, value) {
      state.reportData = value;
    },
    [EDUC_REP_FILE](state, value) {
      state.reportFile = value;
    },
    // программы обучения внутренние
    [EDUCATION_COURS](state, value) {
      state.cours = value;
    },
    [EDUCATION_COURS_COUNT](state, value) {
      state.coursCount = value;
    },
    [EDUCATION_COURS_LOADING](state, value) {
      state.coursLoading = value;
    },
    // программы обучения внешние
    [EDUCATION_COURS_OUT](state, value) {
      state.coursOut = value;
    },
    [EDUCATION_COURS_OUT_COUNT](state, value) {
      state.coursOutCount = value;
    },
    [EDUCATION_COURS_OUT_LOADING](state, value) {
      state.coursOutLoading = value;
    },
    // программы обучения пользователя
    [EDUCATION_USER_COURS](state, value) {
      state.coursUser = value;
    },
    [EDUCATION_USER_COURS_COUNT](state, value) {
      state.coursUserCount = value;
    },
    [EDUCATION_USER_COURS_LOADING](state, value) {
      state.coursUserLoading = value;
    },
    // реестр студентов обучения
    [STUDENTS_LIST](state, value) {
      state.studentsList = value;
    },
    [STUDENTS_LIST_COUNT](state, value) {
      state.studentsListCount = value;
    },
    [STUDENTS_LIST_LOADING](state, value) {
      state.studentsListLoading = value;
    },
    // реестр студентов к назначению
    [STUDENTS_TO_ASS](state, value) {
      state.studentsToAss = value;
    },
    [STUDENTS_TO_ASS_COUNT](state, value) {
      state.studentsToAssCount = value;
    },
    [STUDENTS_TO_ASS_LOADING](state, value) {
      state.studentsToAssLoading = value;
    },
    // вопросы к тестированию при создании программы обучения
    [EDUCATION_QUEST](state, value) {
      state.educationQuest = value;
    },
    [EDUCATION_QUEST_COUNT](state, value) {
      state.educationQuestCount = value;
    },
    [EDUCATION_QUEST_LOADING](state, value) {
      state.educationQuestLoading = value;
    },
    // ответы к вопросам тестирования при создании программы обучения
    [EDUCATION_ANSWER](state, value) {
      state.educationAnswer = value;
    },
    [EDUCATION_ANSWER_COUNT](state, value) {
      state.educationAnswerCount = value;
    },
    [EDUCATION_ANSWER_LOADING](state, value) {
      state.educationAnswerLoading = value;
    },
    // назначение тестирования в личном кабинете
    [EDUCATION_TEST](state, value) {
      state.educationTest = value;
    },
    [EDUCATION_TEST_LOADING](state, value) {
      state.educationTestLoading = value;
    },
    // вопрос с вариантами ответа в назначении теста в личном кабинете
    [EDUCATION_TEST_QUEST](state, value) {
      state.educationTestQuest = value;
    },
    [EDUCATION_TEST_QUEST_LOADING](state, value) {
      state.educationTestQuestLoading = value;
    },
    // список тестов для проверки
    [TESTS_CHECK](state, value) {
      state.testsCheck = value;
    },
    [TESTS_CHECK_COUNT](state, value) {
      state.testsCheckCount = value;
    },
    [TESTS_CHECK_LOADING](state, value) {
      state.testsCheckLoading = value;
    },
    // список ответов теста для проверки
    [ANSWERS_CHECK](state, value) {
      state.answersCheck = value;
    },
    [ANSWERS_CHECK_COUNT](state, value) {
      state.answersCheckCount = value;
    },
    [ANSWERS_CHECK_LOADING](state, value) {
      state.answersCheckLoading = value;
    },
    // данные о назначения прохождения курса в рабочей области
    [EDUCATION_TEST_WP](state, value) {
      state.educationTestWp = value;
    },
    [EDUCATION_TEST_WP_LOADING](state, value) {
      state.educationTestWpLoading = value;
    },
  },

  actions: {
    // запрос реестра студентов к назачению
    async updateStudentsToAss(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          // сортировка
          const requestParams = {
            //sort: (data.sortDesc ? "-" : "") + data.sortBy,
            page: data.page,
          };
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI(
            `/course-assignments/`,
            requestParams
          );
          context.commit(STUDENTS_TO_ASS, response.results);
          context.commit(STUDENTS_TO_ASS_COUNT, response.count);
        },
        STUDENTS_TO_ASS_LOADING
      );
    },
    // запрос реестра студентов обучения
    async updateStudentsList(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          // сортировка
          const requestParams = {
            //sort: (data.sortDesc ? "-" : "") + data.sortBy,
            page: data.page,
          };
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI(
            `/course-assignments/`,
            requestParams
          );
          context.commit(STUDENTS_LIST, response.results);
          context.commit(STUDENTS_LIST_COUNT, response.count);
        },
        STUDENTS_LIST_LOADING
      );
    },
    // запрос с программ обучения для пользователя в ЛК
    async updateUserCourse(context, data) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/my-courses/`, {
            page: data.page,
          });
          context.commit(EDUCATION_USER_COURS, response.results);
          context.commit(EDUCATION_USER_COURS_COUNT, response.count);
        },
        EDUCATION_USER_COURS_LOADING
      );
    },
    // запрос курса для прохождения обучения пользователя в ЛК
    async updateCourse4User(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/my-courses/${id}/`);
          context.commit(EDUCATION_COURSE, response);
        },
        COURSE_LOADING
      );
    },
    // механизм подписания прохождения курса простой электронной подписью
    // Работает так же, как подписание ЛЛ и ЭДКИ
    // Успешное подписание автоматически переводит назначение курса в состояние finished
    // (отдельно переводить уже не надо)
    async sendSignCourse(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-courses/${data.id}/sign/`,
            null,
            "PATCH",
            addLinks({ code: data.code })
          );
          context.commit(EDUCATION_COURSE, result);
          return "Обучение по курсу завершено";
        },
        COURSE_LOADING
      );
    },
    // начать/завершить обучение
    async actionCourse4User(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/my-courses/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          return `Обучение по курсу ${data.title}`;
        },
        COURSE_LOADING
      );
    },
    // внести изменения по курсу (обновить процент, например)
    // запрос курса для прохождения обучения пользователя в ЛК
    async changeCourse4User(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/my-courses/${data.id}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(EDUCATION_COURSE, response);
          //return `Обучение по курсу ${data.title}`;
        },
        COURSE_LOADING
      );
    },
    // создать новый курс(программу обучения)
    async createCourse(context) {
      await wrapCommitDispatch(context, async () => {
        // сразу создадим внутреннее обучение, чтобы попадало в выборку
        const response = await fetchAPI("/courses/", null, "POST", {
          edu_type: "internal",
        });
        context.commit(EDUCATION_COURSE, response);
        return "Программа внутреннего обучения созданa";
      });
    },
    // создать новый материал для курса
    async createMaterial(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const material = await fetchAPI(
            `/courses/${data.course}/materials/`,
            null,
            "POST",
            data.params
          );
          context.commit(EDUCATION_MATERIAL, material);
          return "Материал создан";
        },
        COURSE_LOADING
      );
    },
    // передать в бэк файл для материала
    async uploadFile4Material(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const material = await fetchAPI(
            `/courses/${data.course}/materials/${data.id}/`,
            null,
            "PATCH",
            data.params,
            true
          );
          context.commit(EDUCATION_MATERIAL, material);
        },
        COURSE_LOADING
      );
    },
    // изменить материал к курсу
    async changeMaterial(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const material = await fetchAPI(
            `/courses/${data.course}/materials/${data.id}/`,
            null,
            "PATCH",
            data.params
          );
          context.commit(EDUCATION_MATERIAL, material);
          return "Материал изменен";
        },
        COURSE_LOADING
      );
    },
    // удалить курс(программу обучения)
    async deleteCourse(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/courses/${data.id}/`, null, "DELETE");
        return `Программа обучения ${data.title || CONST_NONAME} удалена`;
      });
    },
    // удалить материал курса
    async deleteMaterial(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/courses/${data.course}/materials/${data.id}/`,
          null,
          "DELETE"
        );
        return "Материал удален";
      });
    },
    // запрос курса для настройки и подготовки программы обучения
    async updateCourse(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/courses/${data}/`);
          context.commit(EDUCATION_COURSE, handlerCourse(response));
        },
        COURSE_LOADING
      );
    },
    // изменить курс(программу обучения)
    async changeCourse(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/courses/${data.id}/`,
            null,
            "PATCH",
            data.params,
            data.fileUpload
          );
          context.commit(EDUCATION_COURSE, handlerCourse(response));
          return "Изменения внесены";
        },
        EDUCATION_COURSE_LOADING
      );
    },
    // сменить статус программы
    async changeState(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          return "Статус изменен";
        },
        COURSE_LOADING
      );
    },
    // назначить обучение для организации/департаментов/сотрудников
    async assignCourse(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const params = {
            date_start_formal: data.dateStart,
            date_end_formal: data.dateEnd,
            deadline: data.deadline,
          };
          if (data.allOrg) {
            // в целом по организации
            params.organization = true;
            await fetchAPI(
              `/courses/${data.course.id}/assign/`,
              null,
              "POST",
              params
            );
          } else {
            // по департаментам
            if (data.depart.length > 0) {
              data.depart.forEach(async (depart) => {
                params.department = depart.id;
                await fetchAPI(
                  `/courses/${data.course.id}/assign/`,
                  null,
                  "POST",
                  params
                );
              });
              delete params.department;
            }
            // по работникам
            if (data.workers.length > 0) {
              data.workers.forEach(async (worker) => {
                params.user = worker.id;
                await fetchAPI(
                  `/courses/${data.course.id}/assign/`,
                  null,
                  "POST",
                  params
                );
              });
            }
          }
          return `Сформирован список обучающихся по программе`;
        },
        COURSE_LOADING
      );
    },
    // отменить обучение для сотрудника
    async unassignCourse(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/course-assignments/${data.id}/`, null, "DELETE");
        return `${formatName(
          {
            first_name: data.student_first_name,
            middle_name: data.student_middle_name,
            last_name: data.student_last_name,
          },
          fmtNameFull
        )} удален из списка`;
      });
    },
    // изменить обучение сотрудника
    async reassignCourse(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/course-assignments/${data.id}/`,
          null,
          "PATCH",
          data.params
        );
        return "Изменения внесены";
      });
    },
    // запрос общего списка программ обучения
    async updateCourses(
      context,
      data = {
        sortBy: null,
        sortDesc: true,
        page: 1,
        filters: {},
      }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = { page: data.page };
          if (data.sortBy)
            requestParams.sort = (data.sortDesc ? "-" : "") + data.sortBy;
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI("/courses/", requestParams);
          context.commit(
            data.filters.edu_type === "internal"
              ? EDUCATION_COURS
              : EDUCATION_COURS_OUT,
            response.results
          );
          context.commit(
            data.filters.edu_type === "internal"
              ? EDUCATION_COURS_COUNT
              : EDUCATION_COURS_OUT_COUNT,
            response.count
          );
        },
        data.filters.edu_type === "internal"
          ? EDUCATION_COURS_LOADING
          : EDUCATION_COURS_OUT_LOADING
      );
    },
    // утвердить назначение обучения
    async confirmCourse(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/courses/${data.id}/assign/`, null, "PATCH", {
          confirmed: true,
        });
        return `Обучение по программе назначено`;
      });
    },
    // запрос Истории
    async updateEvents(context, requestParams) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/course-events/", requestParams);
          context.commit(COURSE_EVENTS_COUNT, response.count);
          context.commit(COURSE_EVENTS, response.results);
        },
        COURSE_EVENTS_LOADING
      );
    },
    // reports
    // запрос данных для отчета
    async updateReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          // сортировка
          const requestParams = {
            //sort: (data.sortDesc ? "-" : "") + data.sortBy,
            page: data.page,
          };
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI(
            "/course-assignments/",
            requestParams
          );
          context.commit(EDUC_REP_DATA, response.results);
          context.commit(EDUC_REP_COUNT, response.count);
        },
        EDUC_REP_LOADING
      );
    },
    // запрос файла для отчета
    async downloadReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = {};
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          context.commit(
            EDUC_REP_FILE,
            getUrl("/course-assignments/download/", requestParams)
          );
        },
        EDUC_REP_LOADING
      );
    },
    // вопросы к тестированию при создании программы обучения (получить список)
    async updateEducationQuest(context, { page, id }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/courses/${id}/questions/`, {
            page,
          });
          context.commit(EDUCATION_QUEST, response.results);
          context.commit(EDUCATION_QUEST_COUNT, response.count);
        },
        EDUCATION_QUEST_LOADING
      );
    },
    // вопросы к тестированию при создании программы обучения (создать новый вопрос)
    async createEducationQuest(context, { courseId, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/courses/${courseId}/questions/`, null, "POST", body);
          return "Вопрос для тестирования создан";
        },
        EDUCATION_QUEST_LOADING
      );
    },
    // вопросы к тестированию при создании программы обучения (удалить вопрос)
    async deleteEducationQuest(context, { courseId, questId }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${courseId}/questions/${questId}/`,
            null,
            "DELETE"
          );
          return "Вопрос для тестирования удален";
        },
        EDUCATION_QUEST_LOADING
      );
    },
    // вопросы к тестированию при создании программы обучения (изменить вопрос)
    async changeEducationQuest(context, { courseId, questId, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${courseId}/questions/${questId}/`,
            null,
            "PATCH",
            body
          );
          return "Вопрос для тестирования изменен";
        },
        EDUCATION_QUEST_LOADING
      );
    },
    // ответы к вопросам тестирования при создании программы обучения (получить список)
    async updateEducationAnswer(context, { page, courseId, questId }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/courses/${courseId}/questions/${questId}/answers/`,
            { page }
          );
          context.commit(EDUCATION_ANSWER, response.results);
          context.commit(EDUCATION_ANSWER_COUNT, response.count);
        },
        EDUCATION_ANSWER_LOADING
      );
    },
    // ответы к вопросам тестирования при создании программы обучения (создать новый ответ)
    async createEducationAnswer(context, { courseId, questId, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${courseId}/questions/${questId}/answers/`,
            null,
            "POST",
            body
          );
          return "Вариант ответа создан";
        },
        EDUCATION_ANSWER_LOADING
      );
    },
    // ответы к вопросам тестирования при создании программы обучения (удалить ответ)
    async deleteEducationAnswer(context, { courseId, questId, answerId }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${courseId}/questions/${questId}/answers/${answerId}/`,
            null,
            "DELETE"
          );
          return "Вариант ответа удален";
        },
        EDUCATION_ANSWER_LOADING
      );
    },
    // ответы к вопросам тестирования при создании программы обучения (изменить ответ)
    async changeEducationAnswer(
      context,
      { courseId, questId, answerId, body }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/courses/${courseId}/questions/${questId}/answers/${answerId}/`,
            null,
            "PATCH",
            body
          );
          return "Вариант ответа изменен";
        },
        EDUCATION_ANSWER_LOADING
      );
    },
    // назначение тестирования в личном кабинете
    // создать новое назначение тестирования в личном кабинете
    async createNewTest4user(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/my-tests/", null, "POST", data);
          context.commit(EDUCATION_TEST, response);
          return "Создана новая попытка тестирования";
        },
        EDUCATION_TEST_LOADING
      );
    },
    // получить данные по попытке тестирования
    async updateEducationTest(context, attempt) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/my-tests/${attempt}/`);
          context.commit(EDUCATION_TEST, response);
        },
        EDUCATION_TEST_LOADING
      );
    },
    // запросить вопрос с вариантами ответа в назначении теста в личном кабинете
    async updateEducationTestQuest(context, { attempt, question }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/my-tests/${attempt}/responses/${question}/`
          );
          context.commit(EDUCATION_TEST_QUEST, response);
        },
        EDUCATION_TEST_QUEST_LOADING
      );
    },
    // изменить вариант ответа в назначении теста в личном кабинете
    async changeEducationTestQuest(context, { params, attempt, question }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/my-tests/${attempt}/responses/${question}/`,
            null,
            "PATCH",
            params
          );
          context.commit(EDUCATION_TEST_QUEST, response);
          return "Ответ записан";
        },
        EDUCATION_TEST_QUEST_LOADING
      );
    },
    // механизм подписания результатов тестирования простой электронной подписью
    async sendSignTest(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-tests/${data.id}/sign/`,
            null,
            "PATCH",
            addLinks({ code: data.code })
          );
          context.commit(EDUCATION_TEST, result);
          return "Результаты тестирования подписаны";
        },
        EDUCATION_TEST_LOADING
      );
    },
    // список тестов для проверки получить список
    async updateTestsCheck(context, { page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/tests/", {
            page,
          });
          context.commit(TESTS_CHECK, response.results);
          context.commit(TESTS_CHECK_COUNT, response.count);
        },
        TESTS_CHECK_LOADING
      );
    },
    // список ответов теста для проверки получить список
    async updateAnswersCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          // сортировка
          const requestParams = {
            //sort: (data.sortDesc ? "-" : "") + data.sortBy,
            page: data.page,
          };
          // фильтры
          if (data.filters) {
            Object.keys(data.filters).forEach((key) => {
              if (data.filters[key] !== null) {
                requestParams[key] = data.filters[key];
              }
            });
          }
          const response = await fetchAPI(
            `/tests/${data.attempt}/responses/`,
            requestParams
          );
          context.commit(ANSWERS_CHECK, response.results);
          context.commit(ANSWERS_CHECK_COUNT, response.count);
        },
        ANSWERS_CHECK_LOADING
      );
    },
    // список ответов теста для проверки изменить ответ
    async changeAnswersCheck(context, { attempt, answer, body }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/tests/${attempt}/responses/${answer}/`,
            null,
            "PATCH",
            body
          );
          return "Проверка ответа выполнена";
        },
        ANSWERS_CHECK_LOADING
      );
    },
    // список тестов для проверки изменить состояния
    async changeTestsState(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/tests/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          return "Статус изменен";
        },
        ANSWERS_CHECK_LOADING
      );
    },
    // данные о назначения прохождения курса в рабочей области update
    async updateEducationTestWp(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/tests/${id}/`);
          context.commit(EDUCATION_TEST_WP, response);
        },
        EDUCATION_TEST_WP_LOADING
      );
    },
  },
};

export default courseStore;
