import { formatDate, fmtDateShort, formatDateObj, formatObjDate, fmtDate, dateObjInit } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound, fmtNameFull } from "@/helpers/text";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import thePersona from "@/components/Helpers/thePersona";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "StudentsToAssign",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    Calendar,
    UserSelector,
    thePersona
  },
  data: () => ({
    showModal: false,
    showModalRemove: false,
    titleRemove: "",
    currentItem: {},
    fmtDateShort,
    fmtNameFull,
    isChange: false,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [{
      key: "FIO",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "student_department_name",
      label: "Подразделение"
    }, {
      key: "date_start_formal",
      label: "Дата начала обучения",
      class: "font-mono"
    }, {
      key: "date_end_formal",
      label: "Дата окончания обучения",
      class: "font-mono"
    }, {
      key: "btnChange",
      label: "",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "",
      class: "text-center"
    }],
    dateStart: {
      ...dateObjInit
    },
    dateEnd: {
      ...dateObjInit
    },
    deadline: {
      ...dateObjInit
    },
    worker: null,
    filters: {
      confirmed: false
    }
  }),
  created() {
    this.filters.course_id = this.item.id;
  },
  methods: {
    ...mapActions("education", ["assignCourse", "unassignCourse", "reassignCourse", "confirmCourse", "updateStudentsToAss", "updateStudentsList"]),
    formatName,
    formatDate,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.studentsToAssCount / this.perPage, this.currentPage);
      this.updateStudentsToAss({
        page: this.currentPage,
        filters: this.filters
      });
    },
    async handlerOk() {
      const data = {};
      const dateStart = formatDateObj(this.dateStart, fmtDate, null);
      const dateEnd = formatDateObj(this.dateEnd, fmtDate, null);
      const deadline = formatDateObj(this.deadline, fmtDate, null);
      if (this.isChange) {
        data.params = {};
        // this.worker = текущий item
        if (dateStart !== this.worker.date_start_formal) {
          data.params.date_start_formal = dateStart;
        }
        if (dateEnd !== this.worker.date_end_formal) {
          data.params.date_end_formal = dateEnd;
        }
        if (deadline !== this.worker.deadline) {
          data.params.deadline = deadline;
        }
        if (Object.keys(data.params).length > 0) {
          data.id = this.worker.id;
          await this.reassignCourse(data);
        }
      } else {
        data.dateStart = dateStart;
        data.dateEnd = dateEnd;
        data.deadline = deadline;
        data.allOrg = false;
        data.depart = [];
        data.workers = [{
          id: this.worker.id
        }];
        data.course = this.item;
        await this.assignCourse(data);
      }
      await this.updateStudentsToAss({
        page: this.currentPage,
        filters: this.filters
      });
    },
    clickRemove(item) {
      this.currentItem = item;
      this.titleRemove = `Удалить ${formatName({
        first_name: item.student_first_name,
        middle_name: item.student_middle_name,
        last_name: item.student_last_name
      }, fmtNameFull)} из списка?`;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.unassignCourse(this.currentItem);
      this.currentPage = 1;
      await this.updateStudentsToAss({
        page: 1,
        filters: this.filters
      });
    },
    selectedWorker(worker) {
      this.worker = worker;
    },
    clickChange(item) {
      this.dateStart = formatObjDate(item.date_start_formal);
      this.dateEnd = formatObjDate(item.date_end_formal);
      this.deadline = formatObjDate(item.deadline);
      this.isChange = true;
      this.worker = item; // сохраним текущий элемент для дальнейших правок
      this.showModal = true;
    },
    changeDateEnd(data) {
      this.dateEnd = data;
      Object.assign(this.deadline, this.dateEnd);
    },
    clickAddStudent() {
      this.dateStart = {
        ...dateObjInit
      };
      this.dateEnd = {
        ...dateObjInit
      };
      this.deadline = {
        ...dateObjInit
      };
      this.isChange = false;
      this.worker = null;
      this.$bvModal.show("modalAddStudent");
    },
    // утвердить назначение обучения
    async clickConfirm() {
      await this.confirmCourse(this.item);
      // обновить списки
      await this.updateStudentsList({
        page: 1,
        filters: {
          course_id: this.item.id,
          confirmed: true
        }
      });
      await this.updateStudentsToAss({
        page: 1,
        filters: {
          course_id: this.item.id,
          confirmed: false
        }
      });
    }
  },
  computed: {
    ...mapGetters("education", ["studentsToAss", "studentsToAssCount", "studentsToAssLoading"]),
    okModalDisabled() {
      return !formatDateObj(this.deadline, fmtDate, null) || !formatDateObj(this.dateEnd, fmtDate, null) || !formatDateObj(this.dateStart, fmtDate, null) || !this.worker;
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateStudentsToAss({
          page: value,
          filters: this.filters
        });
      }
    }
  }
};