import { FIO } from "@/helpers/text";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import ReportTimingControlDetails from "./ReportTimingControlDetails";
export default {
  name: "ReportTimingControl",
  components: {
    ReportTimingControlDetails
  },
  //______________________
  data: () => ({
    currentPage: 1,
    perPage: 10,
    filters: {},
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "main.id",
      label: "Источник",
      formatter: value => {
        return `Декларация #${value}`;
      }
    }, {
      key: "main.author",
      label: "ФИО работника",
      class: "text-nowrap",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "main.position",
      label: "Должность работника"
    }, {
      key: "main.worker_in_charge",
      label: "Ответственный за рассмотрение",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "main.assigned_at",
      label: "Дата отправки на рассмотрение",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "main.deadline",
      label: "Контрольная дата рассмотрения",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "main.completed_at",
      label: "Дата завершения рассмотрения",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }]
  }),
  //______________________
  computed: {
    ...mapGetters("declarationsReports", ["timingControl", "timingControlCount", "timingControlLoading"]),
    isEmpty() {
      return !this.timingControlCount;
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateTimingControl({
          page: value,
          filters: this.filters
        });
      }
    }
  },
  //______________________
  methods: {
    ...mapActions("declarationsReports", ["updateTimingControl"])
  }
};