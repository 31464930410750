import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PartnersTotalRisk = _resolveComponent("PartnersTotalRisk");
  const _component_b_card = _resolveComponent("b-card");
  const _component_PartnersSingleRisk = _resolveComponent("PartnersSingleRisk");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_card, null, {
    default: _withCtx(() => [_createVNode(_component_PartnersTotalRisk)]),
    _: 1
  }), $options.firstCheck && _ctx.partnerForm.object_permissions.includes('access_wa_bpcheck_compliance') ? (_openBlock(), _createBlock(_component_b_card, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_PartnersSingleRisk, {
      riskObject: {
        key: 'check_compliance'
      }
    })]),
    _: 1
  })) : _createCommentVNode("", true), $options.firstCheck && _ctx.partnerForm.object_permissions.includes('access_wa_bpcheck_security') ? (_openBlock(), _createBlock(_component_b_card, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_PartnersSingleRisk, {
      riskObject: {
        key: 'check_security'
      }
    })]),
    _: 1
  })) : _createCommentVNode("", true), $options.secondCheck && _ctx.partnerForm.object_permissions.includes('access_wa_bpcheck_law') ? (_openBlock(), _createBlock(_component_b_card, {
    key: 2
  }, {
    default: _withCtx(() => [_createVNode(_component_PartnersSingleRisk, {
      riskObject: {
        key: 'check_law'
      }
    })]),
    _: 1
  })) : _createCommentVNode("", true), $options.secondCheck && _ctx.partnerForm.object_permissions.includes('access_wa_bpcheck_finance') ? (_openBlock(), _createBlock(_component_b_card, {
    key: 3
  }, {
    default: _withCtx(() => [_createVNode(_component_PartnersSingleRisk, {
      riskObject: {
        key: 'check_finance'
      }
    })]),
    _: 1
  })) : _createCommentVNode("", true)]);
}