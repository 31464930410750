import ProfilePhoto from "./UserInfo/ProfilePhoto.vue";
import ProfileActions from "./ProfileActions.vue";
import theFirst from "./Items/theFirst.vue";
import theSecond from "./Items/theSecond.vue";
import theThird from "./Items/theThird.vue";
import theFourth from "./Items/theFourth.vue";
import theFifth from "./Items/theFifth.vue";
import theSixth from "./Items/theSixth.vue";
import theSeventh from "./Items/theSeventh.vue";
import theEighth from "./Items/theEighth.vue";
import Ninth from "./Items/Ninth.vue";
import Tenth from "./Items/Tenth.vue";
import Eleventh from "./Items/Eleventh.vue";
import Twelfth from "./Items/Twelfth.vue";
import theThirteenth from "./Items/theThirteenth.vue";
import theFourteenth from "./Items/theFourteenth.vue";
import theFifteenth from "./Items/theFifteenth.vue";
import theSixteenth from "./Items/theSixteenth.vue";
import theSeventeenth from "./Items/theSeventeenth.vue";
import theEighteenth from "./Items/theEighteenth.vue";
import theNineteenth from "./Items/theNineteenth.vue";
import theTwentieth from "./Items/theTwentieth.vue";
import TwentyFirst from "./Items/TwentyFirst.vue";
import TwentySecond from "./Items/TwentySecond.vue";
import TwentyThird from "./Items/TwentyThird.vue";
import TwentyFourth from "./Items/TwentyFourth.vue";
import TwentyFifth from "./Items/TwentyFifth.vue";
import TwentySixth from "./Items/TwentySixth.vue";
import TwentySeventh from "./Items/TwentySeventh.vue";
import TwentyEighth from "./Items/TwentyEighth.vue";
import TwentyNinth from "./Items/TwentyNinth.vue";
import theThirtieth from "./Items/theThirtieth.vue";
import ThirtyFirst from "./Items/ThirtyFirst.vue";
import ThirtySecond from "./Items/ThirtySecond.vue";
import ThirtyThird from "./Items/ThirtyThird.vue";
import ThirtyFourth from "./Items/ThirtyFourth.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import { formatDate, fmtDateTime } from "@/helpers/date";
import theNotification from "@/components/theNotification";
export default {
  name: "ProfileCard",
  components: {
    BreadCrumbToHome,
    theNotification,
    ProfilePhoto,
    ProfileActions,
    theFirst,
    theSecond,
    theThird,
    theFourth,
    theFifth,
    theSixth,
    theSeventh,
    theEighth,
    Ninth,
    Tenth,
    Eleventh,
    Twelfth,
    theThirteenth,
    theFourteenth,
    theFifteenth,
    theSixteenth,
    theSeventeenth,
    theEighteenth,
    theNineteenth,
    theTwentieth,
    TwentyFirst,
    TwentySecond,
    TwentyThird,
    TwentyFourth,
    TwentyFifth,
    TwentySixth,
    TwentySeventh,
    TwentyEighth,
    TwentyNinth,
    theThirtieth,
    ThirtyFirst,
    ThirtySecond,
    ThirtyThird,
    ThirtyFourth
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("profile", ["profileLoading", "profileObj"]),
    ...mapGetters("administration", ["profileSheetSettingsLoading"])
  },
  async created() {
    await this.fetchProfile({
      id: this.id,
      myProfile: true
    });
    this.updateCountries();
  },
  data: () => ({
    formatDate,
    fmtDateTime,
    breadcrumbs: [{
      text: "Личный кабинет",
      to: links.profileSheets
    }]
  }),
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    ...mapActions("referenceBooks", ["updateCountries"])
  }
};