import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_calendar = _resolveComponent("b-calendar");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_calendar, _mergeProps({
    class: "calendar-wrap",
    id: "1",
    "selected-variant": "null",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    min: $options.minDate,
    max: $options.maxDate,
    "hide-header": ""
  }, _ctx.labels || {}, {
    locale: "ru-RU",
    "start-weekday": "1",
    "no-key-nav": "",
    onSelected: $options.onSelectDate,
    "date-info-fn": $options.dateClass
  }), null, 16, ["modelValue", "min", "max", "onSelected", "date-info-fn"])]);
}