import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { wrapCommitDispatch } from "@/helpers/global";

const {
  REPORT_STATS_EDKI,
  REPORT_STATS_EDKI_LOADING,
  REPORT_TIMING,
  REPORT_TIMING_COUNT,
  REPORT_TIMING_LOADING,
} = mutations;

const reports = {
  namespaced: true,
  state: {
    statsEDKI: {},
    statsEDKILoading: false,
    timingControl: {},
    timingControlCount: 0,
    timingControlLoading: false,
  },
  getters: {
    statsEDKI: ({ statsEDKI }) => statsEDKI,
    statsEDKILoading: ({ statsEDKILoading }) => statsEDKILoading,
    timingControl: ({ timingControl }) => timingControl,
    timingControlCount: ({ timingControlCount }) => timingControlCount,
    timingControlLoading: ({ timingControlLoading }) => timingControlLoading,
  },
  mutations: {
    [REPORT_STATS_EDKI](state, value) {
      state.statsEDKI = value;
    },
    [REPORT_STATS_EDKI_LOADING](state, value) {
      state.statsEDKILoading = value;
    },
    [REPORT_TIMING](state, value) {
      state.timingControl = value;
    },
    [REPORT_TIMING_COUNT](state, value) {
      state.timingControlCount = value;
    },
    [REPORT_TIMING_LOADING](state, value) {
      state.timingControlLoading = value;
    },
  },
  actions: {
    async updateStatsEDKI(context, filters = {}) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = Object.keys(filters).reduce((acc, key) => {
            if (filters[key] !== null) {
              // что-то есть в фильтре
              acc[key] = filters[key];
            }
            return acc;
          }, {});

          const response = await fetchAPI("/decl-stats/", requestParams);
          context.commit(REPORT_STATS_EDKI, response);
        },
        REPORT_STATS_EDKI_LOADING
      );
    },

    async updateTimingControl(
      context,
      { page, filters } = {
        page: 1,
        filters: {},
      }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          const requestParams = { page };
          // добавим фильтры в параметры
          Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) {
              // что-то есть в фильтре
              requestParams[key] = filters[key];
            }
          });
          const response = await fetchAPI("/coid-tasks-report/", requestParams);
          context.commit(REPORT_TIMING, response.results);
          context.commit(REPORT_TIMING_COUNT, response.count);
        },
        REPORT_TIMING_LOADING
      );
    },
  },
};

export default reports;
