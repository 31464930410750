// шаблон ввода/вывода информации делового партнера
import { optionYesNo } from "@/helpers/text";

export const partnersTemplate = (orgName) => [
  // select
  {
    title: "В организации проводится процедура банкротства",
    key: "has_bankruptcy_procedures",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "В отношении организации приняты решения о приостановлении деятельности в установленном законодательством порядке",
    key: "is_suspended",
    type: "select",
    option: optionYesNo,
  },
  {
    title: "Организация включена в реестр недобросовестных поставщиков",
    key: "is_unreliable",
    type: "select",
    option: optionYesNo,
  },
  {
    title: "Организация имеет аккредитацию на электронной торговой площадке",
    key: "is_accredited",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация обладает интеллектуальными правами на результат  интеллектуальной деятельности и приравненные к ним средства индивидуализации (результаты интеллектуальной деятельности и средства индивидуализации)",
    key: "has_intel_property",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация обладает лицензией (разрешением, допуском) на осуществление определенных видов деятельности или операций?",
    key: "has_license",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация состоит в профессиональных ассоциациях, союзах и объединениях в области деловой этики и антикоррупционного комплаенса",
    key: "has_compliance_membership",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация присоединилась к Антикоррупционной хартии российского бизнеса",
    key: "has_anticorruption_charter_membership",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация привлекалась к административной ответственности по ст. 19.28 КоАП РФ за последние три года",
    key: "has_koap1928_liability",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Организация ознакомлена с локальными нормативными актами ${orgName} в области противодействия коррупции`,
    key: "knows_local_compliance_acts",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Организация согласна соблюдать Кодекс поведения делового партнера ${orgName}`,
    key: "accepts_partner_code",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Организация согласна включить в договор с ${orgName} антикоррупционную оговорку`,
    key: "agrees_to_include_anticorrupt_remark",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Организация согласна с условием о возможности отказа ${orgName} от заключения договора или о включении в договор права ${orgName} расторгнуть договор в одностороннем порядке в случае указания в анкете заведомо неполной или недостоверной информации`,
    key: "agrees_to_false_info_condition",
    type: "select",
    option: optionYesNo,
  },
  {
    title: "Имелись негативные публикации об организации за последние три года",
    key: "has_negative_publicity",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Организация состоит в договорных отношениях с Департаментом транспорта и развития дорожно-транспортной инфраструктуры города Москвы",
    key: "has_mosdeptrans_contracts",
    type: "select",
    option: optionYesNo,
  },
  {
    title: "В организации утверждена процедура проверки деловых партнеров",
    key: "has_bpcheck_procedure",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Планируется привлечение по заключенному с ${orgName} Договору субподрядчиков/субисполнителей`,
    key: "plans_subcontractors",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `В организации проводится проверка субподрядчиков/субисполнителей, привлекаемых в рамках взаимоотношений с ${orgName}`,
    key: "has_subcontractors_checks",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `В организации были установлены негативные факты в отношении субпрдрядчиков/субисполнителей, привлекаемых в рамках взаимоотношений с ${orgName}`,
    key: "has_negative_info_on_subcontractors",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `Организации будет взаимодействовать с государственными органами в рамках договорных отношений с ${orgName}`,
    key: "has_gov_counterparts",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "Договоры организации с субподрядчиками/субисполнителями содержат антикоррупционную оговорку",
    key: "has_anticorrupt_subcontractors_condition",
    type: "select",
    option: optionYesNo,
  },
  {
    title: `В организации имеются лица, аффилированные с работниками ${orgName}`,
    key: "has_mdto_affiliated_workers",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "В организации имеются лица, аффилированные с государственными должностными лицами",
    key: "has_civil_servants_affiliated_workers",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "У организации имеется задолженность по начисленным налогам, сборам и иным обязательным платежам в бюджеты любого уровня или государственные внебюджетные фонды за прошедший календарный год, размер которой превышает 25 % балансовой стоимости активов по данным бухгалтерской отчетности за последний завершенный отчетный период",
    key: "has_tax_debt",
    type: "select",
    option: optionYesNo,
  },
  {
    title:
      "В отношении организации имеется решение о приостановлении операций по счетам",
    key: "has_suspended_transactions",
    type: "select",
    option: optionYesNo,
  },
  // textarea
  {
    title:
      "Ответственное лицо (подразделение) за профилактику коррупционных и иных правонарушений в организации (указать контактные данные)",
    key: "compliance_supervisor",
    type: "textarea",
    placeholder: "Введите контактные данные",
  },
  {
    title:
      "Приняты ли в организации локальные нормативные акты в области противодействия коррупции? (где можно ознакомиться)",
    key: "compliance_rules_location",
    type: "textarea",
    placeholder: "Введите информацию",
  },
  {
    title:
      "Имеется ли информация о наличии судимости руководителя, членов коллегиального исполнительного органа или главного бухгалтера организации за совершение преступлений экономической и коррупционной направленности?",
    key: "top_management_convicted_info",
    type: "textarea",
    placeholder: "Введите информацию",
  },
  {
    title:
      "Заключала ли организация государственные контракты (договоры) за последние три года? (количество, сумма, реквизиты договора)",
    key: "gov_contracts_details",
    type: "textarea",
    placeholder: "Введите информацию",
  },
  {
    title: `В каком объеме субподрядчик/субисполнитель будет осуществлять работу в рамках договорных отношений с ${orgName}?`,
    key: "subcontractors_responsibility_share",
    type: "textarea",
    placeholder: "Введите информацию",
  },
];
