import { mapGetters } from "vuex";
import { option4select } from "@/helpers/global";
export default {
  name: "SectionAdd",
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalTitle: "",
    modalOrg: false,
    modalDepart: [],
    showModal: false
  }),
  methods: {
    clickAdd() {
      this.modalTitle = this.item.title || "";
      this.modalOrg = this.item.is_visible_to_all || false;
      this.modalDepart = this.item.visible_to_departments || [];
      this.showModal = true;
    },
    handlerOk() {
      this.$emit("addSection", {
        title: this.modalTitle,
        is_visible_to_all: this.modalOrg,
        visible_to_departments: this.modalDepart
      });
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    okModalDisabled() {
      return !this.modalTitle.trim().length;
    },
    optionsDepart() {
      return option4select(this.departments, false);
    }
  }
};