import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    "label-cols-sm": "3",
    "label-cols-lg": "2",
    "content-cols-sm": "",
    "content-cols-lg": "",
    class: "field",
    label: "Фамилия"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.profileObj.last_name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.last_name = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.submitData('last_name')),
      state: _ctx.last_name_state,
      disabled: $options.readonly,
      trim: ""
    }, null, 8, ["modelValue", "state", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    "label-cols-sm": "3",
    "label-cols-lg": "2",
    "content-cols-sm": "",
    "content-cols-lg": "",
    class: "field",
    label: "Имя"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.profileObj.first_name,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.profileObj.first_name = $event),
      onChange: _cache[3] || (_cache[3] = $event => $options.submitData('first_name')),
      state: _ctx.first_name_state,
      disabled: $options.readonly,
      trim: ""
    }, null, 8, ["modelValue", "state", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    "label-cols-sm": "3",
    "label-cols-lg": "2",
    "content-cols-sm": "",
    "content-cols-lg": "",
    class: "field",
    label: "Отчество"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.profileObj.middle_name,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.profileObj.middle_name = $event),
      onChange: _cache[5] || (_cache[5] = $event => $options.submitData('middle_name')),
      state: _ctx.middle_name_state,
      disabled: $options.readonly
    }, null, 8, ["modelValue", "state", "disabled"])]),
    _: 1
  })]);
}