import { mapActions, mapGetters } from "vuex";
import { profileReadonly } from "@/helpers/profile";
export default {
  name: "imageUpload",
  data: () => ({
    previewImage: null
  }),
  created() {
    this.previewImage = this.profileObj.photo;
  },
  methods: {
    ...mapActions("profile", ["addProfilePhoto"]),
    async uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("file_name", image.name);
      formData.append("photo", image);
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
        this.addProfilePhoto({
          profileId: this.profileObj.id,
          formData
        });
      };
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};