import thePersona from "@/components/Helpers/thePersona";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapGetters, mapActions } from "vuex";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text.js";
export default {
  name: "ReportByProgram",
  components: {
    thePersona
  },
  props: {
    filters: {
      typeof: Object,
      default: () => ({})
    },
    program: {
      typeof: String,
      default: ""
    }
  },
  data: () => ({
    fmtDateShort,
    fmtNameShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "index",
      label: "№ п/п",
      class: "font-mono"
    }, {
      key: "FIO",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "student_department_name",
      label: "Подразделение"
    }, {
      key: "date_end_realT",
      label: "Дата завершения",
      class: "text-right text-nowrap font-mono"
    }, {
      key: "result",
      label: "Результат",
      class: "text-center"
    }]
  }),
  computed: {
    ...mapGetters("education", ["reportLoading", "reportCount", "reportData"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateReport({
          page: value,
          filters: this.filters
        });
      }
    },
    items4table() {
      return Array.isArray(this.reportData) ? this.reportData.map(el => ({
        ...el,
        date_end_realT: formatDate(el.date_end_real, fmtDateShort)
      })) : [];
    }
  },
  methods: {
    ...mapActions("education", ["updateReport"]),
    formatName,
    formatDate,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.reportCount / this.perPage, this.currentPage);
      this.updateReport({
        page: this.currentPage,
        filters: this.filters
      });
    }
  }
};