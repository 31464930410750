import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import { yearsGenerator } from "@/helpers/date";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly, optionsEduLevel } from "@/helpers/profile";
export default {
  name: "theSecond",
  data: () => ({
    formalEducation: [],
    edu_level: null,
    add_edu_level: null,
    edu_level_state: null,
    add_edu_level_state: null,
    optionsEduLevel,
    readonly: true,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    endpointEducation: "edu-backgrounds",
    templateLanguages: {
      language: null,
      level: null
    },
    statesLanguages: {
      language_state: null,
      level_state: null
    },
    templateEducation: {
      college_name: null,
      department_name: null,
      start_year: null,
      end_year: null,
      break_stage: null,
      specialization: null
    },
    statesEducation: {
      college_name_state: null,
      department_name_state: null,
      start_year_state: null,
      end_year_state: null,
      break_stage_state: null,
      specialization_state: null
    }
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.formalEducation = this.profileObj.edu_backgrounds ? this.profileObj.edu_backgrounds.map(education => ({
      ...education,
      ...this.statesEducation
    })) : [];
    this.profileObjSaved.education = this.profileObj.edu_backgrounds ? this.profileObj.edu_backgrounds.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
    this.profileObjSaved.edu_level = this.profileObj.edu_level;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitDataEndpoint(obj, key, savekey, endpoint) {
      if (this.profileObjSaved[savekey][obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    },
    submitData(key) {
      const value = this[key];
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    endYearsGenerator() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    },
    startYearsGenerator() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    }
  }
};