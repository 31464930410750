import { mapGetters, mapActions } from "vuex";
import theAttachments from "@/components/Helpers/theAttachments.vue";
import { statesHotline } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
import { formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "HotlineFormAttach",
  components: {
    theAttachments
  },
  data: () => ({
    fields: [{
      key: "is_additional",
      label: "Тип",
      formatter: value => value ? "Дополнительное" : ""
    }, {
      key: "user_detail",
      label: "Работник",
      formatter: value => formatName(value, fmtNameShort),
      class: "text-nowrap"
    }]
  }),
  methods: {
    ...mapActions("hotline", ["updateHlineAttach", "createHlineAttach", "deleteHlineAttach", "changeHlineAttach"]),
    async addFile(file) {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("file_name", file.name);
      await this.createHlineAttach({
        id: this.hlineMsg.id,
        file: formData
      });
      if (statesHotline[this.hlineMsg.state] !== statesHotline.draft) await this.changeHlineAttach({
        id: this.hlineMsg.id,
        idAttach: this.hlineAttachment.id,
        params: {
          is_additional: true
        }
      });
      await this.updateHlineAttach({
        id: this.hlineMsg.id,
        page: 1
      });
    },
    async delFile(idAttach) {
      await this.deleteHlineAttach({
        id: this.hlineMsg.id,
        idAttach
      });
      await this.updateHlineAttach({
        id: this.hlineMsg.id,
        page: 1
      });
    },
    async setPage(page) {
      await this.updateHlineAttach({
        id: this.hlineMsg.id,
        page
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg", "hlineAttach", "hlineAttachCount", "hlineAttachLoading", "hlineAttachment"]),
    readOnly() {
      return !["draft", "assigned", "prolonged"].includes(this.hlineMsg.state) || !checkPermissions(this.$perm.change_wa_hotmsg);
    }
  }
};