import { FIO } from "@/helpers/text";
import { statesCp } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReportCpReport",
  //______________________
  data: () => ({
    currentPage: 1,
    perPage: 10,
    sortBy: "id",
    sortDesc: true,
    filters: {},
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "author",
      label: "ФИО работника",
      class: "text-nowrap",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "position",
      label: "Должность работника"
    }, {
      key: "cp_type_name",
      label: "Тип КП"
    }, {
      key: "coi_type_name",
      label: "Тип КИ"
    }, {
      key: "worker_in_charge",
      label: "Ответственный по КП",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "start_date",
      label: "Дата назначения КП",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "deadline",
      label: "Контрольная дата",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "state",
      label: "Статус",
      formatter: value => {
        return statesCp[value];
      }
    }]
  }),
  //______________________
  computed: {
    ...mapGetters("cp", ["cp", "cpCount", "cpLoading"]),
    isEmpty() {
      return !this.cpCount;
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.fetchCp({
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: this.filters,
          page: value
        });
      }
    }
  },
  //______________________
  methods: {
    ...mapActions("cp", ["fetchCp"])
  }
};