import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6aeaaf09"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pe-1"
};
const _hoisted_2 = ["onClick", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_badge = _resolveComponent("b-badge");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (el, index) => {
    return _openBlock(), _createBlock(_component_b_badge, {
      variant: "primary",
      key: index,
      class: "me-1"
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(el.text), 1), _createElementVNode("button", {
        type: "button",
        class: "btn-close p-0 text-white btn-close-wrap",
        "aria-label": "Close",
        onClick: $event => $options.clickIconX(el.id),
        disabled: $props.disabled
      }, null, 8, _hoisted_2)]),
      _: 2
    }, 1024);
  }), 128))]);
}