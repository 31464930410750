import { mapActions, mapGetters } from "vuex";
import { profileReadonly } from "@/helpers/profile";
import { setValueTimeout } from "@/helpers/timeout";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "TwentyFifth",
  data: () => ({
    endpoint: "privileges",
    optionYesNo,
    is_psycho_state: null,
    has_privileges_state: null,
    has_special_conditions_state: null,
    profileObjSaved: {},
    template: {
      category_kind: null
    },
    states: {
      category_kind_state: null
    }
  }),
  created() {
    this.profileObjSaved.is_psycho = this.profileObj.is_psycho;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData(key) {
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        setValueTimeout(this, state);
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};