import { mapGetters } from "vuex";
import { labels } from "@/helpers/date";
export default {
  name: "AdminCalendarItem",
  props: {
    year: {
      type: String,
      default: String(new Date().getFullYear())
    },
    month: {
      type: String,
      default: String(new Date().getMonth() + 1)
    }
  },
  data: () => ({
    labels,
    value: null
  }),
  methods: {
    onSelectDate(data) {
      this.$emit("selectDate", data);
    },
    dateClass(ymd) {
      if (this.adminCalendar[ymd]) {
        if (!this.adminCalendar[ymd]["active"]) return "table-secondary";
        if (!this.adminCalendar[ymd]["worker"]) return "table-danger";
      }
      return "";
    }
  },
  updated() {
    this.value = this.minDate;
  },
  created() {
    this.value = this.minDate;
  },
  computed: {
    ...mapGetters("administration", ["adminCalendar"]),
    // первая дата месяца
    minDate() {
      return `${this.year}-${this.month}-01`;
    },
    // последняя дата месяца
    maxDate() {
      return `${this.year}-${this.month}-31`;
    }
  }
};