import { answerType, limitBound } from "@/helpers/text";
import { option4select } from "@/helpers/global";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProgramAddQuestioning",
  data: () => ({
    // question
    currentPageQuest: 1,
    perPage: 10,
    gotoPageQuest: 1,
    fieldsQuest: [{
      key: "index",
      label: "п/п"
    }, {
      key: "text",
      label: "Вопрос",
      class: "text-truncate"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "Удалить",
      class: "text-center"
    }],
    currentQuest: {},
    answerType,
    answerTypeSelected: "open",
    showModalRemoveQuest: false,
    showModalRemoveAnswer: false,
    currentItem: {},
    // modal
    addNewQuest: true,
    // тип окна - добавить/изменить вопрос.
    modalTitle: "Добавить вопрос",
    modalButton: "Добавить",
    modalDescription: "",
    modalCansel: "Отменить",
    showModal: false
  }),
  methods: {
    ...mapActions("education", ["updateEducationQuest", "createEducationQuest", "deleteEducationQuest", "changeEducationQuest", "updateEducationAnswer", "createEducationAnswer", "deleteEducationAnswer", "changeEducationAnswer", "changeCourse"]),
    async changeValue(key) {
      const value = Number(this.getCourse[key]);
      if (value <= 0 || key === "grade_passing" && value > 100) {
        this[key + "_state"] = false;
        return;
      }
      this[key + "_state"] = null;
      await this.changeCourse({
        id: this.getCourse.id,
        params: {
          [key]: key === "grade_passing" ? value / 100 : value
        }
      });
    },
    changePageQuest() {
      this.currentPageQuest = limitBound(Number(this.gotoPageQuest), 1, this.educationQuestCount / this.perPage, this.currentPageQuest);
      this.updateEducationQuest({
        page: this.currentPageQuest,
        id: this.getCourse.id
      });
    },
    removeQuestion(item) {
      this.currentItem = item;
      this.showModalRemoveQuest = true;
    },
    async doRemoveQuest() {
      await this.deleteEducationQuest({
        courseId: this.getCourse.id,
        questId: this.currentItem.id
      });
      this.currentPageQuest = 1;
      await this.updateEducationQuest({
        page: 1,
        id: this.getCourse.id
      });
    },
    async changeQuestion(item) {
      this.addNewQuest = false;
      this.modalTitle = "Изменить вопрос";
      this.modalButton = "Сохранить вопрос";
      this.modalCansel = "Закрыть";
      this.modalDescription = item.text;
      this.answerTypeSelected = item.question_type;
      this.currentQuest = item;
      this.showModal = true;
      await this.updateEducationAnswer({
        page: 1,
        courseId: this.getCourse.id,
        questId: item.id
      });
    },
    addQuestion() {
      this.addNewQuest = true;
      this.modalTitle = "Добавить вопрос";
      this.modalButton = "Добавить";
      this.modalCansel = "Отменить";
      this.modalDescription = "";
      this.answerTypeSelected = "open";
      this.showModal = true;
    },
    async addAnswer() {
      await this.createEducationAnswer({
        body: {
          content: "Новый ответ"
        },
        courseId: this.getCourse.id,
        questId: this.currentQuest.id
      });
      await this.updateEducationAnswer({
        page: 1,
        courseId: this.getCourse.id,
        questId: this.currentQuest.id
      });
    },
    async handlerOk() {
      if (this.addNewQuest) await this.createEducationQuest({
        courseId: this.getCourse.id,
        body: {
          text: this.modalDescription,
          question_type: this.answerTypeSelected
        }
      });else {
        const body = {};
        if (this.modalDescription.trim() !== this.currentQuest.text.trim()) body.text = this.modalDescription.trim();
        if (this.answerTypeSelected !== this.currentQuest.question_type) body.question_type = this.answerTypeSelected;
        if (!Object.keys(body).length) return;
        await this.changeEducationQuest({
          courseId: this.getCourse.id,
          questId: this.currentQuest.id,
          body
        });
      }
      await this.updateEducationQuest({
        page: this.currentPageQuest,
        id: this.getCourse.id
      });
    },
    removeAnswer(item) {
      this.currentItem = item;
      this.showModalRemoveAnswer = true;
    },
    async doRemoveAnswer() {
      await this.deleteEducationAnswer({
        courseId: this.getCourse.id,
        questId: this.currentQuest.id,
        answerId: this.currentItem.id
      });
      await this.updateEducationAnswer({
        page: 1,
        courseId: this.getCourse.id,
        questId: this.currentQuest.id
      });
    },
    async changeAnswer(item, key) {
      if (key === "is_correct" && answerType[this.answerTypeSelected] === answerType.singlechoice) for (let i = 0; i < this.educationAnswerCount; i++) {
        const answer = this.educationAnswer[i];
        if (answer.id === item.id) await this.changeEducationAnswer({
          // это наш ответ, который стал правильным
          courseId: this.getCourse.id,
          questId: this.currentQuest.id,
          answerId: item.id,
          body: {
            [key]: item[key]
          }
        });else if (answer[key]) await this.changeEducationAnswer({
          // это ответ, который стал неправильным
          courseId: this.getCourse.id,
          questId: this.currentQuest.id,
          answerId: answer.id,
          body: {
            [key]: false
          }
        });
      } else await this.changeEducationAnswer({
        courseId: this.getCourse.id,
        questId: this.currentQuest.id,
        answerId: item.id,
        body: {
          [key]: item[key]
        }
      });
      await this.updateEducationAnswer({
        page: 1,
        courseId: this.getCourse.id,
        questId: this.currentQuest.id
      });
    }
  },
  computed: {
    ...mapGetters("education", ["getCourse", "educationQuest", "educationQuestCount", "educationQuestLoading", "educationAnswer", "educationAnswerCount", "educationAnswerLoading"]),
    okModalDisabled() {
      return !this.modalDescription.trim().length || !this.answerTypeSelected || this.getCourse.readonly;
    },
    addAnswerDisabled() {
      return this.getCourse.readonly || this.educationAnswerCount >= 10;
    },
    currentPageModelQuest: {
      get() {
        return this.currentPageQuest;
      },
      set(value) {
        this.currentPageQuest = value;
        this.updateEducationQuest({
          page: value,
          id: this.getCourse.id
        });
      }
    },
    options4answerType() {
      return option4select(answerType);
    },
    countCorrectAnswers() {
      return this.educationAnswer.filter(x => x.is_correct).length;
    },
    showErrorCheckAnswer() {
      return this.educationAnswerCount === 0 || this.countCorrectAnswers === 0 || answerType[this.answerTypeSelected] === answerType.singlechoice && this.countCorrectAnswers > 1;
    }
  }
};