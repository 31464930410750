import thePersona from "@/components/Helpers/thePersona";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import { statesEducUserText, statesEducUser } from "@/helpers/states";
export default {
  name: "StudentsList",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    statesEducUser,
    fmtDateShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [{
      key: "FIO",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "student_position",
      label: "Должность"
    }, {
      key: "student_department_name",
      label: "Подразделение"
    }, {
      key: "date_start_real",
      label: "Дата обучения",
      class: "font-mono"
    }, {
      key: "state",
      label: "Статус назначения"
    }, {
      key: "state4profile",
      label: "Статус прохождения"
    }, {
      key: "result",
      label: "Результат",
      class: "text-center"
    }, {
      key: "course_grade_max",
      label: "Максимально возможный",
      class: "text-center"
    }],
    filters: {
      confirmed: true
    }
  }),
  created() {
    this.filters.course_id = this.item.id;
  },
  components: {
    thePersona
  },
  methods: {
    ...mapActions("education", ["updateStudentsList"]),
    formatName,
    formatDate,
    statesEducUserText,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.studentsListCount / this.perPage, this.currentPage);
      this.updateStudentsList({
        page: this.currentPage,
        filters: this.filters
      });
    }
  },
  computed: {
    ...mapGetters("education", ["studentsList", "studentsListCount", "studentsListLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateStudentsList({
          page: value,
          filters: this.filters
        });
      }
    }
  }
};