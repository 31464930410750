import { mapGetters, mapActions } from "vuex";
import { statesRiskMatrix } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "MatrixList",
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    links,
    tableFields: [{
      key: "id",
      label: "Матрица",
      class: "text-nowrap",
      formatter: value => `Матрица рисков #${value}`
    }, {
      key: "created_at",
      label: "Создана",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "state",
      label: "Статус",
      formatter: value => {
        return statesRiskMatrix[value];
      }
    }, {
      key: "approved_at",
      label: "Одобрена",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "confirmed_at",
      label: "Согласована",
      formatter: value => formatDate(value, fmtDateShort),
      class: "text-nowrap"
    }, {
      key: "declined_at",
      label: "Отклонена",
      formatter: value => formatDate(value, fmtDateShort),
      class: "text-nowrap"
    }]
  }),
  methods: {
    ...mapActions("risk", ["updateMatrix", "setMatrixPage"]),
    changePage() {
      this.setMatrixPage(limitBound(Number(this.gotoPage), 1, this.matrixCount / this.perPage, this.matrixPage));
      this.updateMatrix();
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixCount", "matrix", "matrixPage", "matrixLoading"]),
    currentPageModel: {
      get() {
        return this.matrixPage;
      },
      set(value) {
        this.setMatrixPage(value);
        this.updateMatrix();
      }
    }
  }
};