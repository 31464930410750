import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
export default {
  name: "theAbout",
  data: () => ({
    links
  }),
  methods: {
    ...mapActions("administration", ["updateCommon"])
  },
  computed: {
    ...mapGetters("administration", ["adminCommon", "adminCommonLoading"])
  },
  created() {
    this.updateCommon();
  }
};