import perm from "@/auth/permissions.js";

export const btn4transitCoi = [
  {
    stateFrom: "draft",
    modal: "coiModalDraft2confirmed",
    stateTo: "confirmed",
    perm: perm.confirm_wa_coi,
    type: "taskBtn",
    msg: "КИ подтвержден",
    settings: {
      buttonTitle: "Подтвердить КИ",
      modalTitle: "Подтвердить конфликт интересов",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "submitted",
    modal: "coiModalSubmitted2confirmed",
    stateTo: "confirmed",
    perm: perm.confirm_wa_coi,
    type: "taskBtn",
    msg: "КИ подтвержден",
    settings: {
      buttonTitle: "Подтвердить КИ",
      modalTitle: "Подтвердить конфликт интересов",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "submitted",
    modal: "coikModalSubmitted2dismissed",
    stateTo: "dismissed",
    perm: perm.dismiss_wa_coi,
    type: "taskBtn",
    msg: "КИ отсутствует",
    settings: {
      buttonTitle: "КИ отсутствует",
      modalTitle: "Конфликт интересов отсутствует",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "confirmed",
    modal: "coiModalConfirmed2assigned",
    stateTo: "assigned",
    perm: perm.assign_wa_coi,
    type: "taskBtn",
    msg: "КИ назначен",
    settings: {
      buttonTitle: "Назначить КИ",
      modalTitle: "Назначить конфликт интересов",
      addAssignee: true,
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
  {
    stateFrom: "assigned",
    modal: "coiModalAssigned2settled",
    stateTo: "settled",
    perm: perm.settle_wa_coi,
    type: "taskBtn",
    msg: "КИ урегулирован",
    settings: {
      buttonTitle: "Урегулировать КИ",
      modalTitle: "Урегулировать конфликт интересов",
      addComment: true,
      addFiles: true,
      limitFiles: 3,
      buttonType: "primary",
    },
  },
];
