import { mapActions, mapGetters } from "vuex";
import { formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "UserSelector",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    fmtName: {
      type: String,
      default: fmtNameShort
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    if (!this.disabled) {
      this.updateAdminWorkers(this.filters);
    }
    this.search = this.name;
  },
  computed: {
    ...mapGetters("administration", ["adminWorkers"])
  },
  data: () => ({
    search: ""
  }),
  methods: {
    ...mapActions("administration", ["updateAdminWorkers"]),
    formatName,
    changeSearch() {
      const index = this.search.indexOf("id:");
      if (index > 0) {
        const name = this.search.slice(0, index - 1);
        this.$emit("selected", {
          name,
          id: this.search.slice(index + 3)
        });
        this.search = name;
      } else {
        this.updateAdminWorkers({
          user_search: this.search,
          ...this.filters
        });
      }
    }
  }
};