import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_col = _resolveComponent("b-col");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_button = _resolveComponent("b-button");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "Суть проверки"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.innerCheckForm.subject,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.innerCheckForm.subject = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.changeItem('subject')),
      placeholder: "Введите текст",
      rows: "3",
      "max-rows": "3",
      trim: "",
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), !_ctx.adminInnerCheckLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 0,
    label: "Категория"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.innerCheckForm.category,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.innerCheckForm.category = $event),
      options: $options.optionsCategory,
      onChange: _cache[3] || (_cache[3] = $event => $options.changeItem('category')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "options", "disabled"])]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_b_spinner, {
    key: 1,
    class: "text-primary"
  })), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Плановая дата проведения",
        date: _ctx.innerCheckForm.datePlanned,
        classCustom: "mt-2",
        onChangeValue: _cache[4] || (_cache[4] = $event => $options.changeItem('date_planned', $event)),
        disabled: $options.readOnly
      }, null, 8, ["date", "disabled"]), _withDirectives(_createElementVNode("div", _hoisted_1, " «Плановая дата проведения» не может быть ранее даты внесения сведений ", 512), [[_vShow, _ctx.invalidDatePlanned]])]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Ответственный исполнитель"
      }, {
        default: _withCtx(() => [_ctx.innerCheckFormLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
          key: 0,
          class: "align-middle text-center text-primary"
        })) : (_openBlock(), _createBlock(_component_UserSelector, {
          key: 1,
          onSelected: _cache[5] || (_cache[5] = $event => $options.changeItem('assignee', $event)),
          disabled: $options.readOnly,
          name: $options.formatName(_ctx.innerCheckForm.assignee_detail, _ctx.fmtNameFull),
          fmtName: _ctx.fmtNameFull,
          showInfo: true
        }, null, 8, ["disabled", "name", "fmtName"]))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "4"
    }, {
      default: _withCtx(() => [_createTextVNode("Работник, в отношении которого проводится проверка")]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.innerCheckForm.has_target_worker,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.innerCheckForm.has_target_worker = $event),
        switch: "",
        onChange: _cache[7] || (_cache[7] = $event => $options.changeItem('has_target_worker')),
        disabled: $options.readOnly
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.innerCheckForm.has_target_worker ? "установлен" : "не установлен"), 1)]),
        _: 1
      }, 8, ["modelValue", "disabled"])]),
      _: 1
    }), _withDirectives(_createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_ctx.innerCheckFormLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
        key: 0,
        class: "align-middle text-center text-primary"
      })) : (_openBlock(), _createBlock(_component_UserSelector, {
        key: 1,
        onSelected: _cache[8] || (_cache[8] = $event => $options.changeItem('target_worker', $event)),
        disabled: $options.readOnly,
        name: $options.formatName(_ctx.innerCheckForm.target_worker_detail, _ctx.fmtNameFull),
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["disabled", "name", "fmtName"]))]),
      _: 1
    }, 512), [[_vShow, _ctx.innerCheckForm.has_target_worker]])]),
    _: 1
  }), !$options.disabledAction ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
    key: 2,
    class: "mt-3",
    variant: "outline-primary",
    onClick: $options.clickAddCP
  }, {
    default: _withCtx(() => [_createTextVNode("Добавить контрольную процедуру")]),
    _: 1
  }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_cp]]) : _createCommentVNode("", true)]);
}