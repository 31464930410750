import "core-js/modules/es.array.push.js";
import links from "@/router/links";
import PartnersList from "./PartnersList.vue";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import PartnersReport from "./Reports/PartnersReport.vue";
export default {
  name: "PartnersArea",
  components: {
    PartnersList,
    BreadCrumbToHome,
    theNotification,
    PartnersReport
  },
  created() {
    const params = {
      page: 1
    };
    if (this.isAdminPath) params.sort = "full_name";
    this.updatePartners(params);
  },
  data: () => ({}),
  methods: {
    ...mapActions("partners", ["updatePartners", "createPartner"]),
    async clickCreate() {
      await this.createPartner();
      if (this.partnerForm.id) {
        const linkForm = (this.isAdminPath ? links.adminPartnersForm : links.partnersForm) + this.partnerForm.id;
        this.$router.push(linkForm);
      }
    }
  },
  computed: {
    ...mapGetters("partners", ["partnersCount", "partnerForm"]),
    isAdminPath() {
      return this.$route.path === links.adminPartners;
    },
    title() {
      return this.isAdminPath ? "Справочник «Деловые партнёры»" : "Проверка деловых партнёров";
    },
    breadcrumbs() {
      return this.isAdminPath ? [{
        text: "Администрирование",
        to: {
          name: "AdminArea"
        }
      }] : [];
    }
  }
};