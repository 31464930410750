import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound, FIO } from "@/helpers/text";
export default {
  name: "StatsKnowledge",
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    fields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "department_name",
      label: " Подразделение"
    }, {
      key: "full_nameT",
      label: "ФИО"
      //formatter: (value) => FIO(value),
    }, {
      key: "subdepartment",
      label: "Отдел"
    }, {
      key: "position",
      label: "Должность"
    }, {
      key: "date_end_realT",
      label: "Дата ознакомления"
      //formatter: (value) => formatDate(value, fmtDateShort),
    }]
  }),

  methods: {
    ...mapActions("knowledge", ["updateReport"]),
    async changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.lnaReportCount / this.perPage, this.currentPage);
      await this.updateReport({
        page: this.currentPage,
        ...this.filters
      });
    }
  },
  computed: {
    ...mapGetters("knowledge", ["lnaReportData", "lnaReportCount"]),
    items4table() {
      return Array.isArray(this.lnaReportData) ? this.lnaReportData.map(el => ({
        ...el,
        full_nameT: FIO(el.full_name),
        date_end_realT: formatDate(el.date_end_real, fmtDateShort)
      })) : [];
    },
    title() {
      return "Статистика ознакомления с локально-нормативными актами за период c " + formatDate(this.filters.date_end_real_gte, fmtDateShort) + " по " + formatDate(this.filters.date_end_real_lte, fmtDateShort);
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateReport({
          page: value,
          ...this.filters
        });
      }
    }
  }
};