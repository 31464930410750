import ActivityListDetailsAdd from "./ActivityListDetailsAdd.vue";
export default {
  name: "ActivityListDetails",
  components: {
    ActivityListDetailsAdd
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  methods: {
    addDetails(data) {
      this.$emit("addDetails", {
        ...data,
        id: this.item.id
      });
    }
  },
  computed: {
    okModalDisabled() {
      return !this.comment.trim().length;
    }
  }
};