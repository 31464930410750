// список ролей из получаемого объекта
export function roleKeysOptions(roles) {
  return Object.keys(roles).map((role) => ({
    value: role,
    text: role,
  }));
}

/** проверка, что при сохранении ролей выбраны/не выбраны дочерние роли
 * @param {Array} arr - массив ролей пользователя
 * @param {Object} obj - объект ролей с целевыми и дочерними ролями
 * @returns {Boolean} true - все дочерние роли выбраны, false - не все дочерние роли выбраны
 */
export function checkСhildRoles(arr, obj) {
  return arr.every(
    (role) => !obj[role] || obj[role].every((child) => arr.includes(child))
  );
}

/** массив (уникальных) дочерних ролей, которые не выбраны
 * @param {Array} arr - массив ролей пользователя
 * @param {Object} obj - объект ролей с целевыми и дочерними ролями
 * @returns {Set}
 */
export function listСhildRoles(arr, obj) {
  const res = new Set();
  arr.forEach((role) => {
    if (obj[role]) {
      obj[role].forEach((child) => {
        if (!arr.includes(child)) {
          res.add(child);
        }
      });
    }
  });
  return res;
}

/** при выборе целевой роли, добавляем дочерние роли к роли пользователя
 * @param {Array} arr - массив ролей пользователя
 * @param {String} role - выбранная роль
 * @param {Object} obj - объект ролей с целевыми и дочерними ролями
 * @returns {Array} новый массив ролей пользователя
 */
export function addСhildRoles(arr, role, obj) {
  return Array.from(new Set(arr.concat(obj[role])));
}
