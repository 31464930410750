import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "d-flex"
};
const _hoisted_2 = {
  class: "me-auto"
};
const _hoisted_3 = {
  key: 0,
  class: "card-title"
};
const _hoisted_4 = {
  key: 1,
  class: "card-title"
};
const _hoisted_5 = {
  key: 0,
  class: "ms-2 rounded-lg mb-2 d-flex align-items-center text-center",
  style: {
    "width": "3rem"
  }
};
const _hoisted_6 = {
  key: 0,
  class: "mb-0"
};
const _hoisted_7 = {
  class: "position-relative me-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_link = _resolveComponent("b-link");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_col = _resolveComponent("b-col");
  return _openBlock(), _createBlock(_component_b_col, {
    cols: "12",
    md: "6",
    lg: "4",
    xl: "3",
    class: "d-flex align-self-stretch"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card, {
      "no-body": "",
      class: "mb-4 border-0 shadow w-100",
      subtitle: $options.subtitle
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card_body, {
        class: "position-relative p-3"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.item.link ? (_openBlock(), _createElementBlock("h5", _hoisted_3, [_createVNode(_component_router_link, {
          to: $props.item.link
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.title), 1)]),
          _: 1
        }, 8, ["to"])])) : (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString($props.item.title), 1))]), $props.item.icon ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("i", {
          class: _normalizeClass(["h3 mdto-iconset text-muted opacity-50", $props.item.icon])
        }, null, 2)])) : _createCommentVNode("", true)])]),
        _: 1
      }), _createVNode(_component_b_card_footer, {
        class: "px-4 pb-4 pt-0 bg-transparent border-0"
      }, {
        default: _withCtx(() => [_ctx.stats[$props.item.name] && _ctx.stats[$props.item.name].new ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, [_createVNode(_component_b_link, {
          onClick: $options.clickToNew,
          class: "card-link stretched-link"
        }, {
          default: _withCtx(() => [_createTextVNode(" Новых ")]),
          _: 1
        }, 8, ["onClick"]), _createVNode(_component_b_badge, {
          class: "ms-1",
          variant: "danger"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.stats[$props.item.name].new), 1)]),
          _: 1
        })])])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    }, 8, ["subtitle"])]),
    _: 1
  });
}