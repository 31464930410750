import "core-js/modules/es.array.push.js";
import { statesEducProg } from "@/helpers/states";
import { getStateOptionsFilter } from "@/helpers/declaration";
import Calendar from "@/components/Helpers/Calendar.vue";
import { optionsEducFormat, selectAll } from "@/helpers/text";
import { mapGetters } from "vuex";
import { dateObjInit } from "@/helpers/date";
export default {
  name: "CourseSidebar",
  data: () => ({
    showItem: true,
    filters: {
      //state: null,
      format: null,
      dateFrom: {
        ...dateObjInit
      },
      dateUntill: {
        ...dateObjInit
      },
      instructor: null,
      owner: null
    }
  }),
  components: {
    Calendar
  },
  computed: {
    ...mapGetters("referenceBooks", ["departmentsLoading", "departments"]),
    stateOptions() {
      return getStateOptionsFilter(statesEducProg);
    },
    formatOptions() {
      return selectAll.concat(optionsEducFormat);
    },
    departOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, []);
    }
  },
  methods: {
    // очистим все фильтры
    clearFilters() {
      this.showItem = false;
      Object.keys(this.filters).forEach(key => {
        if (["dateFrom", "dateUntill"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    // нажали "Применить"
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    }
  }
};