import { shortSubstringDropdown, selectAll } from "./text";
import perm from "@/auth/permissions.js";

// вынесем в функцию однотипные операции записи объекта
// нижней секции
function getBottomObject(objectTemplate, objectForm, templateBottomSection) {
  const isKeyInObject = objectTemplate["keyInObject"];
  const keyDB = objectTemplate["keyDB"];
  const indexName = Number(objectTemplate["indexBS"]);
  const keyIn = isKeyInObject ? objectTemplate["keyIn"] : false;
  let constData = null;
  if (objectForm[keyDB] !== null && objectForm[keyDB] !== undefined) {
    constData = isKeyInObject ? objectForm[keyDB][keyIn] : objectForm[keyDB]; // вот оно, ради чего всего затевалось
  }
  return {
    name: templateBottomSection[indexName],
    data: constData,
    keyForDB: keyDB,
    indexName: indexName,
    keyIn: keyIn,
    keyInObject: isKeyInObject,
    hidden: objectTemplate["hidden"],
  };
}

/**
 * вынесем обработку однотипных операций выбора полей
 мэппинга из шаблона
 *
 * @param {Object} bottomSectionMaping - шаблон работы с элементом bottomSection
 * @param {Object} declarationForm - полученый из БД объект декларации
 * @param {Object} templateElement - шаблон для работы с индексами названий
 * @returns {Object} - результат сборки для template.bottomSectionData
 */
function handleerMultyAndOnly(
  bottomSectionMaping,
  declarationForm,
  templateElement
) {
  const resObject = {};
  try {
    for (let keyBS in bottomSectionMaping) {
      // здесь перебираем записи и нужно анализировать, на множественность еще
      if (bottomSectionMaping[keyBS]["multy"]) {
        // множественный ключ
        const keyDB = bottomSectionMaping[keyBS]["keyDB"]; // здесь получим массив потом по ключу keyDB
        const arrayForKeyDB = declarationForm[keyDB]
          ? declarationForm[keyDB]
          : []; // массив свойств здесь тернарный сделать?
        const multyDB = bottomSectionMaping[keyBS]["multyDB"]; // наш шаблон работы с элементом второго уровня
        resObject[keyBS] = {};
        // перебираем
        arrayForKeyDB.forEach((element, index) => {
          resObject[keyBS][index] = {};
          for (let keyBSLevel2 in multyDB) {
            if (!multyDB[keyBSLevel2]["multy"]) {
              // не множественная запись
              // иначе - Внимание, внимание, это не обрабатываем
              resObject[keyBS][index][keyBSLevel2] = getBottomObject(
                multyDB[keyBSLevel2],
                element,
                templateElement["bottomSection"]
              );
            }
          }
        });
      } else {
        resObject[keyBS] = getBottomObject(
          bottomSectionMaping[keyBS],
          declarationForm,
          templateElement["bottomSection"]
        );
      }
    }
  } catch (error) {
    console.log(error);
  }

  return resObject;
}

/**
 * в рекурсии обработаем bottomSectionData для подготовки вывода на страницу
 *
 * @param {Object} bottomSection - шаблон работы с элементом bottomSection
 * @returns {Array}
 */
function handlerBottomSectionData(bottomSectionData) {
  const result = []; // массив массивов (родители и дети)
  // глубокое копирование
  const resultSheets = []; // плоский массив, безе детей
  for (let key in bottomSectionData) {
    if (Object.prototype.hasOwnProperty.call(bottomSectionData[key], "data")) {
      if (
        bottomSectionData[key].data !== null &&
        bottomSectionData[key].data !== undefined
      ) {
        const item = JSON.stringify(bottomSectionData[key]);
        // не нулевое значение item
        const len = result.length;
        if (len > 0) {
          // что-то уже есть в rusult
          for (let i = 0; i < len; i++) {
            result[i].push(item);
          }
        } else {
          // ничего нет, прибавляем в resultSheets
          resultSheets.push(item);
        }
      }
    } else {
      // вернется массив с родителями и потомками, считаем что такое может быть только один раз
      // иначе многие ко многим
      for (let i in bottomSectionData[key]) {
        const childs = handlerBottomSectionData(bottomSectionData[key][i]);
        result.push(resultSheets.concat(childs));
      }
    }
  }

  return result.length === 0 ? resultSheets : result;
}

/**
 * возвращаем объект из массив
 *
 * @param {Array} incomingArray - элемент массив
 * @returns {Object}
 */
function getObjectFromItem(incomingArray) {
  const resObject = {};
  incomingArray.forEach((element) => {
    if (element) {
      const elementObject = JSON.parse(element);
      const index = elementObject.indexName;
      resObject[index] = elementObject;
    }
  });

  return resObject;
}

// обрботаем данные для части II с учетом шаблона
export function serializeResponseDeclarationPartII(
  declaratioTtemplate,
  declarationForm
) {
  // глубокое копирование на вский случай
  const template = JSON.parse(JSON.stringify(declaratioTtemplate));

  Object.keys(template).forEach((key) => {
    // значение переключателя вопроса
    const switchSection = template[key]["switchSectionMaping"];
    template[key].switchSection = Boolean(declarationForm[switchSection]);

    // здеь соберем данные для отрисовки формы
    template[key].bottomSectionRender = [];

    // значения полей вопроса
    const bottomSectionMaping = template[key]["bottomSectionMaping"];
    if (bottomSectionMaping.multy) {
      // т.е. сразу множество записей на 1м. уровне
      const keyDB = bottomSectionMaping["keyDB"]; // здесь получим массив потом по ключу keyDB
      const arrayForKeyDB = declarationForm[keyDB]
        ? declarationForm[keyDB]
        : [];
      // переберем массив
      arrayForKeyDB.forEach((element, index) => {
        template[key].bottomSectionData[index] = {}; //здесь будет объект объектов
        //тоже создадим множественный объект

        template[key].bottomSectionData[index] = handleerMultyAndOnly(
          bottomSectionMaping["multyDB"],
          element,
          template[key]
        );

        const bottomSectionRenderJSON = handlerBottomSectionData(
          template[key].bottomSectionData[index]
        );

        let isArrayOfArray = true;
        bottomSectionRenderJSON.forEach((el) => {
          if (Array.isArray(el)) {
            const bottomSectionRender = getObjectFromItem(el);
            template[key].bottomSectionRender.push(bottomSectionRender);
          } else {
            isArrayOfArray = false;
          }
        });
        if (!isArrayOfArray) {
          const bottomSectionRender = getObjectFromItem(
            bottomSectionRenderJSON
          );
          template[key].bottomSectionRender.push(bottomSectionRender);
        }
      });
    } else {
      // здесь будет объект объектов
      // не множественная запись
      template[key].bottomSectionData = handleerMultyAndOnly(
        bottomSectionMaping["onlyDB"],
        declarationForm,
        template[key]
      );

      const bottomSectionRenderJSON = handlerBottomSectionData(
        template[key].bottomSectionData
      );

      let isArrayOfArray = true;
      bottomSectionRenderJSON.forEach((el) => {
        if (Array.isArray(el)) {
          const bottomSectionRender = getObjectFromItem(el);
          template[key].bottomSectionRender.push(bottomSectionRender);
        } else {
          isArrayOfArray = false;
        }
      });
      if (!isArrayOfArray) {
        const bottomSectionRender = getObjectFromItem(bottomSectionRenderJSON);
        template[key].bottomSectionRender.push(bottomSectionRender);
      }
    }
  });

  return template;
}

/**
 * подготовка списка статусов для фильтров
 * @param {Object} object - объект статусов
 * @returns {Array}
 */
export function getStateOptionsFilter(object) {
  return Object.keys(object).reduce(
    (acc, key) => [...acc, { value: key, text: object[key] }],
    [...selectAll]
  );
}

/**
 * вывод наименования элемента КИ или КП, в зависимости от выбранного фильтра
 * @param {Object} object - объект КИ или КП
 * @param {String} filter - фильтр КИ или КП
 * @returns {String}
 */
export function showCoiCpElement(
  object,
  filter,
  lenSubstring = shortSubstringDropdown
) {
  return filter
    ? Object.keys(object)
        .find((key) => object[key] === filter)
        .substring(0, lenSubstring)
    : "Все";
}

/**
 * подготовка КИ и КП в виде массива для фильтров
 * @param {Object} object - объект КИ или КП
 * @returns {Array} массив объектов {value, tetxt}
 */
export function getArrayOptionsFilter(
  object,
  initialValue = [...selectAll],
  lenSubstring = shortSubstringDropdown
) {
  const result = initialValue;
  Object.keys(object).forEach((key) => {
    result.push({
      value: object[key],
      text: key.substring(0, lenSubstring),
      fullText: key,
    });
  });
  return result;
}

/**
 * получение строки сортировки для запроса деклараций
 * @param {String} key - код поля, по которому сортируем
 * @param {Boolean} reverse - направление сортировки (true - по убыванию)
 * @returns {String}
 */
export function getSortParamDeclaration(key, reverse) {
  const orderApi = reverse ? "-" : "";

  switch (key) {
    case "id":
      return orderApi + "id";
    case "author":
      return orderApi + "author__last_name";
    case "worker_in_charge":
      return orderApi + "assignee__last_name";
    case "state":
      return orderApi + "state";
    case "state_modified_at":
      return orderApi + "state_modified_at";
    case "user_detail.department":
      return orderApi + "department_name";
    case "user_detail.worker_rank":
      return orderApi + "rank_name";
    default:
      return "";
  }
}

// отображение кнопок действий, на основании transitions
export const buttons4transitions = {
  new: null, // внутренний статус для фронта
  draft: null, // кнопка не нужна
  assigned: {
    type: "taskBtn",
    modal: "taskModalAdd",
    state: "assigned",
    msg: "Декларация направлена исполнителю",
    confirmed: false,
    perm: perm.assign_wa_coid,
    settings: {
      buttonTitle: "Назначить исполнителя",
      modalTitle: "Рассмотреть декларацию",
      addFiles: false,
      addDeadline: true,
      addAssignee: true,
      addACp: false,
      addComment: true,
      buttonIcon: false,
      limitFiles: 0,
      changeState: false,
      transitions: {},
    },
    msg2Dec: "Исполнитель назначен",
    body: { assignee: "worker", deadline: "deadline", commentCheif: "comment" },
  },
  submitted: null, // кнопка прописана в компоненте
  autochecked: null, // внутренний статус бекэнда
  checked: {
    state: "checked",
    msg: "Завершена проверка декларации",
    confirmed: false,
    perm: perm.check_wa_coid,
    type: "taskBtn",
    modal: "taskModalChecked",
    settings: {
      buttonTitle: "Завершить проверку",
      modalTitle: "Завершить проверку",
      addComment: true,
    },
    body: { commentCheif: "comment" },
  },
  archived: null, // в статус переводит бенкэнд, на фронте кнопка не нужа
  completed: {
    state: "completed",
    msg: "Завершено рассмотрение декларации",
    type: "taskBtn",
    modal: "taskModalCompleted",
    settings: {
      buttonTitle: "Завершить рассмотрение",
      modalTitle: "Завершить рассмотрение",
      addComment: true,
    },
    body: { commentCheif: "comment" },
    confirmed: true,
    confirmedText:
      "Декларация будет переведана в статус Рассмотрена. Дальнейшая работа с декларацией будет невозможна.",
    perm: perm.complete_wa_coid,
  },
  commission: {
    type: "taskBtn",
    state: "commission",
    msg: "Декларация направлена на рассмотрение комиссии",
    confirmed: false,
    perm: perm.commission_wa_coid,
    modal: "taskModalCommission",
    settings: {
      buttonTitle: "Рассмотреть комиссией",
      modalTitle: "Рассмотреть комиссией",
      addComment: true,
    },
    body: { commentCheif: "comment" },
  },
  decision: {
    text: "Решение комиссии",
    state: "decision",
    msg: "Решение комиссии предоставлено",
    confirmed: false,
    perm: perm.decision_wa_coid,
    type: "taskBtn",
    modal: "decisionModal",
    settings: {
      buttonTitle: "Решение комиссии",
      modalTitle: "Решение комиссии",
      addFiles: true,
      addDeadline: false,
      addAssignee: false,
      addACp: false,
      addComment: false,
      buttonIcon: false,
      limitFiles: 1,
      changeState: false,
      transitions: {},
    },
    msg2Dec: "Решение прикреплено",
    body: { decision: "files" },
  },
};
