import "core-js/modules/es.array.push.js";
import theNotification from "@/components/theNotification";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapActions, mapGetters } from "vuex";
import { switchText } from "@/helpers/text";
import { formatDate } from "@/helpers/date";
import { validate } from "@/helpers/validate";
import links from "@/router/links";
import { getUserId, logout } from "@/auth/auth.js";
export default {
  name: "ProfileSettings",
  components: {
    theNotification,
    BreadCrumbToHome
  },
  async created() {
    this.updateProfileSettings();
  },
  data: () => ({
    passwordOld: "",
    passwordNew: "",
    passwordNewAgain: "",
    passwordNewState: null,
    passwordNewAgainState: null,
    showModalLogout: false
  }),
  methods: {
    ...mapActions("profile", ["updateProfileSettings", "changeUserPassword", "setPasswordloading"]),
    switchText,
    formatDate,
    validatePasswordNew() {
      const validPassword = validate({
        data: this.passwordNew,
        dataRequired: "password"
      });
      this.passwordNewState = validPassword ? null : validPassword;
      return validPassword;
    },
    validatePasswordNewAgain() {
      const validPassword = this.passwordNew === this.passwordNewAgain;
      this.passwordNewAgainState = validPassword ? null : validPassword;
      if (validPassword === false) this.changePswVisible = true;
      return validPassword;
    },
    async changePassword() {
      await this.changeUserPassword({
        id: getUserId(),
        params: {
          old_password: this.passwordOld,
          new_password: this.passwordNew
        }
      });
      // что-то пошло не так
      if (this.profilSettings[0].error) return;
      this.showModalLogout = true;
    },
    async doLogout() {
      try {
        this.setPasswordloading(true);
        await logout();
      } catch (error) {
        this.showNotify({
          msg: error.message,
          title: "Ошибка выхода из системы",
          variant: "danger"
        });
      } finally {
        this.setPasswordloading(false);
        this.$router.push(links.auth);
      }
    }
  },
  computed: {
    ...mapGetters("profile", ["profilSettings", "profilSettingsLoading"]),
    disabledChange() {
      return this.readOnly || this.passwordNew === "" || !validate({
        data: this.passwordNew,
        dataRequired: "password"
      }) || this.passwordNew !== this.passwordNewAgain;
    },
    readOnly() {
      return this.profilSettingsLoading;
    }
  }
};