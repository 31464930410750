import mutations from "@/store/mutations";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { fetchAPI, getUrl } from "@/helpers/fetch";
import { formatObjDate } from "@/helpers/date";
import { fmtNameFull, formatName } from "@/helpers/text";
import { partnersTemplate } from "@/components/Partners/partnersTemplate";

function additionPartner(obj) {
  obj.profileDate = formatObjDate(obj.profile_offline_date);
  obj.contractDate = formatObjDate(obj.contract_date);
  obj.assigneeDetail = obj.assignee_detail
    ? formatName(obj.assignee_detail, fmtNameFull)
    : null;
  partnersTemplate().forEach((el) => {
    if (el.type === "select" && obj[el.key] === null) obj[el.key] = false;
  });
  return obj;
}

const {
  PARTNERS,
  PARTNERS_COUNT,
  PARTNERS_LOADING,
  PARTNER_FORM,
  PARTNER_FORM_LOADING,
  PARTNER_REP_LOADING,
  PARTNER_REP_FILE,
  PARTNER_REP_DATA,
  PARTNER_CHECKS,
  PARTNER_CHECKS_LOADING,
  PARTNER_CHECKS_COMMENT,
  PARTNER_CHECKS_COMMENT_LOADING,
  PARTNER_CHECKS_ATTACH,
  PARTNER_CHECKS_ATTACH_LOADING,
} = mutations;

const partners = {
  namespaced: true,

  state: {
    partners: [],
    partnersCount: 0,
    partnersLoading: false,
    partnerForm: { all_contracts_data: [] },
    partnerFormLoading: false,
    // report
    partnerReportLoading: false,
    partnerReportData: {},
    partnerReportFile: "",
    // checks
    partnerChecks: {},
    partnerChecksLoading: false,
    partnerChecksComment: {},
    partnerChecksCommentLoading: false,
    partnerChecksAttach: {},
    partnerChecksAttachLoading: false,
  },

  getters: {
    partners: ({ partners }) => partners,
    partnersCount: ({ partnersCount }) => partnersCount,
    partnersLoading: ({ partnersLoading }) => partnersLoading,
    partnerForm: ({ partnerForm }) => partnerForm,
    partnerFormLoading: ({ partnerFormLoading }) => partnerFormLoading,
    partnerReportLoading: ({ partnerReportLoading }) => partnerReportLoading,
    partnerReportData: ({ partnerReportData }) => partnerReportData,
    partnerReportFile: ({ partnerReportFile }) => partnerReportFile,
    // checks
    partnerChecks: ({ partnerChecks }) => partnerChecks,
    partnerChecksLoading: ({ partnerChecksLoading }) => partnerChecksLoading,
    partnerChecksComment: ({ partnerChecksComment }) => partnerChecksComment,
    partnerChecksCommentLoading: ({ partnerChecksCommentLoading }) =>
      partnerChecksCommentLoading,
    partnerChecksAttach: ({ partnerChecksAttach }) => partnerChecksAttach,
    partnerChecksAttachLoading: ({ partnerChecksAttachLoading }) =>
      partnerChecksAttachLoading,
  },

  mutations: {
    [PARTNERS](state, value) {
      state.partners = value;
    },
    [PARTNERS_COUNT](state, value) {
      state.partnersCount = value;
    },
    [PARTNERS_LOADING](state, value) {
      state.partnersLoading = value;
    },
    [PARTNER_FORM](state, value) {
      state.partnerForm = value;
    },
    [PARTNER_FORM_LOADING](state, value) {
      state.partnerFormLoading = value;
    },
    [PARTNER_REP_LOADING](state, value) {
      state.partnerReportLoading = value;
    },
    [PARTNER_REP_FILE](state, value) {
      state.partnerReportFile = value;
    },
    [PARTNER_REP_DATA](state, value) {
      state.partnerReportData = value;
    },
    // checks
    [PARTNER_CHECKS](state, value) {
      state.partnerChecks = value;
    },
    [PARTNER_CHECKS_LOADING](state, value) {
      state.partnerChecksLoading = value;
    },
    [PARTNER_CHECKS_COMMENT](state, value) {
      state.partnerChecksComment = value;
    },
    [PARTNER_CHECKS_COMMENT_LOADING](state, value) {
      state.partnerChecksCommentLoading = value;
    },
    [PARTNER_CHECKS_ATTACH](state, value) {
      state.partnerChecksAttach = value;
    },
    [PARTNER_CHECKS_ATTACH_LOADING](state, value) {
      state.partnerChecksAttachLoading = value;
    },
  },

  actions: {
    // запрос с сервера списка партнеров
    async updatePartners(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/business-partners/", data);
          context.commit(PARTNERS, response.results);
          context.commit(PARTNERS_COUNT, response.count);
        },
        PARTNERS_LOADING
      );
    },

    // запрос партнера по id
    async updatePartnerById(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const partner = await fetchAPI(`/business-partners/${id}/`);
          context.commit(PARTNER_FORM, additionPartner(partner));
        },
        PARTNER_FORM_LOADING
      );
    },

    // изменить партнера
    async changePartner(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/business-partners/${data.id}/`,
          null,
          "PATCH",
          data.params
        );
        return "Деловой партнер изменен";
      });
    },

    // создать нового партнера
    async createPartner(context) {
      context.commit(PARTNER_FORM, {}); // drop current partner form
      await wrapCommitDispatch(
        context,
        async () => {
          const partner = await fetchAPI("/business-partners/", null, "POST");
          context.commit(PARTNER_FORM, additionPartner(partner));
          return "Деловой партнер создан";
        },
        PARTNERS_LOADING
      );
    },

    // удалить партнера
    async removePartner(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/business-partners/${data.id}/`, null, "DELETE");
          return `Деловой партнер ${data.full_name} удален`;
        },
        PARTNERS_LOADING
      );
    },

    // изменить статус партнера
    async changeStatePartner(context, data) {
      await wrapCommitDispatch(context, async () => {
        const response = await fetchAPI(
          `/business-partners/${data.id}/transition/`,
          null,
          "PATCH",
          addLinks({ state: data.state })
        );
        context.commit(PARTNER_FORM, additionPartner(response));
        return data.msg;
      });
    },

    //report
    // запрос данных для отчета
    async updateReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/business-partners/report/", data);
          context.commit(PARTNER_REP_DATA, response);
        },
        PARTNER_REP_LOADING
      );
    },

    // запрос файла для отчета
    async downloadReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(
            PARTNER_REP_FILE,
            getUrl("/business-partners/download/", data)
          );
        },
        PARTNER_REP_LOADING
      );
    },

    // checks
    // запрос проверки по id
    async updatePartnerChecks(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const check = await fetchAPI(`/bp-checks/${id}/`);
          context.commit(
            PARTNER_CHECKS,
            Object.assign({}, context.getters.partnerChecks, {
              [id]: check,
            })
          );
        },
        PARTNER_CHECKS_LOADING
      );
    },

    // запрос комментариев проверки по id
    async updatePartnerChecksComment(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const comments = await fetchAPI(`/bp-checks/${id}/comments/`);
          context.commit(
            PARTNER_CHECKS_COMMENT,
            Object.assign({}, context.getters.partnerChecksComment, {
              [id]: comments.results,
            })
          );
        },
        PARTNER_CHECKS_COMMENT_LOADING
      );
    },

    // создать комментарий для проверки
    async createPartnerChecksComment(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/bp-checks/${data.id}/comments/`,
            null,
            "POST",
            data.params
          );
          return "Комментарий к проверке делового партнера создан";
        },
        PARTNER_CHECKS_COMMENT_LOADING
      );
    },

    // запрос вложений проверки по id
    async updatePartnerChecksAttach(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const attachments = await fetchAPI(`/bp-checks/${id}/attachments/`);
          context.commit(
            PARTNER_CHECKS_ATTACH,
            Object.assign({}, context.getters.partnerChecksAttach, {
              [id]: attachments.results,
            })
          );
        },
        PARTNER_CHECKS_ATTACH_LOADING
      );
    },

    // создать вложение для проверки
    async createPartnerChecksAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/bp-checks/${data.id}/attachments/`,
            null,
            "POST",
            data.params,
            true
          );
          return "Вложение к проверке делового партнера создано";
        },
        PARTNER_CHECKS_ATTACH_LOADING
      );
    },

    // изменение данных проверки
    async changePartnerCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/bp-checks/${data.id}/`, null, "PATCH", data.params);
          return "Проверка изменена";
        },
        PARTNER_CHECKS_LOADING
      );
    },

    // изменение статуса проверки
    async changeStatePartnerCheck(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/bp-checks/${data.id}/transition/`,
            null,
            "PATCH",
            addLinks({ state: data.state })
          );
          return data.msg;
        },
        PARTNER_CHECKS_LOADING
      );
    },
  },
};

export default partners;
