export default {
  name: "ImageUpload",
  props: {
    keyImg: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: false
    },
    types: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    file: {}
  }),
  methods: {
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    async handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0].name;
      const formData = new FormData();
      formData.append(this.keyImg, files[0]);
      this.$emit("formdata", formData);
      this.$refs.fileInput.value = null;
    },
    async handlerDeleteFile() {
      this.$emit("deletefile", this.keyImg);
    }
  }
};