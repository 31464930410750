import perm from "@/auth/permissions.js";

export const needAppoint =
  "Только работники, получившие назначение на ознакомление"; // только для этой группы используем механизм назначений

export const needSignPEP = "ПЭП (Платформа)";

// типы форматов ознакомления с документами базы знаний
export const knowledgeFormat = [needSignPEP, "КЭП (СЭД)", "Личная подпись"];

export const btn4transitLna = {
  draft: [
    {
      type: "taskBtn",
      modal: "taskModalSubmittedLna",
      state: "submitted",
      perm: perm.archive_wa_libmaterial,
      msg: "Документ направлен на рассмотрение",
      settings: {
        buttonTitle: "Направить на рассмотрение",
        modalTitle: "Назначить ответственного и срок ознакомления",
        addAssignee: true,
        addDeadline: true,
        buttonType: "primary",
      },
      body: { responsible: "worker", deadline: "deadline" },
    },
    {
      type: "simple",
      text: "Архивировать",
      variant: "outline-primary",
      state: "archived",
      msg: "Документ переведен в архив",
      perm: perm.archive_wa_libmaterial,
    },
  ],
  submitted: [
    {
      type: "simple",
      text: "Вернуть на доработку",
      variant: "outline-danger",
      state: "draft",
      msg: "Документ возвращён на доработку",
      perm: perm.add_wa_libmaterial,
    },
    {
      type: "simple",
      text: "Опубликовать",
      variant: "primary",
      state: "published",
      msg: "Документ опубликован",
      perm: perm.publish_wa_libmaterial,
    },
    {
      type: "simple",
      text: "Архивировать",
      variant: "outline-primary",
      state: "archived",
      msg: "Документ переведен в архив",
      perm: perm.archive_wa_libmaterial,
    },
  ],
  published: [
    {
      type: "simple",
      text: "Снять с публикации",
      variant: "outline-danger",
      state: "closed",
      msg: "Документ снят с публикации",
      perm: perm.close_wa_libmaterial,
    },
    {
      type: "simple",
      text: "Архивировать",
      variant: "outline-primary",
      state: "archived",
      msg: "Документ переведен в архив",
      perm: perm.archive_wa_libmaterial,
    },
  ],
  closed: [
    {
      type: "simple",
      text: "Опубликовать повторно",
      variant: "outline-danger",
      state: "published",
      msg: "Документ повторно опубликован",
      perm: perm.publish_wa_libmaterial,
    },
    {
      type: "simple",
      text: "Архивировать",
      variant: "outline-primary",
      state: "archived",
      msg: "Документ переведен в архив",
      perm: perm.archive_wa_libmaterial,
    },
  ],
  archived: [],
};
