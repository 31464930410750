import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import { NONUMBER, limitBound } from "@/helpers/text";
export default {
  name: "ExpiredHotline",
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "message_number",
      label: "№ сообщения",
      formatter: value => value || NONUMBER
    }, {
      key: "registration_number",
      label: "№ регистрации"
    }, {
      key: "arrival_date",
      label: "Дата поступления",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "submitted_at",
      label: "Дата регистрации",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "applicant_full_name",
      label: "ФИО заявителя"
    }, {
      key: "applicant_type",
      label: "Тип заявителя"
    }, {
      key: "operator_name",
      label: "Оператор ГЛ"
    }, {
      key: "category_name",
      label: "Тип сообщения"
    }, {
      key: "message_type",
      label: "Категория сообщения"
    }, {
      key: "content",
      label: "Суть сообщения"
    }, {
      key: "measures",
      label: "Принятые меры"
    }, {
      key: "assignee_name",
      label: "ФИО исполнителя"
    }, {
      key: "department_chief_name",
      label: "Руководитель подразделения исполнителя"
    }, {
      key: "deadline",
      label: "Срок исполнения",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "response_date",
      label: "Дата ответа",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "has_prolongation_notice",
      label: "Уведомление о продлении"
    }]
  }),
  methods: {
    ...mapActions("hotline", ["updateReport"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.hlineReportData.count / this.perPage, this.currentPage);
      this.updateReport({
        page: this.currentPage,
        ...this.filters
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineReportData", "hlineReportLoading"]),
    title() {
      return "Просроченные сообщения по горячей линии за период c " + formatDate(this.filters.submitted_gte, fmtDateShort) + " по " + formatDate(this.filters.submitted_lte, fmtDateShort);
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateReport({
          page: value,
          ...this.filters
        });
      }
    }
  }
};