import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
import { statesRiskMatrix, statesCheckMatrix } from "@/helpers/states";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
export default {
  name: "MatrixCheckList",
  components: {
    TaskButton
  },
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "id",
      label: "#"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => statesCheckMatrix[value]
    }, {
      key: "assignee_detail",
      label: "Назначена",
      formatter: value => formatName(value, fmtNameShort),
      class: "text-nowrap"
    }, {
      key: "created_at",
      label: "Создана",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap"
    }, {
      key: "confirmed_at",
      label: "Согласована",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap"
    }, {
      key: "declined_at",
      label: "Отклонена",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap"
    }, {
      key: "btnRemove",
      label: ""
    }],
    settingsAdd: {
      buttonTitle: "Назначить проверку",
      buttonType: "outline-primary",
      modalTitle: "Назначить проверку",
      addAssignee: true,
      filterAssignee: {
        only_chiefs: true
      }
    },
    showModalRemove: false,
    currentItem: {},
    fmtNameShort
  }),
  methods: {
    ...mapActions("risk", ["updateMatrixFormChecks", "createMatrixFormChecks", "removeMatrixFormChecks"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.matrixFormChecksCount / this.perPage, this.currentPage);
      this.updateMatrixFormChecks({
        id: this.matrixForm.id,
        page: this.currentPage
      });
    },
    async clickAddCheck(data) {
      await this.createMatrixFormChecks({
        id: this.matrixForm.id,
        assignee: data.worker
      });
      await this.updateMatrixFormChecks({
        id: this.matrixForm.id,
        page: this.currentPage
      });
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeMatrixFormChecks({
        matrixId: this.matrixForm.id,
        checkId: this.currentItem.id
      });
      await this.updateMatrixFormChecks({
        id: this.matrixForm.id,
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixFormChecks", "matrixFormChecksLoading", "matrixFormChecksCount", "matrixForm"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateMatrixFormChecks({
          id: this.matrixForm.id,
          page: value
        });
      }
    },
    showBtn() {
      return statesRiskMatrix[this.matrixForm.state] === statesRiskMatrix.submitted;
    }
  }
};