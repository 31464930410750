import "core-js/modules/es.array.push.js";
import { switchText } from "@/helpers/text";
export default {
  name: "DeclarationFormPartIIItem",
  props: {
    itemIndex: {
      type: Number,
      required: true
    },
    sectionId: {
      type: String,
      required: true
    },
    disabledDeclActions: {
      type: Boolean,
      default: true
    },
    // недоступность действий с декларацией
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    arrayForTable() {
      const resArray = [];
      for (let key in this.item) {
        const name = this.item[key].name;
        const data = this.item[key].data;
        //id не отображаем
        if (this.item[key].hidden) continue;
        resArray.push({
          name,
          data: typeof data === "boolean" ? switchText(data) : data
        });
      }
      return resArray;
    },
    showCard() {
      return Object.keys(this.item).length > 0 ? true : false;
    }
  },
  methods: {
    emitChangeEvent() {
      this.$emit("changeItem", {
        itemIndex: this.itemIndex,
        sectionId: this.sectionId
      });
    },
    emitRemoveEvent() {
      this.$emit("removeItem", {
        itemIndex: this.itemIndex,
        title: "Удалить элемент?",
        sectionId: this.sectionId
      });
    }
  }
};