import { mapGetters, mapActions } from "vuex";
import { typeAuthorHotline } from "@/helpers/text";
import { statesHotline } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
export default {
  name: "HotlineFormAuthor",
  data: () => ({
    typeAuthorHotline
  }),
  methods: {
    ...mapActions("hotline", ["changeHlineMsg"]),
    async changeData(key) {
      //if (key === "profile_offline_date") {
      //value = formatDateObj(this.partnerForm.profileDate, fmtDate, null);
      //if (!value) return;
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [key]: this.hlineMsg[key]
        }
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg"]),
    readOnly() {
      return statesHotline[this.hlineMsg.state] !== statesHotline.draft || !checkPermissions(this.$perm.change_wa_hotmsg);
    },
    star() {
      return ["Деловой партнер", "Работник"].includes(this.hlineMsg.applicant_type) ? "*" : "";
    }
  }
};