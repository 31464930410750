import { mapActions, mapGetters } from "vuex";
import COIDetails from "../COI/COIDetails.vue";
export default {
  name: "ReportCoiEDKI",
  components: {
    COIDetails
  },
  props: {
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    currentPage: 1,
    perPage: 10,
    sortBy: "id",
    sortDesc: true,
    // по убывынию
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "idT",
      label: "Источник"
    }, {
      key: "authorT",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "user_detail.position",
      label: "Должность работника"
    }, {
      key: "decl_stateT",
      label: "Статус декларации"
    }, {
      key: "coi_type",
      label: "Тип КИ"
    }, {
      key: "proceduresT",
      label: "Тип КП"
    }]
  }),
  computed: {
    ...mapGetters("coi", ["coi", "coiCount", "coiLoading"]),
    isEmpty() {
      return !this.coiCount;
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.fetchCoi({
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: this.filters,
          page: value
        });
      }
    }
  },
  methods: {
    ...mapActions("coi", ["fetchCoi"])
  }
};