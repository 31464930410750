import DeclarationsList from "./DeclarationsList";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
export default {
  name: "DeclarationArea",
  components: {
    DeclarationsList,
    theNotification,
    BreadCrumbToHome
  },
  data: () => ({
    showCOI: true // else show presents
  }),

  async created() {
    await this.fetchDeclarations({
      page: 1,
      filters: {
        is_new: this.onlyNew
      }
    });
    this.setOnlyNew(null); // обнулимся
  },

  computed: {
    ...mapGetters("declarations", ["declarationsObj"]),
    ...mapGetters("homeStats", ["onlyNew"])
  },
  methods: {
    ...mapActions("declarations", ["fetchDeclarations"]),
    ...mapActions("homeStats", ["setOnlyNew"])
  }
};