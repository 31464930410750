export default {
  name: "StringsWithX",
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  methods: {
    clickIconX(id) {
      this.$emit("removeItem", id);
    }
  }
};