import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "theComments",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    disabledActions: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default: () => []
    },
    busy: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    currentPage: 1,
    defaultFields: [{
      key: "index",
      label: "№"
    }, {
      key: "name",
      label: "Описание"
    }, {
      key: "date",
      label: "Создан",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "user",
      label: "Работник",
      formatter: value => formatName(value, fmtNameShort)
    }],
    showModalRemove: false,
    currentName: "",
    currentId: null
  }),
  methods: {
    clickAdd() {
      this.$emit("addComment");
    },
    changeComment(id, name) {
      this.$emit("changeComment", {
        id,
        name
      });
    },
    handlerDel(name, id) {
      this.currentName = name;
      this.currentId = id;
      this.showModalRemove = true;
    },
    async doRemove() {
      this.$emit("delComment", this.currentId);
      this.currentPage = 1;
      this.$emit("setPage", 1);
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.count / this.perPage, this.currentPage);
      this.$emit("setPage", this.currentPage);
    }
  },
  computed: {
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.$emit("setPage", value);
      }
    },
    tableFields() {
      return this.defaultFields.concat(this.fields, [{
        key: "remove",
        label: "Удалить",
        class: "text-center"
      }]);
    }
  }
};