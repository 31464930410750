import { CODE_TO_EMAIL, signTypes } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import { profileReadonly } from "@/helpers/profile";
import { statesEducUser, statesKnowledgeUser, statesTestUser } from "@/helpers/states";
export default {
  name: "SignPEP",
  data: () => ({
    code: "",
    CODE_TO_EMAIL,
    modalShow: false
  }),
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions("profile", ["updateSign", "sendSignProfile"]),
    ...mapActions("education", ["sendSignCourse", "sendSignTest"]),
    ...mapActions("declarationForm", ["sendSignDeclaration"]),
    ...mapActions("knowledge", ["sendSignLna"]),
    resetModal() {
      this.code = "";
    },
    handlerClick() {
      this.modalShow = true;
      this.updateSign({
        id: this.id,
        type: this.type
      });
    },
    async handlerConfirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      switch (this.type) {
        case signTypes.profile:
          await this.sendSignProfile({
            id: this.id,
            code: this.code
          });
          if (profileReadonly(this.profileObj.state))
            // ЛЛ перешел из черновика в другой статус, значит все хорошо и можно закрыть окно
            this.modalShow = false;
          break;
        case signTypes.education:
          await this.sendSignCourse({
            id: this.id,
            code: this.code
          });
          if (statesEducUser[this.getCourse.assignment.state] === statesEducUser.finished)
            // курс перешел в статус пройдено, значит все хорошо и можно закрыть окно
            this.modalShow = false;
          break;
        case signTypes.test:
          await this.sendSignTest({
            id: this.id,
            code: this.code
          });
          if (statesTestUser[this.educationTest.state] !== statesTestUser.started)
            // тест перешел в следующий статус, значит все хорошо и можно закрыть окно
            this.modalShow = false;
          break;
        case signTypes.declaration:
          await this.sendSignDeclaration({
            id: this.id,
            code: this.code
          });
          if (!["new", "draft"].includes(this.declarationFormObj.state))
            // декларация перешла из new/draft в другой статус, значит все хорошо и можно закрыть окно
            this.modalShow = false;
          break;
        case signTypes.knowledge:
          await this.sendSignLna({
            id: this.id,
            code: this.code
          });
          if (statesKnowledgeUser[this.lnaUserForm?.assignment.state] === statesKnowledgeUser.finished)
            // документ перешел в статус finished, значит все хорошо и можно закрыть окно
            this.modalShow = false;
          break;
      }
      // иначе ждем ввода корректного кода (возможно пользователь опечатался)
    }
  },

  computed: {
    ...mapGetters("profile", ["sign", "signLoading", "profileObj"]),
    ...mapGetters("education", ["getCourse", "educationTest"]),
    ...mapGetters("declarationForm", ["declarationFormObj"]),
    ...mapGetters("knowledge", ["lnaUserForm"]),
    okModalDisabled() {
      return !this.code.length;
    }
  }
};