import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
//import { formatDate } from "@/helpers/date";

// нормализуем ответ, после получения по api
// получаем массив объектов
function massageResponse(task) {
  return { results: task["results"] || [], count: task["count"] || 0 };
}

const {
  TASK_COMMENTS,
  TASK_COMMENTS_COUNT,
  TASK_COMMENTS_LOADING,
  TASKS_FOR_DECLARATION,
  TASKS_FOR_DECLARATION_COUNT,
  TASKS_FOR_DECLARATION_LOADING,
} = mutations;

const tasks = {
  namespaced: true,
  state: {
    taskComments: [],
    taskCommentsCount: 0,
    taskCommentsLoading: false,
    tasksForDeclaration: [],
    tasksForDeclarationCount: 0,
    tasksForDeclarationLoading: false,
  },
  getters: {
    taskComments: ({ taskComments }) => taskComments,
    taskCommentsCount: ({ taskCommentsCount }) => taskCommentsCount,
    taskCommentsLoading: ({ taskCommentsLoading }) => taskCommentsLoading,
    tasksForDeclaration: ({ tasksForDeclaration }) => tasksForDeclaration,
    tasksForDeclarationCount: ({ tasksForDeclarationCount }) =>
      tasksForDeclarationCount,
    tasksForDeclarationLoading: ({ tasksForDeclarationLoading }) =>
      tasksForDeclarationLoading,
  },
  mutations: {
    [TASK_COMMENTS](state, value) {
      state.taskComments = value;
    },
    [TASK_COMMENTS_COUNT](state, value) {
      state.taskCommentsCount = Number(value);
    },
    [TASK_COMMENTS_LOADING](state, value) {
      state.taskCommentsLoading = value;
    },
    [TASKS_FOR_DECLARATION](state, value) {
      state.tasksForDeclaration = value;
    },
    [TASKS_FOR_DECLARATION_COUNT](state, value) {
      state.tasksForDeclarationCount = Number(value);
    },
    [TASKS_FOR_DECLARATION_LOADING](state, value) {
      state.tasksForDeclarationLoading = value;
    },
  },
  actions: {
    // запрос комментариев по задаче
    async updateTaskComments({ commit, dispatch }, { coidTasksPk, page }) {
      commit(TASK_COMMENTS_LOADING, true);
      try {
        const response = await fetchAPI(
          `/coid-tasks/${coidTasksPk}/comments/`,
          {
            page,
          }
        );
        const { result, count } = massageResponse(response);
        commit(TASK_COMMENTS, result);
        commit(TASK_COMMENTS_COUNT, count);
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(TASK_COMMENTS_LOADING, false);
      }
    },

    // запрос списка задач (под конкретную Декларацию)
    async updateTasksByDeclarationId({ commit, dispatch }, { id, page }) {
      commit(TASKS_FOR_DECLARATION_LOADING, true);
      try {
        const response = await fetchAPI(`/declarations/${id}/coid-tasks/`, {
          page,
        });
        const { results, count } = massageResponse(response);
        commit(TASKS_FOR_DECLARATION, results);
        commit(TASKS_FOR_DECLARATION_COUNT, count);
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(TASKS_FOR_DECLARATION_LOADING, false);
      }
    },

    // смена статуса задачи
    async changeStateTask({ dispatch }, { id, status }) {
      try {
        await fetchAPI(`/coid-tasks/${id}/state/`, null, "POST", {
          target: status,
        });
        dispatch(
          "showNotify",
          {
            msg: "Статус изменен",
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // изменение задачи для декларции
    async changeTaskForDeclaration({ dispatch }, data) {
      const taskPatch = {
        assignee_id: data["worker"],
        deadline: data["deadline"],
      };
      try {
        await fetchAPI(
          `/declarations/${data["declId"]}/coid-tasks/${data["taskId"]}/`,
          null,
          "PATCH",
          taskPatch
        );
        if (data["comment"].length > 0) {
          await fetchAPI(
            `/coid-tasks/${data["taskId"]}/comments/`,
            null,
            "POST",
            { body: data["comment"] }
          );
        }
        dispatch(
          "showNotify",
          {
            msg: "Задача изменена",
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // создание новой задачи для декларции
    async newTaskForDeclaration({ dispatch }, data) {
      const taskPost = {
        assignee_id: data["worker"],
        deadline: data["deadline"],
        title: data["title"],
      };
      try {
        const response = await fetchAPI(
          `/declarations/${data["declId"]}/coid-tasks/`,
          null,
          "POST",
          taskPost
        );
        // бэк возвращает в ответ задачу
        // запишем комментарий /coid-tasks/{coid_tasks_pk}/comments/
        if (response["id"] && data["comment"].length > 0) {
          await fetchAPI(
            `/coid-tasks/${response["id"]}/comments/`,
            null,
            "POST",
            { body: data["comment"] }
          );
        }
        dispatch(
          "showNotify",
          {
            msg: "Новая задача создана",
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // сброс списка задач для конкретной декларации (в случае создания новой)
    dropTasksForDeclaration({ commit }) {
      commit(TASKS_FOR_DECLARATION, []);
      commit(TASKS_FOR_DECLARATION_COUNT, 0);
    },
  },
};

export default tasks;
