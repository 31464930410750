import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_button, {
    variant: "primary",
    onClick: $options.createBackup
  }, {
    default: _withCtx(() => [_createTextVNode("Создать копию")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    id: "createBackupMoadal",
    title: "Создать резервную копию",
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Создать",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Название копии",
          "label-for": "modal-name-backup"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            id: "modal-name-backup",
            modelValue: _ctx.nameBackup,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.nameBackup = $event),
            placeholder: "Укажите название",
            trim: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["onOk", "ok-disabled"])]);
}