import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "20. Имеются ли в отношении Вас неисполненные исполнительные производства?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          class: "w-auto",
          modelValue: _ctx.profileObj.has_enf_proceedings,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_enf_proceedings = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.executionsState
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}