import mutations from "@/store/mutations";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { fetchAPI, getUrl } from "@/helpers/fetch";
import { formatObjDate } from "@/helpers/date";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";

function additionLna(obj) {
  obj.documentDate = formatObjDate(obj.document_date);
  obj.deadLine = formatObjDate(obj.deadline);
  return obj;
}

const {
  // разделы
  SECTIONS,
  SECTIONS_LOADING,
  SECTIONS_COUNT,
  SECTION_FORM,
  SECTION_FORM_LOADING,
  // документы
  SECTIONS_DOCUMENTS,
  SECTIONS_ARCHIVE,
  SECTIONS_DOCUMENTS_LOADING,
  SECTIONS_DOCUMENTS_COUNT,
  SECTIONS_ARCHIVE_LOADING,
  SECTIONS_ARCHIVE_COUNT,
  DOCUMENT_LOADING,
  DOCUMENT,
  // реестр работников, которым назначено ознакомление
  WORKERS_LIST,
  WORKERS_LIST_COUNT,
  WORKERS_LIST_LOADING,
  // реестр работников к назначению
  WORKERS_TO_ASS,
  WORKERS_TO_ASS_COUNT,
  WORKERS_TO_ASS_LOADING,
  // ЛНА ЛК
  LNA_USER_LIST,
  LNA_USER_LIST_COUNT,
  LNA_USER_LIST_LOADING,
  LNA_USER_FORM,
  LNA_USER_FORM_LOADING,
  // report
  LNA_REP_DATA,
  LNA_REP_COUNT,
  LNA_REP_LOADING,
  LNA_REP_FILE,
} = mutations;

const knowledge = {
  namespaced: true,
  state: {
    // разделы
    sections: [],
    sectionsLoading: false,
    sectionsCount: 0,
    sectionForm: {},
    sectionFormLoading: false,
    // документы
    sectionsDocuments: [],
    sectionsDocumentsCount: 0,
    sectionsArchive: [],
    sectionsArchiveCount: 0,
    sectionsDocumentsLoading: false,
    sectionsArchiveLoading: false,
    knowlegeDocument: {},
    knowlegeDocumentLoading: false,
    // реестр работников, которым назначено ознакомление
    workersList: [],
    workersListCount: 0,
    workersListLoading: false,
    // реестр работников к назначению
    workersToAss: [],
    workersToAssCount: 0,
    workersToAssLoading: false,
    // ЛНА ЛК
    lnaUserList: [],
    lnaUserListCount: 0,
    lnaUserListLoading: false,
    lnaUserForm: {},
    lnaUserFormLoading: false,
    // report
    lnaReportData: [],
    lnaReportLoading: false,
    lnaReportCount: 0,
    lnaReportFile: "",
  },
  getters: {
    // разделы
    sections: ({ sections }) => sections,
    sectionsLoading: ({ sectionsLoading }) => sectionsLoading,
    sectionsCount: ({ sectionsCount }) => sectionsCount,
    sectionForm: ({ sectionForm }) => sectionForm,
    sectionFormLoading: ({ sectionFormLoading }) => sectionFormLoading,
    // документы
    sectionsDocuments: ({ sectionsDocuments }) => sectionsDocuments,
    sectionsDocumentsCount: ({ sectionsDocumentsCount }) =>
      sectionsDocumentsCount,
    sectionsArchiveCount: ({ sectionsArchiveCount }) => sectionsArchiveCount,
    sectionsArchive: ({ sectionsArchive }) => sectionsArchive,
    sectionsDocumentsLoading: ({ sectionsDocumentsLoading }) =>
      sectionsDocumentsLoading,
    sectionsArchiveLoading: ({ sectionsArchiveLoading }) =>
      sectionsArchiveLoading,
    knowlegeDocument: ({ knowlegeDocument }) => knowlegeDocument,
    knowlegeDocumentLoading: ({ knowlegeDocumentLoading }) =>
      knowlegeDocumentLoading,
    // реестр работников, которым назначено ознакомление
    workersList: ({ workersList }) => workersList,
    workersListCount: ({ workersListCount }) => workersListCount,
    workersListLoading: ({ workersListLoading }) => workersListLoading,
    // реестр работников к назначению
    workersToAss: ({ workersToAss }) => workersToAss,
    workersToAssCount: ({ workersToAssCount }) => workersToAssCount,
    workersToAssLoading: ({ workersToAssLoading }) => workersToAssLoading,
    // ЛНА ЛК
    lnaUserList: ({ lnaUserList }) => lnaUserList,
    lnaUserListCount: ({ lnaUserListCount }) => lnaUserListCount,
    lnaUserListLoading: ({ lnaUserListLoading }) => lnaUserListLoading,
    lnaUserForm: ({ lnaUserForm }) => lnaUserForm,
    lnaUserFormLoading: ({ lnaUserFormLoading }) => lnaUserFormLoading,
    // report
    lnaReportData: ({ lnaReportData }) => lnaReportData,
    lnaReportLoading: ({ lnaReportLoading }) => lnaReportLoading,
    lnaReportCount: ({ lnaReportCount }) => lnaReportCount,
    lnaReportFile: ({ lnaReportFile }) => lnaReportFile,
  },

  mutations: {
    // разделы
    [SECTIONS](state, value) {
      state.sections = value;
    },
    [SECTIONS_LOADING](state, value) {
      state.sectionsLoading = value;
    },
    [SECTIONS_COUNT](state, value) {
      state.sectionsCount = value;
    },
    [SECTION_FORM](state, value) {
      state.sectionForm = value;
    },
    [SECTION_FORM_LOADING](state, value) {
      state.sectionFormLoading = value;
    },
    // документы
    [SECTIONS_DOCUMENTS](state, value) {
      state.sectionsDocuments = value;
    },
    [SECTIONS_ARCHIVE](state, value) {
      state.sectionsArchive = value;
    },
    [SECTIONS_DOCUMENTS_LOADING](state, value) {
      state.sectionsDocumentsLoading = value;
    },
    [SECTIONS_DOCUMENTS_COUNT](state, value) {
      state.sectionsDocumentsCount = value;
    },
    [SECTIONS_ARCHIVE_COUNT](state, value) {
      state.sectionsArchiveCount = value;
    },
    [SECTIONS_ARCHIVE_LOADING](state, value) {
      state.sectionsArchiveLoading = value;
    },
    [DOCUMENT](state, value) {
      state.knowlegeDocument = value;
    },
    [DOCUMENT_LOADING](state, value) {
      state.knowlegeDocumentLoading = value;
    },
    // реестр работников, которым назначено ознакомление
    [WORKERS_LIST](state, value) {
      state.workersList = value;
    },
    [WORKERS_LIST_COUNT](state, value) {
      state.workersListCount = value;
    },
    [WORKERS_LIST_LOADING](state, value) {
      state.workersListLoading = value;
    },
    // реестр работников к назначению
    [WORKERS_TO_ASS](state, value) {
      state.workersToAss = value;
    },
    [WORKERS_TO_ASS_COUNT](state, value) {
      state.workersToAssCount = value;
    },
    [WORKERS_TO_ASS_LOADING](state, value) {
      state.workersToAssLoading = value;
    },
    // ЛНА ЛК
    [LNA_USER_LIST](state, value) {
      state.lnaUserList = value;
    },
    [LNA_USER_LIST_COUNT](state, value) {
      state.lnaUserListCount = value;
    },
    [LNA_USER_LIST_LOADING](state, value) {
      state.lnaUserListLoading = value;
    },
    [LNA_USER_FORM](state, value) {
      state.lnaUserForm = value;
    },
    [LNA_USER_FORM_LOADING](state, value) {
      state.lnaUserFormLoading = value;
    },
    // report
    [LNA_REP_DATA](state, value) {
      state.lnaReportData = value;
    },
    [LNA_REP_COUNT](state, value) {
      state.lnaReportCount = value;
    },
    [LNA_REP_LOADING](state, value) {
      state.lnaReportLoading = value;
    },
    [LNA_REP_FILE](state, value) {
      state.lnaReportFile = value;
    },
  },
  actions: {
    // запрос с сервера списка разделов
    async updateSections(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/lib-sections/", data);
          context.commit(SECTIONS, response.results);
          context.commit(SECTIONS_COUNT, response.count);
        },
        SECTIONS_LOADING
      );
    },
    // создание нового раздела
    async createSections(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/lib-sections/", null, "POST", data);
          return "Создан новый раздел";
        },
        SECTIONS_LOADING
      );
    },
    // запрос раздела по id
    async updateSectionForm(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/lib-sections/${id}/`);
          context.commit(SECTION_FORM, response);
        },
        SECTION_FORM_LOADING
      );
    },
    // корректировка раздела
    async changeSection(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/lib-sections/${data.id}/`,
            null,
            "PATCH",
            data.params
          );
          context.commit(SECTION_FORM, response);
          return "Раздел изменён";
        },
        SECTION_FORM_LOADING
      );
    },
    // документы
    // запрос с сервера списка документов
    async updateDocuments(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = data.alt
            ? await fetchAPI("/my-library/", {
                ...data,
                is_active: true,
              })
            : await fetchAPI("/lib-materials/", {
                ...data,
                is_active: true,
              });
          context.commit(SECTIONS_DOCUMENTS, response.results);
          context.commit(SECTIONS_DOCUMENTS_COUNT, response.count);
        },
        SECTIONS_DOCUMENTS_LOADING
      );
    },
    // запрос с сервера списка архивных документов
    async updateArchive(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = data.alt
            ? await fetchAPI("/my-library/", {
                state: "archived",
                ...data,
              })
            : await fetchAPI("/lib-materials/", {
                state: "archived",
                ...data,
              });
          context.commit(SECTIONS_ARCHIVE, response.results);
          context.commit(SECTIONS_ARCHIVE_COUNT, response.count);
        },
        SECTIONS_ARCHIVE_LOADING
      );
    },
    // запрос с сервера конкретного документа
    async updateDocumentForm(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/lib-materials/${id}/`);
          context.commit(DOCUMENT, additionLna(response));
        },
        DOCUMENT_LOADING
      );
    },
    // создание нового документа
    async createDocument(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            "/lib-materials/",
            null,
            "POST",
            data
          );
          context.commit(DOCUMENT, additionLna(response));
          return "Документ создан";
        },
        SECTIONS_DOCUMENTS_LOADING
      );
    },
    // создание нового документа
    async cloneDocument(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/lib-materials/${id}/clone/`, null, "POST");
          return "Документ скопирован";
        },
        SECTIONS_DOCUMENTS_LOADING
      );
    },
    // корректировка документа
    async changeDocument(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/lib-materials/${data.id}/`,
            null,
            "PATCH",
            data.params,
            data.fileUpload
          );
          context.commit(DOCUMENT, additionLna(response));
          return "Документ изменен";
        },
        DOCUMENT_LOADING
      );
    },
    // изменение статуса документа
    async changeStateDocument(context, { id, state, msg }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/lib-materials/${id}/transition/`,
            null,
            "PATCH",
            addLinks({ state })
          );
          context.commit(DOCUMENT, additionLna(response));
          return msg;
        },
        DOCUMENT_LOADING
      );
    },
    // реестр работников, которым назначено ознакомление
    async updateWorkersList(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/material-assignments/", data);
          context.commit(WORKERS_LIST, response.results);
          context.commit(WORKERS_LIST_COUNT, response.count);
        },
        WORKERS_LIST_LOADING
      );
    },
    // реестр работников к назначению
    async updateWorkersToAss(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/material-assignments/", data);
          context.commit(WORKERS_TO_ASS, response.results);
          context.commit(WORKERS_TO_ASS_COUNT, response.count);
        },
        WORKERS_TO_ASS_LOADING
      );
    },
    // назначить ознакомление для организации/департаментов/сотрудников
    async assignDocument(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          if (data.allOrg)
            await fetchAPI(`/lib-materials/${data.id}/assign/`, null, "POST", {
              organization: true,
              deadline: data.deadline,
            });
          else {
            if (data.depart.length > 0) {
              data.depart.forEach(async (depart) => {
                await fetchAPI(
                  `/lib-materials/${data.id}/assign/`,
                  null,
                  "POST",
                  { department: depart.id, deadline: data.deadline }
                );
              });
            }
            if (data.workers.length > 0) {
              data.workers.forEach(async (worker) => {
                await fetchAPI(
                  `/lib-materials/${data.id}/assign/`,
                  null,
                  "POST",
                  { user: worker.id, deadline: data.deadline }
                );
              });
            }
          }
          return "Сформирован список работников для ознакомления";
        },
        WORKERS_TO_ASS_LOADING
      );
    },
    // утвердить назначение для ознакомления
    async confirmDocument(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/lib-materials/${id}/assign/`, null, "PATCH", {
            confirmed: true,
          });
          return "Документ назначен для ознакомления";
        },
        WORKERS_TO_ASS_LOADING
      );
    },
    // изменить назначение сотрудника
    async reassignDocument(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/material-assignments/${data.id}/`, null, "PATCH", {
          deadline: data.deadline,
        });
        return "Изменения внесены";
      });
    },
    // отменить назначение для работника
    async unassignDocument(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/material-assignments/${id}/`, null, "DELETE");
          return "Работник удален из списка";
        },
        WORKERS_TO_ASS_LOADING
      );
    },
    // ЛНА ЛК
    // получить список ЛНА для ЛК
    async updateLnaUserList(context, data) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/my-lib-materials/", data);
          context.commit(LNA_USER_LIST, response.results);
          context.commit(LNA_USER_LIST_COUNT, response.count);
        },
        LNA_USER_LIST_LOADING
      );
    },
    // получить форму ЛНА в ЛК
    async updateLnaUserForm(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = data.alt
            ? await fetchAPI(`/my-library/${data.id}/`)
            : await fetchAPI(`/my-lib-materials/${data.id}/`);
          context.commit(LNA_USER_FORM, response);
        },
        LNA_USER_FORM_LOADING
      );
    },
    // подтвердить ознакомление в ЛК с помощью ЭЦП
    async sendSignLna(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-lib-materials/${data.id}/sign/`,
            null,
            "PATCH",
            addLinks({ code: data.code })
          );
          context.commit(LNA_USER_FORM, result);
          return "Ознакомление завершено";
        },
        LNA_USER_FORM_LOADING
      );
    },
    // подтвердить ознакомление в ЛК с помощью личной подписи
    async changeStateLna(context, { id, state }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-lib-materials/${id}/transition/`,
            null,
            "PATCH",
            addLinks({ state })
          );
          context.commit(LNA_USER_FORM, result);
          return "Ознакомление завершено";
        },
        LNA_USER_FORM_LOADING
      );
    },
    // report
    // запрос данных для отчета
    async updateReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            "/material-assignments/report/",
            data
          );
          context.commit(LNA_REP_DATA, response.results);
          context.commit(LNA_REP_COUNT, response.count);
        },
        LNA_REP_LOADING
      );
    },
    // запрос файла для отчета
    async downloadReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(
            LNA_REP_FILE,
            getUrl("/material-assignments/download/", data)
          );
        },
        LNA_REP_LOADING
      );
    },
  },
};

export default knowledge;
