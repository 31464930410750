import qs from "qs";
import { logout } from "@/auth/auth.js";
import links from "@/router/links.js";
import router from "@/router";
import { globalUpdate } from "@/helpers/global";

export function getUrl(path, params) {
  const apiUrl = "/api";
  const query = qs.stringify(params, { addQueryPrefix: true });
  return `${apiUrl}${path}${query}`;
}

export async function fetchPages(url, queryParams = {}, start = 1, limit = 5) {
  let page = start,
    result = [];
  while (limit-- > 0) {
    const response = await fetchAPI(url, { ...queryParams, page });
    page++;
    const rs = response["results"] || [];
    result = result.concat(rs);
    if (result.length >= response["count"] || rs.length === 0) {
      break;
    }
  }
  return result;
}

export async function fetchAPI(
  path = "",
  queryParams = null,
  method = "GET",
  data = {},
  fileUpload = false,
  glUpdate = true
) {
  const url = getUrl(path, queryParams);
  // параметры запроса
  const params = {
    method, // HTTP request method
    referrerPolicy: "no-referrer",
    headers: {},
    credentials: "same-origin",
  };

  if (fileUpload) {
    //params.headers["Content-Type"] = "multipart/form-data; boundary=blahblah";
    params.body = data;
  } else if (["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
    params.headers["Content-Type"] = "application/json";
    params.body = JSON.stringify(data);
  }

  if (glUpdate) globalUpdate(); // для каждого запроса глобальное обновление, например "колокольчика"

  const response = await fetch(url, params);
  const text = await response.text();
  if (!response.ok) {
    // обработка ошибок
    if (response.status === 401) {
      await logout(false);
      router.push(links.auth).catch(() => {});
      throw Error("Ошибка авторизации, необходимо перелогиниться");
    } else {
      try {
        if (JSON.parse(text).error.errors === "Необходимо обновить пароль")
          router.push(links.profileSettings).catch(() => {});
      } catch (error) {
        console.log(error);
      }
      throw Error(`ошибка ${response.status} ${text}`);
    }
  } else {
    return text ? JSON.parse(text) : undefined;
  }
}
