import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly, relativeDegree } from "@/helpers/profile";
export default {
  name: "TwentySeventh",
  data: () => ({
    familyStatusState: null,
    familyStatus: null,
    readonly: true,
    profileObjSaved: {}
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.familyStatus = this.profileObj.marital_status;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitFamilyStatus() {
      this.updateProfile({
        id: this.profileObj.id,
        marital_status: this.familyStatus
      }).then(() => {
        setValueTimeout(this, "familyStatusState");
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    familyStatusOptions() {
      const isMale = this.profileObj.sex === "Мужской";
      return [{
        value: "single",
        text: isMale ? "не женат" : "не замужем"
      }, {
        value: "divorced",
        text: isMale ? "разведен" : "разведена"
      }, {
        value: "married",
        text: isMale ? "женат" : "замужем"
      }, {
        value: "cohabitation",
        text: "состою в гражданском браке"
      }];
    },
    relativeOptions() {
      return Object.entries(relativeDegree).map(([value, text]) => ({
        value,
        text
      }));
    }
  }
};