import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "22. Имеются ли у Вас действующие кредитные обязательства, совокупный ежемесячный платеж по которым превышает предполагаемый размер ежемесячной заработной платы по планируемой (занимаемой) должности? "
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_actual_loans,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_actual_loans = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.creditsState,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}