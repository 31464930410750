import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theAttachments = _resolveComponent("theAttachments");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theAttachments, {
    onAddFile: $options.addFile,
    onDelFile: $options.delFile,
    onSetPage: $options.setPage,
    items: _ctx.hlineAttach,
    count: _ctx.hlineAttachCount,
    disabledActions: $options.readOnly,
    fields: _ctx.fields,
    busy: _ctx.hlineAttachLoading
  }, null, 8, ["onAddFile", "onDelFile", "onSetPage", "items", "count", "disabledActions", "fields", "busy"])]);
}