import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapGetters, mapActions } from "vuex";
import theNotification from "@/components/theNotification";
import { formatDate, fmtDateShort } from "@/helpers/date";
import MatrixRiskList from "./MatrixRiskList.vue";
import MatrixChangeList from "./MatrixChangeList.vue";
import MatrixCheckList from "./MatrixCheckList.vue";
import { statesRiskMatrix } from "@/helpers/states";
export default {
  name: "RiskForm",
  props: {
    msg: {
      type: String,
      required: true
    } // сюда получаем id матрицы
  },

  components: {
    BreadCrumbToHome,
    theNotification,
    MatrixRiskList,
    MatrixChangeList,
    MatrixCheckList
  },
  data: () => ({
    statesRiskMatrix,
    fmtDateShort,
    breadcrumbs: [{
      text: "Оценка рисков",
      to: {
        name: "RiskMatrixList"
      }
    }]
  }),
  async created() {
    await this.updateForm();
  },
  methods: {
    ...mapActions("risk", ["setRisksPage", "updateMatrixById", "updateMatrixFormRisks", "changeStateMatrix", "updateMatrixFormChange", "updateMatrixFormChecks", "changeStateCheck"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    formatDate,
    async updateForm() {
      this.setRisksPage(1);
      await this.updateMatrixById(this.msg);
      await this.updateMatrixFormRisks(this.msg);
      await this.updateMatrixFormChange({
        id: this.msg,
        page: 1
      });
      await this.updateMatrixFormChecks({
        id: this.msg,
        page: 1
      });
      await this.updateDepartments();
    },
    async clickStateMatrix(state, msg) {
      await this.changeStateMatrix({
        id: this.msg,
        state,
        msg
      });
    },
    async clickStateCheck(state, msg) {
      await this.changeStateCheck({
        matrixId: this.msg,
        checkId: this.matrixForm.assigned_check_id,
        state,
        msg
      });
      await this.updateMatrixById(this.msg);
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixForm", "matrixFormLoading", "matrixFormRisksCount", "matrixFormChangeCount", "matrixFormChecksCount"])
  }
};