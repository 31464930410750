import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import thePage404 from "@/components/common/thePage404.vue";
export default {
  name: "AdminDirectoryList",
  props: {
    msg: String
  },
  // тип справочника

  data: () => ({
    labelItem: "",
    modalTitle: "",
    changeItem: false,
    currentItem: null,
    //index
    types: ["lna", "cp", "coi", "hline"],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    thePage404
  },
  async created() {
    await this.updateDirectory(this.msg);
  },
  methods: {
    ...mapActions("administration", ["updateDirectory", "changeDirectoryItem", "addDirectoryItem", "removeDirectoryItem"]),
    clickChange(data) {
      this.modalTitle = "Редактировать элемент";
      this.labelItem = data.item;
      this.currentItem = data.index;
      this.changeItem = true;
      this.$bvModal.show("modalChangeItem");
    },
    async clickRemove(data) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Удалить элемент ${data.item} ?`, {
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Удалить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      });
      if (isConfirmed) {
        await this.removeDirectoryItem({
          item: {
            label: data.item
          },
          nodeIndex: data.index,
          type: this.msg
        });
        await this.updateDirectory(this.msg);
      }
    },
    clickAdd() {
      this.modalTitle = "Добавить элемент";
      this.labelItem = "";
      this.changeItem = false;
      this.$bvModal.show("modalChangeItem");
    },
    async handlerModalOk() {
      if (this.changeItem) {
        await this.changeDirectoryItem({
          item: {
            label: this.labelItem
          },
          currentItem: this.currentItem,
          type: this.msg
        });
      } else {
        await this.addDirectoryItem({
          item: {
            label: this.labelItem
          },
          type: this.msg
        });
      }
      await this.updateDirectory(this.msg);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminDirectory", "adminDirectoryTitle", "adminDirectoryLoading"]),
    modalOkDisabled() {
      return !(this.labelItem.trim().length > 0);
    }
  }
};