import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound, CONST_NONAME } from "@/helpers/text";
export default {
  name: "theAttachments",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    disabledActions: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default: () => []
    },
    busy: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    currentPage: 1,
    defaultFields: [{
      key: "index",
      label: "№"
    }, {
      key: "name",
      label: "Файл",
      formatter: value => decodeURI(value) || CONST_NONAME
    }, {
      key: "date",
      label: "Загружен",
      formatter: value => formatDate(value, fmtDateShort)
    }],
    CONST_NONAME,
    showModalRemove: false,
    currentName: "",
    currentId: null
  }),
  methods: {
    clickAdd() {
      this.$refs.fileInput.click();
    },
    handlerAdd(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$emit("addFile", files[0]);
      this.currentPage = 1;
      this.$refs.fileInput.value = null;
    },
    handlerDel(name, id) {
      this.currentName = name;
      this.currentId = id;
      this.showModalRemove = true;
    },
    async doRemove() {
      this.$emit("delFile", this.currentId);
      this.currentPage = 1;
      this.$emit("setPage", 1);
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.count / this.perPage, this.currentPage);
      this.$emit("setPage", this.currentPage);
    }
  },
  computed: {
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.$emit("setPage", value);
      }
    },
    tableFields() {
      return this.defaultFields.concat(this.fields, [{
        key: "download",
        label: "Скачать",
        class: "text-center"
      }, {
        key: "remove",
        label: "Удалить",
        class: "text-center"
      }]);
    }
  }
};