import { formatDate, fmtDate_Month_Time } from "@/helpers/date";
export default {
  name: "AdminBackupAdd",
  data: () => ({
    nameBackup: ""
  }),
  methods: {
    createBackup() {
      this.nameBackup = `compliance_backup_${formatDate(new Date(), fmtDate_Month_Time)}`;
      this.$bvModal.show("createBackupMoadal");
    },
    handlerOk() {
      this.$emit("createBackup", {
        nameBackup: this.nameBackup
      });
    }
  },
  computed: {
    okModalDisabled() {
      return !this.nameBackup.trim().length;
    }
  }
};