import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "33.  Владеете ли Вы информацией о том, что Ваши близкие родственники\n            являются членами органов управления юридических лиц или постоянно,\n            временно либо по специальному полномочию выполняют\n            организационно-распорядительные или административно-хозяйственные\n            функции в юридических лицах?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_relatives_high_position,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_relatives_high_position = $event),
          options: _ctx.optionYesNo,
          disabled: _ctx.readonly,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_relatives_high_position')),
          state: _ctx.has_relatives_high_position_state,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}