import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "№ сообщения",
    "label-cols": "4",
    "content-cols": "8",
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.message_number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.message_number = $event),
      type: "number",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата регистрации от... до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.submitted_at_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[1] || (_cache[1] = $event => _ctx.filters.submitted_at_gte = $event)
      }, null, 8, ["date"]), _createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.submitted_at_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[2] || (_cache[2] = $event => _ctx.filters.submitted_at_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Статус",
    "label-cols": "4",
    "content-cols": "8",
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.state,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.filters.state = $event),
      options: $options.sourceState
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата изменения статуса от... до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.state_modified_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.filters.state_modified_gte = $event)
      }, null, 8, ["date"]), _createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.state_modified_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[5] || (_cache[5] = $event => _ctx.filters.state_modified_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Заявитель",
    "label-cols": "4",
    "content-cols": "8",
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.applicant_search,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.filters.applicant_search = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Документ",
    "label-cols": "4",
    "content-cols": "8",
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.response_number,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.filters.response_number = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата документа от... до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.response_date_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[8] || (_cache[8] = $event => _ctx.filters.response_date_gte = $event)
      }, null, 8, ["date"]), _createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.response_date_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[9] || (_cache[9] = $event => _ctx.filters.response_date_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Источник",
    "label-cols": "4",
    "content-cols": "8",
    class: "mb-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.source,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.filters.source = $event),
      options: $options.sourceOption
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Контрольный срок от... до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.deadline_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[11] || (_cache[11] = $event => _ctx.filters.deadline_gte = $event)
      }, null, 8, ["date"]), _createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.deadline_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[12] || (_cache[12] = $event => _ctx.filters.deadline_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Куратор",
      "label-cols": "4",
      "content-cols": "8",
      class: "mb-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_UserSelector, {
        onSelected: _cache[13] || (_cache[13] = $event => $options.setFilter('curator_id', $event)),
        name: _ctx.curator,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["name", "fmtName"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Ответственный",
      "label-cols": "4",
      "content-cols": "8",
      class: "mb-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_UserSelector, {
        onSelected: _cache[14] || (_cache[14] = $event => $options.setFilter('assignee_id', $event)),
        name: _ctx.assignee,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["name", "fmtName"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters,
    class: "me-3 mt-3"
  }, {
    default: _withCtx(() => [_createTextVNode("Применить")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "outline-secondary",
    onClick: $options.clearFilters,
    class: "mt-3"
  }, {
    default: _withCtx(() => [_createTextVNode("Сбросить фильтры")]),
    _: 1
  }, 8, ["onClick"])]);
}