import mutations from "@/store/mutations";

const { SHOW_TOAST_NOTIFY, CLEAR_TOAST_SINGLE } = mutations;

const notification = {
  state: {
    messagePool: [],
    messageSingle: null,
  },
  getters: {
    messagePool: ({ messagePool }) => messagePool[messagePool.length - 1],
    messageSingle: ({ messageSingle }) => messageSingle,
  },
  mutations: {
    [SHOW_TOAST_NOTIFY](state, msg) {
      state.messagePool.push(msg);
    },
    [CLEAR_TOAST_SINGLE](state) {
      state.messageSingle = null;
    },
  },
  actions: {
    showNotify({ commit }, msg) {
      commit(SHOW_TOAST_NOTIFY, msg);
    },
    clearNotifySingle({ commit }) {
      commit(CLEAR_TOAST_SINGLE);
    },
  },
};

export default notification;
