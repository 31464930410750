import HotLineListSidebar from "./HotLineListSidebar.vue";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, NONUMBER, fmtNameShort, formatName, hlineDefaultSort } from "@/helpers/text";
import links from "@/router/links";
import { statesHotline } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HotLineList",
  components: {
    HotLineListSidebar
  },
  data: () => ({
    currentId: null,
    showModalRemove: false,
    fmtNameShort,
    fmtDateShort,
    NONUMBER,
    showSidebar: false,
    statesHotline,
    links,
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    tableFields: [{
      key: "message_number",
      label: "#",
      stickyColumn: true,
      class: "text-nowrap"
    }, {
      key: "submitted_at",
      label: "Зарегистрировано",
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "state_modified_at",
      label: "Изменено",
      class: "text-nowrap"
    }, {
      key: "applicant_full_name",
      label: "Заявитель"
    }, {
      key: "response_number",
      label: "Документ",
      class: "text-nowrap"
    }, {
      key: "response_date",
      label: "Дата документа",
      class: "text-nowrap"
    }, {
      key: "source",
      label: "Источник"
    }, {
      key: "deadline",
      label: "Контрольный срок",
      class: "text-nowrap"
    }, {
      key: "curator_detail",
      label: "Куратор",
      class: "text-nowrap"
    }, {
      key: "assignee_detail",
      label: "Ответственный",
      class: "text-nowrap"
    }, {
      key: "btnRemove",
      label: ""
    }],
    sort: hlineDefaultSort,
    optionsSort: [{
      value: "year,message_number",
      text: "по номеру"
    }, {
      value: "submitted_at",
      text: "по дате регистрации"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "applicant_full_name",
      text: "по заявителю"
    }, {
      value: "response_number",
      text: "по документу"
    }, {
      value: "response_date",
      text: "по дате документа"
    }, {
      value: "source",
      text: "по источнику"
    }, {
      value: "deadline",
      text: "по контрольному сроку"
    }, {
      value: "curator__last_name,curator__first_name,curator__middle_name",
      text: "по куратору"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }],
    filters: {}
  }),
  methods: {
    ...mapActions("hotline", ["deleteHlineMsg", "updateHlines"]),
    formatDate,
    formatName,
    async applyFilters(data) {
      this.filters = {};
      Object.keys(data).forEach(key => {
        const value = ["submitted_at_gte", "submitted_at_lte", "state_modified_gte", "state_modified_lte", "response_date_gte", "response_date_lte", "deadline_gte", "deadline_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") this.filters[key] = value;
      });
      if (this.currentPage === 1) await this.updateHlines({
        page: 1,
        sort: this.sort,
        ...this.filters
      });else this.currentPage = 1;
    },
    // переход к странице
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.hlinesCount / this.perPage, this.currentPage);
      this.sortChanged();
    },
    clickRemove(id) {
      this.currentId = id;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.deleteHlineMsg(this.currentId);
      this.currentPage = 1;
      await this.updateHlines({
        page: 1,
        sort: this.sort,
        ...this.filters
      });
    },
    // обработка изменения сортировки
    async sortChanged(sortVal) {
      await this.updateHlines({
        page: this.currentPage,
        sort: sortVal,
        ...this.filters
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlinesLoading", "hlines", "hlinesCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateHlines({
          page: value,
          sort: this.sort,
          ...this.filters
        });
      }
    }
  }
};