import { partnersTemplate } from "../partnersTemplate";
import { mapActions, mapGetters } from "vuex";
import { statesPartners } from "@/helpers/states";
import { getOrgName } from "@/auth/auth";
export default {
  name: "PartnersFormInfo",
  data: () => ({
    partnersTemplate
  }),
  methods: {
    ...mapActions("partners", ["changePartner"]),
    getOrgName,
    async changeData(key) {
      await this.changePartner({
        id: this.partnerForm.id,
        params: {
          [key]: this.partnerForm[key]
        }
      });
    }
  },
  computed: {
    ...mapGetters("partners", ["partnerForm"]),
    readOnly() {
      return statesPartners[this.partnerForm.state] !== statesPartners.draft;
    }
  }
};