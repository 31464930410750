export const chartSettings = {
  1: {
    label: "Низкий уровень риска",
    borderWidth: 2,
    hoverBorderWidth: 2,
    borderColor: "rgba(203,203,203, 1)",
    pointBackgroundColor: "rgba(203,203,203, .16)",
    radius: 8,
    hoverRadius: 12,
  },
  2: {
    label: "Средний уровень риска",
    borderWidth: 2,
    hoverBorderWidth: 2,
    borderColor: "rgba(255,193,7, 1)",
    pointBackgroundColor: "rgba(255,193,7, .16)",
    radius: 8,
    hoverRadius: 12,
  },
  3: {
    label: "Высокий уровень риска",
    borderWidth: 2,
    hoverBorderWidth: 2,
    borderColor: "rgba(228,0,13, 1)",
    pointBackgroundColor: "rgba(228,0,13, .16)",
    radius: 8,
    hoverRadius: 12,
  },
};

export const chartOptions = {
  responsive: true,
  aspectRatio: 2,
  scales: {
    x: {
      // labelString: ''
      type: "linear",
      // position: "bottom",
      beginAtZero: true,
      ticks: {
        min: 0,
        max: 100,
        stepSize: 25,
        callback: (value) => value + "%",
      },
      title: {
        display: true,
        text: "Влияние",
        font: "bold",
      },
    },
    y: {
      type: "linear",
      // position: "bottom",
      beginAtZero: true,
      ticks: {
        min: 0,
        max: 100,
        stepSize: 25,
        callback: (value) => value + "%",
      },
      title: {
        display: true,
        text: "Вероятность",
        font: "bold",
      },
    },
  },
  plugins: {
    legend: {
      // display: false,
      align: "center",
      labels: {
        // pointStyle: 'rectRounded',
        pointStyle: "rounded",
        usePointStyle: true,
        padding: 24,
      },
    },
    tooltip: {
      // intersect: false,
      position: "nearest",
      xPadding: 12,
      yPadding: 12,
      titleFontSize: 14,
      titleSpacing: 4,
      titleMarginBottom: 12,
      footerFontStyle: "normal",
      footerMarginTop: 8,
      callbacks: {
        title: (context) =>
          "Риск " + context[0].dataset.data[context[0].dataIndex].risk,
        afterTitle: (context) =>
          context[0].dataset.data[context[0].dataIndex].riskName,
        label: (context) => context.dataset.label,
        beforeFooter: (context) =>
          "Вероятность наступления " +
          context[0].dataset.data[context[0].dataIndex].y +
          "%",
        afterFooter: (context) =>
          "Степень влияния " +
          context[0].dataset.data[context[0].dataIndex].x +
          "%",
      },
    },
  },
};
