import { snilsMask } from "@/helpers/masks";
export default {
  name: "DeclarationFormPartI",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    snilsMask
  })
};