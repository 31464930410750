export default {
  name: "TrafficLight",
  props: {
    typeLight: {
      type: String,
      default: null
    },
    tooltip: {
      type: Object,
      default: () => null
    }
  }
};