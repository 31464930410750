// import Vue from "vue";
// import VueRouter from "vue-router";
import { createRouter as _createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { fetchAPI } from "@/helpers/fetch";
import {
  keyForToken,
  isAuthenticated,
  loginFinish,
  needChangePassword,
} from "@/auth/auth";
import store from "@/store";
import { SITE_NAME } from "@/helpers/text.js";

// Vue.use(VueRouter);

// const router = new VueRouter({
//   mode: "history",
//   routes,
// });
const router = _createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authenticated = isAuthenticated();
  const valueForToken = to.query[keyForToken];

  if (valueForToken && !authenticated) {
    try {
      const response = await fetchAPI("/token-auth", null, "POST", {
        logintoken: valueForToken,
      });
      loginFinish(response);
      // смена пароля
      if (needChangePassword(response)) next({ name: "ProfileSettings" });
      else next({ name: "PersonalArea" });
    } catch (error) {
      // пишем напрямую в store
      store.state.notification.messageSingle = {
        msg: error.message,
        title: "Ошибка входа",
        variant: "danger",
      };
      next({ name: "theAuth" });
    }
  } else {
    if (authenticated && to.name === "theAuth") {
      next({ name: "PersonalArea" });
    } else if (!authenticated) {
      document.title = to.meta.title || SITE_NAME;
      next();
    } else {
      document.title = to.meta.title || SITE_NAME;
      next();
    }
  }
});

export default router;
