import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "HotlineFormComments",
  data: () => ({
    fields: [{
      key: "created_at",
      label: "",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap px-3 text-muted"
    }, {
      key: "user_detail",
      label: "",
      formatter: value => formatName(value, fmtNameShort),
      class: "text-nowrap"
    }, {
      key: "body",
      label: ""
    }],
    perPage: 10,
    gotoPage: 1,
    currentPage: 1
  }),
  methods: {
    ...mapActions("hotline", ["updateHlineComments"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.hlineCommentsCount / this.perPage, this.currentPage);
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg", "hlineComments", "hlineCommentsCount", "hlineCommentsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateHlineComments({
          id: this.hlineMsg.id,
          page: value
        });
      }
    }
  }
};