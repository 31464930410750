import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapGetters } from "vuex";
export default {
  name: "ReportRisk",
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableFields: [{
      key: "full_name",
      label: "Наименование"
    }, {
      key: "contract",
      label: "Договор"
    }, {
      key: "risk_level",
      label: "Уровень риска"
    }]
  }),
  computed: {
    ...mapGetters("partners", ["partnerReportData"]),
    title() {
      return "Итоговый уровень риска в разрезе по каждому деловому партнеру за период c " + formatDate(this.filters.submitted_gte, fmtDateShort) + " по " + formatDate(this.filters.submitted_lte, fmtDateShort);
    }
  }
};