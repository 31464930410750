import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateShort, fmtTime } from "@/helpers/date";
import { innerCheckReason } from "@/helpers/text";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
export default {
  name: "AdminActivity",
  data: () => ({
    currentPage: 1,
    perPage: 10,
    fmtTime,
    tableFields: [{
      key: "date",
      label: "Дата",
      formatter: value => formatDate(value, fmtDateShort),
      class: "text-nowrap"
    }, {
      key: "time",
      label: "Время",
      class: "text-nowrap"
    }, {
      key: "reason",
      label: "Источник",
      formatter: value => innerCheckReason[value]
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "author",
      label: "Автор",
      class: "text-nowrap"
    }, {
      key: "text",
      label: "Описание"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  created() {
    this.updateAdminActivity({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminActivity"]),
    formatDate
  },
  computed: {
    ...mapGetters("administration", ["adminActivity", "adminActivityLoading", "adminActivityCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminActivity({
          page: value
        });
      }
    }
  }
};