import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, {
    class: "mt-2 border border-gray rounded mx-0",
    style: {
      "border-style": "dashed !important"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "text-muted text-nowrap text-truncate pt-2"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.value), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        class: "pe-0",
        variant: "link",
        title: "Загрузить файл",
        onClick: $options.clickAddFile,
        disabled: $props.readonly
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "file-circle-plus",
          "fixed-width": ""
        })]),
        _: 1
      }, 8, ["onClick", "disabled"])), [[_directive_b_tooltip, void 0, void 0, {
        hover: true
      }]]), _createVNode(_component_b_button, {
        class: "pe-0",
        variant: "link",
        onClick: $options.handlerDeleteFile,
        disabled: !$props.keyImg || $props.readonly
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "trash-can",
          "fixed-width": ""
        })]),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), $props.hint ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0,
    class: "mt-2 ms-1 text-muted"
  }, {
    default: _withCtx(() => [_createElementVNode("small", null, _toDisplayString($props.hint), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[0] || (_cache[0] = (...args) => $options.handlerAddFile && $options.handlerAddFile(...args)),
    accept: $props.types
  }, null, 40, _hoisted_1)]);
}