import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { dispatchError, CODE_TO_EMAIL, signTypes } from "@/helpers/text";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { needChangePassword } from "@/auth/auth.js";

const endpoint4Sign = {
  [signTypes.profile]: "/my-profiles/",
  [signTypes.education]: "/my-courses/",
  [signTypes.test]: "/my-tests/",
  [signTypes.declaration]: "/my-declarations/",
  [signTypes.knowledge]: "/my-lib-materials/",
};

function additionProfile(obj) {
  if (!Array.isArray(obj.weapons)) obj.weapons = [];
  return obj;
}

const {
  PROFILE,
  PROFILE_LOADING,
  PROFILE_SIGN,
  PROFILE_SIGN_LOADING,
  // settings
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_LOADING,
} = mutations;

const profileStore = {
  namespaced: true,
  state: {
    profileObj: {},
    profileLoading: false,
    sign: CODE_TO_EMAIL,
    signLoading: false,
    // settings
    profilSettings: [{}],
    profilSettingsLoading: false,
  },
  getters: {
    profileObj: ({ profileObj }) => profileObj,
    profileLoading: ({ profileLoading }) => profileLoading,
    sign: ({ sign }) => sign,
    signLoading: ({ signLoading }) => signLoading,
    // settings
    profilSettings: ({ profilSettings }) => profilSettings,
    profilSettingsLoading: ({ profilSettingsLoading }) => profilSettingsLoading,
  },
  mutations: {
    [PROFILE](state, value) {
      state.profileObj = value;
    },
    [PROFILE_SIGN](state, value) {
      state.sign = value;
    },
    [PROFILE_SIGN_LOADING](state, value) {
      state.signLoading = value;
    },
    [PROFILE_LOADING](state, value) {
      state.profileLoading = value;
    },
    // settings
    [PROFILE_SETTINGS](state, value) {
      state.profilSettings = value;
    },
    [PROFILE_SETTINGS_LOADING](state, value) {
      state.profilSettingsLoading = value;
    },
  },
  actions: {
    // запрос информации по пользователю
    async fetchProfile(context, { id, myProfile }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const endpoint = myProfile ? "/my-profiles/" : "/profiles/";
          const result = await fetchAPI(`${endpoint}${id}/`);
          context.commit(PROFILE, additionProfile(result));
        },
        PROFILE_LOADING
      );
    },
    // добавление записи об адресе
    async addAddressRecord({ dispatch }, { profileId, fields }) {
      try {
        return await fetchAPI(
          `/my-profiles/${profileId}/addresses/`,
          null,
          "POST",
          fields
        );
      } catch (error) {
        dispatchError(dispatch, error);
      }
    },
    // upload profile image
    async addProfilePhoto(context, { profileId, formData }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/my-profiles/${profileId}/image/`,
          null,
          "POST",
          formData,
          true
        );
      });
    },
    // изменение записи
    async updateProfileData({ dispatch }, { id, dataId, params, endpoint }) {
      try {
        return await fetchAPI(
          `/my-profiles/${id}/${endpoint}/${dataId}/`,
          null,
          "PATCH",
          params
        );
      } catch (error) {
        dispatchError(dispatch, error);
      }
    },
    // добавление записи
    async addProfileData({ dispatch }, { id, endpoint, data }) {
      try {
        return await fetchAPI(
          `/my-profiles/${id}/${endpoint}/`,
          null,
          "POST",
          data
        );
      } catch (error) {
        dispatchError(dispatch, error);
      }
    },
    // удаление записи
    async delProfileData(context, { id, dataId, endpoint }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/my-profiles/${id}/${endpoint}/${dataId}/`,
          null,
          "DELETE"
        );
      });
    },
    // обновление информации по полю
    async updateProfile({ commit, dispatch }, data) {
      try {
        const result = await fetchAPI(
          `/my-profiles/${data.id}/`,
          null,
          "PATCH",
          data
        );
        commit(PROFILE, additionProfile(result));
        return result;
      } catch (error) {
        dispatchError(dispatch, error);
        throw new Error("updateProfile");
      }
    },
    // В ответ на GET-запрос происходит генерация кода подтверждения.
    // Если код сгенерирован успешно, возвращается статус 200 и json вида: {"next_request_in":"3 мин.","message":"vvgBW01p"}
    async updateSign(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(PROFILE_SIGN, CODE_TO_EMAIL);
          if (endpoint4Sign[data.type]) {
            const result = await fetchAPI(
              endpoint4Sign[data.type] + data.id + "/sign/"
            );
            context.commit(PROFILE_SIGN, result.message);
          }
          return "Проверочный код отправлен пользователю";
        },
        PROFILE_SIGN_LOADING
      );
    },

    // В ответ на PATCH-запрос с передачей кода происходит проверка условий, и в случае успеха документ
    // считается подписанным. Метод ожидает поступления данных в форме {"code": код_подтверждения}
    // Если с момента получения кода прошло более 5 минут или код неверен, то возвращается ошибка 400
    // и сообщение "Неверный код. Возможно, он устарел, попробуйте получить новый".
    // ЛЛ автоматически переводится в состояние submitted (дальнейшие попытки его туда
    // перевести вызовут ошибку 403)
    // в качестве ответа возвращается ЛЛ со всеми данными
    async sendSignProfile(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-profiles/${data.id}/sign/`,
            null,
            "PATCH",
            addLinks({ code: data.code })
          );
          context.commit(PROFILE, additionProfile(result));
          return "Личный листок отправлен на проверку";
        },
        PROFILE_LOADING
      );
    },
    // settings запрос настроек (пока для смены пароля)
    async updateProfileSettings(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI("/my-account/");
          context.commit(PROFILE_SETTINGS, result);
        },
        PROFILE_SETTINGS_LOADING
      );
    },
    // change password
    async changeUserPassword(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const { profilSettings } = context.getters;
          profilSettings[0].error = true; // ошибка по умолчанию
          const result = await fetchAPI(
            `/my-account/${data.id}/password/`,
            null,
            "PATCH",
            data.params
          );
          if (!needChangePassword(result)) {
            context.commit(PROFILE_SETTINGS, [result]); //возврат в массиве
            return "Пароль успешно изменен";
          }
        },
        PROFILE_SETTINGS_LOADING
      );
    },
    // set change loading
    async setPasswordloading(context, value) {
      await wrapCommitDispatch(context, async () => {
        context.commit(PROFILE_SETTINGS_LOADING, value);
      });
    },
  },
};

export default profileStore;
