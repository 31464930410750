import "core-js/modules/es.array.push.js";
import { typeOptionsRisk, selectAll } from "@/helpers/text";
import { mapGetters } from "vuex";
export default {
  name: "RiskReportHeader",
  data: () => ({
    filters: {},
    periodSelected: "month",
    periodOptions: [{
      value: "day",
      text: "День",
      day: 0,
      month: 0,
      year: 0
    }, {
      value: "week",
      text: "Неделя",
      day: 7,
      month: 0,
      year: 0
    }, {
      value: "month",
      text: "Месяц",
      day: 0,
      month: 1,
      year: 0
    }, {
      value: "quarter",
      text: "Квартал",
      day: 0,
      month: 3,
      year: 0
    }, {
      value: "year",
      text: "Год",
      day: 0,
      month: 0,
      year: 1
    }, {
      value: null,
      text: "Всё время"
    }],
    typeSelected: "riskList",
    typeOptionsRisk,
    departmentSelected: null,
    selected: {
      period: null,
      type: null,
      department: null,
      org: null
    } // параметры формирования отчета
  }),

  components: {},
  methods: {},
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [...selectAll]);
    }
  }
};