import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { periodOptions, periodicityOptions, labels } from "@/helpers/date";
import { typeOptionsDecl } from "@/helpers/text";
export default {
  name: "AdminDeclaration",
  data: () => ({
    form: {
      days: 10,
      deadline: new Date(new Date().getFullYear(), 8, 1),
      periodicity: "year",
      period: "month",
      organisation: "all",
      department: "all",
      type: "statsEDKI"
    },
    labels,
    typeOptionsDecl,
    periodOptions,
    periodicityOptions,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    await this.updateDeclaration();
    await this.updateOrganizations();
    await this.updateDepartments();
    this.form.days = this.adminDeclaration.days;
    this.form.deadline = this.adminDeclaration.deadline;
    this.form.periodicity = this.adminDeclaration.periodicity;
    this.form.type = this.adminDeclaration.type;
    this.form.period = this.adminDeclaration.period;
    this.form.organisation = this.adminDeclaration.organisation;
    this.form.department = this.adminDeclaration.department;
  },
  methods: {
    ...mapActions("administration", ["updateDeclaration", "changeDeclaration"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateDepartments"]),
    async clickSave() {
      this.changeDeclaration(this.form);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminDeclaration", "adminDeclarationLoading"]),
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    },
    orgOptions() {
      return Object.keys(this.organizations).reduce((acc, curr) => {
        acc.push({
          value: this.organizations[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    }
  }
};