import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
export default {
  name: "AdminInnerChecks",
  data: () => ({
    tableFields: [{
      key: "id",
      label: "id"
    }, {
      key: "category",
      label: "Название"
    }, {
      key: "check_type",
      label: "Тип"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  created() {
    this.updateInnerCheckDirectory();
  },
  methods: {
    ...mapActions("administration", ["updateInnerCheckDirectory"])
  },
  computed: {
    ...mapGetters("administration", ["adminInnerCheck", "adminInnerCheckLoading"])
  }
};