import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnersTemplate($options.getOrgName()), el => {
    return _openBlock(), _createBlock(_component_b_row, {
      key: el.key,
      class: "mt-2",
      "align-v": "baseline"
    }, {
      default: _withCtx(() => [el.type === 'select' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_b_col, {
        cols: "4",
        md: "1",
        lg: "1",
        xl: "1"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.partnerForm[el.key],
          "onUpdate:modelValue": $event => _ctx.partnerForm[el.key] = $event,
          options: el.option,
          onChange: $event => $options.changeData(el.key),
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "disabled"])]),
        _: 2
      }, 1024), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(el.title), 1)]),
        _: 2
      }, 1024)], 64)) : el.type === 'textarea' ? (_openBlock(), _createBlock(_component_b_col, {
        key: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: el.title,
          class: "mt-2 mb-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
            modelValue: _ctx.partnerForm[el.key],
            "onUpdate:modelValue": $event => _ctx.partnerForm[el.key] = $event,
            placeholder: el.placeholder,
            rows: "3",
            "max-rows": "3",
            trim: "",
            onChange: $event => $options.changeData(el.key),
            disabled: $options.readOnly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "onChange", "disabled"])]),
          _: 2
        }, 1032, ["label"])]),
        _: 2
      }, 1024)) : _createCommentVNode("", true)]),
      _: 2
    }, 1024);
  }), 128))]);
}