import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "theTwentieth",
  data: () => ({
    executionsState: null,
    optionYesNo
  }),
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData() {
      this.executionsState = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_enf_proceedings: this.profileObj.has_enf_proceedings
      }).then(() => {
        setValueTimeout(this, "executionsState");
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};