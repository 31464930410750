import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import StringsWithX from "@/components/Helpers/StringsWithX.vue";
import { VMarkdownEditor } from "vue3-markdown";
import { validate } from "@/helpers/validate";
export default {
  name: "AdminCommon",
  data: () => ({
    email_state: null,
    domain_state: null,
    file: null,
    objSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    domain: ""
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    StringsWithX,
    VMarkdownEditor
  },
  async created() {
    await this.updateCommon();
    if (this.adminCommon.id !== undefined) this.updateCommonDocs(this.adminCommon.id);
    Object.keys(this.adminCommon).forEach(key => {
      this.objSaved[key] = this.adminCommon[key];
    });
  },
  methods: {
    ...mapActions("administration", ["updateCommon", "changeCommon", "createCommon", "updateCommonDocs", "createCommonDocs", "deleteCommonDocs"]),
    async submitData(key) {
      const value = this.adminCommon[key];
      if (this.objSaved[key] === value) return; // изменений не было
      // валидация
      const valid = ["email"].includes(key) ? validate({
        data: value,
        dataRequired: key
      }) : true;
      const state = `${key}_state`;
      this[state] = valid ? null : valid;
      if (!valid) return; // невалидное значение
      // принятие изменений
      // если объекта нет, то его надо создать
      if (!this.adminCommon.id) await this.createCommon();
      // теперь можно передать правки
      await this.changeCommon({
        params: {
          [key]: value
        },
        id: this.adminCommon.id
      });
      this.objSaved[key] = value;
    },
    clickAddFile(key) {
      this.file = key;
      this.$refs.fileInput.click();
    },
    clickAddFileDocs() {
      this.$refs.fileInputDocs.click();
    },
    async handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append(this.file, files[0]);
      // если объекта нет, то его надо создать
      if (!this.adminCommon.id) await this.createCommon();
      // теперь можно передать правки
      await this.changeCommon({
        params: formData,
        fileUpload: true,
        id: this.adminCommon.id
      });
      this.$refs.fileInput.value = null;
    },
    async handlerAddFileDocs(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append("document", files[0]);
      // если объекта нет, то его надо создать
      if (!this.adminCommon.id) await this.createCommon();
      await this.createCommonDocs({
        id: this.adminCommon.id,
        formData
      });
      await this.updateCommonDocs(this.adminCommon.id);
      this.$refs.fileInputDocs.value = null;
    },
    async handlerDeleteFile(id) {
      await this.deleteCommonDocs({
        id: this.adminCommon.id,
        idAttach: id
      });
      await this.updateCommonDocs(this.adminCommon.id);
    },
    async removeDomain(id) {
      const index = this.adminCommon.safe_domains.findIndex(el => el === id);
      if (index >= 0) {
        this.adminCommon.safe_domains.splice(index, 1);
        await this.changeCommon({
          params: {
            safe_domains: this.adminCommon.safe_domains
          },
          id: this.adminCommon.id
        });
      }
    },
    async addDomain() {
      if (this.domain.length > 0 && !this.adminCommon.safe_domains.includes(this.domain)) {
        //const valid = validate({ data: this.domain, dataRequired: "domain" });
        //this.domain_state = valid ? null : valid;
        //if (!valid) return; // невалидное значение
        this.adminCommon.safe_domains.push(this.domain);
        await this.changeCommon({
          params: {
            safe_domains: this.adminCommon.safe_domains
          },
          id: this.adminCommon.id
        });
        this.domain = "";
        this.domain_state = null;
      }
    },
    handleUpload(file) {
      return new Promise(resolve => {
        const objectURL = URL.createObjectURL(file);
        resolve(objectURL);
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCommon", "adminCommonLoading", "adminCommonDocs", "adminCommonDocsLoading"]),
    domens4comp() {
      return this.adminCommon.safe_domains.map(el => ({
        id: el,
        text: el
      }));
    }
  }
};