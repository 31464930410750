import { fetchAPI } from "@/helpers/fetch";
import { formatDate, fmtDate } from "@/helpers/date";

export const lsUserId = "userId";
const lsUserPermissions = "authPermissions";
const lsWorkplaces = "workplaces";
const lsOrgId = "organizationId";
const lsOrgName = "organizationName";
const lsUserInfo = "userInfo";
let userPermissions = {};
export const keyForToken = "logintoken";
export const textLink4Prod = "Отправлено";

// возьмем из localStorage id пользователя
export function getUserId() {
  return localStorage.getItem(lsUserId);
}

/**
 * преобразование всех прав пользователя в один объект
 * @param {Array} perms массив  прав пользователя
 */
export function transformPermissions(perms) {
  return perms.reduce((acc, perm) => {
    acc[perm] = true;
    return acc;
  }, {});
}

export function checkPermissions(perm) {
  return userPermissions[perm];
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem(lsUserInfo));
}

export function getOrgId() {
  return localStorage.getItem(lsOrgId);
}

export function getOrgName() {
  return localStorage.getItem(lsOrgName);
}

/**
 * проверяем доступен ли элемент el для текущего пользователя
 * @param {HTMLElement} el - элемент разметки
 * @param {Object} binding - параметры директивы (см. документацию по vue)
 */
export function handlerPerm(el, binding) {
  if (
    !checkPermissions(binding.value) ||
    (["access_working_area"].includes(binding.value) && // не отображаем (ЛЛ) на панели инструментов, если есть другие модули
      ["view_wa_course", "view_wa_coid", "access_admin"].some((perm) =>
        checkPermissions(perm)
      ))
  ) {
    el.classList.add("no-permission");
  }
}

export function needChangePassword(obj) {
  return (
    obj.needs_change_password ||
    !obj.next_regular_password_update ||
    formatDate(obj.next_regular_password_update, fmtDate) <=
      formatDate(new Date(), fmtDate)
  );
}

// запись в localStorage данных о пользователе, полученных по api
export function loginFinish(data) {
  localStorage.setItem(lsUserId, data.id);
  userPermissions = transformPermissions(data.perms);
  localStorage.setItem(lsUserPermissions, JSON.stringify(userPermissions));
  const workplaces = getWorkplaces();
  workplaces[data.id] = {
    login: data.login,
    position: data.position,
    organization_name: data.organization_name,
  };
  localStorage.setItem(lsWorkplaces, JSON.stringify(workplaces));
  localStorage.setItem(lsOrgId, data.organization_id);
  localStorage.setItem(lsOrgName, data.organization_name);

  const userInfo = {
    first_name: data.first_name,
    last_name: data.last_name,
    middle_name: data.middle_name,
    position: data.position,
    email: data.login,
  };
  localStorage.setItem(lsUserInfo, JSON.stringify(userInfo));
}

// обновление глобальной переменной при перезагрузке страницы
export function updatePerm() {
  userPermissions = JSON.parse(localStorage.getItem(lsUserPermissions));
}

// очистка localStorage и глобальных переменных
export async function logout(api = true) {
  try {
    if (api) {
      await fetchAPI("/logout", null, "POST", {}, false, false);
    }
  } finally {
    localStorage.removeItem(lsUserPermissions);
    localStorage.removeItem(lsUserId);
    localStorage.removeItem(lsUserInfo);
    localStorage.removeItem(lsOrgId);
    localStorage.removeItem(lsOrgName);
    userPermissions = {};
  }
}

export function isAuthenticated() {
  return getUserId() !== null;
}

// по умалчанию возвращаем все рабочие места
export function getWorkplaces(all = true) {
  const workplaces = JSON.parse(localStorage.getItem(lsWorkplaces) || "{}");
  if (!all) {
    delete workplaces[getUserId()]; // удалим текущего юзера из списка
  }
  return workplaces;
}

export async function loginBegin(email, password) {
  const response = await fetchAPI(
    "/login",
    null,
    "POST",
    { login: email, password },
    false,
    false
  );
  // надо распарсить ссылку (для работы на loсalhost)
  const indexOf = String(response.login_link).indexOf(keyForToken);
  if (indexOf > 0) return String(response.login_link).slice(indexOf - 2);
  else return String(response.login_link);
}
