import UserSelector from "@/components/Helpers/UserSelector";
import { fmtNameFull, formatName } from "@/helpers/text";
export default {
  name: "AdminDepartAdd",
  components: {
    UserSelector
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    senior: null,
    fmtNameFull,
    name: "",
    nameState: null
  }),
  methods: {
    formatName,
    setSenior(data) {
      this.senior = data.id;
    },
    changeDepart() {
      this.name = this.item.department_name || "";
      this.senior = this.item.senior || null;
      this.showModal = true;
    },
    handlerOk(bvModalEvt) {
      if (!this.validateName()) {
        bvModalEvt.preventDefault();
        return;
      }
      const params = {};
      if (this.name !== this.item.department_name) params.department_name = this.name;
      if (String(this.senior) !== String(this.item.senior)) params.senior = this.senior;
      if (Object.keys(params).length) this.$emit("updateDepart", {
        params,
        item: this.item
      });
      this.showModal = false;
    },
    focusName() {
      this.nameState = null;
    },
    validateName() {
      const validName = this.name.trim() !== "";
      this.nameState = !validName ? validName : null;
      return validName;
    }
  }
};