import "core-js/modules/es.array.push.js";
import coiTemplate from "./coiTemplate";
import { formatDate } from "@/helpers/date";
import { mapGetters, mapActions } from "vuex";
import { statesDeclarations } from "@/helpers/states";
import { innerCheckReason } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "COIDetails",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    coiTemplate
  }),
  computed: {
    ...mapGetters("declarationForm", ["declarationFormObj"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckLoading"]),
    disabledAction() {
      return [statesDeclarations.new, statesDeclarations.draft, statesDeclarations.completed, statesDeclarations.archived].includes(statesDeclarations[this.declarationFormObj.state]);
    }
  },
  methods: {
    ...mapActions("innerCheck", ["createInnerCheck"]),
    formatDate,
    // создать ВП
    async clickAddInnerCheck() {
      await this.createInnerCheck({
        id: this.item.id,
        type: innerCheckReason.foundations_coi
      });
      if (this.innerCheckForm.id) this.$router.push(links.innerCheckForm + this.innerCheckForm.id);
    }
  }
};