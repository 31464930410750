import { mapGetters, mapActions } from "vuex";
import { limitBound } from "@/helpers/text.js";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import MatrixRiskListDetails from "./MatrixRiskListDetails.vue";
import MatrixRiskAdd from "./MatrixRiskAdd.vue";
import { statesRiskMatrix } from "@/helpers/states";
export default {
  name: "MatrixRiskList",
  components: {
    TrafficLight,
    MatrixRiskListDetails,
    MatrixRiskAdd
  },
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "id",
      label: "#",
      class: "text-center"
    }, {
      key: "risk_owner_detail.department_name",
      label: "Владелец риска"
    }, {
      key: "risk_num",
      label: "№ риска",
      class: "text-center"
    }, {
      key: "activity_num",
      label: "№ вида деятельности",
      class: "text-center"
    }, {
      key: "risk_probability",
      label: "Вероятность наступления",
      class: "text-center",
      formatter: value => value ? Math.round(100 * value) + "%" : ""
    }, {
      key: "financial_costs",
      label: "Финансовый ущерб"
    }, {
      key: "risk_impact",
      label: "Степень влияния",
      class: "text-center",
      formatter: value => value ? Math.round(100 * value) + "%" : ""
    }, {
      key: "risk_levelT",
      label: "Уровень риска",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: ""
    }],
    showModalRemove: false,
    currentItem: {}
  }),
  methods: {
    ...mapActions("risk", ["updateMatrixFormRisks", "removeMatrixFormRisk", "setRisksPage", "addMatrixFormRisk"]),
    async addRisks(data) {
      // только for, т.к. forEach с callback не подходит, по очереди вызовов
      // callback вызывается после обновления списка рисков
      for (const risk of data) {
        await this.addMatrixFormRisk({
          matrixId: this.matrixForm.id,
          riskId: risk.id
        });
      }
      if (data.length > 0) {
        this.setRisksPage(1);
        await this.updateMatrixFormRisks(this.matrixForm.id);
      }
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeMatrixFormRisk({
        matrixId: this.matrixForm.id,
        riskId: this.currentItem.id
      });
      this.setRisksPage(1);
      await this.updateMatrixFormRisks(this.matrixForm.id);
    },
    changePage() {
      this.setRisksPage(limitBound(Number(this.gotoPage), 1, this.matrixFormRisksCount / this.perPage, this.risksPage));
      this.updateMatrixFormRisks(this.matrixForm.id);
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixFormRisks", "matrixFormRisksLoading", "matrixFormRisksCount", "risksPage", "matrixForm"]),
    currentPageModel: {
      get() {
        return this.risksPage;
      },
      set(value) {
        this.setRisksPage(value);
        this.updateMatrixFormRisks(this.matrixForm.id);
      }
    },
    readOnly() {
      return statesRiskMatrix[this.matrixForm.state] !== statesRiskMatrix.draft;
    }
  }
};