import { typeOptionsHline } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import { formatDateObj, fmtDate, dateObjInit } from "@/helpers/date";
import StatsHotline from "./StatsHotline.vue";
import ExpiredHotline from "./ExpiredHotline.vue";
import Calendar from "@/components/Helpers/Calendar.vue";
export default {
  name: "HotLineReport",
  components: {
    StatsHotline,
    ExpiredHotline,
    Calendar
  },
  data: () => ({
    showReport: false,
    typeSelected: "report_stats",
    typeOptionsHline,
    selected: {},
    // параметры формирования отчета
    startDate: {
      ...dateObjInit
    },
    endDate: {
      ...dateObjInit
    }
  }),
  methods: {
    ...mapActions("hotline", ["updateReport", "downloadReport"]),
    setSelected() {
      this.selected.report_type = this.typeSelected;
      this.selected.submitted_gte = formatDateObj(this.startDate, fmtDate, null);
      this.selected.submitted_lte = formatDateObj(this.endDate, fmtDate, null);
    },
    async clickReport() {
      this.setSelected();
      await this.updateReport(this.selected);
      this.showReport = true;
    },
    async clickDownload() {
      this.showReport = false;
      this.setSelected();
      await this.downloadReport(this.selected);
      this.$refs.fileDownload.click();
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineReportLoading", "hlineReportFile"])
  }
};