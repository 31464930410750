import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { wrapCommitDispatch } from "@/helpers/global";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";

const {
  ORGANIZATIONS,
  ORGANIZATIONS_LOADING,
  DEPARTMENTS,
  DEPARTMENTS_LOADING,
  PRORGARMS,
  PRORGARMS_LOADING,
  HLCATEGORY,
  HLCATEGORY_LOADING,
  //lna-types
  LNATYPES,
  LNATYPES_LOADING,
  // countries
  COUNTRIES,
  COUNTRIES_LOADING,
  // security-events
  SEC_EVENTS,
  SEC_EVENTS_LOADING,
} = mutations;

const reports = {
  namespaced: true,
  state: {
    organizations: {},
    organizationsLoading: false,
    departments: {},
    departmentsLoading: false,
    programs: {},
    programsLoading: false,
    hlCategory: {},
    hlCategoryLoading: false,
    //lna-types
    lnaTypes: {},
    lnaTypesLoading: false,
    // countries
    countries: {},
    countriesLoading: false,
    // security-events
    secEvents: {},
    secEventsLoading: false,
  },
  getters: {
    organizations: ({ organizations }) => organizations,
    organizationsLoading: ({ organizationsLoading }) => organizationsLoading,
    departments: ({ departments }) => departments,
    departmentsLoading: ({ departmentsLoading }) => departmentsLoading,
    programs: ({ programs }) => programs,
    programsLoading: ({ programsLoading }) => programsLoading,
    hlCategory: ({ hlCategory }) => hlCategory,
    hlCategoryLoading: ({ hlCategoryLoading }) => hlCategoryLoading,
    //lna-types
    lnaTypes: ({ lnaTypes }) => lnaTypes,
    lnaTypesLoading: ({ lnaTypesLoading }) => lnaTypesLoading,
    // countries
    countries: ({ countries }) => countries,
    countriesLoading: ({ countriesLoading }) => countriesLoading,
    // security-events
    secEvents: ({ secEvents }) => secEvents,
    secEventsLoading: ({ secEventsLoading }) => secEventsLoading,
  },
  mutations: {
    [ORGANIZATIONS](state, value) {
      state.organizations = value;
    },
    [ORGANIZATIONS_LOADING](state, value) {
      state.organizationsLoading = value;
    },
    [DEPARTMENTS](state, value) {
      state.departments = value;
    },
    [DEPARTMENTS_LOADING](state, value) {
      state.departmentsLoading = value;
    },
    [PRORGARMS](state, value) {
      state.programs = value;
    },
    [PRORGARMS_LOADING](state, value) {
      state.programsLoading = value;
    },
    [HLCATEGORY](state, value) {
      state.hlCategory = value;
    },
    [HLCATEGORY_LOADING](state, value) {
      state.hlCategoryLoading = value;
    },
    //lna-types
    [LNATYPES](state, value) {
      state.lnaTypes = value;
    },
    [LNATYPES_LOADING](state, value) {
      state.lnaTypesLoading = value;
    },
    // countries
    [COUNTRIES](state, value) {
      state.countries = value;
    },
    [COUNTRIES_LOADING](state, value) {
      state.countriesLoading = value;
    },
    // security-events
    [SEC_EVENTS](state, value) {
      state.secEvents = value;
    },
    [SEC_EVENTS_LOADING](state, value) {
      state.secEventsLoading = value;
    },
  },
  actions: {
    async updateOrganizations(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/organizations/reference/");
          context.commit(ORGANIZATIONS, response);
        },
        ORGANIZATIONS_LOADING
      );
    },

    async updateDepartments(context) {
      if (!checkPermissions(perm.view_admin_department)) return; // нет прав
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/departments/reference/");
          context.commit(DEPARTMENTS, response);
        },
        DEPARTMENTS_LOADING
      );
    },

    async updatePrograms(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            "/course-assignments/relatedcourses/"
          );
          context.commit(PRORGARMS, response);
        },
        PRORGARMS_LOADING
      );
    },

    async updateHlCategory(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-types/reference/");
          context.commit(HLCATEGORY, response);
        },
        HLCATEGORY_LOADING
      );
    },

    //lna-types
    async updateLnaTypes(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/lna-types/reference/");
          context.commit(LNATYPES, response);
        },
        LNATYPES_LOADING
      );
    },
    // countries
    async updateCountries(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/countries/reference/");
          context.commit(COUNTRIES, response);
        },
        COUNTRIES_LOADING
      );
    },
    // security-events
    async updateSecEvents(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/security-events/reference/");
          context.commit(SEC_EVENTS, response);
        },
        SEC_EVENTS_LOADING
      );
    },
  },
};

export default reports;
