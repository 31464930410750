import "core-js/modules/es.array.push.js";
import { validate } from "@/helpers/validate";
import { checkPermissions } from "@/auth/auth.js";
import { mapGetters } from "vuex";
import { arrayEqual } from "@/helpers/global";
import { addСhildRoles, listСhildRoles } from "@/helpers/roles";
import { stateWorker } from "@/helpers/states";
import { NONAME_SHORT } from "@/helpers/text";
export default {
  name: "AdminUsersAdd",
  props: {
    settings: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    login: "",
    emailState: null,
    password: "",
    passwordState: null,
    orgState: null,
    roleState: null,
    selectedRoles: [],
    oldSelectedRoles: [],
    // роли которые были выбраны до изменения (нужно для проверки дочерних)
    selectedOrg: null,
    active: false,
    canAssignUserOrg: false,
    position: null,
    // change password
    changePswVisible: false,
    passwordNew: "",
    passwordNewAgain: "",
    passwordNewState: null,
    passwordNewAgainState: null,
    canChangeUserOrg: false,
    NONAME_SHORT
  }),
  methods: {
    changeUser() {
      this.password = ""; // только для нового пользователя
      this.passwordState = null;
      this.emailState = null;
      this.orgState = null;
      this.roleState = null;
      this.login = this.item.login;
      this.selectedOrg = this.item.organization;
      this.selectedRoles = this.item.roles || [];
      this.oldSelectedRoles = this.selectedRoles.concat([]);
      this.active = this.item.is_active;
      this.canAssignUserOrg = checkPermissions(this.$perm.assign_org_user);
      this.canChangeUserOrg = checkPermissions(this.$perm.change_org_user);
      this.position = this.item.position;
      // change password
      this.changePswVisible = false;
      this.passwordNew = "";
      this.passwordNewAgain = "";
      this.passwordNewState = null;
      this.passwordNewAgainState = null;
      // modal show
      this.showModal = true;
    },
    async handlerOk(bvModalEvt) {
      if (!this.validateEmail() || !this.validateOrg() || !this.validateRole() || !this.validatePassword("password", "passwordState") || !this.settings.new && (!this.validatePasswordNew() || !this.validatePasswordNewAgain())) {
        bvModalEvt.preventDefault();
        return;
      }
      this.$emit("updateUser", {
        params: {
          login: this.login !== this.item.login ? this.login : null,
          is_active: this.active !== this.item.is_active ? this.active : null,
          roles: arrayEqual(this.selectedRoles, this.item.roles, false) ? null : this.selectedRoles,
          position: this.position !== this.item.position ? this.position : null,
          password: this.settings.new ? this.password : null
        },
        user: this.item.id,
        name: this.item.login,
        org: this.selectedOrg //  это для новых пользователей
      });

      if (!this.settings.new && this.passwordNew !== "") this.$emit("updatePassword", {
        params: {
          new_password: this.passwordNew
        },
        user: this.item.id,
        name: this.item.login
      });
      this.showModal = false;
    },
    validateEmail() {
      const validEmail = validate({
        data: this.login,
        dataRequired: "email"
      });
      this.emailState = validEmail ? null : validEmail;
      return validEmail;
    },
    validatePassword(key, keyState) {
      const validPassword = !this.settings.new && key === "password" || validate({
        data: this[key],
        dataRequired: "password"
      });
      this[keyState] = validPassword ? null : validPassword;
      return validPassword;
    },
    validatePasswordNew() {
      const validPassword = this.passwordNew === "" || this.validatePassword("passwordNew", "passwordNewState");
      if (validPassword === false) this.changePswVisible = true;
      return validPassword;
    },
    validatePasswordNewAgain() {
      const validPassword = this.passwordNew === this.passwordNewAgain;
      this.passwordNewAgainState = validPassword ? null : validPassword;
      if (validPassword === false) this.changePswVisible = true;
      return validPassword;
    },
    validateOrg() {
      const validOrg = this.selectedOrg;
      this.orgState = validOrg ? null : false;
      return validOrg;
    },
    changeRoles() {
      const newRole = this.selectedRoles.find(role => !this.oldSelectedRoles.includes(role));
      if (newRole) {
        this.selectedRoles = addСhildRoles(this.selectedRoles, newRole, this.adminGroups);
      }
      this.oldSelectedRoles = this.selectedRoles.concat([]); // обновим old
      this.validateRole(); // проверим статус
    },

    validateRole() {
      const validRole = this.settings.new || this.selectedRoles.length;
      this.roleState = validRole ? null : false;
      return validRole;
    }
  },
  computed: {
    ...mapGetters("administration", ["adminGroups", "adminRolesRef"]),
    ...mapGetters("referenceBooks", ["organizations"]),
    optionsOrg() {
      return checkPermissions(this.$perm.view_admin_organization) ? Object.keys(this.organizations).reduce((acc, curr) => {
        acc.push({
          value: this.organizations[curr],
          text: curr
        });
        return acc;
      }, []) : [{
        value: this.item.organization,
        text: this.item.organization_name
      }];
    },
    checkRoles() {
      return listСhildRoles(this.selectedRoles, this.adminGroups);
    },
    disableOrg() {
      return (!this.settings.new || !this.canAssignUserOrg) && !this.canChangeUserOrg;
    },
    canChangePassword() {
      return !this.settings.new && stateWorker[this.item.state] !== stateWorker.resigned && stateWorker[this.item.state] !== stateWorker.superadmin && (checkPermissions(this.$perm.change_user_password) && stateWorker[this.item.state] !== stateWorker.admin || checkPermissions(this.$perm.change_admin_password) && stateWorker[this.item.state] === stateWorker.admin);
    }
  }
};