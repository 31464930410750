import { mapGetters, mapActions } from "vuex";
import { limitBound } from "@/helpers/text.js";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import RiskListDetails from "./RiskListDetails.vue";
import links from "@/router/links";
import { statesRisk } from "@/helpers/states";
export default {
  name: "RiskList",
  components: {
    TrafficLight,
    RiskListDetails
  },
  data: () => ({
    statesRisk,
    perPage: 10,
    gotoPage: 1,
    links,
    tableFields: [{
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "id",
      label: "#"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "risk_owner_detail.department_name",
      label: "Владелец риска"
    }, {
      key: "risk_num",
      label: "№ риска",
      class: "text-center"
    }, {
      key: "activity_num",
      label: "№ вида деятельности",
      class: "text-center"
    }, {
      key: "risk_probabilityT",
      label: "Вероятность наступления",
      class: "text-center"
    }, {
      key: "financial_costs",
      label: "Финансовый ущерб"
    }, {
      key: "risk_impactT",
      label: "Степень влияния",
      class: "text-center"
    }, {
      key: "risk_levelT",
      label: "Уровень риска",
      class: "text-center"
    }, {
      key: "btnChange",
      label: ""
    }, {
      key: "btnRemove",
      label: ""
    }],
    showModalRemove: false,
    currentItem: {}
  }),
  methods: {
    ...mapActions("risk", ["updateRisks", "removeRisk", "setRisksPage"]),
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeRisk(this.currentItem);
      await this.setRisksPage(1);
      await this.updateRisks();
    },
    changePage() {
      this.setRisksPage(limitBound(Number(this.gotoPage), 1, this.risksCount / this.perPage, this.risksPage));
      this.updateRisks();
    }
  },
  computed: {
    ...mapGetters("risk", ["risks", "risksCount", "risksPage", "risksLoading"]),
    currentPageModel: {
      get() {
        return this.risksPage;
      },
      set(value) {
        this.setRisksPage(value);
        this.updateRisks();
      }
    }
  }
};