import mutations from "@/store/mutations";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import { fetchAPI, getUrl } from "@/helpers/fetch";
import { formatObjDate } from "@/helpers/date";
import { fmtNameFull, formatName } from "@/helpers/text";

function additionHline(obj) {
  obj.arrivalDate = formatObjDate(obj.arrival_date);
  obj.deadLine = formatObjDate(obj.deadline);
  obj.responseDate = formatObjDate(obj.response_date);
  obj.assigneeDetail = obj.assignee_detail
    ? formatName(obj.assignee_detail, fmtNameFull)
    : null;
  obj.curatorDetail = obj.curator_detail
    ? formatName(obj.curator_detail, fmtNameFull)
    : null;
  obj.departmentChiefDetail = obj.department_chief_detail
    ? formatName(obj.department_chief_detail, fmtNameFull)
    : null;
  return obj;
}

function additionAttach(arr) {
  return arr.map((el) => ({
    name: el.file_name,
    id: el.id,
    date: el.created_at,
    file: el.document,
    is_additional: el.is_additional,
    user_detail: el.user_detail,
  }));
}

const {
  HOTLINE,
  HOTLINE_COUNT,
  HOTLINE_LOADING,
  HOTLINE_MESSAGE,
  HOTLINE_MESSAGE_LOADING,
  // attachments
  HOTLINE_ATTACH,
  HOTLINE_ATTACH_COUNT,
  HOTLINE_ATTACH_LOADING,
  HOTLINE_ATTACHMENT, // для получения конкретного id вложения
  // comments
  HOTLINE_COMMENTS,
  HOTLINE_COMMENTS_COUNT,
  HOTLINE_COMMENTS_LOADING,
  // events
  HOTLINE_EVENTS,
  HOTLINE_EVENTS_COUNT,
  HOTLINE_EVENTS_LOADING,
  // report
  HOTLINE_REP_LOADING,
  HOTLINE_REP_FILE,
  HOTLINE_REP_DATA,
} = mutations;
const formInit = {
  user_detail: {},
  foundations_cp: [],
  foundations_innercheck: [],
};

const hotlineStore = {
  namespaced: true,
  state: {
    hlines: [],
    hlinesCount: 0,
    hlinesLoading: false,
    hlineMsg: JSON.parse(JSON.stringify(formInit)),
    hlineMsgLoading: false,
    // attachments
    hlineAttach: [],
    hlineAttachCount: 0,
    hlineAttachLoading: false,
    hlineAttachment: {},
    // comments
    hlineComments: [],
    hlineCommentsCount: 0,
    hlineCommentsLoading: false,
    // events
    hlineEvents: [],
    hlineEventsCount: 0,
    hlineEventsLoading: false,
    // report
    hlineReportData: {},
    hlineReportFile: "",
    hlineReportLoading: false,
  },
  getters: {
    hlines: ({ hlines }) => hlines,
    hlinesCount: ({ hlinesCount }) => hlinesCount,
    hlinesLoading: ({ hlinesLoading }) => hlinesLoading,
    hlineMsg: ({ hlineMsg }) => hlineMsg,
    hlineMsgLoading: ({ hlineMsgLoading }) => hlineMsgLoading,
    // attachments
    hlineAttach: ({ hlineAttach }) => hlineAttach,
    hlineAttachCount: ({ hlineAttachCount }) => hlineAttachCount,
    hlineAttachLoading: ({ hlineAttachLoading }) => hlineAttachLoading,
    hlineAttachment: ({ hlineAttachment }) => hlineAttachment,
    // comments
    hlineComments: ({ hlineComments }) => hlineComments,
    hlineCommentsCount: ({ hlineCommentsCount }) => hlineCommentsCount,
    hlineCommentsLoading: ({ hlineCommentsLoading }) => hlineCommentsLoading,
    // events
    hlineEvents: ({ hlineEvents }) => hlineEvents,
    hlineEventsCount: ({ hlineEventsCount }) => hlineEventsCount,
    hlineEventsLoading: ({ hlineEventsLoading }) => hlineEventsLoading,
    // report
    hlineReportData: ({ hlineReportData }) => hlineReportData,
    hlineReportFile: ({ hlineReportFile }) => hlineReportFile,
    hlineReportLoading: ({ hlineReportLoading }) => hlineReportLoading,
  },
  mutations: {
    [HOTLINE](state, value) {
      state.hlines = value;
    },
    [HOTLINE_COUNT](state, value) {
      state.hlinesCount = value;
    },
    [HOTLINE_LOADING](state, value) {
      state.hlinesLoading = value;
    },
    [HOTLINE_MESSAGE](state, value) {
      state.hlineMsg = value;
    },
    [HOTLINE_MESSAGE_LOADING](state, value) {
      state.hlineMsgLoading = value;
    },
    // attachments
    [HOTLINE_ATTACH](state, value) {
      state.hlineAttach = value;
    },
    [HOTLINE_ATTACH_COUNT](state, value) {
      state.hlineAttachCount = value;
    },
    [HOTLINE_ATTACH_LOADING](state, value) {
      state.hlineAttachLoading = value;
    },
    [HOTLINE_ATTACHMENT](state, value) {
      state.hlineAttachment = value;
    },
    // comments
    [HOTLINE_COMMENTS](state, value) {
      state.hlineComments = value;
    },
    [HOTLINE_COMMENTS_COUNT](state, value) {
      state.hlineCommentsCount = value;
    },
    [HOTLINE_COMMENTS_LOADING](state, value) {
      state.hlineCommentsLoading = value;
    },
    // events
    [HOTLINE_EVENTS](state, value) {
      state.hlineEvents = value;
    },
    [HOTLINE_EVENTS_COUNT](state, value) {
      state.hlineEventsCount = value;
    },
    [HOTLINE_EVENTS_LOADING](state, value) {
      state.hlineEventsLoading = value;
    },
    // report
    [HOTLINE_REP_DATA](state, value) {
      state.hlineReportData = value;
    },
    [HOTLINE_REP_FILE](state, value) {
      state.hlineReportFile = value;
    },
    [HOTLINE_REP_LOADING](state, value) {
      state.hlineReportLoading = value;
    },
  },
  actions: {
    // запрос с сервера списка сообщений горячей линии
    async updateHlines(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-messages/", data);
          context.commit(HOTLINE, response.results);
          context.commit(HOTLINE_COUNT, response.count);
        },
        HOTLINE_LOADING
      );
    },

    // запрос с сервера конкретного сообщения горячей линии
    async updateHlineMsg(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/hotline-messages/${id}/`);
          context.commit(HOTLINE_MESSAGE, additionHline(response));
        },
        HOTLINE_MESSAGE_LOADING
      );
    },

    // создание нового сообщения горячей линии
    async createHlineMsg(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-messages/", null, "POST");
          context.commit(HOTLINE_MESSAGE, additionHline(response));
          return "Сообщение горячей линии создано";
        },
        HOTLINE_LOADING
      );
    },

    // корректировка сообщения горячей линии
    async changeHlineMsg(context, data) {
      await wrapCommitDispatch(context, async () => {
        const response = await fetchAPI(
          `/hotline-messages/${data.id}/`,
          null,
          "PATCH",
          data.params,
          data.fileUpload
        );
        context.commit(HOTLINE_MESSAGE, additionHline(response));
        return "Сообщение горячей линии изменено";
      });
    },

    // удаление сообщения горячей линии
    async deleteHlineMsg(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/hotline-messages/${id}/`, null, "DELETE");
          return `Сообщение горячей линии #${id} удалено`;
        },
        HOTLINE_LOADING
      );
    },

    // изменение статуса сообщения горячей линии
    async changeStateHlineMsg(context, { id, state, msg }) {
      await wrapCommitDispatch(context, async () => {
        const response = await fetchAPI(
          `/hotline-messages/${id}/transition/`,
          null,
          "PATCH",
          addLinks({ state })
        );
        context.commit(HOTLINE_MESSAGE, additionHline(response));
        return msg;
      });
    },

    // attachments
    // запрос с сервера списка вложений сообщения горячей линии
    async updateHlineAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/hotline-messages/${data.id}/attachments/`,
            { page: data.page }
          );
          context.commit(HOTLINE_ATTACH, additionAttach(response.results));
          context.commit(HOTLINE_ATTACH_COUNT, response.count);
        },
        HOTLINE_ATTACH_LOADING
      );
    },

    // создать вложение к сообщению горячей линии
    async createHlineAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/hotline-messages/${data.id}/attachments/`,
            null,
            "POST",
            data.file,
            true
          );
          context.commit(HOTLINE_ATTACHMENT, response);
          return "Вложение прикреплено к сообщению горячей линии";
        },
        HOTLINE_ATTACH_LOADING
      );
    },

    // удалить вложение к сообщению горячей линии
    async deleteHlineAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/hotline-messages/${data.id}/attachments/${data.idAttach}/`,
            null,
            "DELETE"
          );
          return "Вложение удалено";
        },
        HOTLINE_ATTACH_LOADING
      );
    },

    // изменить вложение к сообщению горячей линии
    async changeHlineAttach(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/hotline-messages/${data.id}/attachments/${data.idAttach}/`,
            null,
            "PATCH",
            data.params,
            false
          );
        },
        HOTLINE_ATTACH_LOADING
      );
    },

    // comments
    // запрос с сервера списка комментариев к сообщению горячей линии
    async updateHlineComments(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/hotline-messages/${data.id}/comments/`,
            { page: data.page }
          );
          context.commit(HOTLINE_COMMENTS, response.results);
          context.commit(HOTLINE_COMMENTS_COUNT, response.count);
        },
        HOTLINE_COMMENTS_LOADING
      );
    },
    // создать комментарий к сообщению горячей линии
    async createHlineComments(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/hotline-messages/${data.id}/comments/`, null, "POST", {
          body: data.comment,
        });
      });
    },

    // events
    // запрос с сервера списка событий к сообщению горячей линии
    async updateHlineEvents(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-events/", data);
          context.commit(HOTLINE_EVENTS, response.results);
          context.commit(HOTLINE_EVENTS_COUNT, response.count);
        },
        HOTLINE_EVENTS_LOADING
      );
    },

    // report
    // запрос данных для отчета
    async updateReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-messages/report/", data);
          context.commit(HOTLINE_REP_DATA, response);
        },
        HOTLINE_REP_LOADING
      );
    },
    // запрос файла для отчета
    async downloadReport(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          context.commit(
            HOTLINE_REP_FILE,
            getUrl("/hotline-messages/download/", data)
          );
        },
        HOTLINE_REP_LOADING
      );
    },

    // сброс формы ГЛ
    async dropHlForm(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(
          HOTLINE_MESSAGE,
          additionHline(JSON.parse(JSON.stringify(formInit)))
        );
      });
    },
  },
};

export default hotlineStore;
