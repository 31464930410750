import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { stateWorker } from "@/helpers/states";
export default {
  name: "FullName",
  data: () => ({
    stateWorker,
    first_name_state: null,
    last_name_state: null,
    middle_name_state: null,
    profileObjSaved: {}
  }),
  created() {
    this.profileObjSaved.first_name = this.profileObj.first_name;
    this.profileObjSaved.last_name = this.profileObj.last_name;
    this.profileObjSaved.middle_name = this.profileObj.middle_name;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData(key) {
      if (this.profileObjSaved[key] === this.profileObj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        this.profileObjSaved[key] = this.profileObj[key];
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};