import { mapGetters, mapActions } from "vuex";
import { checkPermissions } from "@/auth/auth.js";
import { statesCp } from "@/helpers/states";
import { periods4Cp } from "@/helpers/cp";
import { option4select } from "@/helpers/global";
export default {
  name: "CPFormHeader",
  data: () => ({}),
  methods: {
    ...mapActions("cp", ["changeCPForm"]),
    changeData(key) {
      let value = this.cpForm[key];
      this.changeCPForm({
        id: this.cpForm.id,
        params: {
          [key]: value
        }
      });
    }
  },
  computed: {
    ...mapGetters("cp", ["cpForm", "cpDirectory", "cpFormChangeLoading"]),
    option4period() {
      return option4select(periods4Cp);
    },
    option4type() {
      return option4select(this.cpDirectory, false);
    },
    readOnly() {
      return statesCp[this.cpForm.state] !== statesCp.draft || !checkPermissions(this.$perm.add_wa_cp) || this.cpFormChangeLoading;
    }
  }
};