import "core-js/modules/es.array.push.js";
import Calendar from "@/components/Helpers/Calendar.vue";
import { mapGetters, mapActions } from "vuex";
import UserSelector from "@/components/Helpers/UserSelector";
import { fmtNameFull, formatName } from "@/helpers/text";
import { statesInnerCheck } from "@/helpers/states";
import { formatDateObj, fmtDate, formatDate } from "@/helpers/date";
import links from "@/router/links";
import { invalidMinMaxDate } from "@/helpers/validate";
export default {
  name: "InnerCheckFormInfo",
  components: {
    Calendar,
    UserSelector
  },
  data: () => ({
    fmtNameFull,
    invalidDatePlanned: false
  }),
  methods: {
    ...mapActions("innerCheck", ["changeInnerCheck"]),
    ...mapActions("cp", ["createCPFormFromInnerCheck"]),
    formatName,
    async changeItem(key, data) {
      let value = null;
      if (["date_planned"].includes(key)) {
        value = formatDateObj(data, fmtDate, null);
        // «Плановая дата проведения» не ранее даты внесения сведений
        this.invalidDatePlanned = invalidMinMaxDate(value, formatDate(new Date(), fmtDate), null);
        if (this.invalidDatePlanned) return;
      } else if (["assignee", "target_worker"].includes(key)) value = data.id;else value = this.innerCheckForm[key];
      if (value === null) return;
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        params: {
          [key]: value
        }
      });
    },
    // создать КП
    async clickAddCP() {
      await this.createCPFormFromInnerCheck({
        id: this.innerCheckForm.id
      });
      if (this.cpForm.id) this.$router.push(links.cpForm + this.cpForm.id);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminInnerCheck", "adminInnerCheckLoading"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckFormLoading"]),
    ...mapGetters("cp", ["cpForm", "cpFormLoading"]),
    disabledAction() {
      return this.cpFormLoading || statesInnerCheck[this.innerCheckForm.state] !== statesInnerCheck.completed;
    },
    optionsCategory() {
      return this.adminInnerCheck.map(el => ({
        value: el.id,
        text: el.category
      })).concat({
        value: null,
        text: "Не выбрано"
      });
    },
    readOnly() {
      return statesInnerCheck[this.innerCheckForm.state] !== statesInnerCheck.draft || this.innerCheckFormLoading;
    }
  }
};