import { fetchAPI, getUrl } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { checkPermissions, getUserId } from "@/auth/auth";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";
import perm from "@/auth/permissions";
import links from "@/router/links";
import { endptProfile1c } from "@/helpers/profile";

// эндпойнт для скачивания данных ЛЛ для 1с: /profiles/{id}/download1c/. принимает
// запрос GET, в ответ отправляет скачиваемый TXT-файл с данными указанного ЛЛ
function additionProfiles(arr, ids1c) {
  arr.forEach((el) => {
    el.file1c = getUrl(endptProfile1c, { profile_id: el.id });
    el.package41c = ids1c.has(el.id);
    el.printLink =
      links.profileCard +
      JSON.stringify({ profileId: el.id, myProfile: false }) +
      "/print";
  });
  return arr;
}

const {
  PROFILES,
  PROFILES_KADR,
  PROFILES_KADR_COUNT,
  PROFILES_KADR_LOADING,
  PROFILES_IDS_1C, // id`s для пакетной выгрузки ЛЛ в 1С
  PROFILES_IDS_1C_COUNT,
} = mutations;

const profileStore = {
  namespaced: true,
  state: {
    profilesList: [],
    count: 0,
    profilesKadr: [],
    profilesKadrLoading: false,
    profilesKadrCount: 0,
    profilesIds1c: new Set(),
    profilesIds1cCount: 0,
  },
  getters: {
    profilesList: ({ profilesList }) => profilesList,
    profilesCount: ({ count }) => count,
    profilesKadr: ({ profilesKadr }) => profilesKadr,
    profilesKadrLoading: ({ profilesKadrLoading }) => profilesKadrLoading,
    profilesKadrCount: ({ profilesKadrCount }) => profilesKadrCount,
    profilesIds1c: ({ profilesIds1c }) => profilesIds1c,
    profilesIds1cCount: ({ profilesIds1cCount }) => profilesIds1cCount,
  },
  mutations: {
    [PROFILES](state, { profilesList, count }) {
      state.profilesList = profilesList;
      state.count = count;
    },
    [PROFILES_KADR](state, value) {
      state.profilesKadr = value;
    },
    [PROFILES_KADR_COUNT](state, value) {
      state.profilesKadrCount = value;
    },
    [PROFILES_KADR_LOADING](state, value) {
      state.profilesKadrLoading = value;
    },
    [PROFILES_IDS_1C](state, value) {
      state.profilesIds1c = value;
    },
    [PROFILES_IDS_1C_COUNT](state, value) {
      state.profilesIds1cCount = value;
    },
  },
  actions: {
    // запрос личных листков отфильтрованных по пользователю
    async fetchProfiles(context, page = 1) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(`/my-profiles/`, {
            user_id: getUserId(),
            page,
          });
          context.commit(PROFILES, {
            profilesList: result.results,
            count: result.count,
          });
        },
        PROFILES_KADR_LOADING
      );
    },

    // запрос личных листков для рабочего места кадровика
    async updateProfilesKadr(context, { page }) {
      if (!checkPermissions(perm.view_wa_profile)) return; // нет доступа на просмотр
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/profiles/", { page });
          const { profilesIds1c } = context.getters;
          context.commit(
            PROFILES_KADR,
            additionProfiles(response.results, profilesIds1c)
          );
          context.commit(PROFILES_KADR_COUNT, response.count);
        },
        PROFILES_KADR_LOADING
      );
    },

    // клонирование личного листка
    async cloneProfile(context, id) {
      if (!checkPermissions(perm.copy_my_profile)) return; // нет доступа на создание копии личного листка
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/my-profiles/${id}/clone/`, null, "POST");
        return "Личный листок скопирован";
      });
    },

    // сменить статус ЛЛ (принять, отклонить)
    async updateProfileState(context, { id, state, msg }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/profiles/${id}/transition/`,
          null,
          "PATCH",
          addLinks({ state })
        );
        return msg;
      });
    },

    // обновить profilesIds1c
    async updateProfilesIds1c(context, { id, flag, clear }) {
      await wrapCommitDispatch(context, async () => {
        const { profilesIds1c, profilesKadr } = context.getters;
        if (clear) {
          profilesIds1c.clear();
          profilesKadr.forEach((el) => {
            el.package41c = false;
          });
        }

        if (flag) profilesIds1c.add(id);
        else profilesIds1c.delete(id);

        context.commit(PROFILES_IDS_1C, profilesIds1c);
        context.commit(PROFILES_IDS_1C_COUNT, profilesIds1c.size);
      });
    },
  },
};

export default profileStore;
