import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import DeclarationFormPartI from "./DeclarationFormPartI";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import COIForDeclaration from "./COI/COIForDeclaration.vue";
import { statesDeclarations } from "@/helpers/states";
import AttachmentsList from "./Attachments/AttachmentsList.vue";
import DeclarationFormComments from "./DeclarationFormComments.vue";
import EventsList from "./Events/EventsList.vue";
import TaskButton from "./Tasks/TaskButton.vue";
import links from "@/router/links";
import { buttons4transitions } from "@/helpers/declaration";
export default {
  name: "DeclarationForm",
  props: {
    msg: String
  },
  async created() {
    await this.updateForm();
  },
  data: () => ({
    buttons4transitions,
    links,
    statesDeclarations,
    fmtDateMonthTime,
    tabIndex: 0,
    showModalConfirm: false,
    modalConfirmTitle: "",
    data4send: {},
    breadcrumbs: [{
      text: "Декларации",
      to: {
        name: "Declarations"
      }
    }]
  }),
  methods: {
    ...mapActions("declarationForm", ["fetchDeclarationForm", "updateDeclarationFormAttachments", "sendDeclaration", "send2Declaration", "updateDeclFormComments", "createDeclFormComments"]),
    ...mapActions("coi", ["fetchCoiByDeclarationId"]),
    ...mapActions("cp", ["fetchCpByDeclarationId"]),
    ...mapActions("tasks", ["updateTasksByDeclarationId", "dropTasksForDeclaration"]),
    ...mapActions("declarations", ["updateEvents"]),
    formatDate,
    async addTask(settings, data) {
      const body = {};
      // только цикл for, т.к. forEach вызывает callback, который захватывает базу и не изменяет файл
      for (const [key, value] of Object.entries(settings.body)) {
        if (["files"].includes(value)) {
          const formData = new FormData();
          formData.append(key, data[value][0]); // считаем, что можно прикрепить только один файл и других данных в запросе не будет
          await this.send2Declaration({
            msg: settings.msg2Dec,
            body: formData,
            fileUpload: true
          });
        } else if (key === "commentCheif" && String(data[value]).trim().length) {
          await this.createDeclFormComments({
            id: this.msg,
            comment: data[value]
          });
        } else {
          body[key] = data[value];
        }
      }
      if (Object.keys(body).length > 0) {
        await this.send2Declaration({
          msg: settings.msg2Dec,
          body,
          fileUpload: false
        });
      }
      await this.clickAction(settings);
    },
    async clickAction(data) {
      this.data4send = data;
      if (data.confirmed) this.showModalConfirm = true;else this.doConfirm();
    },
    async doConfirm() {
      await this.sendDeclaration({
        state: this.data4send.state,
        msg: this.data4send.msg,
        myDecl: false
      });
      await this.updateForm();
    },
    async updateForm() {
      await this.fetchDeclarationForm({
        id: this.msg,
        myDecl: false
      });
      await this.fetchCoiByDeclarationId({
        id: this.msg,
        page: 1
      });
      await this.updateDeclarationFormAttachments({
        id: this.msg,
        page: 1
      });
      await this.updateEvents({
        decl_id: this.msg,
        page: 1
      });
      this.updateDeclFormComments({
        id: this.msg,
        page: 1
      });
    },
    // доработка settings по задаче issues/#378
    butRef4transitions(key) {
      if (key === "assigned" && this.declarationFormObj.assignee) {
        const obj = {
          ...buttons4transitions[key].settings
        };
        obj.buttonTitle = "Переназначить исполнителя";
        return obj;
      } else return buttons4transitions[key].settings;
    }
  },
  components: {
    theNotification,
    BreadCrumbToHome,
    DeclarationFormPartI,
    COIForDeclaration,
    AttachmentsList,
    EventsList,
    TaskButton,
    DeclarationFormComments
  },
  computed: {
    ...mapGetters("declarationForm", ["declarationFormObj", "declarationFormLoading", "declarationFormAttachmentsCount", "declarationFormAttachmentsLoading", "declFormCommentsCount"]),
    ...mapGetters("coi", ["coiForDeclarationCount", "coiForDeclarationLoading"]),
    ...mapGetters("cp", ["cpForDeclarationCount"]),
    ...mapGetters("tasks", ["tasksForDeclarationCount", "tasksForDeclarationLoading"]),
    ...mapGetters("declarations", ["declarationEventsCount", "declarationEventsLoading"]),
    renderUser() {
      const {
        position,
        organization,
        worker_status,
        worker_code,
        senior,
        email
      } = this.declarationFormObj.user_detail;
      return {
        position,
        organization,
        worker_status,
        worker_code,
        senior,
        email,
        ...this.declarationFormObj.profile_detail
      };
    }
  }
};