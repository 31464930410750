import KnowledgeSidebar from "./KnowledgeSidebar.vue";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { limitBound, CONST_NONAME, formatName, fmtNameShort } from "@/helpers/text";
import { statesKnowledge } from "@/helpers/states";
export default {
  name: "KnowledgeDocuments",
  props: {
    section: {
      type: String,
      required: true
    }
  },
  // id раздела
  components: {
    KnowledgeSidebar
  },
  data: () => ({
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    links,
    sort: null,
    optionsSort: [{
      value: "owner_department_name",
      text: "по владельцу"
    }, {
      value: "title",
      text: "по названию"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "document_date",
      text: "по дате документа"
    }, {
      value: "document_number",
      text: "по номеру документу"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }],
    filters: {},
    showSidebar: false
  }),
  computed: {
    ...mapGetters("knowledge", ["sectionsDocuments", "sectionsDocumentsLoading", "sectionsDocumentsCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        const params = {
          page: value,
          section_id: this.section,
          sort: this.sort,
          ...this.filters
        };
        if (this.isAltUI) params.alt = true;
        this.updateDocuments(params);
      }
    },
    isAltUI() {
      return this.$route.path.includes(links.knowledgeListAlt);
    },
    items4table() {
      return Array.isArray(this.sectionsDocuments) ? this.sectionsDocuments.map(el => ({
        ...el,
        titleT: el.title || CONST_NONAME,
        stateT: statesKnowledge[el.state],
        document_dateT: formatDate(el.document_date, fmtDateShort),
        responsible_detailT: formatName(el.responsible_detail, fmtNameShort)
      })) : [];
    },
    fields() {
      const arr = [{
        key: "id",
        label: "#"
      }, {
        key: "owner_department_name",
        label: "Владелец"
      }, {
        key: "visibility_level",
        label: "Группа"
      }, {
        key: "titleT",
        label: "Название"
        //formatter: (value) => value || CONST_NONAME,
      }, {
        key: "stateT",
        label: "Статус",
        formatter: value => statesKnowledge[value]
      }, {
        key: "document_dateT",
        label: "Дата"
        //formatter: (value) => formatDate(value, fmtDateShort),
      }, {
        key: "document_number",
        label: "Номер"
      }, {
        key: "responsible_detailT",
        label: "Ответственный"
        //formatter: (value) => formatName(value, fmtNameShort),
      }];

      return this.isAltUI ? arr : arr.concat({
        key: "btnClone",
        label: "Копировать",
        class: "text-center"
      });
    }
  },
  methods: {
    ...mapActions("knowledge", ["updateDocuments", "cloneDocument"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.sectionsDocumentsCount / this.perPage, this.currentPage);
      this.sortChanged();
    },
    async copyDocument(id) {
      await this.cloneDocument(id);
      await this.updateDocuments({
        page: this.currentPage,
        section_id: this.section,
        sort: this.sort,
        ...this.filters
      });
    },
    async applyFilters(data) {
      this.filters = {};
      Object.keys(data).forEach(key => {
        const value = ["document_date_gte", "document_date_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") this.filters[key] = value;
      });
      if (this.currentPage === 1) {
        const params = {
          page: 1,
          section_id: this.section,
          sort: this.sort,
          ...this.filters
        };
        if (this.isAltUI) params.alt = true;
        await this.updateDocuments(params);
      } else this.currentPage = 1;
    },
    async sortChanged({
      sortVal
    }) {
      const params = {
        page: this.currentPage,
        section_id: this.section,
        sort: sortVal,
        ...this.filters
      };
      if (this.isAltUI) params.alt = true;
      await this.updateDocuments(params);
    }
  }
};