import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminCalendarItem from "./AdminCalendarItem.vue";
import { formatDate, fmtDateMonth } from "@/helpers/date";
export default {
  name: "AdminCalendar",
  data: () => ({
    yearOptions: [],
    year: null,
    currentDate: null,
    modalTitle: null,
    worker: true,
    active: true,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminCalendarItem
  },
  async created() {
    this.year = new Date().getFullYear();
    await this.updateCalendar(this.year);
    this.yearOptions.push(this.year - 1);
    this.yearOptions.push(this.year);
    this.yearOptions.push(this.year + 1);
  },
  methods: {
    ...mapActions("administration", ["updateCalendar", "changeCalendar"]),
    onSelectDate(date) {
      this.modalTitle = formatDate(date, fmtDateMonth);
      this.currentDate = date;
      if (this.adminCalendar[date]) {
        this.worker = this.adminCalendar[date]["worker"];
        this.active = this.adminCalendar[date]["active"];
      }
      this.$bvModal.show("madalDateSetting");
    },
    async updateYear() {
      await this.updateCalendar(this.year);
    },
    async handlerOk() {
      await this.changeCalendar({
        date: this.currentDate,
        worker: this.worker,
        active: this.active
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCalendar", "adminCalendarLoading"])
  }
};