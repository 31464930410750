import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "23. Имеются ли у Вас заболевания, противопоказания, иные причины,\n            обстоятельства, негативно влияющие и/или препятствующие исполнению\n            принимаемых на себя обязательств, в том числе по выполнению\n            служебных поручений вне места постоянной работы, и/или требующие от\n            работодателя создания для Вас особых условий работы в соответствии с\n            требованиями трудового законодательства? Если имеются, то какие?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_special_conditions,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_special_conditions = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_special_conditions')),
          state: _ctx.has_special_conditions_state,
          class: "w-auto mt-2"
        }, null, 8, ["modelValue", "options", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}