import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "6",
      xl: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "25. Состоите (состояли) ли Вы на учете в наркологическом и/или\n                психоневрологическом диспансере?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.is_psycho,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.is_psycho = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('is_psycho')),
          state: _ctx.is_psycho_state,
          class: "w-auto mt-2"
        }, null, 8, ["modelValue", "options", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}