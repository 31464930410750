import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [$props.settings.buttonIcon ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "link",
    onClick: $options.clickChange,
    class: "border-0 p-0"
  }, {
    default: _withCtx(() => [_createElementVNode("i", {
      class: _normalizeClass(`bi bi-${$props.settings.iconType}`)
    }, null, 2)]),
    _: 1
  }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_b_button, {
    key: 1,
    variant: "primary",
    onClick: $options.clickChange
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.settings.btnTitle), 1)]),
    _: 1
  }, 8, ["onClick"])), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModal = $event),
    id: $props.idModal,
    title: $props.settings.title,
    centered: "",
    onOk: $options.handlerOk,
    "ok-disabled": !_ctx.isAllRequiredFilled,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.settings.texts, (text, index) => {
      return _openBlock(), _createBlock(_component_b_form_group, {
        key: index,
        label: `${text.label} ${text.required ? ' *' : ''}`
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.currentItem[index],
          "onUpdate:modelValue": $event => _ctx.currentItem[index] = $event,
          required: text.required,
          type: "text",
          placeholder: "Укажите текст",
          state: _ctx.states[index],
          onInput: $event => $options.validateText(index),
          trim: ""
        }, null, 8, ["modelValue", "onUpdate:modelValue", "required", "state", "onInput"])]),
        _: 2
      }, 1032, ["label"]);
    }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.settings.selectors, (selector, index) => {
      return _openBlock(), _createBlock(_component_b_form_group, {
        key: index,
        label: `${selector.label} ${selector.required ? ' *' : ''}`
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.currentItem[index],
          "onUpdate:modelValue": $event => _ctx.currentItem[index] = $event,
          options: selector.options
        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])]),
        _: 2
      }, 1032, ["label"]);
    }), 128)), $props.settings.switchName ? (_openBlock(), _createBlock(_component_b_form_checkbox, {
      key: 0,
      modelValue: _ctx.checkbox,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.checkbox = $event),
      switch: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.settings.switchText(_ctx.checkbox)), 1)]),
      _: 1
    }, 8, ["modelValue"])) : _createCommentVNode("", true)], 32)]),
    _: 1
  }, 8, ["modelValue", "id", "title", "onOk", "ok-disabled"])]);
}