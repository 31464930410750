import theNotification from "@/components/theNotification";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import HotlineFormAuthor from "./HotlineFormAuthor.vue";
import HotlineFormInfo from "./HotlineFormInfo.vue";
import HotlineFormLog from "./HotlineFormLog.vue";
import HotlineFormResult from "./HotlineFormResult.vue";
import HotlineFormAttach from "./HotlineFormAttach.vue";
import HotlineFormComments from "./HotlineFormComments.vue";
import HotlineFormSource from "./HotlineFormSource.vue";
import { mapActions, mapGetters } from "vuex";
import { statesHotline } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { NONUMBER, formatName, fmtNameShort } from "@/helpers/text";
import { btn4transitHline } from "@/helpers/hline";
import { validateObject } from "@/helpers/validate";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
export default {
  name: "HotlineFormBase",
  props: {
    idMessage: {
      type: String,
      required: true
    }
  },
  components: {
    theNotification,
    BreadCrumbToHome,
    HotlineFormAuthor,
    HotlineFormInfo,
    HotlineFormLog,
    HotlineFormResult,
    HotlineFormAttach,
    HotlineFormComments,
    TaskButton,
    HotlineFormSource
  },
  data: () => ({
    showAlert: false,
    alertTitle: "",
    btn4transitHline,
    fmtNameShort,
    NONUMBER,
    statesHotline,
    fmtDateShort,
    breadcrumbs: [{
      text: "Горячая линия",
      to: {
        name: "HotLine"
      }
    }]
  }),
  methods: {
    ...mapActions("hotline", ["updateHlineMsg", "updateHlineAttach", "updateHlineComments", "updateHlineEvents", "changeStateHlineMsg", "changeHlineMsg", "createHlineComments"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateHlCategory"]),
    formatDate,
    formatName,
    async refreshHline() {
      await this.updateHlineMsg(this.idMessage);
      this.updateHlineAttach({
        id: this.idMessage,
        page: 1
      });
      this.updateHlineComments({
        id: this.idMessage,
        page: 1
      });
      this.updateHlineEvents({
        hotmsg_id: this.idMessage
      });
    },
    async clickAction(setting, data) {
      this.showAlert = false;
      // сначала проверка на обязательные поля
      const errors = [];
      const hline = this.hlineMsg;
      const setg = setting ? btn4transitHline[setting] : this.stateBtn;
      if (setg.validate) validateObject(hline, setg.validate, errors);
      if (setg.author && !hline[setg.author]) {
        validateObject(hline, setg.validateAuthor, errors);
        if (setg.worker && setg.workerType.includes(hline[setg.worker])) validateObject(hline, setg.validateWorker, errors);
      }
      if (statesHotline[hline.state] === statesHotline.assigned && errors.length === 1 // т.е. один из файлов есть
      ) errors.length = 0; // отдельно проверим для статуса assigned условие или или
      if (errors.length) {
        this.alertTitle = errors.join(", ");
        this.showAlert = true;
        return;
      }
      // пихнем в бэк то, что пришло из модального окна
      const body = {};
      if (setg.settings) Object.entries(setg.settings.body).forEach(async ([key, value]) => {
        if (key === "commentCheif" && String(data[value]).trim().length) {
          await this.createHlineComments({
            id: this.idMessage,
            comment: data[value]
          });
          this.updateHlineComments({
            id: this.idMessage,
            page: 1
          });
        } else body[key] = data[value];
      });
      if (Object.keys(body).length > 0) {
        await this.changeHlineMsg({
          id: this.idMessage,
          params: body
        });
      }
      // смена статуса
      await this.changeStateHlineMsg({
        id: this.idMessage,
        msg: setg.msg,
        state: setg.state
      });
    }
  },
  async created() {
    await this.refreshHline();
    this.updateHlCategory();
    this.updateOrganizations();
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsgLoading", "hlineMsg", "hlineAttachCount", "hlineCommentsCount", "hlineEventsCount"]),
    stateBtn() {
      return btn4transitHline[this.hlineMsg.state];
    },
    sourceCount() {
      return this.hlineMsg.foundations_cp.length + this.hlineMsg.foundations_innercheck.length;
    }
  }
};