import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "5. Ученая степень, ученое звание"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.academic_rank,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.academic_rank = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('academic_rank')),
          state: _ctx.academic_rank_state,
          disabled: _ctx.readonly,
          type: "text",
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}