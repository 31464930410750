import { formatDate, fmtDateShort, monthsCaseR } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import { switchText } from "@/helpers/text.js";
import { getOrgName } from "@/auth/auth";
import { snilsMask } from "@/helpers/masks";
export default {
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  name: "DeclarationPrint",
  data: () => ({
    snilsMask,
    now: null,
    user: {},
    fmtDateShort,
    loading: false,
    monthsCaseR
  }),
  async created() {
    this.now = new Date();
    const {
      id,
      myDecl
    } = JSON.parse(this.msg);
    await this.fetchDeclarationForm({
      id,
      myDecl
    });
    const {
      position
    } = this.declarationFormObj.user_detail;
    this.user = {
      position,
      ...this.declarationFormObj.profile_detail
    };
  },
  computed: {
    ...mapGetters("declarationForm", ["declarationFormObj"]),
    orgName() {
      return getOrgName();
    }
  },
  methods: {
    ...mapActions("declarationForm", ["fetchDeclarationForm"]),
    switchText,
    formatDate
  }
};