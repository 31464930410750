import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminOrgAdd from "./AdminOrgAdd.vue";
import { limitBound } from "@/helpers/text.js";
export default {
  name: "AdminOrg",
  data: () => ({
    showModalRemove: false,
    currentItem: {},
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "id",
      label: "id"
    }, {
      key: "fullname",
      label: "Название",
      class: "org-name-list"
    }, {
      key: "inn",
      label: "ИНН",
      class: "org-name-list"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => {
        return formatDate(value, fmtDateMonthTime);
      },
      tdAttr: value => {
        return {
          "data-created": formatDate(value, fmtDateShort)
        };
      },
      class: "text-nowrap org-date-list"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "p-0"
    }
    // { key: "btnRemove", label: "Удалить", class: "p-0" },
    ],

    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Добавить организацию",
      btnTitle: "Добавить организацию",
      buttonIcon: false,
      iconType: null
    },
    settingsChange: {
      title: "Редактировать организацию",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square"
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminOrgAdd
  },
  created() {
    this.updateAdminOrg({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminOrg", "createAdminOrg", "removeAdminOrg", "changeAdminOrg"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminOrgCount / this.perPage, this.currentPage);
      this.updateAdminOrg({
        page: this.currentPage
      });
    },
    async clickCreateOrg(data) {
      await this.createAdminOrg(data);
      this.currentPage = 1;
      await this.updateAdminOrg({
        page: 1
      });
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeAdminOrg(this.currentItem);
      this.currentPage = 1;
      await this.updateAdminOrg({
        page: 1
      });
    },
    async clickChange(data) {
      await this.changeAdminOrg(data);
      await this.updateAdminOrg({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminOrg", "adminOrgLoading", "adminOrgCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminOrg({
          page: value
        });
      }
    }
  }
};