import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound } from "@/helpers/text.js";
export default {
  name: "AdminLnaTypes",
  data: () => ({
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [{
      key: "id",
      label: "#"
    }, {
      key: "type_name",
      label: "Название"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Добавить тип ЛНА",
      btnTitle: "Добавить тип ЛНА",
      buttonIcon: false,
      iconType: null,
      texts: {
        type_name: {
          required: true,
          label: "Название"
        }
      }
    },
    settingsChange: {
      title: "Редактировать тип ЛНА",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        type_name: {
          required: true,
          label: "Название"
        }
      }
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminElementAdd
  },
  created() {
    this.updateAdminLnaTypes({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminLnaTypes", "createAdminLnaTypes", "changeAdminLnaTypes"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminLnaTypesCount / this.perPage, this.currentPage);
      this.updateAdminLnaTypes({
        page: this.currentPage
      });
    },
    async clickCreate(data) {
      await this.createAdminLnaTypes(data);
      this.currentPage = 1;
      await this.updateAdminLnaTypes({
        page: 1
      });
    },
    async clickChange(data) {
      await this.changeAdminLnaTypes(data);
      await this.updateAdminLnaTypes({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminLnaTypes", "adminLnaTypesLoading", "adminLnaTypesCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminLnaTypes({
          page: value
        });
      }
    }
  }
};