import { mapGetters, mapActions } from "vuex";
import coiTemplate from "./coiTemplate";
import { limitBound } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "COIForDeclaration",
  props: {
    declarationId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    coiTemplate,
    coiFields: [{
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "coi_type_name",
      label: "Тип конфликта",
      sortable: false
    }]
  }),
  methods: {
    ...mapActions("coi", ["fetchCoiByDeclarationId"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.coiForDeclarationCount / this.perPage, this.currentPage);
    }
  },
  computed: {
    ...mapGetters("coi", ["coiForDeclaration", "coiForDeclarationCount", "coiForDeclarationLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.fetchCoiByDeclarationId({
          id: this.declarationId,
          page: value
        });
      }
    }
  }
};