export function snilsMask(number) {
  const cleanedNumber = String(number).replace(/\D/g, "");
  return cleanedNumber.replace(
    /(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/,
    function (_, p1, p2, p3, p4) {
      let result = p1 || "";
      if (p2) result += "-" + p2;
      if (p3) result += "-" + p3;
      if (p4) result += " " + p4;
      return result;
    }
  );
}

export function phoneMask(number) {
  return String(number).replace(
    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
    "$1 $2 $3 $4 $5"
  ); // маска телефонного номера разделителями
}

export function passportIssuerCodeMask(number) {
  return number ? number.replace(/(\d{3})(\d{3})/, "$1-$2") : "";
}
export function ogrnipMask(number) {
  const cleanedNumber = number ? number.replace(/\D/g, "") : "";
  return cleanedNumber.replace(/(\d{15})/, "$1");
}
