import links from "@/router/links";
import personalAreaCards from "@/components/PersonalArea/pa-cards";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "theSidebar",
  data: () => ({
    links,
    personalAreaCards
  }),
  methods: {
    ...mapActions("administration", ["updateCommon"])
  },
  computed: {
    ...mapGetters("administration", ["adminCommon", "adminCommonLoading"])
  },
  created() {
    this.updateCommon();
  }
};