import "core-js/modules/es.array.push.js";
import { optionsEducMaterials } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProgramAddMaterial",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentTitle2Remove: "",
    currentIndex2Remove: null,
    showModalRemove: false,
    showModal: false,
    optionsEducMaterials,
    description: null,
    modalMaterial: "",
    modalType: null,
    modalFile: null,
    currentIndex: 0,
    isChangeMaterial: false,
    tableFields: [{
      key: "index",
      label: "№",
      class: "font-mono"
    }, {
      key: "title",
      label: "Название"
    }, {
      key: "material_type",
      label: "Тип"
    }, {
      key: "btnChange",
      label: "",
      class: "w-auto"
    }, {
      key: "btnRemove",
      label: "",
      class: "w-auto"
    }],
    propItem: {}
  }),
  created() {
    this.propItem = {
      ...this.item
    };
  },
  methods: {
    ...mapActions("education", ["createMaterial", "deleteMaterial", "uploadFile4Material", "changeMaterial"]),
    removeMaterial(index) {
      this.currentIndex2Remove = index;
      this.currentTitle2Remove = this.propItem.materials[index].title;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.deleteMaterial({
        course: this.propItem.id,
        id: this.propItem.materials[this.currentIndex2Remove].id
      });
      this.propItem.materials.splice(this.currentIndex2Remove, 1);
    },
    addMaterial() {
      this.isChangeMaterial = false;
      this.modalMaterial = "";
      this.modalType = "text";
      this.modalFile = null;
      this.description = null;
      this.showModal = true;
    },
    clickisChangeMaterial(index) {
      this.isChangeMaterial = true;
      this.currentIndex = index;
      this.modalMaterial = this.propItem.materials[index].title;
      this.modalType = this.propItem.materials[index].material_type;
      this.description = this.propItem.materials[index].text;
      this.modalFile = null;
      if (optionsEducMaterials[this.modalType] === optionsEducMaterials.presentation) {
        this.modalFile = {
          name: this.propItem.materials[index].presentation,
          api: true // файл пришел из бэка как строка
        };
      } else if (optionsEducMaterials[this.modalType] === optionsEducMaterials.attachment) {
        this.modalFile = {
          name: this.propItem.materials[index].attachment,
          api: true // файл пришел из бэка как строка
        };
      } else if (optionsEducMaterials[this.modalType] === optionsEducMaterials.video) {
        this.modalFile = {
          name: this.propItem.materials[index].video_file,
          api: true // файл пришел из бэка как строка
        };
      }

      this.showModal = true;
    },
    async uploadFile(type, id) {
      if (!this.modalFile || ![optionsEducMaterials.presentation, optionsEducMaterials.attachment, optionsEducMaterials.video].includes(type)) return;
      const formData = new FormData();
      if (type === optionsEducMaterials.presentation) {
        formData.append("presentation", this.modalFile);
      } else if (type === optionsEducMaterials.attachment) {
        formData.append("attachment", this.modalFile);
      } else if (type === optionsEducMaterials.video) {
        formData.append("video_file", this.modalFile);
      }
      await this.uploadFile4Material({
        course: this.propItem.id,
        id,
        params: formData
      });
    },
    async handlerOk() {
      if (this.isChangeMaterial) {
        const params = {
          title: this.modalMaterial,
          material_type: this.modalType,
          text: this.description
        };
        // file
        if (!this.modalFile) {
          // файл === null, обнулим и вложения
          params.presentation = null;
          params.attachment = null;
          params.video_file = null;
        } else if (!this.modalFile.api) {
          // файл был загружен в модальном окне
          await this.uploadFile(optionsEducMaterials[this.modalType], this.propItem.materials[this.currentIndex].id);
        }
        // material
        await this.changeMaterial({
          course: this.propItem.id,
          id: this.propItem.materials[this.currentIndex].id,
          params
        });
        // array
        this.propItem.materials.splice(this.currentIndex, 1, this.material);
      } else {
        const params = {
          title: this.modalMaterial,
          material_type: this.modalType,
          text: this.description
          //video: this.video,
        };
        // material
        await this.createMaterial({
          course: this.propItem.id,
          params
        });
        // file
        await this.uploadFile(optionsEducMaterials[this.modalType], this.material.id);
        // array
        this.propItem.materials.push(this.material);
      }
    },
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.modalFile = files[0];
      this.$refs.fileInput.value = null;
    }
  },
  computed: {
    ...mapGetters("education", ["material"]),
    okModalDisabled() {
      return !this.modalMaterial.trim().length;
    },
    modalFileName() {
      return this.modalFile ? this.modalFile.name : "";
    },
    optionsTypes() {
      return Object.keys(optionsEducMaterials).reduce((acc, curr) => {
        acc.push({
          value: curr,
          text: optionsEducMaterials[curr]
        });
        return acc;
      }, []);
    }
  }
};