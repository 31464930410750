import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import { limitBound, selectAll } from "@/helpers/text.js";
export default {
  name: "MatrixRiskAdd",
  data: () => ({
    state: "published",
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    risk_owner_id: null,
    activity_num: null,
    risksToAdd: [],
    tableFields: [{
      key: "id",
      label: "#",
      class: "text-center"
    }, {
      key: "risk_owner_detail.department_name",
      label: "Владелец риска"
    }, {
      key: "risk_num",
      label: "№ риска",
      class: "text-center"
    }, {
      key: "activity_num",
      label: "№ вида деятельности",
      class: "text-center"
    }, {
      key: "selected",
      label: "Добавить",
      class: "text-center"
    }],
    showModal: false
  }),
  methods: {
    ...mapActions("risk", ["updateAvailableRisks"]),
    onSelection(items) {
      this.risksToAdd = items;
    },
    clearForm() {
      this.currentPage = 1;
      this.risk_owner_id = null;
      this.activity_num = null;
      this.risksToAdd = [];
    },
    async handlerClick() {
      this.clearForm();
      await this.updateAvailableRisks({
        risk_owner_id: this.risk_owner_id,
        activity_num: this.activity_num,
        state: this.state,
        page: this.currentPage
      });
      this.showModal = true;
    },
    async clickAvailable() {
      await this.updateAvailableRisks({
        risk_owner_id: this.risk_owner_id,
        activity_num: this.activity_num,
        state: this.state,
        page: this.currentPage
      });
    },
    handlerOk() {
      this.$emit("addRisks", this.risksToAdd);
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.availableRiskCount / this.perPage, this.currentPage);
      this.updateAvailableRisks({
        risk_owner_id: this.risk_owner_id,
        activity_num: this.activity_num,
        state: this.state,
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("risk", ["availableRisk", "availableRiskCount", "availableRiskLoading", "matrixForm", "matrixFormLoading"]),
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [...selectAll]);
    },
    vdOptions() {
      return Object.keys(this.matrixForm.activities_reference).reduce((acc, curr) => {
        acc.push({
          value: curr,
          text: this.matrixForm.activities_reference[curr]
        });
        return acc;
      }, [...selectAll]);
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateAvailableRisks({
          risk_owner_id: this.risk_owner_id,
          activity_num: this.activity_num,
          state: this.state,
          page: value
        });
      }
    }
  }
};