import { hlineReason } from "@/helpers/text";
import { statesHotline } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { option4select } from "@/helpers/global";
export default {
  name: "HotlineFormSource",
  data: () => ({
    showModalRemove: false,
    currentItem: {},
    showModal: false,
    typeSource: null,
    idSource: "",
    tableFields: [{
      key: "index",
      label: "№"
    }, {
      key: "title",
      label: "Источник"
    }, {
      key: "remove",
      label: "Удалить",
      class: "text-center"
    }]
  }),
  methods: {
    ...mapActions("hotline", ["changeHlineMsg"]),
    ...mapActions("cp", ["updateCPForm", "dropCPForm"]),
    ...mapActions("innerCheck", ["updateInnerCheckForm", "dropICForm"]),
    ...mapActions(["showNotify"]),
    addSource() {
      this.typeSource = null;
      this.idSource = "";
      this.showModal = true;
    },
    async handlerAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      // foundations_cp: "Контрольная процедура",
      // foundations_innercheck: "Внутренняя проверка",
      if (hlineReason[this.typeSource] === hlineReason.foundations_cp) {
        await this.dropCPForm();
        await this.updateCPForm(this.idSource);
        if (!this.cpForm.id) {
          this.showNotify({
            msg: `Контрольная процедура #${this.idSource} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (hlineReason[this.typeSource] === hlineReason.foundations_innercheck) {
        await this.dropICForm();
        await this.updateInnerCheckForm(this.idSource);
        if (!this.innerCheckForm.id) {
          this.showNotify({
            msg: `Внутренняя проверка #${this.idSource} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else return;
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [this.typeSource]: this.hlineMsg[this.typeSource].concat(this.idSource)
        }
      });
      this.showModal = false;
    },
    async removeSource(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      this.hlineMsg[this.currentItem.key].splice(this.currentItem.index, 1);
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [this.currentItem.key]: this.hlineMsg[this.currentItem.key]
        }
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg", "hlineMsgLoading"]),
    ...mapGetters("cp", ["cpForm", "cpFormLoading"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckFormLoading"]),
    okModalAddDisabled() {
      return !this.typeSource || !this.idSource.trim().length || this.cpFormLoading || this.innerCheckFormLoading;
    },
    readOnly() {
      return statesHotline[this.hlineMsg.state] !== statesHotline.draft || !checkPermissions(this.$perm.change_wa_hotmsg) || this.hlineMsgLoading;
    },
    optionsSource() {
      return option4select(hlineReason);
    },
    source() {
      return [].concat(this.hlineMsg.foundations_cp.map((cp, index) => ({
        key: "foundations_cp",
        index,
        link: links.cpForm + cp,
        title: hlineReason.foundations_cp + " #" + cp
      })), this.hlineMsg.foundations_innercheck.map((inch, index) => ({
        key: "foundations_innercheck",
        index,
        link: links.innerCheckForm + inch,
        title: hlineReason.foundations_innercheck + " #" + inch
      })));
    }
  }
};