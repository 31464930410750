import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
export default {
  name: "theSixth",
  data: () => ({
    academic_products: null,
    academic_products_state: null,
    readonly: true,
    profileObjSaved: {}
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.academic_products = this.profileObj.academic_products;
    this.profileObjSaved.academic_products = this.profileObj.academic_products;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData(key) {
      const value = this[key];
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"])
  }
};