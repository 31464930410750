import { fetchAPI } from "@/helpers/fetch";
import { getSortParamDeclaration } from "@/helpers/declaration";
import { wrapCommitDispatch } from "@/helpers/global";
import mutations from "@/store/mutations";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";

const {
  DECLARATIONS,
  DECLARATIONS_COUNT,
  DECLARATIONS_LOADING,
  DECLARATION_EVENTS,
  DECLARATION_EVENTS_COUNT,
  DECLARATION_EVENTS_LOADING,
  DECLARATIONS_PAGE,
} = mutations;

const declarationsStore = {
  namespaced: true,
  state: {
    declarationsObj: [],
    declarationsCount: 0,
    declarationsLoading: false,
    declarationEvents: [],
    declarationEventsCount: 0,
    declarationEventsLoading: false,
    declarationsPage: 1,
  },
  getters: {
    declarationsObj: ({ declarationsObj }) => declarationsObj,
    declarationsCount: ({ declarationsCount }) => declarationsCount,
    declarationsLoading: ({ declarationsLoading }) => declarationsLoading,
    declarationsPage: ({ declarationsPage }) => declarationsPage,
    declarationEvents: ({ declarationEvents }) => declarationEvents,
    declarationEventsCount: ({ declarationEventsCount }) =>
      declarationEventsCount,
    declarationEventsLoading: ({ declarationEventsLoading }) =>
      declarationEventsLoading,
  },
  mutations: {
    [DECLARATIONS](state, value) {
      state.declarationsObj = value;
    },
    [DECLARATIONS_COUNT](state, value) {
      state.declarationsCount = Number(value);
    },
    [DECLARATIONS_LOADING](state, value) {
      state.declarationsLoading = value;
    },
    [DECLARATION_EVENTS](state, value) {
      state.declarationEvents = value;
    },
    [DECLARATION_EVENTS_COUNT](state, value) {
      state.declarationEventsCount = Number(value);
    },
    [DECLARATION_EVENTS_LOADING](state, value) {
      state.declarationEventsLoading = value;
    },
    [DECLARATIONS_PAGE](state, value) {
      state.declarationsPage = value;
    },
  },
  actions: {
    // запрос декларация для ЛК
    async fetchDeclarations4User(
      context,
      { sortBy, sortDesc, page, filters } = {
        sortBy: null,
        sortDesc: true,
        page: 1,
        filters: {},
      }
    ) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          const sortParam = getSortParamDeclaration(sortBy, sortDesc);
          const requestParams = { sort: sortParam, page: page };
          // добавим фильтры в параметры
          Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) requestParams[key] = filters[key]; // что-то есть в фильтре
          });
          const response = await fetchAPI("/my-declarations/", requestParams);
          context.commit(DECLARATIONS_COUNT, response.count);
          context.commit(DECLARATIONS, response.results);
        },
        DECLARATIONS_LOADING
      );
    },

    // запрос деклараций
    async fetchDeclarations(
      context,
      { sortBy, sortDesc, page, filters } = {
        sortBy: null,
        sortDesc: true,
        page: 1,
        filters: {},
      }
    ) {
      await wrapCommitDispatch(
        context,
        async () => {
          const sortParam = getSortParamDeclaration(sortBy, sortDesc);
          const requestParams = { sort: sortParam, page: page };
          // добавим фильтры в параметры
          Object.keys(filters).forEach((key) => {
            if (filters[key] !== null) requestParams[key] = filters[key]; // что-то есть в фильтре
          });
          const response = await fetchAPI("/declarations/", requestParams);
          context.commit(DECLARATIONS_COUNT, response.count);
          context.commit(DECLARATIONS, response.results);
        },
        DECLARATIONS_LOADING
      );
    },

    // запрос Истории
    async updateEvents(context, requestParams) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/coid-events/", requestParams);
          context.commit(DECLARATION_EVENTS_COUNT, response.count);
          context.commit(DECLARATION_EVENTS, response.results);
        },
        DECLARATION_EVENTS_LOADING
      );
    },

    setDeclarationsPage({ commit }, page) {
      commit(DECLARATIONS_PAGE, page);
    },

    // копирование декларации
    async cloneDeclaration(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/my-declarations/${id}/clone/`, null, "POST");
          return `Декларация ${id} скопирована`;
        },
        DECLARATIONS_LOADING
      );
    },
  },
};

export default declarationsStore;
