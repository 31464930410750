import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select_option_group = _resolveComponent("b-form-select-option-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "14. Владеете ли вы оружием (гражданским (оружие самообороны, спортивное\n            оружие, охотничье оружие, сигнальное оружие, холодное клинковое\n            оружие, оружие, используемое в культурных и образовательных целях),\n            служебным, боевым ручным стрелковым, холодным)?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_row, null, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "6"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              class: "w-auto",
              modelValue: _ctx.profileObj.has_weapons,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_weapons = $event),
              disabled: $options.readonly,
              state: _ctx.has_weapons_state,
              options: _ctx.optionYesNo,
              onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_weapons'))
            }, null, 8, ["modelValue", "disabled", "state", "options"])]),
            _: 1
          }), _withDirectives(_createVNode(_component_b_col, {
            cols: "6"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.profileObj.weapons,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.profileObj.weapons = $event),
              multiple: "",
              "select-size": 10,
              class: "mb-3",
              disabled: $options.readonly,
              onChange: _cache[3] || (_cache[3] = $event => $options.submitData('weapons'))
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_select_option_group, {
                label: "Гражданским"
              }, {
                default: _withCtx(() => [_createVNode(_component_b_form_select_option, {
                  value: "оружие самообороны"
                }, {
                  default: _withCtx(() => [_createTextVNode("оружие самообороны")]),
                  _: 1
                }), _createVNode(_component_b_form_select_option, {
                  value: "спортивное оружие"
                }, {
                  default: _withCtx(() => [_createTextVNode("спортивное оружие")]),
                  _: 1
                }), _createVNode(_component_b_form_select_option, {
                  value: "охотничье оружие"
                }, {
                  default: _withCtx(() => [_createTextVNode("охотничье оружие")]),
                  _: 1
                }), _createVNode(_component_b_form_select_option, {
                  value: "сигнальное оружие"
                }, {
                  default: _withCtx(() => [_createTextVNode("сигнальное оружие")]),
                  _: 1
                }), _createVNode(_component_b_form_select_option, {
                  value: "холодное клинковое оружие"
                }, {
                  default: _withCtx(() => [_createTextVNode("холодное клинковое оружие")]),
                  _: 1
                }), _createVNode(_component_b_form_select_option, {
                  value: 'оружие, используемое в культурных и образовательных целях'
                }, {
                  default: _withCtx(() => [_createTextVNode("оружие, используемое в культурных и образовательных целях")]),
                  _: 1
                })]),
                _: 1
              }), _createVNode(_component_b_form_select_option, {
                value: "служебное"
              }, {
                default: _withCtx(() => [_createTextVNode("Служебным")]),
                _: 1
              }), _createVNode(_component_b_form_select_option, {
                value: "боевое ручное стрелковое"
              }, {
                default: _withCtx(() => [_createTextVNode("Боевым ручным стрелковым")]),
                _: 1
              }), _createVNode(_component_b_form_select_option, {
                value: "холодное"
              }, {
                default: _withCtx(() => [_createTextVNode("Холодным")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue", "disabled"])]),
            _: 1
          }, 512), [[_vShow, _ctx.profileObj.has_weapons]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}