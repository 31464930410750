import links from "@/router/links";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapCheckToText } from "@/helpers/profile";
import { formatName, limitBound, fmtNameShort } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import ProfileListAction from "./ProfileListAction.vue";
export default {
  name: "ProfileChecksList",
  data: () => ({
    links,
    fmtNameShort,
    mapCheckToText,
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    columns: [{
      key: "id",
      label: "п/п"
    }, {
      key: "profile",
      label: "#"
    }, {
      key: "author",
      label: "Сотрудник",
      class: "text-nowrap"
    }, {
      key: "profile_created_at",
      label: "Дата создания лл",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "profile_submitted_at",
      label: "Дата принятия не работу",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "state",
      label: "Статус",
      class: "text-nowrap",
      formatter: value => mapCheckToText[value]
    }, {
      key: "action",
      class: "text-center",
      label: "Действие"
    }, {
      key: "glass",
      class: "text-center",
      label: "Просмотр"
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    ProfileListAction
  },
  created() {
    this.getProfileChecksList({
      page: 1
    });
  },
  methods: {
    ...mapActions("profileChecks", ["getProfileChecksList", "transitionProfileCheck"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.profileChecksCount / this.perPage, this.currentPage);
      this.getProfileChecksList({
        page: this.currentPage
      });
    },
    async completeCheck(id) {
      await this.transitionProfileCheck({
        id: id,
        body: {
          state: "completed"
        }
      });
      this.getProfileChecksList({
        page: 1
      });
    }
  },
  computed: {
    ...mapGetters("profileChecks", ["profileChecksList", "profileChecksCount", "profileChecksLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.getProfileChecksList({
          page: value
        });
      }
    }
  }
};