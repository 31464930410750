import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_button, {
    variant: "primary",
    onClick: $options.clickAdd
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.setting.btn), 1)]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModal = $event),
    id: $props.setting.modalId,
    title: $props.setting.title,
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Применить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_group, {
      label: "Наименование"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.modalTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.modalTitle = $event),
        placeholder: "Укажите название",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Видимость в рамках организации"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.modalOrg,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.modalOrg = $event),
        switch: ""
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.modalOrg ? "Все работники организации" : "Ограничено"), 1)]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), !_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Видимость по подразделениям"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox_group, {
        modelValue: _ctx.modalDepart,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.modalDepart = $event),
        stacked: "",
        options: $options.optionsDepart
      }, null, 8, ["modelValue", "options"])]),
      _: 1
    })) : (_openBlock(), _createBlock(_component_b_spinner, {
      key: 1,
      class: "text-center text-primary my-2 align-middle"
    }))]),
    _: 1
  }, 8, ["modelValue", "id", "title", "onOk", "ok-disabled"])]);
}