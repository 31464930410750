import ActivityListDetails from "./ActivityListDetails.vue";
import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
export default {
  name: "ActivityList",
  components: {
    ActivityListDetails
  },
  data: () => ({
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "source",
      label: "Источник",
      formatter: value => {
        return "Риск #" + value;
      }
    }, {
      key: "recommendation",
      label: "Рекомендации"
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "executor",
      label: "Исполнитель",
      formatter: value => {
        return formatName(value, fmtNameShort);
      },
      class: "text-nowrap px-3"
    }, {
      key: "dateAssigned",
      label: "Назначено",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      },
      class: "text-nowrap px-3"
    }, {
      key: "datePlan",
      label: "Запланировано",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      },
      class: "text-nowrap px-3"
    }, {
      key: "dateCarry",
      label: "Проведено",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      },
      class: "text-nowrap px-3"
    }]
  }),
  methods: {
    ...mapActions("risk", ["updateActivity", "addActivityDetails", "setActivityPage"]),
    addDetails(data) {
      this.addActivityDetails(data);
    },
    changePage() {
      this.setActivityPage(limitBound(Number(this.gotoPage), 1, this.activityCount / this.perPage, this.activityPage));
    }
  },
  computed: {
    ...mapGetters("risk", ["activity", "activityCount", "activityPage"]),
    currentPageModel: {
      get() {
        return this.activityPage;
      },
      set(value) {
        this.setActivityPage(value);
        this.updateActivity();
      }
    }
  }
};