export const adminStatisticsChart = {
  type: "bar",
  data: {
    labels: [],
    datasets: [
      {
        label: "Поступило",
        data: [],
        backgroundColor: "rgba(11,106,198, .24)",
        borderColor: "rgba(11,106,198, 1)",
        // borderWidth: { top: 4 },
        order: 1,
        barPercentage: 1,
        // categoryPercentage: 0.9,
        datalabels: {
          display: "auto",
          align: "end",
          anchor: "end",
          clamp: true,
          color: "rgba(11,106,198, 1)",
          font: {
            weight: "bold",
          },
          formatter: function (value) {
            return value || null;
          },
        },
      },
      {
        label: "Рассмотрено",
        data: [],
        backgroundColor: "rgba(54,181,86, 0)",
        borderColor: "rgba(54,181,86, 1)",
        borderWidth: { right: 4, top: 0, bottom: 0, left: 0 },
        order: 2,
        barPercentage: 1,
        // categoryPercentage: 0.9,
        datalabels: {
          display: "auto",
          align: "end",
          anchor: "end",
          clamp: true,
          color: "rgba(54,181,86, 1)",
          font: {
            weight: "bold",
          },
          formatter: function (value) {
            return value || null;
          },
        },
      },
    ],
  },
  options: {
    responsive: true,
    indexAxis: "y",
    aspectRatio: 3,
    devicePixelRatio: 4,
    legend: {
      display: false,
      align: "start",
      reverse: true,
      labels: {
        // pointStyle: 'rectRounded',
        pointStyle: "square",
        usePointStyle: true,
        padding: 16,
      },
    },
    scales: {
      x: {
        gridLines: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3,
        },
      },
      y: {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        right: 50,
        bottom: -20,
      },
    },
    tooltips: {
      displayColors: false,
    },
  },
};
