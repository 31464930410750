import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound } from "@/helpers/text";
export default {
  name: "AttachmentsList",
  props: {
    declId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "file_name",
      label: "Файл"
    }, {
      key: "created_at",
      label: "Загружен",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "download",
      label: "Скачать"
    }]
  }),
  methods: {
    ...mapActions("declarationForm", ["updateDeclarationFormAttachments"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.declarationFormAttachmentsCount / this.perPage, this.currentPage);
      this.updateDeclarationFormAttachments({
        id: this.declId,
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("declarationForm", ["declarationFormAttachments", "declarationFormAttachmentsCount", "declarationFormAttachmentsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateDeclarationFormAttachments({
          id: this.declId,
          page: value
        });
      }
    }
  }
};