import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./app.scss";
import FontAwesomeIcon from "./plugins/fontAwesomeIcon";
import { handlerPerm, updatePerm } from "./auth/auth.js";
import perm from "./auth/permissions.js";
import { ff } from "./helpers/global.js";
import BootstrapVueNext from "bootstrap-vue-next";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue3-markdown/dist/style.css";
import { createApp } from "vue";
const app = createApp(App);

//app.config.productionTip = false;
app.use(BootstrapVueNext);
app.component("font-awesome-icon", FontAwesomeIcon);

// инициализация прав пользователя
app.config.globalProperties.$perm = perm;
updatePerm();
// регистрация custom директивы для ролевой модели
app.directive("can", (el, binding) => {
  handlerPerm(el, binding);
});
// Feature Flags - механизм отключения отдельных функций приложения
app.config.globalProperties.$ff = ff;

app.use(router);
app.use(store);
app.mount("#app");
