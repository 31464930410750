import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound } from "@/helpers/text.js";
export default {
  name: "AdminCpTypes",
  data: () => ({
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [{
      key: "id",
      label: "#"
    }, {
      key: "cp_name",
      label: "Название"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Добавить КП",
      btnTitle: "Добавить КП",
      buttonIcon: false,
      iconType: null,
      texts: {
        cp_name: {
          required: true,
          label: "Название"
        }
      }
    },
    settingsChange: {
      title: "Редактировать КП",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        cp_name: {
          required: true,
          label: "Название"
        }
      }
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminElementAdd
  },
  created() {
    this.updateAdminCpTypes({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminCpTypes", "createAdminCpTypes", "changeAdminCpTypes"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminCpTypesCount / this.perPage, this.currentPage);
      this.updateAdminCpTypes({
        page: this.currentPage
      });
    },
    async clickCreateCp(data) {
      await this.createAdminCpTypes(data);
      this.currentPage = 1;
      await this.updateAdminCpTypes({
        page: 1
      });
    },
    async clickChange(data) {
      await this.changeAdminCpTypes(data);
      await this.updateAdminCpTypes({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCpTypes", "adminCpTypesLoading", "adminCpTypesCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminCpTypes({
          page: value
        });
      }
    }
  }
};