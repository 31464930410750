import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import SignPEP from "@/components/Helpers/SignPEP.vue";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { signTypes, answerType } from "@/helpers/text";
import { statesTestUser } from "@/helpers/states";
export default {
  name: "EducationCourseTest",
  components: {
    BreadCrumbToHome,
    theNotification,
    SignPEP
  },
  props: {
    msg: String
  },
  //сюда получаем JSON.stringify({ assignment, attempt })

  data: () => ({
    showModal: false,
    signTypes,
    answerType,
    statesTestUser,
    currentTests: 0,
    assignment: undefined,
    attempt: undefined,
    singlechoice: undefined
  }),
  async created() {
    const propsParce = JSON.parse(this.msg);
    this.assignment = propsParce.assignment;
    this.attempt = propsParce.attempt;
    await this.updateCourse4User(this.assignment);
    await this.updateEducationTest(this.attempt);
    await this.updateCurrentQuest();
  },
  methods: {
    ...mapActions("education", ["updateCourse4User", "updateEducationTest", "updateEducationTestQuest", "changeEducationTestQuest"]),
    async changeData(key) {
      const params = {};
      if (key === "singlechoice") params.answer_ids = [this.singlechoice];else params[key] = this.educationTestQuest[key];
      await this.changeEducationTestQuest({
        attempt: this.attempt,
        question: this.educationTest.response_ids[this.currentTests],
        params
      });
    },
    onClickSend() {
      this.showModal = true;
    },
    doSend() {
      this.$refs.btnPEP.handlerClick();
    },
    async updateCurrentQuest() {
      this.singlechoice = undefined;
      if (this.educationTest.response_ids.length > this.currentTests && this.currentTests >= 0) {
        await this.updateEducationTestQuest({
          attempt: this.attempt,
          question: this.educationTest.response_ids[this.currentTests]
        });
        const answers = this.educationTestQuest.answer_ids;
        this.singlechoice = Array.isArray(answers) && answers > 0 ? answers[0] : undefined;
      }
    },
    async updateTest(step) {
      this.currentTests = this.currentTests + step;
      this.updateCurrentQuest();
    }
  },
  computed: {
    ...mapGetters("education", ["getCourse", "courseLoading", "educationTest", "educationTestLoading", "educationTestQuest", "educationTestQuestLoading"]),
    disabledPrevious() {
      return this.currentTests === 0;
    },
    disabledNext() {
      return this.currentTests === this.educationTest.response_ids.length - 1;
    },
    breadcrumbs() {
      return [{
        text: "Личный кабинет",
        to: {
          name: "ProfileEducation"
        }
      }, {
        text: "Программа обучения",
        to: links.educationCourse + this.assignment
      }];
    },
    readOnly() {
      return this.educationTestQuestLoading || statesTestUser[this.educationTest.state] !== statesTestUser.started;
    }
  }
};