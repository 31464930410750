import { FIO } from "@/helpers/text";
import { formatDate, fmtDateShort } from "@/helpers/date";
export default {
  name: "ReportTimingControlDetails",
  //______________________
  props: {
    item: {
      type: Array,
      required: true
    }
  },
  //______________________
  data: () => ({
    tableFields: [{
      key: "author",
      //"ФИО работника",
      class: "text-nowrap",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "position"
      //"Должность работника",
    }, {
      key: "worker_in_charge",
      //"Ответственный за рассмотрение",
      formatter: value => {
        return FIO(value);
      }
    }, {
      key: "assigned_at",
      //"Дата отправки на рассмотрение",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "deadline",
      //"Контрольная дата рассмотрения",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }, {
      key: "completed_at",
      //"Дата завершения рассмотрения",
      formatter: value => {
        return formatDate(value, fmtDateShort);
      }
    }]
  })
};