import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import { states } from "@/helpers/states";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, optionsDesc, formatName, fmtNameShort } from "@/helpers/text";
import COISidebar from "./COISidebar";
import links from "@/router/links";
export default {
  name: "COI",
  components: {
    COISidebar
  },
  data: () => ({
    showSidebar: false,
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    sortBy: null,
    sortDesc: true,
    optionsDesc,
    filters: {
      //кодировка полей snake_case (для соответствия полей бэку)
      declaration: null,
      state: null,
      state_modified_lte: null,
      //До указанной даты изменения состояния включительно
      state_modified_gte: null,
      //Начиная с указанной даты изменения состояния
      user_search: null,
      coi_type: null,
      assignee_search: null //Поиск по ФИО ответственного
    },

    optionsSort: [{
      value: "user__last_name,user__first_name,user__middle_name",
      text: "по сотруднику"
    }, {
      value: "coi_type",
      text: "по типу конфликта"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "assignee__last_name",
      text: "по ответственному"
    }],
    coiFields: [{
      key: "id",
      label: "#"
    }, {
      key: "source",
      label: "Источник"
    }, {
      key: "user_detailT",
      label: "Сотрудник",
      class: "text-nowrap"
    }, {
      key: "coi_type_name",
      label: "Тип конфликта"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "state_modified_atT",
      label: "Изменено"
    }, {
      key: "assignee_detailT",
      label: "Ответственный",
      class: "text-nowrap"
    }]
  }),
  methods: {
    ...mapActions("coi", ["fetchCoi", "createCOIForm"]),
    // обработка изменения сортировки
    sortChanged({
      sortBy,
      sortDesc
    }) {
      this.currentPage = 1;
      this.fetchCoi({
        sortBy,
        sortDesc: sortDesc ? "-" : "",
        filters: this.filters,
        page: 1
      });
    },
    // применение фильтров по нажатии кнопки "Применить"
    applyFilters(filters) {
      this.filters.state = filters.state;
      this.filters.state_modified_gte = formatDateObj(filters.dateFrom, fmtDate, null);
      this.filters.state_modified_lte = formatDateObj(filters.dateUntill, fmtDate, null);
      this.filters.assignee_search = filters.assignee;
      this.filters.decl_id = filters.number;
      this.filters.user_search = filters.author;
      this.filters.coi_type = filters.coi;
      if (this.currentPage === 1) this.fetchCoi({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: 1
      });else this.currentPage = 1;
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.coiCount / this.perPage, this.currentPage);
      this.fetchCoi({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: this.currentPage
      });
    },
    async clickCreateCoi() {
      await this.createCOIForm();
      if (this.coiForm.id) this.$router.push(links.coiForm + this.coiForm.id);
    }
  },
  computed: {
    ...mapGetters("coi", ["coi", "coiCount", "coiLoading", "coiForm"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.fetchCoi({
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: this.filters,
          page: value
        });
      }
    },
    items4table() {
      return Array.isArray(this.coi) ? this.coi.map(el => ({
        ...el,
        user_detailT: formatName(el.user_detail, fmtNameShort),
        stateT: states[el.state],
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        assignee_detailT: formatName(el.assignee_detail, fmtNameShort)
      })) : [];
    }
  }
};