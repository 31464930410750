import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import SectionAdd from "./SectionAdd.vue";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import links from "@/router/links";
import { limitBound } from "@/helpers/text";
export default {
  name: "KnowledgeArea",
  components: {
    BreadCrumbToHome,
    theNotification,
    SectionAdd
  },
  data: () => ({
    gotoPage: 1,
    currentPage: 1,
    perPage: 10,
    setting: {
      btn: "Добавить раздел",
      title: "Добавить раздел",
      modalId: "modalAddSection"
    }
  }),
  created() {
    this.updateSections({
      page: 1
    });
    if (!this.isAltUI) this.updateDepartments();
  },
  methods: {
    ...mapActions("knowledge", ["updateSections", "createSections"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    onMouseClick(item) {
      if (this.isAltUI) this.$router.push(links.knowledgeListAlt + item.id);else this.$router.push(links.knowledgeList + item.id);
    },
    async addSection(data) {
      await this.createSections(data);
      await this.updateSections({
        page: this.currentPage
      });
    },
    async changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.sectionsCount / this.perPage, this.currentPage);
      await this.updateSections({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("knowledge", ["sections", "sectionsLoading", "sectionsCount"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      async set(value) {
        this.currentPage = value;
        await this.updateSections({
          page: value
        });
      }
    },
    isAltUI() {
      return this.$route.path === links.knowledgeAlt;
    }
  }
};