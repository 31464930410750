/* eslint-disable no-useless-escape */
// есть поля ввода
// Дата
// Текст (только русские)?
// Текст русские, английские, цифры, кавычки?
// Цифры (длина 10 и 12)
// Справочники (списки)
// Переключатели 3.1, 4.1 оветы по декларации

const regExpDic = {
  email:
    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/,
  ///^[0-9a-zA-Z]{8,}$/,

  //   Minimum eight characters, at least one letter and one number:
  // "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
  // Minimum eight characters, at least one letter, one number and one special character:
  // "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
  // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
  // Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
  phone: /^[0-9]{11}$/,
  inn: /^(\d{10}|\d{12})$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
  ogrnip: /^[0-9]{15}$/,
  snils: /^[0-9]{11}$/,
  passport_issuer_code: /^[0-9]{6}$/,
  domain: /^[a-zA-Z0-9][a-zA-Z0-9-.]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
  rusText: /^[?!,.а-яА-ЯёЁ0-9\s]+$/,
};

/**
 * Function validate. Check Input on RegExp provided in regExpDic by input data-required type
 * @param {Object} el
 * @returns {Boolean} - Return true if input valid or (у него не будет атрубиута data-required - т.е. валиден при любом раскладе) doesn't has data-required attr
 * иначе возврат false
 */
export function validate(el) {
  const regExpName = el["dataRequired"];
  if (!regExpDic[regExpName]) return true; //валиден при любом раскладе, для него нет регулярного выражения

  return regExpDic[regExpName].test(el["data"]);
}

import { fmtDate, formatDate } from "@/helpers/date";
/**
 * function validateSendDeclaration проверяет корректность заполения II части
 * декларации перед отправкой ее в БД
 * @param {Object} partII II часть декларации
 * @returns {Boolean} корректно заполнена или нет часть II
 */
export function validateSendDeclaration(partII, declObj) {
  let msg = ""; // сообщение для вывода на экран
  const invalidDate = [];
  const invalidValue = [];
  const noCheck = ["14"];
  for (let key in partII) {
    // эти вопросы не проверяем
    if (noCheck.includes(key)) continue;
    const switchSection = partII[key].switchSection;
    const bottomSectionRender = partII[key].bottomSectionRender;
    if (switchSection) {
      // полей пункта 1 «Дата начала работы» не позднее даты внесения сведений, не ранее числа, месяца и года рождения,
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 1: Наименование юридического лица и/или индивидуального предпринимателя, в котором выполняется работа по совместительству,
      // осуществляется деятельность на основании гражданско-правового договора; ИНН юридического лица и/ или индивидуального предпринимателя
      if (partII[key].switchSectionId === "1")
        bottomSectionRender.forEach((bsr, ind) => {
          const invalid = invalidMinMaxDate(
            bsr?.["4"]?.data,
            declObj?.profile_detail?.birth_date,
            formatDate(new Date(), fmtDate),
            `пункт 1(${
              ind + 1
            }): «Дата начала работы» должна быть не позднее даты внесения сведений и не ранее числа, месяца и года рождения`
          );
          if (invalid) invalidDate.push(invalid);
          // запрет на использование латинских букв и символов при заполнении полей
          if (!validate({ data: bsr?.[0]?.data, dataRequired: "rusText" }))
            invalidValue.push(`пункт 1(${ind + 1}): ${bsr[0].name}`);
          if (!validate({ data: bsr?.[1]?.data, dataRequired: "rusText" }))
            invalidValue.push(`пункт 1(${ind + 1}): ${bsr[1].name}`);
        });
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 3: наименование юридического лица, ИНН юридического лица
      if (partII[key].switchSectionId === "3")
        bottomSectionRender.forEach((bsr, ind) => {
          if (!validate({ data: bsr?.[1]?.data, dataRequired: "rusText" }))
            invalidValue.push(`пункт 3(${ind + 1}): ${bsr[1].name}`);
          if (!validate({ data: bsr?.[2]?.data, dataRequired: "rusText" }))
            invalidValue.push(`пункт 3(${ind + 1}): ${bsr[2].name}`);
        });
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 4: фамилия, имя, отчество, наименование юридического лица, ИНН юридического лица
      if (partII[key].switchSectionId === "4")
        bottomSectionRender.forEach((bsr, ind) => {
          [2, 3, 4, 5, 6].forEach((k) => {
            if (!validate({ data: bsr?.[k]?.data, dataRequired: "rusText" }))
              invalidValue.push(`пункт 4(${ind + 1}): ${bsr[k].name}`);
          });
        });
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 5: фамилия, имя, отчество
      if (partII[key].switchSectionId === "5")
        bottomSectionRender.forEach((bsr, ind) => {
          [1, 2, 3].forEach((k) => {
            if (!validate({ data: bsr?.[k]?.data, dataRequired: "rusText" }))
              invalidValue.push(`пункт 5(${ind + 1}): ${bsr[k].name}`);
          });
        });
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 6: фамилия, имя, отчество, наименование юридического лица, занимаемая должность
      if (partII[key].switchSectionId === "6")
        bottomSectionRender.forEach((bsr, ind) => {
          [1, 2, 3, 4, 5, 6].forEach((k) => {
            if (!validate({ data: bsr?.[k]?.data, dataRequired: "rusText" }))
              invalidValue.push(`пункт 6(${ind + 1}): ${bsr[k].name}`);
          });
        });
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 7: фамилия, имя, отчество, наименование юридического лица, ИНН юридического лица, занимаемая должность
      if (partII[key].switchSectionId === "7")
        bottomSectionRender.forEach((bsr, ind) => {
          [1, 2, 3, 4, 5, 7].forEach((k) => {
            if (!validate({ data: bsr?.[k]?.data, dataRequired: "rusText" }))
              invalidValue.push(`пункт 7(${ind + 1}): ${bsr[k].name}`);
          });
        });
      // полей пункта 8 «Дата начала службы/работы» не позднее данных полей «Дата окончания службы/работы»,
      // «Дата окончания службы/работы» не позднее даты внесения сведений, не ранее числа, месяца и года рождения;
      // запрет на использование латинских букв и символов при заполнении полей
      // пункт 8: вид службы/деятельности, место работы/службы, должность
      if (partII[key].switchSectionId === "8")
        bottomSectionRender.forEach((bsr, ind) => {
          const invalid1 = invalidMinMaxDate(
            bsr?.["3"]?.data,
            declObj?.profile_detail?.birth_date,
            bsr?.["4"]?.data,
            `пункт 8(${
              ind + 1
            }) «Дата начала службы/работы» должна быть не позднее «Даты окончания службы/работы» и не ранее числа, месяца и года рождения`
          );
          if (invalid1) invalidDate.push(invalid1);
          const invalid2 = invalidMinMaxDate(
            bsr?.["4"]?.data,
            declObj?.profile_detail?.birth_date,
            formatDate(new Date(), fmtDate),
            `пункт 8(${
              ind + 1
            }) «Дата окончания службы/работы» должна быть не позднее даты внесения сведений и не ранее числа, месяца и года рождения`
          );
          if (invalid2) invalidDate.push(invalid2);
          // запрет на использование латинских букв и символов при заполнении полей
          [0, 1, 2].forEach((k) => {
            if (!validate({ data: bsr?.[k]?.data, dataRequired: "rusText" }))
              invalidValue.push(`пункт 8(${ind + 1}): ${bsr[k].name}`);
          });
        });

      let answerFalse = true; // пойдем от противного - ответ не корректен
      for (let element of bottomSectionRender) {
        // внутри объекты - ответы на вопросы
        if (!answerFalse) break;
        for (let i in element)
          if (element[i].data) {
            answerFalse = false; // есть ответ! дальше не идем - выход из цикла
            break;
          }
      }
      if (answerFalse) msg += key + ", ";
    }
  }
  const res = [];
  if (invalidDate.length > 0)
    res.push(`Некорректно заполнены даты:\n${invalidDate.join("\n")}`);
  if (invalidValue.length > 0)
    res.push(`Некорректно заполнены поля:\n${invalidValue.join("\n")}`);
  if (msg) res.push("Не заполены ответы на вопросы: " + msg.slice(0, -2));
  return res.join("\n");
}

/**
 * функция проверяет исследуемый объект на заполненность полей по шаблону
 * @param {Object} obj исследуемый объект (например сообщение горячей линии)
 * @param {Object} required шаблон объека с обязательными полями
 * @returns {Array} массив полей, которые не заполнены
 */
export function validateObject(obj, required, result = []) {
  Object.keys(required).forEach((key) => {
    const value = obj[key];
    if (value === null || value === undefined || String(value).trim() === "")
      result.push(required[key]);
  });
  return result;
}

// задача 489 Валидация дат
export function invalidMinMaxDate(
  value,
  minValue = null,
  maxValue = null,
  msg = "error"
) {
  if (
    !value ||
    (minValue && minValue > value) ||
    (maxValue && maxValue < value)
  )
    return msg;
  return false;
}
