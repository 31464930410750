import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { checkPermissions } from "@/auth/auth";
import { wrapCommitDispatch } from "@/helpers/global";
import perm from "@/auth/permissions";

const { PROFILE_CHECKS, PROFILE_CHECKS_COUNT, PROFILE_CHECKS_LOADING } =
  mutations;

const profileChecksStore = {
  namespaced: true,
  state: {
    profileChecksCount: 0,
    profileChecksList: [],
    profileChecksLoading: false,
  },
  getters: {
    profileChecksCount: ({ profileChecksCount }) => profileChecksCount,
    profileChecksList: ({ profileChecksList }) => profileChecksList,
    profileChecksLoading: ({ profileChecksLoading }) => profileChecksLoading,
  },
  mutations: {
    [PROFILE_CHECKS](state, value) {
      state.profileChecksList = value;
    },
    [PROFILE_CHECKS_COUNT](state, value) {
      state.profileChecksCount = value;
    },
    [PROFILE_CHECKS_LOADING](state, value) {
      state.profileChecksLoading = value;
    },
  },
  actions: {
    // запрос проверок личных листков
    async getProfileChecksList(context, data) {
      if (!checkPermissions(perm.view_wa_profilecheck)) return;
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/profile-checks/", {
            page: data.page,
          });
          context.commit(PROFILE_CHECKS, response.results);
          context.commit(PROFILE_CHECKS_COUNT, response.count);
        },
        PROFILE_CHECKS_LOADING
      );
    },
    // перевод состояния проверки
    async transitionProfileCheck(context, params) {
      if (!checkPermissions(perm.complete_wa_profilecheck)) return;
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/profile-checks/${params.id}/transition/`,
            null,
            "PATCH",
            params.body
          );
          return "Проверка подтверждена";
        },
        PROFILE_CHECKS_LOADING
      );
    },
  },
};

export default profileChecksStore;
