import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import Chart from "chart.js/auto";
import { formatDate, monthsOptions, fmtDate } from "@/helpers/date";
import { mapGetters, mapActions } from "vuex";
import { adminStatisticsChart } from "@/helpers/chart";
import { limitBound, typeRiskLevel } from "@/helpers/text";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
export default {
  name: "AdminStatistics",
  components: {
    BreadCrumbToHome,
    theNotification,
    TrafficLight
  },
  chart4dept: null,
  data: () => ({
    startDate: null,
    endDate: null,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    currentChart: {},
    types4chart: [{
      text: "Личных листков",
      active: true,
      num: 128,
      last: 12,
      moreless: true,
      id: "profiles",
      received: "поступивших",
      reviewed: "рассмотренных",
      endText: "личных листков"
    }, {
      text: "Сообщений на горячую линию",
      active: false,
      num: 213,
      last: 22,
      moreless: true,
      id: "hotline",
      received: "поступивших на Горячую линию сообщений",
      reviewed: "рассмотренные сообщения",
      endText: "поступившие на Горячую линию"
    }, {
      text: "Анкет деловых партнёров",
      active: false,
      num: 12,
      last: 3,
      moreless: true,
      id: "business_partners",
      received: "поступивших",
      reviewed: "рассмотренных",
      endText: "анкет деловых партнёров"
    }, {
      text: "Внутренних проверок",
      active: false,
      num: 7,
      last: 1,
      moreless: false,
      id: "innerchecks",
      received: "назначенных",
      reviewed: "проведенных",
      endText: "внутренних проверок"
    }, {
      text: "Курсов обучения",
      active: false,
      num: 23,
      last: 3,
      moreless: true,
      id: "education",
      received: "назначенных",
      reviewed: "пройденных",
      endText: "курсов обучения"
    }, {
      text: "Рисков",
      active: false,
      num: 2,
      last: 1,
      moreless: false,
      id: "risks",
      received: "рисков среднего",
      reviewed: "высокого",
      endText: "уровней"
    }],
    periods: [{
      text: "месяц",
      step: 1,
      active: true
    }, {
      text: "квартал",
      step: 3,
      active: false
    }, {
      text: "полугодие",
      step: 6,
      active: false
    }, {
      text: "год",
      step: 12,
      active: false
    }],
    // риски
    sort: null,
    optionsSort: [{
      value: "org_short_name",
      text: "по ОМТ"
    }, {
      value: "risk_description",
      text: "по описанию риска"
    }],
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "org_short_name",
      label: "ОМТ"
    }, {
      key: "risk_description",
      label: "Описание риска"
    }, {
      key: "risk_level",
      label: "Уровень риска",
      class: "text-center",
      formatter: value => typeRiskLevel(value)
    }]
  }),
  created() {
    const now = new Date();
    this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    this.endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  },
  mounted() {
    const ctx = document.getElementById("RiskChart");
    this.chart4dept = new Chart(ctx, Object.assign({}, adminStatisticsChart));
  },
  methods: {
    ...mapActions("administration", ["updateAdminStatsDept", "updateAdminStatsRisk"]),
    clickBtn(arr, index, period = false) {
      arr.forEach((el, i) => {
        el.active = i === index;
      });
      if (period) {
        const count = this.periods[index].step;
        const year = this.startDate.getFullYear();
        this.startDate = new Date(year, 0, 1);
        this.endDate = new Date(year, count, 0);
      } else this.showChart();
    },
    setData(sign) {
      const count = this.periods.find(el => el.active).step;
      // start data
      this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + sign * count, 1);
      // end data
      this.endDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() + sign * count + 1, 0);
    },
    showChart() {
      this.currentChart = this.types4chart.find(el => el.active) || {};
      const key = this.currentChart.id;
      if (key === undefined || this.adminStatsDept[key] === undefined) return;
      this.chart4dept.data.labels = this.adminStatsDept[key].labels;
      this.chart4dept.data.datasets.forEach((el, index) => {
        Object.assign(this.chart4dept.data.datasets[index], this.adminStatsDept[key].datasets[index]);
      });
      this.chart4dept.update();
    },
    totalCount(index) {
      const key = this.types4chart[index].id;
      if (!this.adminStatsDept[key] || !this.adminStatsDept[key].datasets || !Array.isArray(this.adminStatsDept[key].datasets) || this.adminStatsDept[key].datasets.length < 0 || typeof this.adminStatsDept[key].datasets[0] !== "object" || !this.adminStatsDept[key].datasets[0].data || !Array.isArray(this.adminStatsDept[key].datasets[0].data)) return 0;
      return this.adminStatsDept[key].datasets[0].data.reduce((a, b) => a + b, 0);
    },
    async clickReport() {
      await this.updateAdminStatsRisk({
        page: this.currentPage,
        sort: this.sort,
        start_date: formatDate(this.startDate, fmtDate),
        end_date: formatDate(this.endDate, fmtDate)
      });
      await this.updateAdminStatsDept({
        start_date: formatDate(this.startDate, fmtDate),
        end_date: formatDate(this.endDate, fmtDate)
      });
      this.showChart();
    },
    // обработка изменения сортировки
    sortChanged({
      sortBy
    }) {
      this.updateAdminStatsRisk({
        page: this.currentPage,
        sort: sortBy,
        start_date: formatDate(this.startDate, fmtDate),
        end_date: formatDate(this.endDate, fmtDate)
      });
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminStatsRiskCount / this.perPage, this.currentPage);
      this.sortChanged();
    }
  },
  computed: {
    ...mapGetters("administration", ["adminStatsDept", "adminStatsDeptLoading", "adminStatsRisk", "adminStatsRiskCount", "adminStatsRiskLoading"]),
    periodText() {
      const count = this.periods.find(el => el.active).step;
      const month = this.startDate.getMonth();
      const year = this.startDate.getFullYear();
      let str = "";
      switch (count) {
        case 1:
          str = monthsOptions.find(el => el.value === month + 1).text;
          return `${str.charAt(0).toUpperCase()}${str.slice(1)} ${year}`;
        case 3:
          return `${Math.floor(month / 3 + 1)} квартал ${year}`;
        case 6:
          return `${Math.floor(month / 6 + 1)} полугодие ${year}`;
        case 12:
          return year;
        default:
          return formatDate(this.startDate, fmtDate);
      }
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminStatsRisk({
          page: value,
          sort: this.sort,
          start_date: formatDate(this.startDate, fmtDate),
          end_date: formatDate(this.endDate, fmtDate)
        });
      }
    }
  }
};