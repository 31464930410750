import "core-js/modules/es.array.push.js";
import { yearsGenerator } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { UNTIL_NOW, optionYesNo } from "@/helpers/text";
export default {
  name: "ElectionParticipation",
  data: () => ({
    optionYesNo,
    hasElectedtate: null,
    elections: [],
    endpoint: "elected-bodies",
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    template: {
      location: null,
      body_name: null,
      position: null,
      start_year: null,
      end_year: null,
      till_present: null
    },
    states: {
      location_state: null,
      body_name_state: null,
      position_state: null,
      start_year_state: null,
      end_year_state: null
    }
  }),
  created() {
    this.elections = this.profileObj.elected_bodies ? this.profileObj.elected_bodies.map(record => ({
      ...record,
      ...this.states
    })) : [];
    this.profileObjSaved.elections = this.profileObj.elected_bodies ? this.profileObj.elected_bodies.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData() {
      this.hasElectedtate = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_elected_bodies: this.profileObj.has_elected_bodies
      }).then(() => {
        setValueTimeout(this, "hasElectedtate");
      });
    },
    submitDataEndpoint(obj, key) {
      if (this.profileObjSaved.elections[obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const params = {
        [key]: obj[key]
      };
      if (key === "end_year" && obj[key] == UNTIL_NOW) {
        params.till_present = true;
        params.end_year = null;
        obj.till_present = true;
        obj.end_year = null;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.elections[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.elections[response.id] = {
          ...this.template
        };
        this.elections.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeDataEndpoint(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.elections.findIndex(el => el.id === id);
        delete this.profileObjSaved.elections[id];
        this.elections.splice(index, 1);
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    },
    yearsEnd() {
      return [UNTIL_NOW].concat(yearsGenerator({
        offset: 0,
        amount: 50
      }));
    },
    yearsStart() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    }
  }
};