import { createStore as _createStore } from "vuex";
import declarations from "./modules/declarations";
import declarationForm from "./modules/declaration-form";
import paUser from "./modules/pa-user";
import notification from "./modules/notification";
import education from "./modules/education";
import hotline from "./modules/hotline";
import coi from "./modules/coi";
import cp from "./modules/cp";
import profile from "./modules/profile";
import profileChecks from "./modules/profile-checks";
import profiles from "./modules/profiles";
import homeStats from "./modules/home-stats";
import declarationsReports from "./modules/declarations-reports";
import referenceBooks from "./modules/reference-books";
import tasks from "./modules/tasks";
import knowledge from "./modules/knowledge";
import partners from "./modules/partners";
import innerCheck from "./modules/inner-check";
import risk from "./modules/risk";
import administration from "./modules/administration";
import administration2 from "./modules/administration-2";

const store = _createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    declarations,
    declarationForm,
    paUser,
    notification,
    education,
    hotline,
    coi,
    cp,
    profiles,
    profile,
    profileChecks,
    homeStats, // статистика домашней страницы
    declarationsReports, // отчеты по декларациям
    referenceBooks, // справочники
    tasks, // задачи
    knowledge, // база знаний
    partners, // деловые партнеры
    innerCheck, // внутренние проверки
    risk, // риски
    administration, // администирование
    administration2, // а то 1й уже большой получился
  },
});

export default store;
