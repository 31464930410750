import { option4select } from "@/helpers/global";
import { sourceHotline, relevantHlText, fmtNameFull, typeMessageHotline } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import Calendar from "@/components/Helpers/Calendar.vue";
import { checkPermissions } from "@/auth/auth.js";
import { statesHotline } from "@/helpers/states";
import { formatDateObj, fmtDate, formatDate } from "@/helpers/date";
import { invalidMinMaxDate } from "@/helpers/validate";
export default {
  name: "HotlineFormInfo",
  components: {
    Calendar
  },
  data: () => ({
    fmtNameFull,
    sourceHotline,
    invalidArrivalDate: false,
    invalidDeadLine: false
  }),
  methods: {
    ...mapActions("hotline", ["changeHlineMsg"]),
    relevantHlText,
    async changeData(key, data) {
      this.invalidArrivalDate = false;
      this.invalidDeadLine = false;
      let value = this.hlineMsg[key];
      if (["arrival_date", "deadline"].includes(key)) {
        value = formatDateObj(data, fmtDate, null);
        if (!value) return;
        // «Дата поступления» не позднее данных поля «Срок исполнения»
        // «Срок исполнения» не ранее даты внесения сведений.
        if (key === "arrival_date") {
          this.invalidArrivalDate = invalidMinMaxDate(value, null, this.hlineMsg.deadline);
        }
        if (key === "deadline") {
          this.invalidArrivalDate = invalidMinMaxDate(this.hlineMsg.arrival_date, null, value);
          this.invalidDeadLine = invalidMinMaxDate(value, formatDate(new Date(), fmtDate), null);
        }
        if (this.invalidArrivalDate || this.invalidDeadLine) return;
      }
      await this.changeHlineMsg({
        id: this.hlineMsg.id,
        params: {
          [key]: value
        }
      });
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlineMsg"]),
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "hlCategory", "hlCategoryLoading"]),
    optionsOrganization() {
      return option4select(this.organizations, false);
    },
    optionsCategory() {
      return option4select(this.hlCategory, false);
    },
    categoryName() {
      return this.hlineMsg.message_category ? typeMessageHotline[this.hlineMsg.message_category] : "";
    },
    readOnly() {
      return statesHotline[this.hlineMsg.state] !== statesHotline.draft || !checkPermissions(this.$perm.change_wa_hotmsg);
    }
  }
};