// заполним шаблон для корректировки данных
export function setNameAndData(
  bottomSectionTemplate,
  bottomSection,
  bottomSectionRenderItem
) {
  for (let key in bottomSectionTemplate) {
    const indexName = bottomSectionTemplate[key].indexName;
    const itemData = bottomSectionRenderItem[key];
    bottomSectionTemplate[key].name = bottomSection[indexName];

    const data = itemData ? bottomSectionRenderItem[key]["data"] : null;
    const type = bottomSectionTemplate[key]["type"];
    switch (type) {
      case "checkbox":
        bottomSectionTemplate[key].data = Boolean(data);
        bottomSectionTemplate[key].valid = true; // для доступности кнопки "Сохранить"
        break;
      case "id":
        bottomSectionTemplate[key].data = data;
        bottomSectionTemplate[key].valid = true; // для доступности кнопки "Сохранить"
        break;
      default:
        bottomSectionTemplate[key].data = data;
        bottomSectionTemplate[key].valid = Boolean(data); // для доступности кнопки "Сохранить"
    }

    bottomSectionTemplate[key].state = null; // изначально поля не подсвечиваем
  }

  return bottomSectionTemplate;
}

// запишем текущие параметры
export function setCurrentData(itemIndex, sectionId, sectionData) {
  const bottomSectionTemplate = sectionId
    ? sectionData[sectionId]["bottomSectionRenderTemplate"]
    : {};
  const bottomSection = sectionId
    ? sectionData[sectionId]["bottomSection"]
    : {};

  const bottomSectionRenderItem = sectionId
    ? itemIndex >= 0
      ? sectionData[sectionId]["bottomSectionRender"][itemIndex]
      : {}
    : {};

  const bottomSectionRenderItemResult = setNameAndData(
    bottomSectionTemplate,
    bottomSection,
    bottomSectionRenderItem
  );

  return bottomSectionRenderItemResult;
}
