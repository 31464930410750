import { option4select } from "@/helpers/global";
import { fmtNameFull, selectAll } from "@/helpers/text";
import { statesKnowledge } from "@/helpers/states";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { dateObjInit } from "@/helpers/date";
import { mapGetters } from "vuex";
export default {
  name: "KnowledgeSidebar",
  components: {
    Calendar,
    UserSelector
  },
  data: () => ({
    fmtNameFull,
    assignee: null,
    showItem: true,
    filters: {
      owner_department_id: null,
      title_search: null,
      state: null,
      document_number_search: null,
      document_date_gte: {
        ...dateObjInit
      },
      document_date_lte: {
        ...dateObjInit
      },
      responsible_id: null
    }
  }),
  methods: {
    clearFilters() {
      this.showItem = false;
      this.assignee = null;
      Object.keys(this.filters).forEach(key => {
        if (["document_date_gte", "document_date_lte"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    },
    setFilter(key, data) {
      this.filters[key] = data.id;
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    sourceState() {
      return selectAll.concat(option4select(statesKnowledge));
    },
    optionsOwner() {
      return selectAll.concat(option4select(this.departments, false));
    }
  }
};