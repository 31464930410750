import Calendar from "@/components/Helpers/Calendar.vue";
import { statesCp } from "@/helpers/states";
import { dateObjInit, formatDateObj, fmtDate } from "@/helpers/date";
import { mapGetters } from "vuex";
import { getArrayOptionsFilter, getStateOptionsFilter } from "@/helpers/declaration";
export default {
  name: "CPSidebar",
  components: {
    Calendar
  },
  data: () => ({
    filters: {
      //кодировка полей snake_case (для соответствия полей бэку)
      state: null,
      state_modified_lte: null,
      //До указанной даты изменения состояния включительно
      state_modified_gte: null,
      //Начиная с указанной даты изменения состояния
      date_planned_lte: null,
      //До указанной даты включительно
      date_planned_gte: null,
      //Начиная с указанной плановой даты
      assignee_search: null,
      //Поиск по ФИО ответственного
      cp_type: null // Тип КП
    },

    state_modified_gte: {
      ...dateObjInit
    },
    state_modified_lte: {
      ...dateObjInit
    },
    date_planned_gte: {
      ...dateObjInit
    },
    date_planned_lte: {
      ...dateObjInit
    },
    dateKeys: ["state_modified_gte", "state_modified_lte", "date_planned_gte", "date_planned_lte"],
    showItem: true
  }),
  computed: {
    ...mapGetters("cp", ["cpDirectory"]),
    stateOptions() {
      return getStateOptionsFilter(statesCp);
    },
    cpOptions() {
      return getArrayOptionsFilter(this.cpDirectory);
    }
  },
  methods: {
    clearFilters() {
      this.showItem = false;
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.dateKeys.forEach(key => {
        this[key] = {
          ...dateObjInit
        };
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.dateKeys.forEach(key => {
        this.filters[key] = formatDateObj(this[key], fmtDate, null);
      });
      this.$emit("emitApplyFilters", this.filters);
    }
  }
};