import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-164f79e3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrap-task-button"
};
const _hoisted_2 = ["disabled"];
const _hoisted_3 = {
  key: 4,
  class: "text-muted"
};
const _hoisted_4 = {
  class: "mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_AttachmentByOne = _resolveComponent("AttachmentByOne");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("span", _hoisted_1, [$props.settings.buttonIcon ? (_openBlock(), _createElementBlock("i", {
    key: 0,
    class: _normalizeClass(["bi icon-wrap", 'bi-' + $props.settings.iconType]),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handlerClick && $options.handlerClick(...args)),
    disabled: $props.disabled
  }, null, 10, _hoisted_2)) : (_openBlock(), _createBlock(_component_b_button, {
    key: 1,
    variant: $props.settings.buttonType || 'primary',
    onClick: $options.handlerClick,
    disabled: $props.disabled
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.settings.buttonTitle), 1)]),
    _: 1
  }, 8, ["variant", "onClick", "disabled"])), _createVNode(_component_b_modal, {
    id: $props.idModal,
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModal = $event),
    size: "lg",
    title: $props.settings.modalTitle,
    onOk: $options.handlerOk,
    "ok-title": "Применить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    centered: "",
    "ok-disabled": $options.okDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [$props.settings.addDeadline ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      class: "mb-2",
      label: "Срок *"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.deadline,
        onChangeValue: _cache[1] || (_cache[1] = $event => _ctx.deadline = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true), $props.settings.addAssignee ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 1,
      label: "Работник *",
      class: "mb-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_UserSelector, {
        onSelected: $options.selectedWorker,
        fmtName: _ctx.fmtNameFull,
        showInfo: true,
        filters: $props.settings.filterAssignee
      }, null, 8, ["onSelected", "fmtName", "filters"])]),
      _: 1
    })) : _createCommentVNode("", true), $props.settings.addSelects ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 2
    }, _renderList($props.settings.selects, (el, index) => {
      return _openBlock(), _createBlock(_component_b_form_group, {
        class: "mb-2",
        key: index,
        label: el.title
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          options: el.options,
          modelValue: el.value,
          "onUpdate:modelValue": $event => el.value = $event
        }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])]),
        _: 2
      }, 1032, ["label"]);
    }), 128)) : _createCommentVNode("", true), $props.settings.addComment ? (_openBlock(), _createBlock(_component_b_form_textarea, {
      key: 3,
      id: "inputComment",
      modelValue: _ctx.comment,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.comment = $event),
      placeholder: "Комментарий",
      rows: "3",
      trim: "",
      "max-rows": "3"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $props.settings.addSelects || $props.settings.addDeadline || $props.settings.addAssignee ? (_openBlock(), _createElementBlock("p", _hoisted_3, " * обязательные к заполнению поля ")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [$props.settings.addFiles ? (_openBlock(), _createBlock(_component_AttachmentByOne, {
      key: 0,
      limitFiles: $props.settings.limitFiles,
      attachments: this.settings.updateAttachmentsCp ? _ctx.cpAttachments : $props.attachments,
      onUpdateFiles: $options.updateFiles
    }, null, 8, ["limitFiles", "attachments", "onUpdateFiles"])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["id", "modelValue", "title", "onOk", "ok-disabled"])]);
}