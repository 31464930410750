import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound, relevantHlText } from "@/helpers/text.js";
import { typeMessageHotline } from "@/helpers/text";
import { option4select } from "@/helpers/global";
export default {
  name: "AdminHlCategory",
  data: () => ({
    currentPage: 1,
    gotoPage: 1,
    perPage: 10,
    tableFields: [{
      key: "id",
      label: "#"
    }, {
      key: "message_type",
      label: "Название"
    }, {
      key: "message_category",
      label: "Уполномоченное подразделение",
      formatter: value => typeMessageHotline[value] || "-"
    }, {
      key: "is_relevant",
      label: "Релевантность",
      formatter: value => relevantHlText(value),
      class: "text-nowrap"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Добавить тип",
      btnTitle: "Добавить тип",
      buttonIcon: false,
      iconType: null,
      texts: {
        message_type: {
          required: true,
          label: "Название"
        }
      },
      selectors: {
        message_category: {
          required: true,
          label: "Уполномоченное подразделение",
          options: option4select(typeMessageHotline)
        }
      },
      switchName: "is_relevant",
      switchText: relevantHlText
    },
    settingsChange: {
      title: "Редактировать тип",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        message_type: {
          required: true,
          label: "Название"
        }
      },
      selectors: {
        message_category: {
          required: true,
          label: "Уполномоченное подразделение",
          options: option4select(typeMessageHotline)
        }
      },
      switchName: "is_relevant",
      switchText: relevantHlText
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminElementAdd
  },
  created() {
    this.updateAdminHlCategory({
      page: 1
    });
  },
  methods: {
    ...mapActions("administration", ["updateAdminHlCategory", "createAdminHlCategory", "changeAdminHlCategory"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminHlCategoryCount / this.perPage, this.currentPage);
      this.updateAdminHlCategory({
        page: this.currentPage
      });
    },
    async clickCreate(data) {
      await this.createAdminHlCategory(data);
      this.currentPage = 1;
      await this.updateAdminHlCategory({
        page: 1
      });
    },
    async clickChange(data) {
      await this.changeAdminHlCategory(data);
      await this.updateAdminHlCategory({
        page: this.currentPage
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminHlCategory", "adminHlCategoryLoading", "adminHlCategoryCount"]),
    ...mapGetters("hotline", ["hlineMsg"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminHlCategory({
          page: value
        });
      }
    }
  }
};