const items = [
  // Декларации
  "DECLARATIONS",
  "DECLARATIONS_COUNT",
  "DECLARATIONS_LOADING",
  "DECLARATIONS_PAGE",
  "DECLARATION_FORM",
  "DECLARATION_FORM_LOADING",
  "DECLARATION_FORM_PART_II",
  "CURRENT_DECLARATION_ID",
  "DECLARATION_FORM_ATTACHMENTS",
  "DECLARATION_FORM_ATTACHMENTS_COUNT",
  "DECLARATION_FORM_ATTACHMENTS_LOADING",
  "DECLARATION_FORM_COMMENTS",
  "DECLARATION_FORM_COMMENTS_COUNT",
  "DECLARATION_FORM_COMMENTS_LOADING",
  "DECLARATION_FORM_PATH_PROPS",

  "DECLARATION_EVENTS",
  "DECLARATION_EVENTS_COUNT",
  "DECLARATION_EVENTS_LOADING",
  // coi
  "COI",
  "COI_COUNT",
  "COI_LOADING",
  "COI_FOR_DECLARATION",
  "COI_FOR_DECLARATION_COUNT",
  "COI_FOR_DECLARATION_LOADING",
  "COI_DIRECTORY",
  "COI_FORM",
  "COI_FORM_LOADING",
  "COI_FORM_CHANGE_LOADING",
  "COI_ATTACHMENTS",
  "COI_ATTACHMENTS_COUNT",
  "COI_ATTACHMENTS_LOADING",
  "COI_COMMENTS",
  "COI_COMMENTS_COUNT",
  "COI_COMMENTS_LOADING",
  "COI_EVENTS",
  "COI_EVENTS_COUNT",
  "COI_EVENTS_LOADING",
  // cp
  "CP",
  "CP_COUNT",
  "CP_LOADING",
  "CP_FOR_COI",
  "CP_FOR_COI_COUNT",
  "CP_FOR_COI_LOADING",
  "CP_DIRECTORY",
  "CP_DIRECTORY_LOADING",
  "CP_ATTACHMENTS",
  "CP_ATTACHMENTS_COUNT",
  "CP_ATTACHMENTS_LOADING",
  "CP_FORM",
  "CP_FORM_LOADING",
  "CP_FORM_CHANGE_LOADING",
  "CP_COMMENTS",
  "CP_COMMENTS_COUNT",
  "CP_COMMENTS_LOADING",
  "CP_EVENTS",
  "CP_EVENTS_COUNT",
  "CP_EVENTS_LOADING",
  // отчеты
  "REPORT_STATS_EDKI",
  "REPORT_STATS_EDKI_LOADING",
  "REPORT_TIMING",
  "REPORT_TIMING_COUNT",
  "REPORT_TIMING_LOADING",

  // Нотификация
  "SHOW_TOAST_NOTIFY",
  "CLEAR_TOAST_SINGLE",

  // Пользователь
  "ASSIGNEE", // ответственные
  "ASSIGNEE_LOADING",
  "WORKPLACES",
  "WORKPLACES_LOADING",
  "WORKPLACES_COUNT",

  // Образование
  "EDUCATION_LNA",
  "EDUCATION_LNA_LOADING",
  // программы обучения внутренние
  "EDUCATION_COURS",
  "EDUCATION_COURS_COUNT",
  "EDUCATION_COURS_LOADING",
  // программы обучения внешние
  "EDUCATION_COURS_OUT",
  "EDUCATION_COURS_OUT_COUNT",
  "EDUCATION_COURS_OUT_LOADING",
  // программы обучения пользователя
  "EDUCATION_USER_COURS",
  "EDUCATION_USER_COURS_COUNT",
  "EDUCATION_USER_COURS_LOADING",
  // реестр студентов обучения
  "STUDENTS_LIST",
  "STUDENTS_LIST_COUNT",
  "STUDENTS_LIST_LOADING",
  // реестр студентов к назначению
  "STUDENTS_TO_ASS",
  "STUDENTS_TO_ASS_COUNT",
  "STUDENTS_TO_ASS_LOADING",
  // конкретный курс
  "EDUCATION_COURSE",
  "EDUCATION_COURSE_LOADING",
  "COURSE_LOADING",
  "EDUCATION_MATERIAL",
  "COURSE_EVENTS",
  "COURSE_EVENTS_COUNT",
  "COURSE_EVENTS_LOADING",
  // отчеты по образованию
  "EDUC_REP_LOADING",
  "EDUC_REP_COUNT",
  "EDUC_REP_DATA",
  "EDUC_REP_FILE",
  // вопросы к тестированию при создании программы обучения
  "EDUCATION_QUEST",
  "EDUCATION_QUEST_COUNT",
  "EDUCATION_QUEST_LOADING",
  // ответы к вопросам тестирования при создании программы обучения
  "EDUCATION_ANSWER",
  "EDUCATION_ANSWER_COUNT",
  "EDUCATION_ANSWER_LOADING",
  // назначение тестирования в личном кабинете
  "EDUCATION_TEST",
  "EDUCATION_TEST_LOADING",
  // вопрос с вариантами ответа в назначении теста в личном кабинете
  "EDUCATION_TEST_QUEST",
  "EDUCATION_TEST_QUEST_LOADING",
  // список тестов для проверки
  "TESTS_CHECK",
  "TESTS_CHECK_COUNT",
  "TESTS_CHECK_LOADING",
  // список ответов теста для проверки
  "ANSWERS_CHECK",
  "ANSWERS_CHECK_COUNT",
  "ANSWERS_CHECK_LOADING",
  // данные о назначения прохождения курса в рабочей области
  "EDUCATION_TEST_WP",
  "EDUCATION_TEST_WP_LOADING",

  // Горячая линия
  "HOTLINE",
  "HOTLINE_COUNT",
  "HOTLINE_LOADING",
  "HOTLINE_MESSAGE",
  "HOTLINE_MESSAGE_LOADING",
  "HOTLINE_ATTACH",
  "HOTLINE_ATTACH_COUNT",
  "HOTLINE_ATTACH_LOADING",
  "HOTLINE_ATTACHMENT", // для получения конкретного id вложения
  "HOTLINE_COMMENTS",
  "HOTLINE_COMMENTS_COUNT",
  "HOTLINE_COMMENTS_LOADING",
  "HOTLINE_EVENTS",
  "HOTLINE_EVENTS_COUNT",
  "HOTLINE_EVENTS_LOADING",
  "HOTLINE_REP_LOADING",
  "HOTLINE_REP_FILE",
  "HOTLINE_REP_DATA",

  // Личный листок
  "PROFILES",
  "PROFILE",
  "PROFILE_LOADING",
  "PROFILE_SIGN",
  "PROFILE_SIGN_LOADING",
  // settings
  "PROFILE_SETTINGS",
  "PROFILE_SETTINGS_LOADING",

  // Проверки личных листков
  "PROFILE_CHECKS",
  "PROFILE_CHECKS_COUNT",
  "PROFILE_CHECKS_LOADING",

  // Статистика главной страницы
  // stats
  "STATS",
  "STATS_LOADING",
  "STATS_NEW",
  // notify
  "NEWNOTIFYLK",
  "NOTIFYLK_LIST",
  "NOTIFYLK_COUNT",
  "NOTIFYLK_LOADING",

  // Справочники
  "ORGANIZATIONS",
  "ORGANIZATIONS_LOADING",
  "DEPARTMENTS",
  "DEPARTMENTS_LOADING",
  "PRORGARMS",
  "PRORGARMS_LOADING",
  "HLCATEGORY",
  "HLCATEGORY_LOADING",
  "LNATYPES",
  "LNATYPES_LOADING",
  "COUNTRIES",
  "COUNTRIES_LOADING",
  // security-events
  "SEC_EVENTS",
  "SEC_EVENTS_LOADING",

  // Задачи
  "TASK_COMMENTS",
  "TASK_COMMENTS_COUNT",
  "TASK_COMMENTS_LOADING",
  "TASKS_FOR_DECLARATION",
  "TASKS_FOR_DECLARATION_COUNT",
  "TASKS_FOR_DECLARATION_LOADING",

  // База знаний
  "SECTIONS",
  "SECTIONS_LOADING",
  "SECTIONS_COUNT",
  "SECTION_FORM",
  "SECTION_FORM_LOADING",
  "SECTIONS_DOCUMENTS",
  "SECTIONS_DOCUMENTS_COUNT",
  "SECTIONS_ARCHIVE",
  "SECTIONS_ARCHIVE_COUNT",
  "SECTIONS_DOCUMENTS_LOADING",
  "SECTIONS_ARCHIVE_LOADING",
  "DOCUMENT_LOADING",
  "DOCUMENT",
  // реестр работников, которым назначено ознакомление
  "WORKERS_LIST",
  "WORKERS_LIST_COUNT",
  "WORKERS_LIST_LOADING",
  // реестр работников к назначению
  "WORKERS_TO_ASS",
  "WORKERS_TO_ASS_COUNT",
  "WORKERS_TO_ASS_LOADING",
  // ЛНА ЛК
  "LNA_USER_LIST",
  "LNA_USER_LIST_COUNT",
  "LNA_USER_LIST_LOADING",
  "LNA_USER_FORM",
  "LNA_USER_FORM_LOADING",
  // report
  "LNA_REP_DATA",
  "LNA_REP_COUNT",
  "LNA_REP_LOADING",
  "LNA_REP_FILE",

  // Деловые партнеры
  "PARTNERS",
  "PARTNERS_COUNT",
  "PARTNERS_LOADING",
  "PARTNER_FORM",
  "PARTNER_FORM_LOADING",
  "PARTNER_REP_LOADING",
  "PARTNER_REP_FILE",
  "PARTNER_REP_DATA",
  "PARTNER_CHECKS",
  "PARTNER_CHECKS_LOADING",
  "PARTNER_CHECKS_COMMENT",
  "PARTNER_CHECKS_COMMENT_LOADING",
  "PARTNER_CHECKS_ATTACH",
  "PARTNER_CHECKS_ATTACH_LOADING",

  // Внутренние проверки
  "INNER_CHECKS",
  "INNER_CHECKS_COUNT",
  "INNER_CHECKS_LOADING",
  "INNER_CHECKS_CHANGES",
  "INNER_CHECKS_CHANGES_COUNT",
  "INNER_CHECKS_CHANGES_LOADING",
  "INNER_CHECKS_COMMENT",
  "INNER_CHECKS_COMMENT_COUNT",
  "INNER_CHECKS_COMMENT_LOADING",
  "INNER_CHECK_FORM",
  "INNER_CHECK_FORM_LOADING",
  // отчеты по ВП
  "INNER_CHECK_REP_LOADING",
  "INNER_CHECK_REP_COUNT",
  "INNER_CHECK_REP_DATA",
  "INNER_CHECK_REP_FILE",
  // вложения
  "IC_ATTACH_LIST",
  "IC_ATTACH_LIST_COUNT",
  "IC_ATTACH_LIST_LOADING",
  "IC_ATTACH_ITEM",
  "IC_ATTACH_ITEM_LOADING",

  // Риски
  "RISK_MATRIX",
  "RISK_MATRIX_COUNT",
  "RISK_MATRIX_LOADING",
  "RISK_MATRIX_PAGE",
  "RISKS",
  "RISKS_PAGE",
  "RISKS_COUNT",
  "RISKS_LOADING",
  "RISK_FORM",
  "RISK_FORM_LOADING",
  "MATRIX_FORM",
  "MATRIX_FORM_LOADING",
  "MATRIX_FORM_CHANGE",
  "MATRIX_FORM_CHANGE_COUNT",
  "MATRIX_FORM_CHANGE_LOADING",
  "MATRIX_FORM_RISKS",
  "MATRIX_FORM_RISKS_COUNT",
  "MATRIX_FORM_RISKS_LOADING",
  "MATRIX_FORM_CHECKS",
  "MATRIX_FORM_CHECKS_COUNT",
  "MATRIX_FORM_CHECKS_LOADING",
  "RISK_AVAILABLE",
  "RISK_AVAILABLE_COUNT",
  "RISK_AVAILABLE_LOADING",
  "RISK_CHART",
  "RISK_CHART_LOADING",

  //  Aдминистирование
  "ADMIN_ACTIVITY",
  "ADMIN_ACTIVITY_COUNT",
  "ADMIN_ACTIVITY_LOADING",
  "ADMIN_BACKUP",
  "ADMIN_BACKUP_COUNT",
  "ADMIN_BACKUP_LOADING",
  "ADMIN_USERS",
  "ADMIN_USERS_COUNT",
  "ADMIN_USERS_LOADING",
  "ADMIN_WORKERS",
  "ADMIN_WORKERS_COUNT",
  "ADMIN_WORKERS_LOADING",
  "ADMIN_COMMON",
  "ADMIN_COMMON_LOADING",
  "ADMIN_KNOWLEDGE",
  "ADMIN_KNOWLEDGE_LOADING",
  "ADMIN_EDUCATION",
  "ADMIN_EDUCATION_LOADING",
  "ADMIN_DECLARATION",
  "ADMIN_DECLARATION_LOADING",
  "ADMIN_INNER_CHECK",
  "ADMIN_INNER_CHECK_LOADING",
  "ADMIN_RISK",
  "ADMIN_RISK_LOADING",
  "ADMIN_PARTNERS",
  "ADMIN_PARTNERS_LOADING",
  "ADMIN_HL",
  "ADMIN_HL_LOADING",
  "ADMIN_HL_COUNT",
  "ADMIN_ADMIN",
  "ADMIN_ADMIN_LOADING",
  "ADMIN_CALENDAR",
  "ADMIN_CALENDAR_LOADING",
  "ADMIN_ORG",
  "ADMIN_ORG_COUNT",
  "ADMIN_ORG_LOADING",
  "ADMIN_GROUPS",
  "ADMIN_GROUPS_LOADING",
  "PROFILES_KADR",
  "PROFILES_KADR_COUNT",
  "PROFILES_KADR_LOADING",
  "PROFILES_IDS_1C", // id`s для пакетной выгрузки ЛЛ в 1С
  "PROFILES_IDS_1C_COUNT",
  "ADMIN_DEPART",
  "ADMIN_DEPART_COUNT",
  "ADMIN_DEPART_LOADING",
  "ADMIN_CP",
  "ADMIN_CP_COUNT",
  "ADMIN_CP_LOADING",
  "ADMIN_LNA_TYPES",
  "ADMIN_LNA_TYPES_COUNT",
  "ADMIN_LNA_TYPES_LOADING",
  "ADMIN_COUNTRY",
  "ADMIN_COUNTRY_COUNT",
  "ADMIN_COUNTRY_LOADING",
  "ADMIN_STATS_DEPT",
  "ADMIN_STATS_DEPT_LOADING",
  "ADMIN_ROLES",
  "ADMIN_ROLES_COUNT",
  "ADMIN_ROLES_LOADING",
  "ADMIN_ROLESREF",
  "ADMIN_ROLESREF_LOADING",
  "ADMIN_PROFILESHEET_SETTINGS",
  "ADMIN_PROFILESHEET_SETTINGS_LOADING",
  // документация по платформе
  "ADMIN_COMMON_DOCS",
  "ADMIN_COMMON_DOCS_LOADING",
  // риски для статистики
  "ADMIN_STATS_RISK",
  "ADMIN_STATS_RISK_COUNT",
  "ADMIN_STATS_RISK_LOADING",
  // securityEvents
  "SECURITY_EVENTS",
  "SECURITY_EVENTS_COUNT",
  "SECURITY_EVENTS_LOADING",
  "SECURITY_EVENTS_ITEM",
  "SECURITY_EVENTS_ITEM_LOADING",
  // /security-events/guide/
  "SECURITY_GUIDE",
  "SECURITY_GUIDE_LOADING",
  // adminNotification
  "ADMIN_NOTIFICATION_LIST",
  "ADMIN_NOTIFICATION_COUNT",
  "ADMIN_NOTIFICATION_LOADING",
  "ADMIN_NOTIFICATION_ITEM",
  "ADMIN_NOTIFICATION_ITEM_LOADING",
  // dataAdress
  "DATA_ADDRESS",
  "DATA_ADDRESS_LOADING",
];
const map = items.reduce((o, x) => ((o[x] = x), o), {});
export default map;
