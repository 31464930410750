import "core-js/modules/es.array.push.js";
import StringsWithX from "@/components/Helpers/StringsWithX.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import Calendar from "@/components/Helpers/Calendar.vue";
import { FIO, CONST_NONAME, fmtNameFull } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import { getOrgName } from "@/auth/auth";
import { option4select } from "@/helpers/global";
import { formatDateObj, fmtDate, dateObjInit, formatObjDate } from "@/helpers/date";
export default {
  name: "KnowledgeAssign",
  components: {
    StringsWithX,
    UserSelector,
    Calendar
  },
  data: () => ({
    fmtNameFull,
    CONST_NONAME,
    allOrg: false,
    // в целом по организации
    orgName: CONST_NONAME,
    depart: null,
    departs: [],
    departsToAssign: [],
    workersToAssign: [],
    deadline: {
      ...dateObjInit
    },
    showModal: false
  }),
  methods: {
    ...mapActions("knowledge", ["assignDocument", "updateWorkersToAss"]),
    ...mapActions(["showNotify"]),
    // нажали кнопку - открываем модальное окно
    async clickAssign() {
      this.orgName = getOrgName();
      this.allOrg = false;
      this.clearDepart();
      this.departsToAssign = [];
      this.workersToAssign = [];
      this.deadline = formatObjDate(this.knowlegeDocument.deadline);
      this.departs = option4select(this.departments, false);
      this.showModal = true;
    },
    // обработка закрытия модального окна и назначения
    async handlerOk(bvModalEvt) {
      let err = false;
      // проверка работников
      if (!this.allOrg && this.departsToAssign.length === 0 && this.workersToAssign.length === 0) {
        this.showNotify({
          msg: "Список работников пуст",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // проверка даты
      const deadline = formatDateObj(this.deadline, fmtDate, null);
      if (!deadline) {
        this.showNotify({
          msg: "Некорректная дата",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        err = true;
      }
      // ошибка
      if (err) {
        bvModalEvt.preventDefault();
        return;
      }
      // назначаем
      await this.assignDocument({
        deadline,
        id: this.knowlegeDocument.id,
        allOrg: this.allOrg,
        depart: this.departsToAssign,
        workers: this.workersToAssign
      });
      this.currentPage = 1;
      await this.updateWorkersToAss({
        page: 1,
        libmat_id: this.knowlegeDocument.id,
        confirmed: false
      });
      this.showModal = false;
    },
    // очистка закладки Подразделение
    clearDepart() {
      this.depart = null;
    },
    // обработка выбора подразделения
    changeDepart() {
      const choose = this.departs.find(el => el.value === this.depart);
      if (choose) {
        this.departsToAssign.push({
          id: choose.value,
          text: choose.text
        });
        this.clearDepart();
      }
    },
    // обработка выбора работника
    selectedWorker(choose) {
      this.workersToAssign.push({
        id: choose.id,
        text: FIO(choose.name)
      });
    },
    // удаление выбранного подраздления
    removeDeparts(id) {
      const index = this.departsToAssign.findIndex(el => el.id === id);
      if (index >= 0) {
        this.departsToAssign.splice(index, 1);
      }
    },
    // удаление выбранного работника
    removeWorkers(id) {
      const index = this.workersToAssign.findIndex(el => el.id === id);
      if (index >= 0) {
        this.workersToAssign.splice(index, 1);
      }
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departmentsLoading", "departments"]),
    ...mapGetters("knowledge", ["knowlegeDocument", "knowlegeDocumentLoading", "sectionFormLoading"]),
    // список выбора подразделений
    departOptions() {
      return this.departs.filter(dep => !this.departsToAssign.find(el => dep.value === el.id));
    },
    readOnly() {
      return this.knowlegeDocumentLoading || this.sectionFormLoading;
    }
  }
};