import DeclarationListFullSidebar from "./DeclarationListFullSidebar";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import { statesDeclarations } from "@/helpers/states";
import { FIO, limitBound, optionsDesc } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "DeclarationListFull",
  components: {
    DeclarationListFullSidebar
  },
  data() {
    return {
      links,
      filters: {
        //кодировка полей snake_case (для соответствия полей бэку)
        state: null,
        decl_id: null,
        state_modified_lte: null,
        //До указанной даты изменения состояния включительно
        state_modified_gte: null,
        //Начиная с указанной даты изменения состояния
        department_id: null,
        author_search: null,
        //Поиск по ФИО автора
        assignee_search: null //Поиск по ФИО ответственного
      },

      currentPage: 1,
      gotoPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: true,
      optionsSort: [{
        value: "id",
        text: "по декларации"
      }, {
        value: "author",
        text: "по сотруднику"
      }, {
        value: "state_modified_at",
        text: "по дате изменения"
      }, {
        value: "user_detail.department",
        text: "по подразделению"
      }, {
        value: "worker_in_charge",
        text: "по ответственному"
      }],
      optionsDesc,
      declarationsFields: [{
        key: "id",
        label: "Декларация"
      }, {
        key: "authorT",
        label: "Сотрудник",
        class: "text-nowrap"
      }, {
        key: "stateT",
        label: "Статус"
      }, {
        key: "state_modified_atT",
        label: "Изменено"
      }, {
        key: "user_detail.department",
        label: "Подразделение"
      }, {
        key: "worker_in_chargeT",
        label: "Ответственный",
        class: "text-nowrap"
      }],
      showSidebar: false
    };
  },
  computed: {
    ...mapGetters("declarations", ["declarationsObj", "declarationsCount", "declarationsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.requestDeclarations();
      }
    },
    items4table() {
      return Array.isArray(this.declarationsObj) ? this.declarationsObj.map(el => ({
        ...el,
        authorT: FIO(el.author),
        stateT: statesDeclarations[el.state],
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        worker_in_chargeT: FIO(el.worker_in_charge)
      })) : [];
    }
  },
  methods: {
    ...mapActions("declarations", ["fetchDeclarations"]),
    // формирование запроса а api
    requestDeclarations() {
      const page = this.currentPage;
      this.fetchDeclarations({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page: page
      });
    },
    // обработка изменения сортировки
    sortChanged() {
      this.currentPage = 1;
      this.requestDeclarations();
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.declarationsCount / this.perPage, this.currentPage);
      this.requestDeclarations();
    },
    // применение фильтров по нажатии кнопки "Применить"
    applyFilters(filters) {
      this.filters.state = Object.keys(statesDeclarations).filter(key => statesDeclarations[key] === filters.state).join();
      this.filters.department_id = filters.department;
      this.filters.state_modified_gte = formatDateObj(filters.dateFrom, fmtDate, null);
      this.filters.state_modified_lte = formatDateObj(filters.dateUntill, fmtDate, null);
      this.filters.author_search = filters.author;
      this.filters.assignee_search = filters.assignee;
      this.filters.decl_id = filters.number;
      this.filters.is_new = filters.new;
      if (this.currentPage === 1) this.requestDeclarations();else this.currentPage = 1;
    }
  }
};