import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import { statesCp } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound, optionsDesc, formatName, fmtNameShort } from "@/helpers/text";
import CPSidebar from "./CPSidebar";
import links from "@/router/links";
export default {
  name: "CP",
  //контрольные процедуры
  components: {
    CPSidebar
  },
  data: () => ({
    showSidebar: false,
    statesCp,
    links,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    sortBy: null,
    sortDesc: true,
    optionsDesc,
    optionsSort: [{
      value: "state",
      text: "по статусу"
    }, {
      value: "cp_type",
      text: "по субъекту КП"
    }, {
      value: "date_planned",
      text: "по сроку КП"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }],
    filters: {
      //кодировка полей snake_case для соответствия полей бэку
      state: null,
      state_modified_lte: null,
      //До указанной даты изменения состояния включительно
      state_modified_gte: null,
      //Начиная с указанной даты изменения состояния
      date_planned_lte: null,
      //До указанной даты включительно
      date_planned_gte: null,
      //Начиная с указанной плановой даты
      assignee_search: null,
      //Поиск по ФИО ответственного
      cp_type: null // Тип КП
    },

    cpFields: [{
      key: "id",
      label: "#"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "foundation",
      label: "Источник"
    }, {
      key: "target_usersT",
      label: "Работник"
    }, {
      key: "cp_type_name",
      label: "Субъект КП"
    }, {
      key: "date_plannedT",
      label: "Срок КП"
    }, {
      key: "state_modified_atT",
      label: "Изменено"
    }, {
      key: "assignee_detailT",
      label: "Ответственный"
    }
    //{ key: "btnRemove", label: "" },
    ]
  }),

  methods: {
    ...mapActions("cp", ["fetchCp", "createCPForm"]),
    updateSort({
      sortBy,
      sortDesc
    }) {
      this.currentPage = 1;
      this.fetchCp({
        sortBy: sortBy,
        sortDesc: sortDesc,
        filters: this.filters,
        page: 1
      });
    },
    // обновить таблицу
    updateCPList(page = 1) {
      this.currentPage = page;
      this.fetchCp({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filters: this.filters,
        page
      });
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.cpCount / this.perPage, this.currentPage);
      this.updateCPList(this.currentPage);
    },
    // применение фильтров по нажатии кнопки "Применить"
    applyFilters(filters) {
      this.filters = {
        ...filters
      }; // неглубокое копирование
      this.updateCPList(1);
    },
    // удаление элемента
    async clickRemove(id) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Удалить КП #${id}?`, {
        size: "sm",
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Удалить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      });
      if (isConfirmed) {
        this.updateCPList(1);
      }
    },
    async clickCreateCp() {
      await this.createCPForm();
      if (this.cpForm.id) this.$router.push(links.cpForm + this.cpForm.id);
    }
  },
  computed: {
    ...mapGetters("cp", ["cp", "cpCount", "cpLoading", "cpForm"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateCPList(value);
      }
    },
    DateNow() {
      return formatDate(Date.now());
    },
    items4table() {
      return Array.isArray(this.cp) ? this.cp.map(el => ({
        ...el,
        target_usersT: el.target_users.map(x => `${formatName(x, fmtNameShort)} (${x.worker_code})`).join(", "),
        stateT: statesCp[el.state],
        date_plannedT: formatDate(el.date_planned, fmtDateShort),
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        assignee_detailT: formatName(el.assignee_detail, fmtNameShort)
      })) : [];
    }
  }
};