import theNotification from "@/components/theNotification";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import KnowledgeFormDescription from "./KnowledgeFormDescription.vue";
import WorkersList from "./WorkersList.vue";
import WorkersToAssign from "./WorkersToAssign.vue";
import KnowledgeAssign from "./KnowledgeAssign.vue";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
import links from "@/router/links";
import { mapGetters, mapActions } from "vuex";
import { statesKnowledge } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort } from "@/helpers/text";
import { btn4transitLna, needAppoint } from "@/helpers/knowledge";
export default {
  name: "KnowledgeFormBase",
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  // id документа

  components: {
    theNotification,
    BreadCrumbToHome,
    KnowledgeFormDescription,
    WorkersList,
    WorkersToAssign,
    TaskButton,
    KnowledgeAssign
  },
  data: () => ({
    statesKnowledge,
    fmtDateShort,
    fmtNameShort
  }),
  async created() {
    this.updateDepartments();
    this.updateLnaTypes();
    await this.updateDocumentForm(this.msg);
    if (this.useAppoint) {
      this.updateWorkersList({
        page: 1,
        libmat_id: this.msg,
        confirmed: true,
        is_signed: true
      });
      this.updateWorkersToAss({
        page: 1,
        libmat_id: this.msg,
        confirmed: false
      });
    }
  },
  methods: {
    ...mapActions("knowledge", ["updateDocumentForm", "updateWorkersList", "updateWorkersToAss", "changeStateDocument", "changeDocument"]),
    ...mapActions("referenceBooks", ["updateDepartments", "updateLnaTypes"]),
    formatDate,
    formatName,
    async clickAction(btn, data) {
      const body = {};
      if (btn.body) Object.entries(btn.body).forEach(([key, value]) => {
        body[key] = data[value];
      });
      if (Object.keys(body).length > 0) await this.changeDocument({
        id: this.msg,
        params: body
      });
      // смена статуса
      await this.changeStateDocument({
        id: this.msg,
        msg: btn.msg,
        state: btn.state
      });
    }
  },
  computed: {
    ...mapGetters("knowledge", ["knowlegeDocument", "knowlegeDocumentLoading", "workersListCount", "workersToAssCount"]),
    stateBtn() {
      return btn4transitLna[this.knowlegeDocument.state];
    },
    readOnly() {
      return this.knowlegeDocumentLoading;
    },
    breadcrumbs() {
      return [{
        text: "Библиотека знаний",
        to: {
          name: "Knowledge"
        }
      }, {
        text: this.knowlegeDocument.section_name,
        to: links.knowledgeList + this.knowlegeDocument.section
      }];
    },
    useAppoint() {
      return this.knowlegeDocument.visibility_level === needAppoint;
    }
  }
};