import mutations from "@/store/mutations";
import { fetchAPI } from "@/helpers/fetch";
import declarationSectionTemplate from "@/components/Declaration/declarationSectionTemplate";
import { serializeResponseDeclarationPartII } from "@/helpers/declaration";
import { wrapCommitDispatch, addLinks } from "@/helpers/global";

const {
  DECLARATION_FORM,
  DECLARATION_FORM_LOADING,
  DECLARATION_FORM_PART_II,
  DECLARATION_FORM_ATTACHMENTS,
  DECLARATION_FORM_ATTACHMENTS_COUNT,
  DECLARATION_FORM_ATTACHMENTS_LOADING,
  DECLARATION_FORM_PATH_PROPS,
  // comments
  DECLARATION_FORM_COMMENTS,
  DECLARATION_FORM_COMMENTS_COUNT,
  DECLARATION_FORM_COMMENTS_LOADING,
} = mutations;
const formInit = { transitions: [] };

const declarationFormStore = {
  namespaced: true,
  state: {
    declarationFormObj: JSON.parse(JSON.stringify(formInit)), // здесь храним то, что пришло по API
    declarationFormPartII: {}, // здесь храним актуальную информацию по II части декларации
    declarationFormLoading: false,
    declarationFormAttachments: [],
    declarationFormAttachmentsCount: 0,
    declarationFormAttachmentsLoading: false,
    pathProps: null,
    // comments
    declFormComments: [],
    declFormCommentsCount: 0,
    declFormCommentsLoading: false,
  },
  getters: {
    declarationFormObj: ({ declarationFormObj }) => declarationFormObj,
    declarationFormPartII: ({ declarationFormPartII }) => declarationFormPartII,
    declarationFormLoading: ({ declarationFormLoading }) =>
      declarationFormLoading,
    declarationFormAttachments: ({ declarationFormAttachments }) =>
      declarationFormAttachments,
    declarationFormAttachmentsCount: ({ declarationFormAttachmentsCount }) =>
      declarationFormAttachmentsCount,
    declarationFormAttachmentsLoading: ({
      declarationFormAttachmentsLoading,
    }) => declarationFormAttachmentsLoading,
    pathProps: ({ pathProps }) => pathProps,
    declFormComments: ({ declFormComments }) => declFormComments,
    declFormCommentsCount: ({ declFormCommentsCount }) => declFormCommentsCount,
    declFormCommentsLoading: ({ declFormCommentsLoading }) =>
      declFormCommentsLoading,
  },
  mutations: {
    [DECLARATION_FORM](state, value) {
      state.declarationFormObj = value;
    },
    [DECLARATION_FORM_PART_II](state, value) {
      state.declarationFormPartII = value;
    },
    [DECLARATION_FORM_LOADING](state, value) {
      state.declarationFormLoading = value;
    },
    [DECLARATION_FORM_ATTACHMENTS](state, value) {
      state.declarationFormAttachments = value;
    },
    [DECLARATION_FORM_ATTACHMENTS_COUNT](state, value) {
      state.declarationFormAttachmentsCount = value;
    },
    [DECLARATION_FORM_ATTACHMENTS_LOADING](state, value) {
      state.declarationFormAttachmentsLoading = value;
    },
    [DECLARATION_FORM_PATH_PROPS](state, value) {
      state.pathProps = value;
    },
    // comments
    [DECLARATION_FORM_COMMENTS](state, value) {
      state.declFormComments = value;
    },
    [DECLARATION_FORM_COMMENTS_COUNT](state, value) {
      state.declFormCommentsCount = value;
    },
    [DECLARATION_FORM_COMMENTS_LOADING](state, value) {
      state.declFormCommentsLoading = value;
    },
  },
  actions: {
    // патч данных в декларацию панель инструментов (например ответственнго исполнителея)
    async send2Declaration(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const { declarationFormObj } = context.getters;
          await fetchAPI(
            `/declarations/${declarationFormObj.id}/`,
            null,
            "PATCH",
            data.body,
            data.fileUpload
          );
          return data.msg;
        },
        DECLARATION_FORM_LOADING
      );
    },

    // запрос формы декларации
    async fetchDeclarationForm(context, { id, myDecl }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const declarationForm = await fetchAPI(
            myDecl ? `/my-declarations/${id}/` : `/declarations/${id}/`
          );
          context.commit(DECLARATION_FORM, declarationForm);
          // обработаем часть II
          const declarationFormPartII = serializeResponseDeclarationPartII(
            declarationSectionTemplate,
            declarationForm
          );
          context.commit(DECLARATION_FORM_PART_II, declarationFormPartII);
        },
        DECLARATION_FORM_LOADING
      );
    },

    // запрос вложений декларации
    async updateDeclarationFormAttachments(context, { id, page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const attachments = await fetchAPI(
            `/declarations/${id}/attachments/`,
            { page }
          );
          context.commit(DECLARATION_FORM_ATTACHMENTS_COUNT, attachments.count);
          context.commit(DECLARATION_FORM_ATTACHMENTS, attachments.results);
        },
        DECLARATION_FORM_ATTACHMENTS_LOADING
      );
    },

    //удаление КИ
    async delCOI(context, { sectionId, itemIndex }) {
      await wrapCommitDispatch(context, async () => {
        const { declarationFormPartII, declarationFormObj } = context.getters;
        const countCOI =
          declarationFormPartII[sectionId]["bottomSectionRender"].length;
        if (
          declarationFormPartII[sectionId] &&
          declarationFormPartII[sectionId]["bottomSectionRender"] &&
          itemIndex >= 0 &&
          countCOI > itemIndex
        ) {
          const delElement =
            declarationFormPartII[sectionId].bottomSectionRender[itemIndex];
          const delElementId = Object.values(delElement).find(
            (el) => el["name"] === "id"
          )["data"];

          if (countCOI === 1 && delElementId) {
            // это последний
            const switchSection = {};
            switchSection[
              declarationFormPartII[sectionId].switchSectionMaping
            ] = false;
            await fetchAPI(
              `/my-declarations/${declarationFormObj.id}/`,
              null,
              "PATCH",
              switchSection
            );
          }

          if (delElementId) {
            await fetchAPI(
              `/my-declarations/${declarationFormObj.id}/conflicts/${delElementId}/`,
              null,
              "DELETE"
            );
          } else {
            console.log("Неверный id КИ");
          }
          return "Конфликт интересов удален";
        }
      });
    },

    //изменение элемента из секции II части декларации
    async changeCOI(context, { sectionId, itemIndex, newItem }) {
      await wrapCommitDispatch(context, async () => {
        const { declarationFormObj, declarationFormPartII } = context.getters;
        const coiData = {};
        let delElementId = null;
        if (
          declarationFormPartII[sectionId] &&
          declarationFormPartII[sectionId]["bottomSectionRender"] &&
          itemIndex >= 0 &&
          declarationFormPartII[sectionId]["bottomSectionRender"].length >
            itemIndex
        ) {
          Object.values(newItem).forEach((el) => {
            if (!el["hidden"]) {
              // id не добавляем
              coiData[el["keyForDB"]] = el["data"];
            } else if (el["name"] === "id") {
              delElementId = el["data"];
            }
          });
          if (delElementId) {
            await fetchAPI(
              `/my-declarations/${declarationFormObj.id}/conflicts/${delElementId}/`,
              null,
              "PATCH",
              coiData
            );
          } else {
            console.log("Неверный id КИ");
          }
          return "Конфликт интересов изменен";
        }
      });
    },

    //добавление нового КИ
    async addCOI(context, { sectionId, newItem }) {
      await wrapCommitDispatch(context, async () => {
        const { declarationFormObj, declarationFormPartII } = context.getters;
        const switchSection = {};
        const coiData = {};
        if (
          declarationFormPartII[sectionId] &&
          declarationFormPartII[sectionId]["bottomSectionRender"]
        ) {
          switchSection[
            declarationFormPartII[sectionId].switchSectionMaping
          ] = true;
          coiData.coi_type_code =
            declarationFormPartII[sectionId].bottomSectionMaping.keyDB;

          Object.values(newItem).forEach((el) => {
            if (!el["hidden"]) {
              // id не добавляем
              coiData[el["keyForDB"]] = el["data"];
            }
          });
          await fetchAPI(
            `/my-declarations/${declarationFormObj.id}/`,
            null,
            "PATCH",
            switchSection
          );
          await fetchAPI(
            `/my-declarations/${declarationFormObj.id}/conflicts/`,
            null,
            "POST",
            coiData
          );
          return "Конфликт интересов сохранен";
        }
      });
    },

    // создание новой декларации
    async newDeclaration(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(DECLARATION_FORM_PATH_PROPS, null);
        const newDecl = await fetchAPI("/my-declarations/", null, "POST", {});
        const pathProps = newDecl.id;
        context.commit(DECLARATION_FORM_PATH_PROPS, pathProps);
        return "Новая декларация создана";
      });
    },

    async saveDeclarationAttachments(context, formData) {
      await wrapCommitDispatch(context, async () => {
        const { declarationFormObj } = context.getters;
        await fetchAPI(
          `/declarations/${declarationFormObj.id}/attachments/`,
          null,
          "POST",
          formData,
          true
        );
        return "Файл прикреплен к декларации";
      });
    },

    async delDeclarationAttachments(context, idAttachment) {
      await wrapCommitDispatch(context, async () => {
        const { declarationFormObj } = context.getters;
        await fetchAPI(
          `/declarations/${declarationFormObj.id}/attachments/${idAttachment}/`,
          null,
          "DELETE"
        );
        return "Файл удален";
      });
    },

    // смена статуса декларации
    async sendDeclaration(context, { state, msg, myDecl }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const { declarationFormObj } = context.getters;
          context.commit(DECLARATION_FORM_PATH_PROPS, null); // воспользуемся этим параметром как флагом для перехода
          const endpoint =
            (myDecl ? "/my-declarations/" : "/declarations/") +
            `${declarationFormObj.id}/transition/`;
          const decl = await fetchAPI(
            endpoint,
            null,
            "PATCH",
            addLinks({ state })
          );
          declarationFormObj.state = state; // чтобы не закрывать окно с декларацией, обновим локально
          context.commit(DECLARATION_FORM_PATH_PROPS, decl);
          return msg || "";
        },
        DECLARATION_FORM_LOADING
      );
    },

    // механизм подписания ЭДКИ простой электронной подписью
    // Работает так же, как подписание ЛЛ
    // Успешное подписание автоматически переводит ЭДКИ в состояние autochecked
    // (отдельно переводить уже не надо)
    async sendSignDeclaration(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const result = await fetchAPI(
            `/my-declarations/${data.id}/sign/`,
            null,
            "PATCH",
            addLinks({ code: data.code })
          );
          context.commit(DECLARATION_FORM, result);
          return "Декларация направлена на проверку";
        },
        DECLARATION_FORM_LOADING
      );
    },

    async removeDeclaration(context, { id }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/my-declarations/${id}/`, null, "DELETE");
        return `Декларация ${id} удалена`;
      });
    },

    // comments
    // запрос с сервера списка комментариев к форме декларации
    async updateDeclFormComments(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/declarations/${data.id}/comments/`,
            { page: data.page }
          );
          context.commit(DECLARATION_FORM_COMMENTS, response.results);
          context.commit(DECLARATION_FORM_COMMENTS_COUNT, response.count);
        },
        DECLARATION_FORM_COMMENTS_LOADING
      );
    },
    // создать комментарий к форме декларации
    async createDeclFormComments(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/declarations/${data.id}/comments/`, null, "POST", {
          body: data.comment,
        });
      });
    },

    // сброс формы декларации
    async dropDeclarationForm(context) {
      await wrapCommitDispatch(context, async () => {
        context.commit(DECLARATION_FORM, JSON.parse(JSON.stringify(formInit)));
        context.commit(DECLARATION_FORM_PART_II, {});
      });
    },
  },
};

export default declarationFormStore;
