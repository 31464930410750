import { formatDate, fmtDateShort } from "@/helpers/date";
import { getOrgName } from "@/auth/auth";

/**
 * вывод Да/Нет в зависимости от boolean
 * @param {Boolean} value - значение true/false
 * @returns {String}
 */
export function switchText(value, [yes, no] = ["Да", "Нет"]) {
  return value ? yes : no;
}

/**
 * вывод Фамилии в виде Фамалия И.О.
 * @param {String} fullFIO - полное имя сотрудника
 * @returns {String}
 */
export function FIO(fullFIO) {
  if (!fullFIO) return NONAME_DOTS;
  try {
    const words = fullFIO.split(" ");
    if (!words.length) return NONAME_DOTS;
    const lastName = words[0] + " ";
    const endNames = words.slice(1);
    const letterNames = endNames.reduce(
      (letters, name) => letters + name[0] + ". ",
      ""
    );
    return String(lastName + letterNames).trim();
  } catch (error) {
    return NONAME_DOTS;
  }
}

// полное имя или буква с точкой
function cutName(name, format) {
  if (name[0]) {
    switch (format) {
      case fmtNameShort:
        return `${name[0]}.`;
      case fmtNameFull:
        return name;
      default:
        return "";
    }
  } else return "";
}

/**
 * вывод Фамилии в виде Фамалия И.О. или Фамилия Имя Отчество из объекта с полями last_name, first_name, middle_name
 * @param {Object} nameObject - объект с полями last_name, first_name, middle_name
 * @param {String} format - формат ФИО
 * @returns {String}
 */
export function formatName(nameObject, format = fmtNameFull) {
  if (
    !nameObject ||
    !nameObject.first_name ||
    !nameObject.middle_name ||
    !nameObject.last_name
  ) {
    return NONAME_DOTS;
  }
  const parts = [
    nameObject.last_name,
    cutName(nameObject.first_name, format),
    cutName(nameObject.middle_name, format),
  ];
  return parts.join(" ").trim();
}

export function userInitials(userObj) {
  return (
    (userObj.first_name
      ? userObj.first_name.substring(0, 1)
      : NONAME_FIRST.substring(0, 1)) +
    (userObj.last_name
      ? userObj.last_name.substring(0, 1)
      : NONAME_LAST.substring(0, 1))
  );
}

// формирование строки из шаблона
export function valueForTemplate(template, item) {
  switch (template) {
    case "fio":
      return formatName(item, fmtNameShort);
    case "data":
      return formatDate(item, fmtDateShort);
    case "comment":
      return `${formatName(item.author_detail, fmtNameShort)}/${formatDate(
        item.modified_at,
        fmtDateShort
      )}`;
    default:
      return item;
  }
}

// входит ли значение в границы (для условия перехода по страницам, например)
export function limitBound(x, min, max, def) {
  return x >= Math.floor(min) && x <= Math.ceil(max) ? x : def;
}

/**
 * возвращаем значение из массива или null
 * @param {Array} arr - массив
 * @param {Number} pos - позиция
 * @param {String} key - ключ элемента
 */
export function getValueObjectFromArray(arr, pos, key) {
  if (arr.length > pos) {
    return arr[pos][key];
  }

  return null;
}

// вывод успешных сообщений из store
export function dispatchSuccess(dispatch, msg) {
  return dispatch(
    "showNotify",
    {
      msg: msg,
      title: "Успех",
      variant: "success",
    },
    { root: true }
  );
}

// вывод сообщений об ошибках из store
export function dispatchError(dispatch, error) {
  dispatch(
    "showNotify",
    {
      msg: error.message,
      title: "Ошибка",
      variant: "danger",
    },
    { root: true }
  );
}

export const shortSubstringDropdown = 25; // длина текста для списков dropdown
export const longSubstringDropdown = 100; // большая длина текста для списков dropdown
export const substringPreviousDocument = 45; // длина текста для выбора предыдущих версий документа
export const countShowPersons = 3; // количество отображаемых ФИО
// ВНИМАНИЕ содержимое этих строк с форматами ни на что не влияет, реальный формат задается функцей text/formatName
export const fmtNameShort = "Фамилия И. О.";
export const fmtNameFull = "Фамилия Имя Отчество";
export const CONST_NONAME = "<без названия>";
export const NONAME_SHORT = "?";
export const NONUMBER = "<N/A>";
export const NONAME_DOTS = "";
export const NONAME_FIRST = "Имя";
export const NONAME_LAST = "Фамилия";
export const NONAME_MIDDLE = "Отчество";
export const INDENT_TREE = 20; // оступ в px для рекурусивного дерева (справочники)
export const SITE_NAME =
  "Единая цифровая антикоррупционная комплаенс-платформа";
export const OWNER_NAME = getOrgName();
export const UNTIL_NOW = "По настоящее время";
export const CODE_TO_EMAIL = "Email"; // код эцп или ссылка для входа отправлен на email
export const hlineDefaultSort = null;
export const selectAll = [{ value: null, text: "Все" }];

// источники подачи сообщений горячей линии
export const sourceHotline = [
  "Почтовая связь/нарочно",
  "Электронная почта",
  "Телефон/устно",
  "МОСЭДО",
];

// task 185 - внутри сообщения есть поле "Тип сообщения" - переименовать на "Категория сообщения" и в выпадающем списке соответственно "Категория 1, категория 2, категория 3"
// task 269 - переименовать значение столбца "категория": 1 - Управление комплаенса, 2 - Отдел безопасности, 3 - Заместителя генерального директора
// task 305 - в справочнике типов сообщений на ГЛ поменять «Отдел безопасности» на «Управление безопасности»
export const typeMessageHotline = {
  1: "Управление комплаенса",
  2: "Управление безопасности",
  3: "Заместителя генерального директора",
};

// тип автора сообщения горячей линии
export const typeAuthorHotline = [
  "Бывший работник",
  "Работник",
  "Деловой партнёр",
  "Физическое лицо",
  "Юридическое лицо",
];

// уровни рисков деловых партнеров
export const riskLevelPartners = {
  1: "Низкий",
  2: "Средний",
  3: "Высокий",
};

// основания внутренних проверок
export const innerCheckReason = {
  foundations_coi: "Конфликт интересов",
  foundations_message: "Сообщение горячей линии",
  foundations_cp: "Контрольная процедура",
};

// основания для горячей линии
export const hlineReason = {
  foundations_cp: "Контрольная процедура",
  foundations_innercheck: "Внутренняя проверка",
};

// основания для КП
export const cpReason = {
  foundations_coi: "Конфликт интересов",
  foundations_message: "Сообщение горячей линии",
  foundations_innercheck: "Внутренняя проверка",
  foundations_coid: "ЭДКИ",
};

// мероприятия внутренних проверок
export const innerCheckMeasures = {
  1: "Дисциплинарное взыскание",
  2: "Материальная ответственность",
  3: "Обучение",
  4: "Контроль",
  5: "Иные",
  6: "Нет",
};
export const MEASURES_NO = "Нет"; // нет принятых мер для ВП

// статусы резервных копий backup
export const stateBackup = {
  created: "Создана",
  error: "Ошибка",
};

// типы отчетов для деклараций
export const typeOptionsDecl = [
  { value: "statsEDKI", text: "Статистика по ЭДКИ" },
  { value: "coiEDKI", text: "ЭДКИ с КИ" },
  //{ value: "timingControl", text: "Контроль сроков" },
  //{ value: "cpReport", text: "Отчет по КП" },
  //{ value: "cpInfo", text: "Непредоставление данных о КИ" },
];

// типы отчетов для рисков
export const typeOptionsRisk = [
  { value: "riskList", text: "Реестр рисков" },
  { value: "activityPlan", text: "План мероприятий" },
];

// типы отчетов для горячей линии
export const typeOptionsHline = [
  { value: "report_stats", text: "Статистика горячей линии" },
  { value: "report_overdue", text: "Просроченные сообщения" },
];

// типы сортировок
export const optionsDesc = [
  { value: true, text: "по убыванию" },
  { value: false, text: "по возрастанию" },
];

// типы материалов обучения
export const optionsEducMaterials = {
  text: "Описание",
  presentation: "Презентация",
  attachment: "Файл",
  video: "Видео",
};

// типы программ обучения
export const optionsEducType = [
  { value: "external", text: "Внешнее" },
  { value: "internal", text: "Внутреннее" },
];

// типы форм обучения
export const optionsEducFormat = [
  { value: "offline", text: "Очно" },
  { value: "online", text: "Онлайн" },
];

// типы эцп для подтвержения документов - т.к. разные запросы к бэку
export const signTypes = {
  profile: "Личный листок",
  declaration: "Декларация",
  education: "Обучение",
  test: "Тестирование",
  knowledge: "Ознакомление",
};

// для select Да/Нет. много где применяется
export const optionYesNo = [
  { value: false, text: "Нет" },
  { value: true, text: "Да" },
];

// преобразование уровней риска в стили bootstrap
export function typeRiskLevel(value) {
  switch (value) {
    case 1:
      return "success";
    case 2:
      return "warning";
    case 3:
      return "danger";
    default:
      return null;
  }
}

// релевантность категорий сообщения горячей линии
export function relevantHlText(value) {
  return value ? "Релевантно" : "Не релевантно";
}

// тип ответа на вопрос теста
export const answerType = {
  singlechoice: "один из предложенных",
  multichoice: "несколько из предложенных",
  open: "текстовый",
};

// верный / неверный ответ
export const answerResult = {
  correct: "Верно",
  incorrect: "Не верно",
};
export const notVerified = "Не проверено";
