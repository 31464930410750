import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "31. Паспорт гражданина Российской Федерации"
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "3",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Серия"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.pass_series,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.pass_series = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('pass_series')),
          state: _ctx.pass_series_state,
          disabled: $options.readonly,
          trim: "",
          type: "number"
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "3",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Номер"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.pass_num,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.profileObj.pass_num = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.submitData('pass_num')),
          disabled: $options.readonly,
          state: _ctx.pass_num_state,
          trim: "",
          type: "number"
        }, null, 8, ["modelValue", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Кем выдан"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.pass_issuer,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.profileObj.pass_issuer = $event),
          onChange: _cache[5] || (_cache[5] = $event => $options.submitData('pass_issuer')),
          disabled: $options.readonly,
          state: _ctx.pass_issuer_state,
          trim: ""
        }, null, 8, ["modelValue", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "4",
      xl: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата выдачи",
        date: _ctx.pass_issue_date,
        disabled: $options.readonly,
        classCustom: "mt-2 no-gutters",
        state: _ctx.pass_issue_date_state,
        onChangeValue: _cache[6] || (_cache[6] = $event => $options.submitData('pass_issue_date', $event))
      }, null, 8, ["date", "disabled", "state"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      xl: "3"
    }), _createVNode(_component_b_col, {
      cols: "3",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Код подразделения"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.pass_issuer_code = $event),
          type: "text",
          disabled: $options.readonly,
          maxlength: "7",
          class: _normalizeClass(["form-control", (_ctx.pass_issuer_code_state === false ? 'is-invalid' : '', _ctx.pass_issuer_code_state === true ? 'is-valid' : '')]),
          onChange: _cache[8] || (_cache[8] = $event => $options.submitData('pass_issuer_code'))
        }, null, 42, _hoisted_1), [[_vModelText, _ctx.pass_issuer_code]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}