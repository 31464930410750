import { extractKeysWithRequiredValue } from "@/helpers/global";
export default {
  name: "AdminElementAdd",
  props: {
    settings: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    currentItem: {},
    states: {},
    checkbox: false,
    isAllRequiredFilled: false
  }),
  computed: {
    requiredKeys: function () {
      return extractKeysWithRequiredValue(this.settings);
    }
  },
  watch: {
    currentItem: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          if (!Object.values(newValue).includes("") && this.requiredKeys.every(elem => Object.keys(newValue).includes(elem))) {
            this.isAllRequiredFilled = true;
          } else {
            this.isAllRequiredFilled = false;
          }
        }
      }
    }
  },
  methods: {
    // изменение объекта
    clickChange() {
      this.currentItem = {
        ...this.item
      };
      Object.keys(this.settings.texts).forEach(key => {
        this.states[key] = null;
      });
      this.checkbox = this.item[this.settings.switchName] || false;
      this.showModal = true;
    },
    // обработка сохранения
    handlerOk(bvModalEvt) {
      const params = {};
      for (const key of Object.keys(this.settings.texts)) {
        if (!this.validateText(key)) {
          bvModalEvt.preventDefault();
          return;
        }
        if (this.item[key] !== this.currentItem[key]) {
          params[key] = this.currentItem[key];
        }
      }
      if (this.settings.selectors) {
        for (const key of Object.keys(this.settings.selectors)) {
          if (this.item[key] !== this.currentItem[key]) {
            params[key] = this.currentItem[key];
          }
        }
      }
      if (this.settings.switchName && this.checkbox !== this.item[this.settings.switchName]) {
        params[this.settings.switchName] = this.checkbox;
      }
      if (Object.keys(params).length) {
        this.$emit("update", {
          params,
          item: this.item
        });
      }
      this.showModal = false;
    },
    // валидация текста
    validateText(key) {
      if (!this.settings.texts[key].required) return true;
      const validText = this.currentItem[key].trim() !== "";
      this.states[key] = !validText ? validText : null;
      return validText;
    }
  }
};