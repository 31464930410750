export const ntfKeys = [
  "bpartner",
  "bpcheck",
  "coid",
  "course",
  "course_assignment",
  "hotmsg",
  "inch",
  "libmat",
  "libmat_assignment",
  "matrix",
  "profile",
];
