import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "InnerCheckFormComments",
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "created_at",
      label: "",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap px-3 text-muted"
    }, {
      key: "author_detail",
      label: "",
      formatter: value => formatName(value, fmtNameShort),
      class: "text-nowrap"
    }, {
      key: "body",
      label: ""
    }]
  }),
  props: {
    msg: {
      type: String,
      required: true
    } // id ВП
  },

  created() {
    this.updateInnerCheckComments({
      id: this.msg
    });
  },
  methods: {
    ...mapActions("innerCheck", ["updateInnerCheckComments"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.innerCheckCommentCount / this.perPage, this.currentPage);
      this.updateInnerCheckComments({
        page: this.currentPage,
        id: this.msg
      });
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckComment", "innerCheckCommentCount", "innerCheckCommentLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateInnerCheckComments({
          page: value,
          id: this.msg
        });
      }
    }
  }
};