import links from "@/router/links";
import perm from "@/auth/permissions.js";
import { ff } from "@/helpers/global";

const personalAreaCards = {
  1: {
    enabled: ff.KNOWLEDGE,
    name: "knowledge",
    icon: "icon-knowledge",
    title: "Библиотека знаний",
    subtitle: "Всего документов",
    link: links.knowledge,
    perm: perm.view_wa_libmaterial,
  },
  2: {
    enabled: ff.EDUCATION,
    name: "education",
    icon: "icon-education",
    title: "Обучение",
    subtitle: "Всего документов",
    link: links.education,
    perm: perm.view_wa_course,
  },
  3: {
    enabled: ff.DECLARATION,
    name: "declarations",
    icon: "icon-declaration",
    iconType: "far",
    title: "Декларации",
    subtitle: "Всего деклараций",
    link: links.declarations,
    perm: perm.view_wa_coid,
  },
  4: {
    enabled: ff.INNERCHECK,
    name: "innerchecks",
    icon: "icon-innercheck",
    title: "Внутренние проверки",
    subtitle: "Всего проверок",
    link: links.innerCheck,
    perm: perm.view_wa_innercheck,
  },
  5: {
    enabled: ff.RISK,
    name: "risks",
    icon: "icon-risk",
    title: "Оценка рисков",
    subtitle: "Всего рисков",
    link: links.risk,
    perm: perm.view_wa_matrix,
  },
  6: {
    enabled: ff.PARTNERS,
    name: "business_partners",
    icon: "icon-partners",
    title: "Проверка деловых партнёров",
    subtitle: "Всего партнёров",
    link: links.partners,
    perm: perm.view_wa_bp,
  },

  7: {
    enabled: ff.HOTLINE,
    name: "hotline",
    icon: "icon-hotline",
    title: "Горячая линия",
    subtitle: "Всего сообщений",
    link: links.hotline,
    perm: perm.view_wa_hotmsg,
  },
  8: {
    enabled: ff.ADMINISTRATION,
    name: "administration",
    icon: "icon-administration",
    title: "Администрирование",
    link: links.administration,
    perm: perm.access_admin,
  },
  9: {
    enabled: ff.CP,
    name: "control_procedures",
    icon: "icon-innercheck",
    title: "Контрольные процедуры",
    link: links.cpBase,
    perm: perm.view_wa_cp,
  },
};

export default Object.keys(personalAreaCards).reduce((acc, key) => {
  if (personalAreaCards[key].enabled) {
    acc[key] = personalAreaCards[key];
  }
  return acc;
}, {});
