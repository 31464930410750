import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
import { getOrgName } from "@/auth/auth";
export default {
  name: "TwentyFirst",
  data: () => ({
    debtState: null,
    optionYesNo
  }),
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData() {
      this.debtState = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_past_due_debts: this.profileObj.has_past_due_debts
      }).then(() => {
        setValueTimeout(this, "debtState");
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    },
    orgName() {
      return getOrgName();
    }
  }
};