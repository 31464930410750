export default {
  name: "BreadCrumbToHome",
  props: {
    breadCrumb: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({}),
  computed: {
    breadcrumbs() {
      const startItem = [{
        text: "Панель управления",
        to: {
          name: "PersonalArea"
        }
      }];
      const endItem = [{
        html: '<small><i class="fas fa-level-down-alt"></i></small>',
        active: true
      }];
      return startItem.concat(this.breadCrumb, endItem);
    }
  }
};