import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { optionsEducType, optionsEducFormat, fmtNameFull, formatName } from "@/helpers/text";
import { formatDateObj, fmtDate, formatDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import { option4select } from "@/helpers/global";
import ImageUpload from "@/components/common/ImageUpload";
import { invalidMinMaxDate } from "@/helpers/validate";
export default {
  name: "ProgramAddDescription",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    optionsEducType,
    optionsEducFormat,
    fmtNameFull,
    propItem: {},
    invalidStartDate: false,
    invalidEndDate: false
  }),
  components: {
    Calendar,
    UserSelector,
    ImageUpload
  },
  created() {
    this.updateData();
  },
  methods: {
    ...mapActions("education", ["changeCourse"]),
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    clickAddCertificateFrame() {
      this.$refs.certFrameInput.click();
    },
    updateData() {
      this.invalidStartDate = false;
      this.invalidEndDate = false;
      this.propItem = {
        ...this.item
      };
    },
    async changeValue(key) {
      const params = {
        [key]: this.propItem[key]
      };
      if (key === "format" && this.propItem.format === "online") params.location = null;
      await this.changeCourse({
        id: this.propItem.id,
        params
      });
      this.updateData();
    },
    selectedUser(key, user) {
      this.propItem[key] = user.id;
      this.changeValue(key);
    },
    async changeDate(value, apiKey) {
      this.invalidStartDate = false;
      this.invalidEndDate = false;
      const date = formatDateObj(value, fmtDate, null);
      // «Дата начала обучения» не позднее «Даты окончания обучения»
      // «Дата окончания обучения» не ранее даты внесения сведений
      if (apiKey === "start_date") {
        this.invalidStartDate = invalidMinMaxDate(date, null, this.item.end_date);
      }
      if (apiKey === "end_date") {
        this.invalidStartDate = invalidMinMaxDate(this.item.start_date, null, date);
        this.invalidEndDate = invalidMinMaxDate(date, formatDate(new Date(), fmtDate), null);
      }
      if (this.invalidStartDate || this.invalidEndDate) return;
      if (date) {
        await this.changeCourse({
          id: this.propItem.id,
          params: {
            [apiKey]: date
          }
        });
        this.updateData();
      }
    },
    async addFile(data) {
      await this.changeCourse({
        id: this.propItem.id,
        fileUpload: true,
        params: data
      });
      this.updateData();
    },
    async deleteFile(field) {
      await this.changeCourse({
        id: this.propItem.id,
        params: {
          [field]: null
        }
      });
      this.updateData();
      this.propItem[field] = null;
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departmentsLoading", "departments"]),
    ...mapGetters("education", ["educationCourseLoading"]),
    departOptions() {
      return option4select(this.departments, false);
    },
    instructorInternal() {
      return formatName({
        first_name: this.propItem.internal_instructor_first_name,
        last_name: this.propItem.internal_instructor_last_name,
        middle_name: this.propItem.internal_instructor_middle_name
      }, fmtNameFull);
    }
  }
};