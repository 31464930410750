export default {
  name: "CoursePreview",
  props: {
    item: {
      typeof: Object,
      required: true
    }
  },
  data: () => ({
    noDescription: "<без описания>"
  })
};