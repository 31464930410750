import { mapActions, mapGetters } from "vuex";
import { yearsGenerator } from "@/helpers/date";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
export default {
  name: "theFifth",
  data: () => ({
    academic_rank: null,
    academic_rank_state: null,
    readonly: true,
    profileObjSaved: {} // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
  }),

  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.academic_rank = this.profileObj.academic_rank;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    submitData(key) {
      const value = this[key];
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    endYearsGenerator() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    },
    startYearsGenerator() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    }
  }
};