import { fetchAPI } from "@/helpers/fetch";
import mutations from "@/store/mutations";
import { wrapCommitDispatch } from "@/helpers/global";
import { ntfKeys } from "@/helpers/notify";
import { checkPermissions } from "@/auth/auth";
import perm from "@/auth/permissions";

function additionNtf(arr) {
  arr.forEach((ntf) => {
    const key = ntfKeys.find((el) => ntf[el]);
    ntf.obj = key;
  });
  return arr;
}

const {
  // stats
  STATS,
  STATS_LOADING,
  STATS_NEW,
  // notify
  NEWNOTIFYLK,
  NOTIFYLK_LIST,
  NOTIFYLK_COUNT,
  NOTIFYLK_LOADING,
} = mutations;

const statsStore = {
  namespaced: true,
  state: {
    // stats
    stats: {},
    statsLoading: false,
    onlyNew: null,
    // notify
    newNotifyLk: {},
    notifyLkList: [],
    notifyLkCount: 0,
    notifyLkLoading: false,
  },
  getters: {
    // stats
    stats: ({ stats }) => stats,
    statsLoading: ({ statsLoading }) => statsLoading,
    onlyNew: ({ onlyNew }) => onlyNew,
    // notify
    newNotifyLk: ({ newNotifyLk }) => newNotifyLk,
    notifyLkList: ({ notifyLkList }) => notifyLkList,
    notifyLkCount: ({ notifyLkCount }) => notifyLkCount,
    notifyLkLoading: ({ notifyLkLoading }) => notifyLkLoading,
  },
  mutations: {
    // stats
    [STATS](state, value) {
      state.stats = value;
    },
    [STATS_LOADING](state, value) {
      state.statsLoading = value;
    },
    [STATS_NEW](state, value) {
      state.onlyNew = value;
    },
    // notify
    [NEWNOTIFYLK](state, value) {
      state.newNotifyLk = value;
    },
    [NOTIFYLK_LIST](state, value) {
      state.notifyLkList = value;
    },
    [NOTIFYLK_COUNT](state, value) {
      state.notifyLkCount = value;
    },
    [NOTIFYLK_LOADING](state, value) {
      state.notifyLkLoading = value;
    },
  },
  actions: {
    // stats
    // запрос статистики
    async updateStats(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            "/wa-stats",
            null,
            "GET",
            {},
            false,
            false
          );
          context.commit(STATS, response);
        },
        STATS_LOADING
      );
    },
    // установка параметра onlyNew
    setOnlyNew({ commit }, value) {
      commit(STATS_NEW, value);
    },
    // notify
    // обновление "колокольчика" сообщений ЛК
    async updateNewNotifyLk(context) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(context, async () => {
        const response = await fetchAPI(
          "/notifications/new/",
          null,
          "GET",
          {},
          false,
          false
        );
        context.commit(NEWNOTIFYLK, response);
      });
    },
    // обновление списка уведомлений
    async updateNotifyLk(context, data) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/notifications/", data);
          context.commit(NOTIFYLK_LIST, additionNtf(response.results));
          context.commit(NOTIFYLK_COUNT, response.count);
        },
        NOTIFYLK_LOADING
      );
    },
    // прочитали конкретное сообщение
    async updateNotifyLk4m(context, data) {
      if (!checkPermissions(perm.access_account)) return; // нет прав на ЛК
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/notifications/${data.id}/`);
        },
        NOTIFYLK_LOADING
      );
    },
  },
};

export default statsStore;
