import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
export default {
  name: "theThirtieth",
  data: () => ({
    phone_home_state: null,
    phone_mob_state: null,
    email_state: null,
    profileObjSaved: {} // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
  }),

  created() {
    this.profileObjSaved.phone_home = this.profileObj.phone_home;
    this.profileObjSaved.phone_mob = this.profileObj.phone_mob;
    this.profileObjSaved.email = this.profileObj.email;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    ...mapActions(["showNotify"]),
    submitData(key) {
      let value = this.profileObjSaved[key];
      let valid = true;
      value = this.profileObj[key];
      if (this.profileObjSaved[key] === value) return; // изменений не было

      const state = `${key}_state`;
      this[state] = false;
      if (!valid) {
        this.showNotify({
          msg: "Неверное значение",
          title: "Ошибка пользователя ",
          variant: "danger"
        });
        return;
      }
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    },
    submitDataEndpoint(obj, key, savekey, endpoint) {
      const value = obj[key];
      if (this.profileObjSaved[savekey][obj.id][key] === value) return; // изменений не было
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint,
        data: template
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};