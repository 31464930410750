import { formatDate, fmtDateTime } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
import thePersona from "@/components/Helpers/thePersona";
export default {
  name: "ProgramEvents",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fmtDateTime,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [{
      key: "event",
      label: "Событие"
    }, {
      key: "timestamp",
      label: "Дата/время",
      class: "font-mono"
    }, {
      key: "worker",
      label: "Работник",
      class: "text-nowrap"
    }]
  }),
  components: {
    thePersona
  },
  methods: {
    ...mapActions("education", ["updateEvents"]),
    formatName,
    formatDate,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.courseEventsCount / this.perPage, this.currentPage);
      this.updateEvents({
        page: this.currentPage,
        course_id: this.item.id
      });
    }
  },
  computed: {
    ...mapGetters("education", ["courseEvents", "courseEventsCount", "courseEventsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateEvents({
          page: value,
          course_id: this.item.id
        });
      }
    }
  }
};