import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { periodOptions } from "@/helpers/date";
export default {
  name: "AdminEducation",
  data: () => ({
    form: {
      daysInner: 10,
      daysOuter: 10,
      period: "month",
      organisation: "all",
      department: "all",
      type: "statsEducation"
    },
    typeOptions: [{
      value: "statsEducation",
      text: "Статистика по обучению"
    }],
    periodOptions,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    await this.updateEducation();
    await this.updateOrganizations();
    await this.updateDepartments();
    this.form.daysInner = this.adminEducation.daysInner;
    this.form.daysOuter = this.adminEducation.daysOuter;
    this.form.type = this.adminEducation.type;
    this.form.period = this.adminEducation.period;
    this.form.organisation = this.adminEducation.organisation;
    this.form.department = this.adminEducation.department;
  },
  methods: {
    ...mapActions("administration", ["updateEducation", "changeEducation"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateDepartments"]),
    async clickSave() {
      this.changeEducation(this.form);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminEducation", "adminEducationLoading"]),
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    },
    orgOptions() {
      return Object.keys(this.organizations).reduce((acc, curr) => {
        acc.push({
          value: this.organizations[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    }
  }
};