import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      class: "tin"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "30. Телефон дом."
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.phone_home,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.phone_home = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('phone_home')),
          state: _ctx.phone_home_state,
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      class: "cellphone"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "моб."
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.phone_mob,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.profileObj.phone_mob = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.submitData('phone_mob')),
          state: _ctx.phone_mob_state,
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "e-mail"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.email,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.profileObj.email = $event),
          onChange: _cache[5] || (_cache[5] = $event => $options.submitData('email')),
          state: _ctx.email_state,
          disabled: $options.readonly,
          type: "email",
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}