import { mapGetters, mapActions } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
import { stateWorker } from "@/helpers/states";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminUsersAdd from "./AdminUsersAdd.vue";
import { getOrgId, getOrgName } from "@/auth/auth";
export default {
  name: "AdminUsers",
  data: () => ({
    showModalRemove: false,
    currentItem: {},
    fmtNameShort,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    sort: null,
    tableFields: [{
      key: "problem",
      label: ""
    }, {
      key: "index",
      label: "п/п"
    }, {
      key: "id",
      label: "id"
    }, {
      key: "login",
      label: "Логин",
      class: "user-login-list"
    }, {
      key: "FIO",
      label: "ФИО"
    }, {
      key: "position",
      label: "Должность"
    }, {
      key: "organization_name",
      label: "Организация"
    }, {
      key: "created_at",
      label: "Создан",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap user-date-list"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => stateWorker[value],
      class: "user-state-list"
    }, {
      key: "btnChange",
      label: "",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "",
      class: "text-center"
    }],
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    settingsAdd: {
      title: "Создать пользователя",
      btnTitle: "Создать пользователя",
      buttonIcon: false,
      iconType: null,
      new: true
    },
    settingsChange: {
      title: "Редактировать пользователя",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      new: false
    }
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    AdminUsersAdd
  },
  async created() {
    await this.updateAdminUsers({
      page: 1,
      sort: this.sort
    });
    await this.updateOrganizations();
    await this.updateAdminGroups();
    await this.updateAdminRolesRef();
  },
  methods: {
    ...mapActions("administration", ["updateAdminUsers", "createAdminUser", "removeAdminUser", "changeAdminUser", "changePasswordUser", "updateAdminGroups", "updateAdminRolesRef"]),
    ...mapActions("referenceBooks", ["updateOrganizations"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.adminUsersCount / this.perPage, this.currentPage);
      this.updateAdminUsers({
        page: this.currentPage,
        sort: this.sort
      });
    },
    async clickCreateUser(data) {
      await this.createAdminUser(data);
      this.currentPage = 1;
      await this.updateAdminUsers({
        page: 1,
        sort: this.sort
      });
    },
    async clickRemove(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.removeAdminUser(this.currentItem);
      this.currentPage = 1;
      await this.updateAdminUsers({
        page: 1,
        sort: this.sort
      });
    },
    async clickChange(data) {
      await this.changeAdminUser(data);
      await this.updateAdminUsers({
        page: this.currentPage,
        sort: this.sort
      });
    },
    async updatePassword(data) {
      await this.changePasswordUser(data);
      await this.updateAdminUsers({
        page: this.currentPage,
        sort: this.sort
      });
    }
  },
  computed: {
    ...mapGetters("administration", ["adminUsers", "adminUsersLoading", "adminUsersCount", "adminOrgLoading", "adminGroupsLoading", "adminRolesRefLoading"]),
    ...mapGetters("referenceBooks", ["organizationsLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateAdminUsers({
          page: value,
          sort: this.sort
        });
      }
    },
    templateAdd() {
      return {
        organization: getOrgId(),
        organization_name: getOrgName()
      };
    }
  }
};