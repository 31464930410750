import { mapGetters, mapActions } from "vuex";
import { checkPermissions } from "@/auth/auth.js";
import { states } from "@/helpers/states";
import { switchText } from "@/helpers/text";
export default {
  name: "COIFormSource",
  data: () => ({}),
  methods: {
    ...mapActions("coi", ["changeCOIForm"]),
    ...mapActions("innerCheck", ["updateInnerCheckForm", "dropICForm"]),
    ...mapActions("hotline", ["updateHlineMsg", "dropHlForm"]),
    ...mapActions(["showNotify"]),
    switchText,
    async changeData(key) {
      const id = this.coiForm[key];
      if (key === "hotmsg") {
        await this.dropHlForm();
        await this.updateHlineMsg(id);
        if (!this.hlineMsg.id) {
          this.showNotify({
            msg: `Сообщение горячей линии #${id} недоступно`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          this.coiForm[key] = null;
          return;
        }
      } else if (key === "innercheck") {
        await this.dropICForm();
        await this.updateInnerCheckForm(id);
        if (!this.innerCheckForm.id) {
          this.showNotify({
            msg: `Внутренняя проверка #${id} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          this.coiForm[key] = null;
          return;
        }
      }
      this.changeCOIForm({
        id: this.coiForm.id,
        params: {
          [key]: id
        }
      });
    }
  },
  computed: {
    ...mapGetters("coi", ["coiForm", "coiFormChangeLoading"]),
    ...mapGetters("hotline", ["hlineMsg", "hlineMsgLoading"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckFormLoading"]),
    readOnly() {
      return states[this.coiForm.state] !== states.draft || !checkPermissions(this.$perm.add_wa_coi) || this.coiFormChangeLoading || this.hlineMsgLoading || this.innerCheckFormLoading;
    }
  }
};