import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import theNotification from "@/components/theNotification";
import KnowledgeDocuments from "./KnowledgeDocuments";
import KnowledgeArchive from "./KnowledgeArchive";
import KnowledgeReports from "./Reports/KnowledgeReport.vue";
import SectionAdd from "./SectionAdd.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
export default {
  name: "KnowledgeList",
  components: {
    BreadCrumbToHome,
    KnowledgeDocuments,
    KnowledgeArchive,
    theNotification,
    KnowledgeReports,
    SectionAdd
  },
  props: {
    msg: String
  },
  // сюда получаем id раздела

  data: () => ({
    modalSubsection: "",
    setting: {
      btn: "Редактировать раздел",
      title: "Редактировать раздел"
    }
  }),
  async created() {
    await this.updateSectionForm(this.msg);
    this.setting.modalId = "modalChangeSection" + this.msg;
    const params = {
      page: 1,
      section_id: this.msg
    };
    if (this.onlyNew) params.is_new = true;
    if (this.isAltUI) params.alt = true;
    await this.updateDocuments(params);
    await this.updateArchive(params);
    this.updateDepartments();
    this.setOnlyNew(null); // обнулимся
  },

  computed: {
    ...mapGetters("knowledge", ["sectionForm", "sectionFormLoading", "sectionsDocumentsCount", "sectionsArchiveCount", "knowlegeDocument"]),
    ...mapGetters("homeStats", ["onlyNew"]),
    breadcrumbs() {
      return this.isAltUI ? [{
        text: "Библиотека знаний",
        to: {
          name: "KnowledgeAlt"
        }
      }] : [{
        text: "Библиотека знаний",
        to: {
          name: "Knowledge"
        }
      }];
    },
    isAltUI() {
      return this.$route.path.includes(links.knowledgeListAlt);
    }
  },
  methods: {
    ...mapActions("knowledge", ["changeSection", "updateSectionForm", "updateDocuments", "createDocument", "updateArchive"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    clickChange(params) {
      this.changeSection({
        params,
        id: this.msg
      });
    },
    async clickAdd() {
      await this.createDocument({
        section: this.msg
      });
      if (this.knowlegeDocument.id) this.$router.push(links.knowledgeForm + this.knowlegeDocument.id);
    }
  }
};