import "core-js/modules/es.array.push.js";
import links from "@/router/links";
import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateTime } from "@/helpers/date";
import { signTypes } from "@/helpers/text";
import { profileReadonly, requiredFields, profileInvalidValue } from "@/helpers/profile";
import SignPEP from "@/components/Helpers/SignPEP.vue";
export default {
  name: "ProfileActions",
  components: {
    SignPEP
  },
  props: {
    profileId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    signTypes,
    links,
    fmtDateTime,
    btnSendDisabled: false,
    stepConfirm: 0,
    showModalConfirm: false,
    confirms: [{
      title: "Согласие на обработку персональных данных",
      text: "В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» подтверждаю достоверность сведений, изложенных в настоящем листке по учету кадров, и добровольно выражаю свое согласие на осуществление организацией обработки (сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (распространения, предоставления, доступа), обезличивания, блокирования, удаления, уничтожения персональных данных, в том числе автоматизированным способом, своих персональных данных",
      btnOk: "Подтверждаю"
    }, {
      title: "Согласие на передачу данных третьим лицам",
      text: "Отраженные в настоящем личном листке по учету кадров персональные данные могут быть переданы третьим лицам только с письменного согласия субъекта персональных данных или в случаях, предусмотренных законодательством Российской Федерации. Я ознакомлен(а), что настоящее согласие действует со дня его подписания и до дня отзыва в письменной форме",
      btnOk: "Ознакомлен(a)"
    }, {
      title: "Согласие сообщать о всех изменениях",
      text: "Я осознаю и соглашаюсь с условием, что представление недостоверной информации может являться основанием для отказа в приеме на работу либо расторжения заключенного трудового договора. Я ознакомлен(а), что работник, заполняющий личный листок, обязан обо всех последующих изменениях сведений, указанных в личном листке сообщать по месту работы для внесения этих изменений в его личную карточку (личное дело)",
      btnOk: "Ознакомлен(a)"
    }, {} // фиктивный блок, чтобы не вылетала ошибка
    ],

    showModalRequiredFields: false,
    showModalInvalidDate: false,
    requiredFieldsText: "",
    invalidDateText: ""
  }),
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    ...mapActions("administration", ["updateProfileSheetSettings"]),
    formatDate,
    // проверка обязательного поля
    checkField(value, field, fields, parent = "") {
      if (value === null || value === undefined || String(value).trim() === "") fields.push(parent + field);
    },
    // проверка на заполнение обязательных полей в ЛЛ
    async updateStatus() {
      // обновим информацию перед проверкой
      await this.fetchProfile({
        id: this.profileId,
        myProfile: true
      });
      await this.updateProfileSheetSettings();
      const config = this.profileSheetSettings.config || {};
      // валидация дат
      const invalidDate = profileInvalidValue(this.profileObj);
      if (invalidDate) {
        this.invalidDateText = invalidDate.join("\n");
        this.showModalInvalidDate = true;
        return false;
      }
      // проверка заполнения обязательных полей
      const fields = [];
      let value = null;
      Object.keys(requiredFields).forEach(key => {
        const requiredKey = requiredFields[key];
        if (config[key]) {
          if (requiredKey?.typeObject) {
            // обработка типов typeObject = галочка и внутри объект (массив)
            if (requiredKey?.checkEmpty && !this.profileObj[requiredKey.checkEmpty]) {
              // галочку не поставили, тогде ничего не делаем
            } else if (requiredKey?.checkEmpty && this.profileObj[requiredKey.checkEmpty] && this.profileObj[key].length === 0) {
              // галочку поставили, а данные не ввели
              Object.entries(requiredKey.fields).forEach(([k, v]) => {
                if (config[key].includes(k)) fields.push(requiredKey.name + v);
              });
            } else {
              const parent = requiredKey.name;
              const checkFields = Object.keys(requiredKey.fields); // массив обязательных полей из настроек
              const profile = this.profileObj[key] || []; // массив объектов/записей из профайла
              profile.forEach(el => {
                // обходим поля элемента
                Object.keys(el).forEach(elKey => {
                  if (checkFields.includes(elKey) && config[key].includes(elKey)) {
                    // т.е. поле обязательное
                    value = el[elKey];
                    this.checkField(value, requiredKey.fields[elKey], fields, parent);
                  }
                });
              });
            }
          } else if (requiredKey?.typeOne) {
            // обработка типов typeOne = галочка и внутри не объект
            if (requiredKey?.checkEmpty && !this.profileObj[requiredKey.checkEmpty]) {
              // галочку не поставили, тогде ничего не делаем
            } else {
              value = this.profileObj[key];
              this.checkField(value, requiredKey.name, fields);
            }
          } else {
            // все остальные одиночные поля типа ФИО, пол и т.п.
            value = this.profileObj[key];
            this.checkField(value, requiredKey, fields);
          }
        }
      });
      if (fields.length) {
        this.requiredFieldsText = fields.join(", ");
        this.showModalRequiredFields = true;
        return false;
      }
      // согласия
      this.stepConfirm = 0;
      this.showModalConfirm = true;
      this.btnSendDisabled = true;
    },
    doConfirm() {
      this.stepConfirm = this.stepConfirm + 1;
      if (this.stepConfirm > 2) {
        this.btnSendDisabled = false;
        this.$refs.btnPEP.handlerClick();
      } else setTimeout(() => {
        this.showModalConfirm = true;
      }, 500);
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("administration", ["profileSheetSettings"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};